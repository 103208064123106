import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';

import AuthService from "../services/auth.service";
import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW";

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const TabStudentClass = React.lazy(() => import('../classAppComponents/TabStudentClass'));

class PageShowStudentStat extends React.Component {

  state = {
    currentUser: AuthService.getCurrentUser(),
    title: "RezerwujKort.pl - Szkółki tenisowe",
    restStudentData: {
      successful: false,
      status: '',
      message: '',
      data: []
    },
    restStudentTotalPriceStatData: {
      successful: false,
      status: '',
      message: '',
      data: []
    }
  }

  async fechGetStudent(url) {

    var data;

    data = await FetchGetDataWithAuthNEW(url);
    if (data) {
      this.setState({ restStudentData: data });
    }
  }

  async fechGetStudentTotalPriceStat(url) {

    var data;

    data = await FetchGetDataWithAuthNEW(url);
    if (data) {
      this.setState({ restStudentTotalPriceStatData: data });
    }
  }

  async componentDidMount() {


    var url = "/class_app/" + this.props.match.params.club + "/admin/student/show/" + this.props.match.params.id;

    await this.fechGetStudent(url);

    var urlPrice = "/class_app/" + this.props.match.params.club + "/admin/user_price_total/" + this.props.match.params.id;
    console.log(" turlPrice: " + urlPrice); //usun
    await this.fechGetStudentTotalPriceStat(urlPrice);

    document.title = this.state.title;

  }

  render() {

    var beltClassApp = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp club={this.props.match.params.club} />
      </Suspense>
    </div>

    var tabStudentClass = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <TabStudentClass
          club={this.props.match.params.club}
          studentId={this.props.match.params.id}
        />
      </Suspense>
    </div>

    return (

      <div>
        {beltClassApp}

        <div id="content">
          <div class="container">

            

            <br />
            <h5><b>Szczegóły klienta</b></h5>

            Imie: {this.state.restStudentData.data.name}<br />
            Nazwisko: {this.state.restStudentData.data.surname}<br />
            Email: {this.state.restStudentData.data.email}<br />
            Kiedy dodany: {this.state.restStudentData.data.createdAt}<br /><br />
            <b>Płatności:</b>
            <br />
            Łącznie zobowiązań:<br />
            Łącznie wpłat:
            <br /><br />
            Zajęcia w których zapisany jest uczestnik:
            <br /><br />

            {tabStudentClass}

            <br /><br />
          </div>
        </div>

      </div>
    );
  }
}
export default PageShowStudentStat;
