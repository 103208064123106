import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';

import AuthService from "../services/auth.service";

import { Calendar, momentLocalizer } from "react-big-calendar";

import FechGetClassTrainer from "../classAppFechData/FechGetClassTrainer";
import FechGetClassNames from "../classAppFechData/FechGetClassNames";
import FechGetClassLessonDateRange from "../classAppFechData/FechGetClassLessonDateRange";
import FechGetCalendar from '../classAppFechData/FechGetCalendar'

import queryString from 'query-string'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";

//UTILS
import DateTimeNow from "../utils/DateTimeNow";

let plLocate = require('moment/locale/pl');
moment.locale('pl', plLocate)

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const SearchCalendarEvents = React.lazy(() => import('../forms/SearchCalendarEvents'));

//KLASA WYSWIETLA SZCZEGOLY KONKRETNYCH ZAJEC SZKOLKI
class PageCalendar extends React.Component {

  TRAINER_SELECT_DESC = "Wybierz trenera";
  DATE_SELECT_DESC = "Wybierz datę";
  CLASS_SELECT_DESC = "Wybierz szkółke";

  emptyItem = {
    title: '',
    start: '',
    dateStart: '',
    dateStop: '',
    trainer: '',
    classId: '',
    end: Date.parse("Aug 9, 1995"),
    desc: ""
  };

  urlValue = "";

  postData = {
    dateStart: '',
    dateStop: '',
    trainer: '',
    classId: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      logInfo: "LogDebug __" + DateTimeNow() + "__ PageCalendar ",
      title: "RezerwujKort.pl - Profil",
      currentUser: AuthService.getCurrentUser(),
      data: [],
      calendarEvents: [],
      localizer: momentLocalizer(moment),
      search: "",
      urlValue: "",
      test: [],
      newsCount: "",
      currentPage: "1",
      rowsCount: "25",
      restClassLessonDateRangeData: {
        successful: false,
        status: '',
        message: '',
        data: []
      },
      restClassTrainerData: {
        successful: false,
        status: '',
        message: '',
        data: []
      },
      restClassNamesData: {
        successful: false,
        status: '',
        message: '',
        data: []
      },
      restCalendarDataTemp: {
        successful: false,
        status: '',
        message: '',
        data: []
      }
    }

    this.onSelectEvent = this.onSelectEvent.bind(this);
  }

  async componentDidMount() { //todo dorobic aby wyszukiwal daty od pol roku wstecz do + 8mc

    const { location: { search } } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    if (this.urlValue.dateStart != this.DATE_SELECT_DESC &&
      this.urlValue.dateStart != undefined) {
      this.postData.dateStart = this.urlValue.dateStart;
    } else {
      this.postData.dateStart = "";
    }
    if (this.urlValue.dateStop != this.DATE_SELECT_DESC &&
      this.urlValue.dateStop != undefined) {
      this.postData.dateStop = this.urlValue.dateStop;
    } else {
      this.postData.dateStop = "";
    }
    if (this.urlValue.trainer != this.TRAINER_SELECT_DESC &&
      this.urlValue.trainer != undefined) {
      this.postData.trainer = this.urlValue.trainer;
    } else {
      this.postData.trainer = "";
    }
    if (this.urlValue.classId != this.CLASS_SELECT_DESC &&
      this.urlValue.classId != undefined) {
      this.postData.classId = this.urlValue.classId;
    } else {
      this.postData.classId = "";
    }

    console.log(this.state.logInfo + " componentDidMount 2a this.state.item " + JSON.stringify(this.state.item));

    var token = "";
    if (this.state.currentUser != null) {
      token = this.state.currentUser.accessToken;
    }

    this.setState({ restClassLessonDateRangeData: await FechGetClassLessonDateRange(this.props.match.params.club) });
    this.setState({ restClassTrainerData: await FechGetClassTrainer(this.props.match.params.club) });
    this.setState({ restClassNamesData: await FechGetClassNames(this.props.match.params.club) });

    var dateStart;
    if (this.postData.dateStart === "") {
      if (typeof this.state.restClassLessonDateRangeData.data.map === 'function') {
        dateStart = this.state.restClassLessonDateRangeData.data[0];
        this.postData.dateStart = this.state.restClassLessonDateRangeData.data[0];
      }
    }

    var dateStop;
    if (this.postData.dateStop == "") {
      if (typeof this.state.restClassLessonDateRangeData.data.map === 'function') {
        dateStop = this.state.restClassLessonDateRangeData.data[this.state.restClassLessonDateRangeData.data.length - 1];
        this.postData.dateStop = this.state.restClassLessonDateRangeData.data[this.state.restClassLessonDateRangeData.data.length - 1];
      }
    }

    this.state.restCalendarDataTemp = await FechGetCalendar(this.props.match.params.club, this.postData);


    if (this.state.restCalendarDataTemp.successful) {

      var calendarEventsTemp = [];
      for (var i in this.state.restCalendarDataTemp.data) {

        var event = { ...this.emptyItem };
        event['title'] = this.state.restCalendarDataTemp.data[i].title //+ i
        event['start'] = new Date(this.state.restCalendarDataTemp.data[i].start)
        event['end'] = new Date(this.state.restCalendarDataTemp.data[i].end)
        event['desc'] = this.state.restCalendarDataTemp.data[i].desc
        calendarEventsTemp.push(event);
      }
      this.setState({ calendarEvents: calendarEventsTemp })
    }

  }


  onSelectEvent() {
    //console.log(this.state.logInfo + " jeeeeee : " + JSON.stringify(this.state.urlValue));
  }

  render() {

    var calendar = "";
    if (!this.state.restCalendarDataTemp.successful) {
      calendar = <div id="loader_div">
        <Spinner animation="border" />
      </div>
    } else {
      calendar = <div>
        <Suspense fallback={<div>
          <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
        </div>}>
          <Calendar
            localizer={this.state.localizer}
            events={this.state.calendarEvents}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 800 }}
            onSelectSlot={(this.onSelectEvent)}
            onSelectEvent={(this.onSelectEvent)}
            // eventPropGetter={(this.handleChange)}
            step={60}
            messages={{
              next: "Dalej",
              previous: "Wstecz",
              today: "Dziś",
              month: "Miesiąc",
              week: "Tydzień",
              day: "Dzień"
            }}
          />
        </Suspense>
      </div>
    }

    var beltClassApp = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp club={this.props.match.params.club} />
      </Suspense>
    </div>

    var searchCalendarEvents = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <SearchCalendarEvents urlValue={this.urlValue}
          club={this.props.match.params.club}
          restClassLessonDateRangeData={this.state.restClassLessonDateRangeData}
          restClassTrainerData={this.state.restClassTrainerData}
          restClassNamesData={this.state.restClassNamesData}
        />
      </Suspense>
    </div>

    return (

      <div>
        <div style={{ 'position': '-webkit-sticky', 'background': '#660000' }}>

          {beltClassApp}
        </div>

        {/* <div id="content">
          <div class="container"> */}

<div style={{ 'padding': '20px', 'background': '#660000' }}>

  </div>

          <br /><br /><br /><br />

          {searchCalendarEvents}

          {calendar}
       

        {/* </div>
        </div> */}

        {/* <Footer /> */}

      </div>
    );
  }
}
export default PageCalendar;
