import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW"; 

export default function FechGetChallenge(club, league, id) {
 
  var url = "/league_app/" + club + "/" + league + "/find_challenge_by_id/" + id;
  var data = FetchGetDataWithAuthNEW(url);

  return data;
}

