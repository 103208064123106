class HeadersCsv {

    reservationStandard() {

        var headers = [
            { label: "Numer", key: "counter" },
            { label: "Imię", key: "name" },
            { label: "Nazwisko", key: "surname" },
            { label: "Telefon", key: "phone" },
            { label: "Metoda rezerwacji", key: "reservationMethodDesc" },
            { label: "Type rezerwacji", key: "reservationTypeDesc" },
            { label: "Czy opłacona", key: "payedDesc" },
            { label: "Rodzaj płatności", key: "paymentMethodDesc" },
            { label: "Cena", key: "price" },
            { label: "Nieobecność", key: "absentDesc" },
            { label: "Data", key: "date" },
            { label: "Dzień tygodnia", key: "dayOfWeek" },
            { label: "Godzina start", key: "hourStart" },
            { label: "Godzina koniec", key: "hourStop" },
            { label: "Łącznie godzin", key: "totalHours" },
            { label: "Kort", key: "courtName" },
            { label: "Opis", key: "description" },
            { label: "Trener", key: "trainerName" },
            { label: "Karnet kort", key: "ticketCourtDesc" },
            { label: "Karnet kort cena", key: "ticketCourtPrice" },
            { label: "Karnet trener", key: "ticketTrainerDesc" },
            { label: "Karnet trener cena", key: "ticketTrainerPrice" },
            { label: "Utworzone przez: imię", key: "createdByName" },
            { label: "Utworzone przez: nazwisko", key: "createdBySurname" },
            { label: "Utworzone przez: email", key: "createdByEmail" },
            { label: "Kiedy utworzone", key: "createdAt" },
            { label: "Kiedy edytowane", key: "editAt" },
            { label: "Edytowane przez: imię", key: "editByName" },
            { label: "Edytowane przez: nazwisko", key: "editBySurname" },
            { label: "Edytowane przez: email", key: "editByEmail" },
            { label: "Usunięte", key: "deleted" },
            { label: "Kiedy usunięte", key: "deletedAt" },
            { label: "Usunięte przez: imię", key: "deletedByName" },
            { label: "Usunięte przez: nazwisko", key: "deletedBySurname" },
            { label: "Usunięte przez: email", key: "deletedByEmail" }
        ];

        return headers;
    }

}
export default new HeadersCsv();