import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW"; 

export default function FechGetPlayers(club, league) {
 
  var url = "/league_app/" + club + "/" + league + "/find_all_players";
  var data = FetchGetDataWithAuthNEW(url);

  return data;
}

