import React, { Suspense } from "react";

import { Spinner, Button } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl"; // the locale you want

import FechGetCloseOpenCourtBySearch from "../APP_RESERVATION_FECH_DATA/FechGetCloseOpenCourtBySearch";

import PageCounterNew from "../components/PageCounterNew";
import BeltReservationApp from "../APP_RESERVATION_COMPONENTS/BeltReservationApp";

import queryString from "query-string";
import moment from "moment";
import ParseLinkToArray from "../utils/ParseLinkToArray";

const AddCloseOpenPopupForm = React.lazy(() =>
  import("../APP_RESERVATION_FORM_POPUP/AddCloseOpenPopupForm")
);
const TabAllOpenCourt = React.lazy(() =>
  import("../APP_RESERVATION_COMPONENTS/TabAllOpenCourt")
);
const SearchCloseOpenCourts = React.lazy(() =>
  import("../APP_RESERVATION_FORMS/SearchCloseOpenCourts")
);

let plLocate = require("moment/locale/pl");
moment.locale("pl", plLocate);

//KLASA WYSWIETLA SZCZEGOLY KONKRETNYCH ZAJEC SZKOLKI
class PageCloseOpenCourts extends React.Component {
  TRAINER_SELECT_DESC = "Wybierz trenera";
  DATE_SELECT_DESC = "Wybierz datę";
  CLASS_SELECT_DESC = "Wybierz szkółke";

  urlDeafulValues = [
    this.TRAINER_SELECT_DESC,
    this.CLASS_SELECT_DESC,
    this.DATE_SELECT_DESC,
  ];

  urlValue = "";

  postData = {
    dateStart: "",
    dateStop: "",
    trainer: "",
    classId: "",
    className: "",
    status: "",
    page: "0",
  };

  constructor(props) {
    super(props);

    this.state = {
      title: "RezerwujKort.pl - Rezerwację",
      datePickerIsOpen: false,
      previousDay: "",
      refreshReservation: "",
      addCloseOpenPopupForm: "",
      restCloseOpenData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
    };
    this.handlerRefreshReservation = this.handlerRefreshReservation.bind(this);
  }

  async componentDidMount() {
    document.title = "Rezerwacje - RezerwujKort.pl";
    registerLocale("pl", pl); // register it with the name you want
    const {
      location: { search },
    } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.postData = await ParseLinkToArray(
      this.urlValue,
      this.postData,
      this.urlDeafulValues
    );

    this.setState({
      restCloseOpenData: await FechGetCloseOpenCourtBySearch(
        this.props.match.params.club,
        search
      ),
    });

    var cc = this.state.restCloseOpenData;
  }

  async addClientForm() {
    this.setState({
      addCloseOpenPopupForm: (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <AddCloseOpenPopupForm
              dateReservation={""}
              club={this.props.match.params.club}
              refresh={this.handlerRefreshReservation}
            />
          </Suspense>
        </div>
      ),
    });
  }

  async handlerRefreshReservation() {
    //this.componentDidMount();
    const {
      location: { search },
    } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);
    this.postData = await ParseLinkToArray(
      this.urlValue,
      this.postData,
      this.urlDeafulValues
    );

    this.setState({
      restCloseOpenData: await FechGetCloseOpenCourtBySearch(
        this.props.match.params.club,
        search
      ),
    });

    var cc = this.state.restCloseOpenData;
  }

  render() {
    var loader = "";
    if (!this.state.restCloseOpenData.successful) {
      loader = (
        <div id="loader_div">
          <Spinner animation="border" />
        </div>
      );
      totalRecords = this.state.restCloseOpenData.data;
    }

    var totalRecords = "";
    var tabAllOpenCourt = "";
    var pageCounter = "";
    if (this.state.restCloseOpenData.successful) {
      totalRecords = this.state.restCloseOpenData.data.totalRecordsCount;

      tabAllOpenCourt = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <TabAllOpenCourt
              club={this.props.match.params.club}
              restLessonsData={
                this.state.restCloseOpenData.data.closeOpenResources
              }
              refresh={this.handlerRefreshReservation}
            />
          </Suspense>
        </div>
      );

      const urlValues = new Map();
      urlValues.set("dateStart", this.postData.dateStart);
      urlValues.set("dateStop", this.postData.dateStop);
      urlValues.set("trainer", this.postData.trainer);
      urlValues.set("classId", this.postData.classId);
      urlValues.set("className", this.postData.className);
      urlValues.set("status", this.postData.status);
      urlValues.set("page", "replace");

      pageCounter = (
        <PageCounterNew
          elementCount={totalRecords}
          urlValues={urlValues}
          urlDeafulValues={this.urlDeafulValues}
          elementOnPage={this.state.restCloseOpenData.data.recordsOnPage}
          currentPage={parseInt(this.postData.page) + 1}
          searchName={"search"}
          linkTo={
            "reservation_app/" +
            this.props.match.params.club +
            "/zamkniecia_kortow"
          }
        />
      );

      var searchPanel = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <SearchCloseOpenCourts
              urlValue={this.postData}
              club={this.props.match.params.club}
            />
          </Suspense>
        </div>
      );
    }

    return (
      <div>
        <div id="content">
          <BeltReservationApp club={this.props.match.params.club} />

          {searchPanel}

          <br />
          <br />
          <Button
            href="#"
            variant="success"
            size="lg"
            style={{ "margin-left": "20px" }}
            onClick={() => this.addClientForm()}
          >
            Zamknij/Otwórz kort
          </Button>

          <br />
          <br />
          <b>Lista wszystkich zmian: ({totalRecords}):</b>
          <br />
          <br />

          {this.state.addCloseOpenPopupForm}
          {loader}

          {tabAllOpenCourt}

          {pageCounter}
        </div>
      </div>
    );
  }
}
export default PageCloseOpenCourts;
