import React from 'react'

class AdverbLeagueShort extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {

        return (

            <div class="pokaz_lig" >

                <div class="pokaz_lig_box_tx">
                    {this.props.adverb.name}
                </div>

                <div class="pokaz_lig_btn">
                    <a href= {"/liga/" + this.props.club + "/" + this.props.adverb.nameSlug + "/ranking/"}>
                        <button type="button"
                            class="btn-more semibold">Przejdź do ligi</button>
                    </a>
                </div>
            </div>


            // <div class="team-item">

            //     {/*{%<!-- {console.log(this.state.logInfo + "uuuu: " + JSON.stringify(this.props.adverb ))}  */}


            //     <div class="left profile-photo">
            //         <img src="adres" alt="" />
            //         {/*{%<!-- <img src="{{ asset( adres_zdjecia[entity.id] ) }}" /> --> <img src={ require('./images/image111.jpg') } /> */}

            //         {this.props.adverb.image ? (
            //             <img src={`/images/sparinguploads/${this.props.adverb.image}`} style={{ maxHeight: 115, maxWidth: 115 }} />
            //         ) : (
            //             <img src={`/images/sparinguploads/default_sparing.png`} style={{ maxHeight: 115, maxWidth: 115 }} />
            //         )}

            //     </div>
            //     <div class="right profile-desc">
            //         <div class="profile-fullname">
            //             {this.props.adverb.userName}  {this.props.adverb.userSurname}
            //         </div>
            //         <div class="profile-info">
            //             Miasto:  {this.props.adverb.club1City}  <br />

            //             Miejsce gry: <br /><a href="xxx"> {this.props.adverb.club1Name} </a>

            //             {this.props.adverb.club2Name != this.props.adverb.club1Name ? (
            //                 <c><br /><a href="xxx">  {this.props.adverb.club2Name}</a></c>
            //             ) : (
            //                 <a></a>
            //             )}

            //             {(this.props.adverb.club3Name != this.props.adverb.club1Name) &&
            //                 (this.props.adverb.club3Name != this.props.adverb.club2Name) ? (
            //                 <c><br /><a href="xxx">  {this.props.adverb.club3Name}</a></c>
            //             ) : (
            //                 <a></a>
            //             )}


            //         </div>

            //         <div class="profile-more">
            //             {/*{% {% if czy_wlasciciel_ogloszenia is defined %} 
            //                 <div class="sparing_btn_wl">
            //                     <a href="{{ path('MyFrontendBundle_oglsparing_edit', { 'id': entity.id }) }}" class="rollover_edit"><div class="btn-more semibold">edytuj</div></a>
            //                 </div>'
            //                 <div class="sparing_btn_wl1">
            //                     <a href="{{ path('MyFrontendBundle_oglsparing_czyskasowac', { 'id': entity.id }) }}" class="rollover_delete"><div class="btn-more semibold">usuń</div></a> 
            //                 </div>
            //             {/*{% {% endif %} */}

            //             <a href={"/sparingpartner/" + this.props.adverb.slug}><div class="btn-more semibold">zobacz więcej</div></a>
            //         </div>
            //     </div>
            // </div>


        )
    }
}

export default AdverbLeagueShort;