import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';
import PageCounterNew from '../components/PageCounterNew';
import FechGetAdminRoles from "../leagueAppFechData/FechGetAdminRoles";
import FechGetChallenges from "../leagueAppFechData/FechGetChallenges";
import DateTimeNow from "../utils/DateTimeNow";

import ParseLinkToArray from "../utils/ParseLinkToArray";
import queryString from 'query-string';

const BeltLeague = React.lazy(() => import('../leagueAppComponents/BeltLeague'));
const ChallangesList = React.lazy(() => import('../leagueAppTab/ChallangesList'));

class PageLeagueChallenge extends React.Component {

  searchSparingItem = {
    page: ''
  };
  urlDeafulValues = [];
  urlValue = "";

  state = {
    title: "Liga - Ranking - RezerwujKort.pl",
    logInfo: "LogDebug __" + DateTimeNow() + "__ PageLeagueChallenge ",
    rowsCount: "6",
    restGamesData: {
      successful: false,
      status: '',
      message: '',
      data: []
    },
    restLeagueAdminData: {
      successful: false,
      status: '',
      message: '',
      data: []
    },
    restLeagueAdminData: {
      successful: false,
      status: '',
      message: '',
      data: []
    }
  }

  async componentDidMount() {

    document.title = this.state.title

    const { location: { search } } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.searchSparingItem = await ParseLinkToArray(this.urlValue, this.searchSparingItem, this.urlDeafulValues);

    this.setState({
      restLeagueAdminData: await FechGetAdminRoles(this.props.match.params.club, this.props.match.params.league)
    });

    this.setState({
      restGamesData: await FechGetChallenges(this.props.match.params.club, this.props.match.params.league)
    });

  }

  render() {


    const urlValues = new Map();
    urlValues.set('page', "replace");

    var pageCounter = "";
    var challangesList = "";
    if (this.state.restGamesData.successful) {
      pageCounter = <PageCounterNew
        elementCount={this.state.restGamesData.data.totalRecordsCount}
        urlValues={urlValues}
        urlDeafulValues={this.urlDeafulValues}
        elementOnPage={this.state.rowsCount}
        currentPage={parseInt(this.searchSparingItem.page) + 1}
        searchName={"sparing_search"}
        linkTo={"sparingpartnerzy"}
      />

      challangesList = <div>
        <Suspense fallback={<div>
          <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
        </div>}>
          <ChallangesList
            club={this.props.match.params.club}
            league={this.props.match.params.league}
            gamesData={this.state.restGamesData}
            admin={this.state.restLeagueAdminData}
          />
        </Suspense>
      </div>
    }

    var beltLeague;
    if (this.state.restLeagueAdminData.successful) {
      beltLeague = <div>
        <Suspense fallback={<div>
          <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
        </div>}>
          <BeltLeague
            currentPage={"challenge"}
            club={this.props.match.params.club}
            league={this.props.match.params.league}
          />
        </Suspense>
      </div>

    }

    return (

      <div>

        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">
            <div class="main-subpage">


              {beltLeague}
              <br /><br />
              {challangesList}

              <div >

              </div>

            </div>

          </div>
        </div>

        <Footer />

      </div>
    );
  }
}

export default PageLeagueChallenge;
