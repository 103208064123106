import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';

import AuthService from "../services/auth.service";

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const AddClassPayment = React.lazy(() => import('../classAppForms/AddClassPayment'));


class PageClassAddPayment extends React.Component {

  state = {
    currentUser: AuthService.getCurrentUser(),
    data: [],
    currentPage: "1",
    rowsCount: "25",
    title: "RezerwujKort.pl - Szkółki tenisowe"
  }

  componentDidMount() {
    document.title = this.state.title;
    //console.log("this.props.match.params.dayofweekwwwwwwwww " + JSON.stringify(this.props.match.params.dayofweek)); //usun
    var url = "/rest/class_app/" + this.props.match.params.club + "/admin/admins";
    if (this.props.match.params.page != null) {
      url = url + "/" + this.props.match.params.page + "/" + this.state.rowsCount;
      this.setState({ currentPage: this.props.match.params.page });
    }

    fetch(url, {
      method: 'GET',
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer ' + this.state.currentUser.accessToken,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ data });
      })

    fetch("/class_app/" + this.props.match.params.club + "/admin/admins/count", {
      method: 'GET',
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': 'Bearer ' + this.state.currentUser.accessToken,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(adminsCount => {
        //console.log("adminsCount " + adminsCount);
        this.setState({ adminsCount });
      })
  }

  render() {
    
    var msg = this.props.location.state;
    var button = "";
    if (msg === undefined) {
      button = "";
    } else {
      button = <div class="tenis-center-content-info">+  "{msg}"  + </div>;
    }

    var beltClassApp = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp club={this.props.match.params.club} />
      </Suspense>
    </div>

    var addClassPayment = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <AddClassPayment id={this.props.match.params.id}
          club={this.props.match.params.club}
          name={this.props.location.state[0]}
          description={this.props.location.state[1]}
          court={this.props.location.state[2]}
          day={this.props.location.state[3]}
          hourStart={this.props.location.state[4]}
          hourStop={this.props.location.state[5]}
          dateStart={this.props.location.state[6]}
          dateStop={this.props.location.state[7]}
          trainer={this.props.location.state[8]}
          classType={this.props.location.state[9]} />
      </Suspense>
    </div>

    return (

      <div>
        {beltClassApp}

        <div id="content">
          <div class="container">

            

            <br /><br />

            {button}

            <br /><br />
            <b>Dodaj nową szkółkę </b>
            <br /><br />

            {addClassPayment}

          </div>
        </div>


      </div>
    );
  }
}
export default PageClassAddPayment;
