import React from 'react';

import { Form, Button } from "react-bootstrap";

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';
import Footer from '../components/Footer';
import history from "../components/history";
import DateTimeNow from "../utils/DateTimeNow";

import ValidationFormField from "../utils/ValidationFormField";
import MailCooperation from "../mailNotification/MailCooperation";

class CooperationPage extends React.Component {

  mailData = {
    clubName: '',
    phone: '',
    email: '',
    description: ''
  };


  constructor(props) {
    super(props);

    this.state = {
      title: "Współpraca - RezerwujKort.pl",
      item: this.mailData,
      logInfo: "LogDebug __" + DateTimeNow() + "__ CooperationPage ",
      isError: {
        clubName: '',
        phone: '',
        email: '',
        description: ''
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    document.title = this.state.title
  }

  handleChange(event) {

    const target = event.target;
    const value = target.value;
    const name = target.name;

    let item = { ...this.state.item };
    item[name] = target.value;

    this.setState({ item: item });

    this.fieldValidation(name, value);
  }

  async handleSubmit(event) {

    event.preventDefault();

    for (var key in this.state.item) {
      this.fieldValidation(key, this.state.item[key]);
    }

    if (ValidationFormField.formValid(this.state.isError)) {

      this.state.mailResponseData = await MailCooperation(this.state.item);

      history.push('/info', "Wiadomość została wysłana");
      window.location.reload();
    }
  }

  fieldValidation(name, value, compareValue) {

    let isError = { ...this.state.isError };

    switch (name) {
      case "clubName":
        isError.clubName = ValidationFormField.validClassName("Nazwa klubu", value);
        break;
      case "phone":
        isError.phone = ValidationFormField.validPhone("Telefon", value);
        break;
      case "email":
        isError.email = ValidationFormField.validEmail("Email", value);
        break;
      case "description":
        isError.description = ValidationFormField.validLongDescription("Treść wiadomości", value);
        break;
      default:
        isError.password = "";
        break;
    }
    this.setState({
      isError,
      [name]: value
    })
    this.state.isError[name] = isError[name];
  }


  render() {

    const { item } = this.state;
    let isError = { ...this.state.isError };

    return (

      <div>

        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">

            <div class="main-subpage">
              <div class="head">
                <img src={`/images/login.png`} />
                <p>Współpraca</p>
              </div>
              <div class="tenis-content-info">
                <span>
                  <br />

                  Prezentujemy profesjonalną aplikację do zarządzania klubem tenisowym.
                  {/* <br /><br />
                  To co odróżnia nas od innych tego typu systemów, to fakt, że nasz program nie działa w przeglądarce internetowej, a uruchamiany jest bezpośrednio z komputera oraz jest całkowicie bezpłatny. */}
                  <br /><br />
                  Aplikacja stworzona jest z myślą o klubach tenisowych i squashowych. Zawiera wszelkie niezbędne funkcje, których obiekty tego typu potrzebują, w tym: stałe rezerwacje, zarządzanie płatnościami, generowanie raportów wykorzystania kortów czy możliwość logowania dla kilku pracowników recepcji, a to tylko niektóre z nich.
                  <br /><br />
                  Program jest wyjątkowo intuicyjny. Nawet osoby posługujące się komputerem w stopniu podstawowym, nie będą miały problemów z jego obsługą.
                  {/* <br /><br />
                  Bezpłatna wersja, którą oferujemy wyposażona jest w samodzielną bazę danych, a na portalu www.RezerwujKort.pl przechowywane są wyłącznie informacje, o tym który kort jest wolny i w jakich godzinach. Dzięki temu Klienci klubów zawsze mają możliwość sprawdzenia aktualnego grafiku, wybrania interesującej ich godziny rezerwacji i kortu. Podczas rozmowy telefonicznej z pracownikiem klubu gracze rezerwują już wcześniej wybrany termin. Regularnie gramy w tenisa i wiemy, że taka funkcja wyjątkowo dobrze sprawdza się w praktyce. */}
                  {/* <br /><br />
                  Nasza Aplikacja jest bezpieczna. Samodzielna baza danych dołączona do programu gwarantuje, że nikt poza jego właścicielem nie ma dostępu do informacji gromadzonych o Klientach, rezerwacjach czy płatnościach. */}
                  {/* <br />
                  Program możesz połączyć z Twoim kontem w serwisie DropBox dzięki czemu jednym kliknięciem wykonasz kopie zapasową całego programu. */}
                  <br /><br />
                  Oferta skierowana jest do w klubów tenisowych i squash-owych, posiadających co najmniej dwa korty.
                  <br /><br />
                  POTRZEBUJESZ WIĘCEJ?
                  <br /><br />

                  - grafik dostępności kortów możemy umieścić na Twojej stronie internetowej
                  <br /><br />
                  - posiadamy narzędzia do organizowania lig i wydarzeń sportowych (<a href="">Przejdź do przykładowej ligi</a>),
                  <br /><br />
                  - jesteśmy elastyczni – jeżeli potrzebujesz wyłącznie programu do zarządzania (bez ogólnodostępnego grafiku), to możemy taki dla Ciebie przygotować
                  <br /><br />
                  - posiadamy aplikacje mobilną <a href="https://www.rezerwujkort.pl/aplikacja_mobilna">(Zobacz)</a>
                  {/* <br /><br />
                  - program „Rezerwator” możemy zainstalować w Twoim smartfonie, a rezerwację przygotować w systemie on-line */}

                  <br /><br />
                  Wypełnij formularz lub zadzwoń pod numer: 660 916 097. Udostępnimy Ci program abyś sam mógł sprawdzić jego możliwości.
                  <br /><br />
                  Zajrzyj również na stronę <a href="/o_nas">o nas</a>

                </span>
              </div>



              <div class="main-subpage-content subpage-contact">
                <Form onSubmit={this.handleSubmit} id="mail_sugestia">
                  <div class="form-err">
                    {/* {% if form_errors(form.nazwaklubu) != "" %}
                                          <tr><td colspan="6">{{ form_errors(form.nazwaklubu) }}</td> </tr>
                                  {#{% elseif form_errors(form.wojewodztwo) != "" %}
                                          <tr><td colspan="6">{{ form_errors(form.wojewodztwo) }}</td> </tr>
                                  {% elseif form_errors(form.miasto) != "" %}
                                          <tr><td colspan="6">{{ form_errors(form.miasto) }}</td> </tr>#}
                                  {% elseif form_errors(form.telefon) != "" %}
                                          <tr><td colspan="6">{{ form_errors(form.telefon) }}</td> </tr>
                                  {% elseif form_errors(form.emaile) != "" %}
                                          <tr><td colspan="6">{{ form_errors(form.emaile) }}</td> </tr>
                                  {#{% elseif form_errors(form.ilekortow) != "" %}
                                          <tr><td colspan="6">{{ form_errors(form.ilekortow) }}</td> </tr>#}
                                  {% elseif form_errors(form.dodatkoweinformacje) != "" %}
                                          <tr><td colspan="6">{{ form_errors(form.dodatkoweinformacje) }}</td> </tr>
                                  {% elseif form_errors(form) != "" %}
                                          <tr><td colspan="6">{{ form_errors(form) }}</td> </tr>
                                  {% endif %} */}
                  </div>
                  <div class="contact-item">
                    <label>Nazwa Ośrodka<span style={{ 'color': '#86b606' }}>*</span>:</label>
                    <Form.Group controlId="formGridAddress1">
                      <Form.Control
                        placeholder=""
                        id="clubName"
                        name="clubName"
                        value={item.clubName || ''}
                        onChange={this.handleChange}
                      />
                      {this.state.isError.clubName.length > 0 && (
                        <span className="invalid-feedback-contakt">{this.state.isError.clubName}</span>
                      )}
                    </Form.Group>
                  </div>
                  <div class="contact-item">
                    <label>Telefon<span style={{ 'color': '#86b606' }}>*</span>:</label>
                    <Form.Group controlId="formGridAddress1">
                      <Form.Control
                        placeholder=""
                        id="phone"
                        name="phone"
                        value={item.phone || ''}
                        onChange={this.handleChange}
                      />
                      {this.state.isError.phone.length > 0 && (
                        <span className="invalid-feedback-contakt">{this.state.isError.phone}</span>
                      )}
                    </Form.Group>
                  </div>
                  <div class="contact-item">
                    <label>E-mail<span style={{ 'color': '#86b606' }}>*</span>:</label>
                    <Form.Group controlId="formGridAddress1">
                      <Form.Control
                        placeholder=""
                        id="email"
                        name="email"
                        onChange={this.handleChange}
                      />
                      {this.state.isError.email.length > 0 && (
                        <span className="invalid-feedback-contakt">{this.state.isError.email}</span>
                      )}
                    </Form.Group>
                  </div>

                  <div class="contact-item">
                    <label>Treść<span style={{ 'color': '#86b606' }}>*</span>:</label>
                    <Form.Group controlId="formGridAddress1">
                      <Form.Control as="textarea"
                        placeholder=""
                        id="description"
                        name="description"
                        onChange={this.handleChange}
                      />
                      {this.state.isError.description.length > 0 && (
                        <span className="invalid-feedback-contakt">{this.state.isError.description}</span>
                      )}
                    </Form.Group>
                  </div>
                  <div class="contact-button">
                    <span class="register-confirm-alert">Pole oznaczone <span style={{ 'color': '#86b606' }}>*</span> są wymagane</span>
                    <span><input class="btn-more semibold" value="Wyślij" type="submit" /></span>

                  </div>
                </Form>

              </div>
            </div>
          </div>
        </div>
        <Footer />

      </div>

    );
  }
}

export default CooperationPage;
