import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';

const AddSparingPartner = React.lazy(() => import('../forms/AddSparingPartnerForm'));

class ProfileSparingNewPage extends React.Component {

    state = {
        data: [],
        newsCount: "",
        currentPage: "1",
        title: "RezerwujKort.pl - Profil"
    }

    componentDidMount() {
        document.title = this.state.title

    }

    render() {

        var addSparingPartner = <div>
            <Suspense fallback={<div>
                <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
            </div>}>
                <AddSparingPartner />
            </Suspense>
        </div>

        return (

            <div>

                <Belt />

                <BeltLogged />

                {addSparingPartner}

                <Footer />

            </div>
        );
    }
}
export default ProfileSparingNewPage;
