import React from "react";

import ReactDOM from "react-dom";

//import Belt                  from './components/Belt'; test 2
//import News                  from './components/News';
//import Profile               from "./components/profile.component";
// import Register from "./components/register.component";
import Login from "./components/Login";
import history from "./components/history";

import HomePage from "./pageDefault/HomePage";
import TrainersPage from "./pageDefault/TrainersPage";
import TrainerPage from "./pageDefault/TrainerPage";
import TrainersSearchPage from "./pageDefault/TrainersSearchPage";
import SparingpartnersPage from "./pageDefault/SparingpartnersPage";
import SparingpartnerPage from "./pageDefault/SparingpartnerPage";
import SparingpartnersSearchPage from "./pageDefault/SparingpartnersSearchPage";
import ClubsPage from "./pageDefault/ClubsPage";
import ClubPage from "./pageDefault/ClubPage";
import ClubsSearchPage from "./pageDefault/ClubsSearchPage";
import ClubReservationPage from "./pageDefault/ClubReservationPage";
import ClubReservationOnlineConfirmPage from "./pageDefault/ClubReservationOnlineConfirmPage";
import ClubReservationOnlineConfirmPaymentPage from "./pageDefault/ClubReservationOnlineConfirmPaymentPage";
import UpdateTicketConfirmPaymentPage from "./pageDefault/UpdateTicketConfirmPaymentPage";
import ClubTrainersPage from "./pageDefault/ClubTrainersPage";
import ClubSparingPage from "./pageDefault/ClubSparingPage";
import ClubTournamentsPage from "./pageDefault/ClubTournamentsPage";
import ClubLeaguePage from "./pageDefault/ClubLeaguePage";
import TurnamentsPage from "./pageDefault/TurnamentsPage";
import AboutPage from "./pageDefault/AboutPage";
import CooperationPage from "./pageDefault/CooperationPage";
import NewsPage from "./pageDefault/NewsPage";
import LogoutPage from "./pageDefault/LogoutPage";
import InfoPage from "./pageDefault/InfoPage";

import RegulationsPage from "./pageDefault/RegulationsPage";
import CookiePage from "./pageDefault/CookiePage";
import MobileAppPage from "./pageDefault/MobileAppPage";
import ContactPage from "./pageDefault/ContactPage";

import LoginPage from "./pageDefault/LoginPage";
import RegisterPage from "./pageDefault/RegisterPage";
import RegisterOkPage from "./pageDefault/RegisterOkPage";
import RegisterConfirmPage from "./pageDefault/RegisterConfirmPage";
import ForgotPassword from "./pageDefault/ForgotPassword";
import ResetPassword from "./pageDefault/ResetPassword";
import ProfilePage from "./pageDefault/ProfilePage";
import ProfileInfoPage from "./pageDefault/ProfileInfoPage";
import ProfileEditPage from "./pageDefault/ProfileEditPage";
import ConfirmPhonePage from "./pageDefault/ConfirmPhonePage";
import ProfileChangePasswordPage from "./pageDefault/ProfileChangePasswordPage";
import ProfileDeletePage from "./pageDefault/ProfileDeletePage";
import ProfileClubsPage from "./pageDefault/ProfileClubsPage";
import ProfileReservationPage from "./pageDefault/ProfileReservationPage";
import ProfileTicketsPage from "./pageDefault/ProfileTicketsPage";
import ProfileSparingPage from "./pageDefault/ProfileSparingPage";
import ProfileSparingNewPage from "./pageDefault/ProfileSparingNewPage";

import PageAdminPanel from "./classAppPage/PageAdminPanel";
import PageAdmins from "./classAppPage/PageAdmins";
import PageShowAdmins from "./classAppPage/PageShowAdmins";
import PageCourts from "./classAppPage/PageCourts";
import PageShowCourts from "./classAppPage/PageShowCourts";
import PageShowClass from "./classAppPage/PageShowClass";
import PageClassAdd from "./classAppPage/PageClassAdd";
import PageClassAddPayment from "./classAppPage/PageClassAddPayment";
import PageShowClassStudents from "./classAppPage/PageShowClassStudents";
import PageShowClassStudentsOne from "./classAppPage/PageShowClassStudentsOne";
import PageShowClassDelete from "./classAppPage/PageShowClassDelete";
import PageShowClassEdit from "./classAppPage/PageShowClassEdit";
import PageClass from "./classAppPage/PageClass";

import PageLesson from "./classAppPage/PageLesson";
import PageLessonAll from "./classAppPage/PageLessonAll";
import PageLessonDelete from "./classAppPage/PageLessonDelete";
import PageLessonAdd from "./classAppPage/PageLessonAdd";

import PageStudents from "./classAppPage/PageStudents";
import PageStudentsSearch from "./classAppPage/PageStudentsSearch";
import PageStudentAddToClassPayment from "./classAppPage/PageStudentAddToClassPayment";
import PageStudentAddToClass from "./classAppPage/PageStudentAddToClass";
import PageStudentAdd from "./classAppPage/PageStudentAdd";
import PageShowStudent from "./classAppPage/PageShowStudent";
import PageShowStudentStat from "./classAppPage/PageShowStudentStat";
import PageShowStudentClassPresence from "./classAppPage/PageShowStudentClassPresence";
import PageShowStudentClassPayment from "./classAppPage/PageShowStudentClassPayment";
import PageShowStudentClassAddPayment from "./classAppPage/PageShowStudentClassAddPayment";

import PagePriceType from "./classAppPage/PagePriceType";
import PagePriceSettings from "./classAppPage/PagePriceSettings";
import PagePriceTypeAdd from "./classAppPage/PagePriceTypeAdd";

import PageMailToClassStudents from "./classAppPage/PageMailToClassStudents";

import PageCalendar from "./classAppPage/PageCalendar";

import ReactNotifications from "react-notifications-component";

import PageClassGraphics from "./classAppPage/PageClassGraphics";

import PageError500 from "./classAppError/PageError500";

import PageLeagueInfo from "./leagueAppPage/PageLeagueInfo";
import PageLeagueRanking from "./leagueAppPage/PageLeagueRanking";
import PageLeagueUsers from "./leagueAppPage/PageLeagueUsers";
import PageLeagueChallenge from "./leagueAppPage/PageLeagueChallenge";
import PageLeagueGame from "./leagueAppPage/PageLeagueGame";
import PageLeagueVacation from "./leagueAppPage/PageLeagueVacation";
import PageLeagueStatistics from "./leagueAppPage/PageLeagueStatistics";
import PageLeagueStatute from "./leagueAppPage/PageLeagueStatute";
import PageLeagueAdmin from "./leagueAppPage/PageLeagueAdmin";

import PageLeagueAdminAddNewUser from "./leagueAppPage/PageLeagueAdminAddNewUser";
import PageLeagueAdminEditUser from "./leagueAppPage/PageLeagueAdminEditUser";
import PageLeagueAdminAddUser from "./leagueAppPage/PageLeagueAdminAddUser";
import PageLeagueAdminDeleteUser from "./leagueAppPage/PageLeagueAdminDeleteUser";
import PageLeagueAdminChangeRankPosition from "./leagueAppPage/PageLeagueAdminChangeRankPosition";
import PageLeagueAdminCreateMatch from "./leagueAppPage/PageLeagueAdminCreateMatch";
import PageLeagueAdminEditMatch from "./leagueAppPage/PageLeagueAdminEditMatch";
import PageLeagueAdminFinishMatch from "./leagueAppPage/PageLeagueAdminFinishMatch";
import PageLeagueAdminDeletehMatch from "./leagueAppPage/PageLeagueAdminDeletehMatch";

import PageLeagueAllPlayers from "./leagueAppPage/PageLeagueAllPlayers";

import PageLeagueShowChallenge from "./leagueAppPage/PageLeagueShowChallenge";
import PageLeagueShowGame from "./leagueAppPage/PageLeagueShowGame";

import PageReservationCalendar from "./APP_RESERVATION_PAGE/PageReservationCalendar";
import PageReservationHistory from "./APP_RESERVATION_PAGE/PageReservationHistory";
import PageReservationDetails from "./APP_RESERVATION_PAGE/PageReservationDetails";
import PageReservationUsers from "./APP_RESERVATION_PAGE/PageReservationUsers";
import PageRegularReservation from "./APP_RESERVATION_PAGE/PageRegularReservation";
import PageRegularReservationDetails from "./APP_RESERVATION_PAGE/PageRegularReservationDetails";
import PageTickets from "./APP_RESERVATION_PAGE/PageTickets";
import PageTicketDetails from "./APP_RESERVATION_PAGE/PageTicketDetails";
import PageTrainers from "./APP_RESERVATION_PAGE/PageTrainers";
import PageClients from "./APP_RESERVATION_PAGE/PageClients";
import PageCloseOpenCourts from "./APP_RESERVATION_PAGE/PageCloseOpenCourts";
import PageSentEmails from "./APP_RESERVATION_PAGE/PageSentEmails";
import PageCourtsSettings from "./APP_RESERVATION_PAGE/PageCourtsSettings.js";
import PagePaymentsSettings from "./APP_RESERVATION_PAGE/PagePaymentsSettings.js";
import PagePaymentsOnlineGraphic from "./APP_RESERVATION_PAGE/PagePaymentsOnlineGraphic.js";
import PagePromotions from "./APP_RESERVATION_PAGE/PagePromotions.js";
import PagePromotionsDetails from "./APP_RESERVATION_PAGE/PagePromotionsDetails.js";
import PageSettings from "./APP_RESERVATION_PAGE/PageSettings.js";

import AppReservationDeski from "./pageTemp/AppReservationDeski.js";
import AppReservationMkt from "./pageTemp/AppReservationMkt.js";
import ClubReservationRedirectPage from "./pageTemp/ClubReservationRedirectPage.js";

import AppStart from "./pageTemp/AppStart";
import PageMktDzis from "./pageDefault/PageMktDzis";

import Template404 from "./pageError/Template404.js";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { isPostfixUnaryExpression } from "typescript";

//url = __DEV__ ? 'http://dev.url.com' : 'https://prod.url.com'; <Route path="/aktualnosci" component={NewsPage} />
//<Route exact path="/login" component={Login} />
//<Route exact path="/profile" component={Profile} />

//const __DEV__ = React.createContext('light');
//const ThemeContext = React.createContext('light');

export default function App() {
  return (
    <Switch>
      <Route path="/" component={HomePage} exact />
      <Route path="/trenerzy/trainers_search" component={TrainersPage} />
      <Route path="/trenerzy" component={TrainersPage} />
      <Route path="/trainers_search" component={TrainersSearchPage} />
      <Route path="/trener/:slug?" component={TrainerPage} />
      <Route
        path="/sparingpartnerzy/sparing_search"
        component={SparingpartnersPage}
      />
      <Route path="/sparingpartnerzy" component={SparingpartnersPage} />
      <Route
        path="/sparingpartnerzy_search"
        component={SparingpartnersSearchPage}
      />
      <Route path="/sparingpartner/:slug?" component={SparingpartnerPage} />
      <Route path="/kluby" component={ClubsPage} />
      <Route path="/clubs_search" component={ClubsSearchPage} />
      <Route path="/turnieje" component={TurnamentsPage} />
      <Route path="/o_nas" component={AboutPage} />
      <Route path="/wspolpraca" component={CooperationPage} />
      <Route path="/aktualnosci/news_search" component={NewsPage} />
      <Route path="/aktualnosci/" component={NewsPage} />
      <Route path="/login" component={LoginPage} />
      <Route path="/logout" component={LogoutPage} />
      <Route path="/kluby/clubs_search" component={ClubsPage} />
      <Route
        path="/klub/:slug?/rezerwacja_kortu_:page/:date?"
        component={ClubReservationPage}
      />
      {/* <Route path="/klub/:slug?/rezerwacja_online/:date?/:court?/:hour?" component={ClubReservationOnlineConfirmPage} /> */}
      <Route
        path="/klub/:slug?/rezerwacja_online"
        component={ClubReservationOnlineConfirmPage}
      />
      <Route
        path="/rezerwacja_online_potwierdzenie/:token?"
        component={ClubReservationOnlineConfirmPaymentPage}
      />
      <Route
        path="/doladowanie_karnetu_potwierdzenie/:token?"
        component={UpdateTicketConfirmPaymentPage}
      />
      <Route
        path="/klub/:slug?/trenerzy/trainers_search"
        component={ClubTrainersPage}
      />
      <Route path="/klub/:slug?/trenerzy" component={ClubTrainersPage} />
      <Route
        path="/klub/:slug?/sparingpartnerzy/sparing_search"
        component={ClubSparingPage}
      />
      <Route path="/klub/:slug?/sparingpartnerzy" component={ClubSparingPage} />
      <Route
        path="/klub/:slug?/turnieje/:page?"
        component={ClubTournamentsPage}
      />{" "}
      x
      <Route path="/klub/:slug?/liga/:page?" component={ClubLeaguePage} />
      <Route path="/klub/:slug?" component={ClubPage} />
      <Route path="/regulamin" component={RegulationsPage} />
      <Route path="/polityka_cookie" component={CookiePage} />
      <Route path="/aplikacja_mobilna" component={MobileAppPage} />
      <Route path="/kontakt" component={ContactPage} />
      <Route path="/profile/info" component={ProfileInfoPage} />
      <Route path="/profile/edycja" component={ProfileEditPage} />
      <Route
        path="/profile/weryfikacja_numeru_telefonu"
        component={ConfirmPhonePage}
      />
      <Route
        path="/profile/zmiana_hasla"
        component={ProfileChangePasswordPage}
      />
      <Route path="/profile/kasowania_konta" component={ProfileDeletePage} />
      <Route path="/profile/kluby" component={ProfileClubsPage} />
      <Route
        path="/profile/moje_rezerwacje"
        component={ProfileReservationPage}
      />
      <Route path="/profile/moje_karnety" component={ProfileTicketsPage} />
      <Route
        path="/profile/sparingpartnerzy/dodaj"
        component={ProfileSparingNewPage}
      />
      <Route path="/profile/sparingpartnerzy" component={ProfileSparingPage} />
      <Route path="/profile" component={ProfilePage} />
      {/* <Route path="/register1" component={Register} />   */}
      <Route path="/info" component={InfoPage} />
      {/* <Route path="/login1" component={Login} /> */}
      <Route path="/register" component={RegisterPage} />
      <Route path="/register_ok" component={RegisterOkPage} />
      <Route
        path="/regitrationConfirm/:token?"
        component={RegisterConfirmPage}
      />
      <Route path="/forgot_password" component={ForgotPassword} />
      <Route path="/reset_password/:token?" component={ResetPassword} />
      {/* ----- APLIKACJA SZKÓŁKI ----- */}
      {/* ustawienia panel admina  */}
      <Route
        path="/class_app/:club?/panel_admin"
        component={PageAdminPanel}
      />{" "}
      {/* #poprawione */}
      {/* wyswietlanie/edycja/usuwanie/dodawanie administratorów w klubie  */}
      <Route
        path="/class_app/:club?/admin/admins"
        component={PageAdmins}
      />{" "}
      {/* #poprawione */}
      <Route
        path="/class_app/:club?/admin/admins/admins_search"
        component={PageAdmins}
      />{" "}
      {/* #poprawione */}
      <Route
        path="/class_app/:club?/admin/admin/edit/:id?"
        component={PageShowAdmins}
      />{" "}
      {/* #poprawione */}
      {/* wyswietlanie/edycja/usuwanie/dodawanie kortów w klubie  */}
      <Route
        path="/class_app/:club?/admin/courts"
        component={PageCourts}
      />{" "}
      {/* #poprawione */}
      <Route
        path="/class_app/:club?/admin/court/edit/:id?"
        component={PageShowCourts}
      />{" "}
      {/* #poprawione */}
      {/* wysylanie maili do uczestnikow szkolek  */}
      <Route
        path="/class_app/:club?/class/mail_to_students/:id?"
        component={PageMailToClassStudents}
      />
      {/* wyswietlanie/edycja/usuwanie/dodawanie zajec  */}
      <Route path="/class_app/:club?/class/:page?" component={PageClass} />{" "}
      {/* #poprawione */}
      <Route
        path="/class_app/:club?/class/class_search"
        component={PageClass}
      />{" "}
      {/* #poprawione */}
      <Route
        path="/class_app/:club?/class_show/:id?/lesson_search"
        component={PageShowClass}
      />{" "}
      {/* #poprawione */}
      <Route
        path="/class_app/:club?/class_show/:id?"
        component={PageShowClass}
      />{" "}
      {/* #poprawione */}
      <Route
        path="/class_app/:club?/class_delete/:id?"
        component={PageShowClassDelete}
      />{" "}
      {/* #poprawione */}
      <Route
        path="/class_app/:club?/class_edit/:id?"
        component={PageShowClassEdit}
      />{" "}
      {/* #poprawione */}
      <Route
        path="/class_app/:club?/admin/class_add/"
        component={PageClassAdd}
      />{" "}
      {/* #poprawione */}
      <Route
        path="/class_app/:club?/admin/class_add_payment"
        component={PageClassAddPayment}
      />{" "}
      {/* #NIE UZYWANE??? */}
      <Route
        path="/class_app/:club?/admin/class_add_similar/:id?"
        component={PageClassAdd}
      />{" "}
      {/* #poprawione */}
      <Route
        path="/class_app/:club?/class_show_students/:id?"
        component={PageShowClassStudents}
      />{" "}
      {/* #poprawione */}
      <Route
        path="/class_app/:club?/admin/class_show_students_edit/:id?/:idstudent?/:page?"
        component={PageShowClassStudentsOne}
      />{" "}
      {/* #poprawione */}
      {/* wyswietlanie/edycja/usuwanie/dodawanie lekcji  */}
      <Route
        path="/class_app/:club?/lesson_all/:page?"
        component={PageLessonAll}
      />
      <Route path="/class_app/:club?/lesson_all/" component={PageLessonAll} />
      <Route path="/class_app/:club?/lesson/:id?" component={PageLesson} />
      <Route
        path="/class_app/:club?/lesson_edit/:id?"
        component={PageLessonDelete}
      />
      <Route
        path="/class_app/:club?/lesson_add/:idClass?"
        component={PageLessonAdd}
      />{" "}
      {/* #poprawione */}
      {/* wyswietlanie/edycja/usuwanie/dodawanie uczestnikow szkolek  */}
      <Route
        path="/class_app/:club?/students/students_search"
        component={PageStudents}
      />
      <Route
        path="/class_app/:club?/students_search/:slug"
        component={PageStudentsSearch}
      />
      <Route
        path="/class_app/:club?/admin/student/add_to_class_payment/:id?"
        component={PageStudentAddToClassPayment}
      />
      <Route
        path="/class_app/:club?/admin/student/add_to_class/:id?"
        component={PageStudentAddToClass}
      />
      <Route
        path="/class_app/:club?/admin/student/add"
        component={PageStudentAdd}
      />
      <Route
        path="/class_app/:club?/admin/student/edit/:id?"
        component={PageShowStudent}
      />
      <Route
        path="/class_app/:club?/admin/student/stat/:id?"
        component={PageShowStudentStat}
      />
      <Route
        path="/class_app/:club?/admin/student/class_presence/:classId?/:id?"
        component={PageShowStudentClassPresence}
      />
      <Route
        path="/class_app/:club?/admin/student/class_payment/:classId?/:studentId?"
        component={PageShowStudentClassPayment}
      />
      <Route
        path="/class_app/:club?/admin/student/add_payment/:classId?/:studentId?/:type?"
        component={PageShowStudentClassAddPayment}
      />
      <Route
        path="/class_app/:club?/admin/student/add_student_payment/:classId?/:studentId?/:type?"
        component={PageShowStudentClassAddPayment}
      />
      {/* wyswietlanie/edycja/usuwanie/dodawanie uczestnikow szkolek  */}
      <Route
        path="/class_app/:club?/admin/price_settings/:type?/:id?"
        component={PagePriceSettings}
      />
      <Route
        path="/class_app/:club?/admin/price_type"
        component={PagePriceType}
      />
      <Route
        path="/class_app/:club?/admin/price_type_add"
        component={PagePriceTypeAdd}
      />
      <Route path="/class_app/:club?/error500" component={PageError500} />
      <Route path="/class_app/:club?/calendar" component={PageCalendar} />{" "}
      {/* #poprawione */}
      <Route
        path="/class_app/:club?/calendar_search"
        component={PageCalendar}
      />
      <Route
        path="/class_app/:club?/class_graphics/:dayOfWeek?"
        component={PageClassGraphics}
      />{" "}
      {/* #poprawione */}
      {/* kalendarz szkolek  */}
      {/* ----- KONIEC APLIKACJA SZKÓŁKI ----- */}
      {/* ----- APLIKACJA LIGA ----- */}
      <Route path="/liga/:club?/:league?/info" component={PageLeagueInfo} />
      <Route
        path="/liga/:club?/:league?/admin/dodaj_nowego_uczestnika"
        component={PageLeagueAdminAddNewUser}
      />
      <Route
        path="/liga/:club?/:league?/admin/dodaj_uczestnika"
        component={PageLeagueAdminAddUser}
      />
      <Route
        path="/liga/:club?/:league?/admin/usun_uczestnika/:mameSlug?"
        component={PageLeagueAdminDeleteUser}
      />
      <Route
        path="/liga/:club?/:league?/admin/zmien_pozycje_w_rankingu/:mameSlug?"
        component={PageLeagueAdminChangeRankPosition}
      />
      <Route
        path="/liga/:club?/:league?/admin/utworz_wyzwanie/:mameSlug?"
        component={PageLeagueAdminCreateMatch}
      />
      <Route
        path="/liga/:club?/:league?/admin/edytuj_wyzwanie/:id?"
        component={PageLeagueAdminEditMatch}
      />
      <Route
        path="/liga/:club?/:league?/admin/zakoncz_wyzwanie/:id?"
        component={PageLeagueAdminFinishMatch}
      />
      <Route
        path="/liga/:club?/:league?/admin/usun_wyzwanie/:id?"
        component={PageLeagueAdminDeletehMatch}
      />
      <Route
        path="/liga/:club?/:league?/admin/all_club_players/players_search"
        component={PageLeagueAllPlayers}
      />
      <Route
        path="/liga/:club?/:league?/admin/club_player_edit/:mameSlug?"
        component={PageLeagueAdminEditUser}
      />
      <Route
        path="/liga/:club?/:league?/wyzwanie/:id?"
        component={PageLeagueShowChallenge}
      />
      <Route
        path="/liga/:club?/:league?/mecz/:id?"
        component={PageLeagueShowGame}
      />
      <Route
        path="/liga/:club?/:league?/ranking"
        component={PageLeagueRanking}
      />
      <Route
        path="/liga/:club?/:league?/uczestnicy"
        component={PageLeagueUsers}
      />
      <Route
        path="/liga/:club?/:league?/wyzwania"
        component={PageLeagueChallenge}
      />
      <Route path="/liga/:club?/:league?/mecze" component={PageLeagueGame} />
      <Route
        path="/liga/:club?/:league?/urlopy"
        component={PageLeagueVacation}
      />
      <Route
        path="/liga/:club?/:league?/statystyki"
        component={PageLeagueStatistics}
      />
      <Route
        path="/liga/:club?/:league?/regulamin"
        component={PageLeagueStatute}
      />
      <Route path="/liga/:club?/:league?/admin" component={PageLeagueAdmin} />
      {/* ----- strona chwilowa dla mkt dzis ----- */}
      <Route path="/osrodek/MKT_Lodz/tenis/dzis" component={PageMktDzis} />
      {/* ----- KONIEC strona chwilowa dla mkt dzis ----- */}
      {/* ----- KONIEC APLIKACJA LIGA ----- */}
      {/* -----  APLIKACJA REZERWACJE ----- */}
      <Route
        path="/reservation_app/:club?/calendar"
        component={PageReservationCalendar}
      />
      <Route
        path="/reservation_app/:club?/historia_rezerwacji/search"
        component={PageReservationHistory}
      />
      <Route
        path="/reservation_app/:club?/rezerwacja_szczegoly/:id?"
        component={PageReservationDetails}
      />
      <Route
        path="/reservation_app/:club?/administratorzy"
        component={PageReservationUsers}
      />
      <Route
        path="/reservation_app/:club?/stale_rezerwacje/search"
        component={PageRegularReservation}
      />
      <Route
        path="/reservation_app/:club?/stala_rezerwacja_szczegoly/:id?/:page?"
        component={PageRegularReservationDetails}
      />
      <Route
        path="/reservation_app/:club?/klienci/search"
        component={PageClients}
      />
      <Route
        path="/reservation_app/:club?/trenerzy/search"
        component={PageTrainers}
      />
      <Route
        path="/reservation_app/:club?/karnety/search"
        component={PageTickets}
      />
      <Route
        path="/reservation_app/:club?/karnety_szczegoly/:id?"
        component={PageTicketDetails}
      />
      <Route
        path="/reservation_app/:club?/zamkniecia_kortow/search"
        component={PageCloseOpenCourts}
      />
      <Route
        path="/reservation_app/:club?/wyslane_maile/search"
        component={PageSentEmails}
      />
      <Route
        path="/reservation_app/:club?/ustawienia_kortow/search"
        component={PageCourtsSettings}
      />
      <Route
        path="/reservation_app/:club?/ustawienia_platnosci/:id"
        component={PagePaymentsSettings}
      />
      <Route
        path="/reservation_app/:club?/ustawienia_platnosci_grafik/:id"
        component={PagePaymentsOnlineGraphic}
      />
      <Route
        path="/reservation_app/:club?/promocje/search"
        component={PagePromotions}
      />
      <Route
        path="/reservation_app/:club?/promocje_szczegoly/search"
        component={PagePromotionsDetails}
      />
      <Route
        path="/reservation_app/:club?/ustawienia"
        component={PageSettings}
      />
      {/* ----- KONIEC APLIKACJA REZERWACJE ----- */}
      {/* <Route path="/app/klub/Warszawskie_Towarzystwo_Sportowe_DeSki/rezerwacja_kortu/:data?" component={AppReservationDeski} />
      <Route path="/app/klub/mkt_lodz/rezerwacja_kortu/:data?" component={AppReservationMkt} /> */}
      {/* <Route path="/app/start" component={AppStart} />
      {/* <Route path="/app/klub//:club?/rezz//:data?" component={AppStart} /> 
      <Route path="/app/start" component={AppStart} />
      <Route path="/app/klub/Warszawskie_Towarzystwo_Sportowe_DeSki" component={PageCourtsSettings} />
      <Route path="/app/klub/mkt_lodz" component={PageCourtsSettings} /> */}
      <Route
        path="/osrodek/Warszawskie_Towarzystwo_Sportowe_DeSki/rezerwacja_kortu"
        component={ClubReservationRedirectPage}
      />
      <Route path="*" component={Template404} />
    </Switch>
  );
}

ReactDOM.render(
  <Router history={history}>
    <div>
      <ReactNotifications />
      <App />
    </div>
  </Router>,
  document.getElementById("insert")
);
