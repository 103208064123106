import React, { Suspense } from 'react';

import Belt from '../components/Belt';
import Footer from '../components/Footer';

import DateTimeNow from "../utils/DateTimeNow";

import { Table, Card, Spinner } from "react-bootstrap";

import NotificationPrint from '../Notification/NotificationPrint'

import FechGetClass from "../classAppFechData/FechGetClass";
import ParseLinkToArray from "../utils/ParseLinkToArray"
import queryString from 'query-string'

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const SendEmailsToClassStudents = React.lazy(() => import('../classAppForms/SendEmailsToClassStudents'));

class PageMailToClassStudents extends React.Component {

  searchLessonsItem = {
    classId: '',
    page: ''
  };

  urlDeafulValues = [];

  urlValue = "";

  constructor(props) {
    super(props);

    this.state = {
      title: "RezerwujKort.pl - Szkółki tenisowe",
      logInfo: "LogDebug __" + DateTimeNow() + "__ PageMailToClassStudents ",
      currentPage: "1",
      rowsCount: "15",
      totalRecordsCount: "0",
      restClassData: {
        successful: false,
        status: '',
        message: '',
        data: []
      }
    }
  }

  async componentDidMount() {

    document.title = this.state.title;

    const { location: { search } } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.searchLessonsItem = await ParseLinkToArray(this.urlValue, this.searchLessonsItem, this.urlDeafulValues);

    this.searchLessonsItem.classId = this.props.match.params.id;

    this.setState({
      restClassData: await FechGetClass(this.props.match.params.club,
        this.searchLessonsItem.classId)
    });
  }

  render() {

    NotificationPrint.printOnNewPage(this.props.location.state);

    var start = "1"
    if (this.state.currentPage > 1) {
      start = this.state.rowsCount * (this.state.currentPage - 1);
    }

    const urlValues = new Map();
    urlValues.set('page', "replace");

    var emailForm;
    if (this.state.restClassData.successful) {
      emailForm = <div>
        <Suspense fallback={<div>
          <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
        </div>}>
          <SendEmailsToClassStudents id={this.props.match.params.id}
            club={this.props.match.params.club} />
        </Suspense>
      </div>;
    }

    var beltClassApp = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp club={this.props.match.params.club} />
      </Suspense>
    </div>

    return (

      <div>

        {beltClassApp}

        <div id="content">
          <div class="container">



            <br />

            <Card style={{ width: '100%' }}>
              <Card.Body>
                <Card.Title>Informację podstawowe o szkółce:</Card.Title>
                <Table responsive="sm">

                  <tbody>
                    <tr>
                      <td><b>Nazwa szkółki:</b></td>
                      <td>{this.state.restClassData.data.name}</td>
                      <td><b>Opis:</b></td>
                      <td>{this.state.restClassData.data.description}</td>
                    </tr>
                    <tr>
                      <td><b>Kort:</b></td>
                      <td>{this.state.restClassData.data.court}</td>
                      <td><b>Dzień:</b></td>
                      <td>{this.state.restClassData.data.dayOfWeek}</td>
                    </tr>
                    <tr>
                      <td><b>Godzina start:</b></td>
                      <td>{this.state.restClassData.data.hourStart}</td>
                      <td><b>Godzina koniec:</b></td>
                      <td>{this.state.restClassData.data.hourStop}</td>
                    </tr>
                    <tr>
                      <td><b>Data start:</b></td>
                      <td>{this.state.restClassData.data.dateStart}</td>
                      <td><b>Data koniec:</b></td>
                      <td>{this.state.restClassData.data.dateStop}</td>
                    </tr>
                    <tr>
                      <td><b>Trener:</b></td>
                      <td>{this.state.restClassData.data.trainer}</td>
                      <td><b>Utworzone:</b></td>
                      <td>{this.state.restClassData.data.createdAt}</td>
                    </tr>
                    <tr>

                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>


            <br /><br />

            {emailForm}


          </div>
        </div>


      </div>
    );
  }
}
export default PageMailToClassStudents;
