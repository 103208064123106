const regExp = RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/);

class ValidationFormField {
  courtDefaultValue = "Wybierz kort";
  hourDefaultValue = "Wybierz godzinę";
  dateDefaultValue = "Wybierz datę";
  trainerDefaultValue = "Wybierz trenera";
  classTypeDefaultValue = "Wybierz typ";
  emptyValue = "Wybierz typ";

  formFieldValid(isError, name, value, compareValue, item) {
    //let isError = { ...this.state.isError };

    var temp = "";

    switch (name) {
      case "name":
        isError.name = this.validName("Imię", value);
        break;
      case "nameAdmin":
        isError.nameAdmin = this.validNameRequire("Nazwa promocji", value);
        break;
      case "nameUser":
        isError.nameUser = this.validNameLongNotRequire(
          "Nazwa promocji",
          value
        );
        break;
      case "surname":
        isError.surname = this.validSurname("Nazwisko", value);
        break;
      case "email":
        isError.email = this.validEmail("Email", value);
        break;
      case "phone":
        isError.phone = this.validPhoneNotRequired("Telefon", value);
      case "phoneRequired":
        isError.phone = this.validPhoneRequired("Telefon", value);
      case "validPhoneNotRequired":
        isError.phone = this.validPhoneNotRequiredOrNumber("Telefon", value);
      case "code":
        isError.code = this.validCode("Otrzymany kod sms", value);
      case "priority":
        isError.priority = this.validNumber("Priorytet", value);
      case "nameNewClient":
        isError.nameNewClient = this.validClassName("Imię", value);
        break;
      case "surnameNewClient":
        isError.surnameNewClient = this.validClassSurname("Nazwisko", value);
        break;
      case "phoneNewClient":
        isError.phoneNewClient = this.validReservationNewClintPhoneName(
          "Telefon",
          value
        );
        break;
      case "descriptionReservation":
        isError.descriptionReservation = this.validReservationDescription(
          "Opis rezerwacji",
          value
        );
        break;
      case "price":
        isError.price = this.validPrice("Cena", value);
        break;
      case "ticketCourtPrice":
        isError.ticketCourtPrice = this.validPrice("Cena", value);
        break;
      case "ticketTrainerPrice":
        isError.ticketTrainerPrice = this.validPrice("Cena", value);
        break;
      case "valueNewTicket":
        isError.valueNewTicket = this.validPrice(
          "Wartość karnetu",
          value.toString()
        );
        break;
      case "court":
        isError.court = this.validCourt(value, this.courtDefaultValue);
        break;
      case "courtCloseOpen":
        isError.courtCloseOpen = this.validCourt(value, this.courtDefaultValue);
        break;
      case "dayOfWeek":
        isError.dayOfWeek = this.validDayOfWeek(value, this.dayDefaultValue);
        break;
      case "dayOfWeekList":
        isError.dayOfWeek = this.validDayOfWeekList(
          value,
          this.dayDefaultValue
        );
        break;
      case "usersType":
        isError.usersType = this.validSelectNotEmpty("", value);
        break;
      case "promotionType":
        isError.promotionType = this.validSelectNotEmpty("", value);
        break;
      case "promoFromHoleReservation":
        isError.promoFromHoleReservation = this.validSelectNotEmpty("", value);
        break;
      case "howManyTimesCanBeUsed":
        isError.howManyTimesCanBeUsed = this.validSelectNotEmpty("", value);
        break;
      case "msgToUserInEmail":
        isError.msgToUserInEmail = this.validEmailMessage("Pole", value);
        break;
      case "hourStart":
        if (compareValue != undefined) {
          temp = compareValue;
        } else {
          temp = item.hourStop;
        }
        isError.hourStart = this.validHourStart(
          value,
          this.hourDefaultValue,
          temp
        );
        break;
      case "hourStop":
        if (compareValue != undefined) {
          temp = compareValue;
        } else {
          temp = item.hourStart;
        }
        isError.hourStop = this.validHourStop(
          value,
          this.hourDefaultValue,
          temp
        );
        break;
      case "mondayOpen":
        if (compareValue != undefined) {
          temp = compareValue;
        } else {
          temp = item.mondayClose;
        }
        isError.mondayOpen = this.validHourStart(
          value,
          this.hourDefaultValue,
          temp
        );
        break;
      case "mondayClose":
        if (compareValue != undefined) {
          temp = compareValue;
        } else {
          temp = item.mondayOpen;
        }
        isError.mondayClose = this.validHourStop(
          value,
          this.hourDefaultValue,
          temp
        );
        break;
      case "tuesdayOpen":
        if (compareValue != undefined) {
          temp = compareValue;
        } else {
          temp = item.tuesdayClose;
        }
        isError.tuesdayOpen = this.validHourStart(
          value,
          this.hourDefaultValue,
          temp
        );
        break;
      case "tuesdayClose":
        if (compareValue != undefined) {
          temp = compareValue;
        } else {
          temp = item.tuesdayOpen;
        }
        isError.tuesdayClose = this.validHourStop(
          value,
          this.hourDefaultValue,
          temp
        );
        break;
      case "wednesdayOpen":
        if (compareValue != undefined) {
          temp = compareValue;
        } else {
          temp = item.wednesdayClose;
        }
        isError.wednesdayOpen = this.validHourStart(
          value,
          this.hourDefaultValue,
          temp
        );
        break;
      case "wednesdayClose":
        if (compareValue != undefined) {
          temp = compareValue;
        } else {
          temp = item.wednesdayOpen;
        }
        isError.wednesdayClose = this.validHourStop(
          value,
          this.hourDefaultValue,
          temp
        );
        break;
      case "thursdayOpen":
        if (compareValue != undefined) {
          temp = compareValue;
        } else {
          temp = item.thursdayClose;
        }
        isError.thursdayOpen = this.validHourStart(
          value,
          this.hourDefaultValue,
          temp
        );
        break;
      case "thursdayClose":
        if (compareValue != undefined) {
          temp = compareValue;
        } else {
          temp = item.thursdayOpen;
        }
        isError.thursdayClose = this.validHourStop(
          value,
          this.hourDefaultValue,
          temp
        );
        break;
      case "fridayOpen":
        if (compareValue != undefined) {
          temp = compareValue;
        } else {
          temp = item.fridayClose;
        }
        isError.fridayOpen = this.validHourStart(
          value,
          this.hourDefaultValue,
          temp
        );
        break;
      case "fridayClose":
        if (compareValue != undefined) {
          temp = compareValue;
        } else {
          temp = item.fridayOpen;
        }
        isError.fridayClose = this.validHourStop(
          value,
          this.hourDefaultValue,
          temp
        );
        break;
      case "saturdayOpen":
        if (compareValue != undefined) {
          temp = compareValue;
        } else {
          temp = item.saturdayClose;
        }
        isError.saturdayOpen = this.validHourStart(
          value,
          this.hourDefaultValue,
          temp
        );
        break;
      case "saturdayClose":
        if (compareValue != undefined) {
          temp = compareValue;
        } else {
          temp = item.saturdayOpen;
        }
        isError.saturdayClose = this.validHourStop(
          value,
          this.hourDefaultValue,
          temp
        );
        break;
      case "sundayOpen":
        if (compareValue != undefined) {
          temp = compareValue;
        } else {
          temp = item.sundayClose;
        }
        isError.sundayOpen = this.validHourStart(
          value,
          this.hourDefaultValue,
          temp
        );
        break;
      case "sundayClose":
        if (compareValue != undefined) {
          temp = compareValue;
        } else {
          temp = item.sundayOpen;
        }
        isError.sundayClose = this.validHourStop(
          value,
          this.hourDefaultValue,
          temp
        );
        break;
      case "dateStart":
        if (compareValue != undefined) {
          temp = compareValue;
        } else {
          temp = item.dateStop;
        }
        isError.dateStart = this.validDateStart(
          value,
          this.dateDefaultValue,
          temp
        );
        break;
      case "dateStop":
        if (compareValue != undefined) {
          temp = compareValue;
        } else {
          temp = item.dateStart;
        }
        isError.dateStop = this.validDateStop(
          value,
          this.dateDefaultValue,
          temp
        );
        break;
      case "trainer":
        isError.trainer = this.validTrainer(value, this.trainerDefaultValue);
        break;
      case "statusCloseOpen":
        isError.statusCloseOpen = this.validStatusCloseOpen(
          value,
          this.classTypeDefaultValue
        );
        break;
      case "classType":
        isError.classType = this.validClassType(
          value,
          this.classTypeDefaultValue
        );
        break;
      case "clientId":
        isError.clientId = this.validClientId(
          value,
          this.classTypeDefaultValue
        );
        break;
      case "clientIdNewTicket":
        isError.clientIdNewTicket = this.validClientId(
          value,
          this.classTypeDefaultValue
        );
        break;
      case "role":
        isError.role = this.validRole(value);
        break;
      case "password":
        isError.password = this.validPassword("Hasło", value);
        break;
      case "passwordConfirm":
        if (compareValue != undefined) {
          temp = compareValue;
        } else {
          temp = item.password;
        }
        isError.passwordConfirm = this.validPasswordConfirm(value, temp);
        break;
      default:
        isError.password = "";
        break;
    }

    isError[name] = isError[name];
    return isError;
  }

  formValid(isError, ...rest) {
    let isValid = false;
    let exit = false;

    Object.values(isError).forEach((val) => {
      if (val !== undefined && exit === false) {
        if (val.length > 0) {
          isValid = false;
          exit = true;
        } else {
          isValid = true;
        }
      }
    });

    return isValid;
  }

  validationLengthMin(fildName, name, length) {
    if (name.length < length) {
      return fildName + " musi mieć conajmniej " + length + " znaki!";
    } else {
      return "";
    }
  }

  validationLengthMax(fildName, name, length) {
    if (name.length > length) {
      return fildName + " może mieć maksymalnie " + length + " znaków!";
    } else {
      return "";
    }
  }

  validationLengthExactly(fildName, name, length) {
    if (name.length != length) {
      return fildName + " musi mieć " + length + " znaków!";
    } else {
      return "";
    }
  }

  validName(fildName, value) {
    // var validationLengthMin = this.validationLengthMin(fildName, value, 3);
    // if (validationLengthMin !== "") return validationLengthMin

    var validationLengthMax = this.validationLengthMax(fildName, value, 15);
    if (validationLengthMax !== "") return validationLengthMax;

    return "";
  }

  validNameLongNotRequire(fildName, value) {
    // var validationLengthMin = this.validationLengthMin(fildName, value, 3);
    // if (validationLengthMin !== "") return validationLengthMin

    var validationLengthMax = this.validationLengthMax(fildName, value, 250);
    if (validationLengthMax !== "") return validationLengthMax;

    return "";
  }

  validNameRequire(fildName, value) {
    var validationLengthMin = this.validationLengthMin(fildName, value, 2);
    if (validationLengthMin !== "") return validationLengthMin;

    var validationLengthMax = this.validationLengthMax(fildName, value, 20);
    if (validationLengthMax !== "") return validationLengthMax;

    return "";
  }

  validSelectNotEmpty(fildName, value) {
    if (value === "") {
      return "Pole nie może być puste!";
    } else {
      return "";
    }
  }

  validClassName(fildName, value) {
    // var validationLengthMin = this.validationLengthMin(fildName, value, 3);
    // if (validationLengthMin !== "") return validationLengthMin

    var validationLengthMax = this.validationLengthMax(fildName, value, 250);
    if (validationLengthMax !== "") return validationLengthMax;

    return "";
  }

  validClassSurname(fildName, value) {
    var validationLengthMin = this.validationLengthMin(fildName, value, 3);
    if (validationLengthMin !== "") return validationLengthMin;

    var validationLengthMax = this.validationLengthMax(fildName, value, 250);
    if (validationLengthMax !== "") return validationLengthMax;

    return "";
  }

  validClassStudent(fildName, value) {
    if (value === undefined || value === "") {
      return "Musisz wybrać uczestnika!";
    }

    return "";
  }

  validEmailMessage(fildName, value) {
    if (value != "") {
      var validationLengthMin = this.validationLengthMin(fildName, value, 2);
      if (validationLengthMin !== "") return validationLengthMin;

      var validationLengthMax = this.validationLengthMax(fildName, value, 2500);
      if (validationLengthMax !== "") return validationLengthMax;
    }
    return "";
  }

  validLongDescription(fildName, value) {
    var validationLengthMin = this.validationLengthMin(fildName, value, 2);
    if (validationLengthMin !== "") return validationLengthMin;

    var validationLengthMax = this.validationLengthMax(fildName, value, 2500);
    if (validationLengthMax !== "") return validationLengthMax;

    return "";
  }

  validClassDescription(fildName, value) {
    var validationLengthMin = this.validationLengthMin(fildName, value, 2);
    if (validationLengthMin !== "") return validationLengthMin;

    var validationLengthMax = this.validationLengthMax(fildName, value, 250);
    if (validationLengthMax !== "") return validationLengthMax;

    return "";
  }

  validReservationDescription(fildName, value) {
    var validationLengthMax = this.validationLengthMax(fildName, value, 250);
    if (validationLengthMax !== "") return validationLengthMax;

    return "";
  }

  validPhone(fildName, value) {
    var validationLengthMin = this.validationLengthMin(fildName, value, 3);
    if (validationLengthMin !== "") return validationLengthMin;

    var validationLengthMax = this.validationLengthMax(fildName, value, 25);
    if (validationLengthMax !== "") return validationLengthMax;

    return "";
  }

  onlyNumber(fildName, value) {
    const pattern = /^[0-9]+$/;
    if (!pattern.test(value)) {
      return fildName + " może zawierać tylko cyfry!";
    }
    return "";
  }

  validPhoneNotRequired(fildName, value) {
    var validationLengthMax = this.validationLengthMax(fildName, value, 25);
    if (value.length > 0) this.onlyNumber(fildName, value);
    if (validationLengthMax !== "") return validationLengthMax;

    return "";
  }

  validPhoneNotRequiredOrNumber(fildName, value) {
    if (value.length > 0) {
      var validationLengthMax = this.validationLengthExactly(
        fildName,
        value,
        9
      );
      var onlyNumber = "";
      if (value.length > 0) {
        onlyNumber = this.onlyNumber(fildName, value);
      }
      if (onlyNumber !== "") return onlyNumber;
      if (validationLengthMax !== "") return validationLengthMax;
    }
    return "";
  }

  validPhoneRequired(fildName, value) {
    var onlyNumber = "";
    if (value.length > 0) onlyNumber = this.onlyNumber(fildName, value);
    if (onlyNumber !== "") return onlyNumber;
    var validationLengthMin = this.validationLengthMin(fildName, value, 3);
    var validationLengthMax = this.validationLengthMax(fildName, value, 25);
    if (validationLengthMin !== "") return validationLengthMin;
    if (validationLengthMax !== "") return validationLengthMax;

    return "";
  }

  validCode(fildName, value) {
    var onlyNumber = "";
    if (value.length > 0) onlyNumber = this.onlyNumber(fildName, value);
    if (onlyNumber !== "") return onlyNumber;
    var validationLengthMin = this.validationLengthMin(fildName, value, 2);
    var validationLengthMax = this.validationLengthMax(fildName, value, 6);
    if (validationLengthMin !== "") return validationLengthMin;
    if (validationLengthMax !== "") return validationLengthMax;

    return "";
  }

  validNumber(fildName, value) {
    var onlyNumber = "";
    if (value.length > 0) onlyNumber = this.onlyNumber(fildName, value);
    if (onlyNumber !== "") return onlyNumber;
    var validationLengthMin = this.validationLengthMin(fildName, value, 1);
    var validationLengthMax = this.validationLengthMax(fildName, value, 3);
    if (validationLengthMin !== "") return validationLengthMin;
    if (validationLengthMax !== "") return validationLengthMax;

    return "";
  }

  validSurname(fildName, value) {
    var validationLengthMin = this.validationLengthMin(fildName, value, 2);
    //console.log(" walidddd : validationLengthMin " + JSON.stringify(validationLengthMin));
    if (validationLengthMin !== "") return validationLengthMin;

    var validationLengthMax = this.validationLengthMax(fildName, value, 25);
    if (validationLengthMax !== "") return validationLengthMax;

    return "";
  }

  validEmail(fildName, value) {
    var validationLengthMin = this.validationLengthMin(fildName, value, 3);
    //console.log(" walidddd : validationLengthMin " + JSON.stringify(validationLengthMin));
    if (validationLengthMin !== "") return validationLengthMin;

    var validationLengthMax = this.validationLengthMax(fildName, value, 130);
    if (validationLengthMax !== "") return validationLengthMax;

    // var name = regExp.test(value);
    // if (!name) return "Błędny email!";

    return "";
  }

  validEmailMsg(fildName, value) {
    var validationLengthMin = this.validationLengthMin(fildName, value, 3);
    if (validationLengthMin !== "") return validationLengthMin;

    return "";
  }

  validEmailTitle(fildName, value) {
    var validationLengthMin = this.validationLengthMin(fildName, value, 3);
    if (validationLengthMin !== "") return validationLengthMin;

    var validationLengthMax = this.validationLengthMax(fildName, value, 130);
    if (validationLengthMax !== "") return validationLengthMax;

    return "";
  }

  validRoles(value) {
    if (Array.isArray(value) && !value.length) {
      return "Musisz wybrać rolę!";
    } else {
      return "";
    }
  }

  validRole(value) {
    if (value == "") {
      return "Musisz wybrać uprawnienia!";
    } else {
      return "";
    }
  }

  validCourt(value, defaultValue) {
    if (value == defaultValue || value == "") {
      return "Musisz wybrać kort!";
    } else {
      return "";
    }
  }

  validDayOfWeek(value, defaultValue) {
    if (
      value == defaultValue ||
      value == "0" ||
      value == "" ||
      value.length > 1
    ) {
      return "Musisz wybrać dzień tygodnia!";
    } else {
      return "";
    }
  }

  validDayOfWeekList(value, defaultValue) {
    if (value == defaultValue || value == "0" || value == "") {
      return "Musisz wybrać dzień tygodnia!";
    } else {
      return "";
    }
  }

  validHourStart(value, defaultValue, hourStop) {
    var error = "";
    if (value == defaultValue || value == "") {
      return "Musisz wybrać godzinę rozpoczęcia!";
    }
    if (
      value !== undefined &&
      value.length === 5 &&
      hourStop !== undefined &&
      hourStop.length === 5
    ) {
      var dateStartDate = new Date("2022-11-11T" + value);
      var dateStopDate = new Date("2022-11-11T" + hourStop);
      if (dateStartDate >= dateStopDate) {
        error = "Godzina końca nie może być niższa niż data początkowa!";
      }
    }
    return error;
  }

  validHourStop(value, defaultValue, hourStart) {
    var error = "";
    if (value == defaultValue || value == "") {
      return "Musisz wybrać godzinę zakończenia!";
    }
    if (
      value !== undefined &&
      value.length === 5 &&
      hourStart !== undefined &&
      hourStart.length === 5
    ) {
      var dateStartDate = new Date("2022-11-11T" + hourStart);
      var dateStopDate = new Date("2022-11-11T" + value);
      if (dateStartDate >= dateStopDate) {
        error = "Godzina końca nie może być niższa niż data początkowa!";
      }
    }
    return error;
  }

  validDateStart(value, defaultValue, dateStop) {
    var error = "";
    if (value == defaultValue || value == "") {
      error = "Musisz wybrać datę rozpoczęcia!";
    }
    if (
      value !== undefined &&
      value.length === 10 &&
      dateStop !== undefined &&
      dateStop.length === 10
    ) {
      var dateStartDate = new Date(value);
      var dateStopDate = new Date(dateStop);
      if (dateStartDate >= dateStopDate) {
        error = "Data końca nie może być niższa niż data początkowa!";
      }
      //this.validDateStop(dateStop, defaultValue, value);
    }
    return error;
  }

  validDateStop(value, defaultValue, dateStart) {
    var error = "";
    if (value == defaultValue || value == "") {
      error = "Musisz wybrać datę zakończenia!";
    }
    if (
      value !== undefined &&
      value.length === 10 &&
      dateStart !== undefined &&
      dateStart.length === 10
    ) {
      var dateStartDate = new Date(dateStart);
      var dateStopDate = new Date(value);
      if (dateStartDate >= dateStopDate) {
        error = "Data końca nie może być niższa niż data początkowa!";
      }
    }
    return error;
  }

  validTrainer(value, defaultValue) {
    if (value == defaultValue || value == "") {
      return "Musisz wybrać trenera!";
    } else {
      return "";
    }
  }

  validStatusCloseOpen(value, defaultValue) {
    if (value == defaultValue || value === "") {
      return "Musisz wybrać Status!";
    } else {
      return "";
    }
  }

  validClassType(value, defaultValue) {
    if ((value == defaultValue || value == "") && value != "0") {
      return "Musisz wybrać typ szkółki!";
    } else {
      return "";
    }
  }

  validProvince(value, defaultValue) {
    if (value == defaultValue || value == "") {
      return "Musisz wybrać województwo!";
    } else {
      return "";
    }
  }

  validCity(value, defaultValue) {
    if (value == defaultValue || value == "") {
      return "Musisz wybrać miasto!";
    } else {
      return "";
    }
  }

  validClientId(value, defaultValue) {
    if (value == defaultValue || value == "") {
      return "Musisz wybrać klienta!";
    } else {
      return "";
    }
  }

  validRegulationCheckbox(value) {
    if (!value) {
      return "Musisz zatwierdzić regulamin!";
    } else {
      return "";
    }
  }

  validPassword(fildName, value) {
    var validationLengthMin = this.validationLengthMin(fildName, value, 6);
    if (validationLengthMin !== "") return validationLengthMin;

    var validationLengthMax = this.validationLengthMax(fildName, value, 50);
    if (validationLengthMax !== "") return validationLengthMax;

    return "";
  }

  validPasswordConfirm(value1, value2) {
    if (value1 == "") {
      return "To pole nie może być puste!";
    }
    if (value1 != value2) {
      return "Hasła muszą być identyczne!";
    } else {
      return "";
    }
  }

  validPrice(fildName, value) {
    this.validNotEmpty(fildName, value);
    var regex = /[0-9]|\./;
    var value = value.replace(",", ".");
    if (isNaN(value)) {
      return "Pole " + fildName + " musi być liczbą!";
    } else {
      return "";
    }
  }

  validNotEmpty(fildName, value) {
    if (value == "") {
      return "Pole " + fildName + " nie może być puste!";
    } else {
      return "";
    }
  }

  validReservationNewClintPhoneName(fildName, value) {
    if (value == "") {
      return "Pole " + fildName + " nie może być puste!";
    }
    var validationLengthMin = this.validationLengthMin(fildName, value, 6);
    if (validationLengthMin !== "") return validationLengthMin;

    var validationLengthMax = this.validationLengthMax(fildName, value, 15);
    if (validationLengthMax !== "") return validationLengthMax;

    return "";
  }
}

export default new ValidationFormField();
