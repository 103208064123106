import React, { Suspense } from 'react';

import Belt from '../components/Belt';
import Footer from '../components/Footer';

import DateTimeNow from "../utils/DateTimeNow";
import PageCounterNew from '../components/PageCounterNew';

import { Link } from 'react-router-dom'
import { Button, Table, Card, Spinner } from "react-bootstrap";

import NotificationPrint from '../Notification/NotificationPrint'

import FechGetClass from "../classAppFechData/FechGetClass";
import FechPostLessonsClass from "../classAppFechData/FechPostLessonsClass";
import ParseLinkToArray from "../utils/ParseLinkToArray"
import queryString from 'query-string'

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const TabAllClassLessons = React.lazy(() => import('../classAppComponents/TabAllClassLessons'));

class PageShowClass extends React.Component {

  searchLessonsItem = {
    classId: '',
    page: ''
  };

  urlDeafulValues = [];

  urlValue = "";

  constructor(props) {
    super(props);

    this.state = {
      title: "RezerwujKort.pl - Szkółki tenisowe",
      logInfo: "LogDebug __" + DateTimeNow() + "__ PageShowClass ",
      currentPage: "1",
      rowsCount: "15",
      totalRecordsCount: "0",
      restClassData: {
        successful: false,
        status: '',
        message: '',
        data: []
      },
      restClassLessonsData: {
        successful: false,
        status: '',
        message: '',
        data: []
      }
    }
  }

  async componentDidMount() {

    document.title = this.state.title;

    const { location: { search } } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.searchLessonsItem = await ParseLinkToArray(this.urlValue, this.searchLessonsItem, this.urlDeafulValues);

    this.searchLessonsItem.classId = this.props.match.params.id;

    this.state.restClassLessonsData = await FechPostLessonsClass(this.props.match.params.club,
      this.searchLessonsItem);

    if (this.state.restClassLessonsData.successful) { 
        this.state.totalRecordsCount = this.state.restClassLessonsData.data.totalElements;
    }

    this.setState({
      restClassData: await FechGetClass(this.props.match.params.club,
        this.searchLessonsItem.classId)
    });
  }

  render() {

    NotificationPrint.printOnNewPage(this.props.location.state);

    var start = "1"
    if (this.state.currentPage > 1) {
      start = this.state.rowsCount * (this.state.currentPage - 1);
    }

    const urlValues = new Map();
    urlValues.set('page', "replace");

    var tabAllClassLessons = "";
    if (!this.state.restClassLessonsData.successful) {
      tabAllClassLessons = <div id="loader_div">
        <Spinner animation="border" />
      </div>
    } else {
      tabAllClassLessons = <div>
        <Suspense fallback={<div>
          <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
        </div>}>
          <TabAllClassLessons
            clubId={this.props.match.params.club}
            restClassLessonsData={this.state.restClassLessonsData.data}
            start={start}
            page={this.state.currentPage}
            classid={this.props.match.params.id} />
        </Suspense>
      </div>
    }

    var beltClassApp = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp club={this.props.match.params.club} />
      </Suspense>
    </div>

    return (

      <div>

{beltClassApp}

        <div id="content">
        <div style={{ paddingTop: '55px'}}>

            

            <br /><br /><br />

            <Card style={{ width: '100%' }}>
              <Card.Body>
                <Card.Title>Informację podstawowe o szkółce:</Card.Title>
                <Table responsive="sm">

                  <tbody>
                    <tr>
                      <td><b>Nazwa szkółki:</b></td>
                      <td>{this.state.restClassData.data.name}</td>
                      <td><b>Opis:</b></td>
                      <td>{this.state.restClassData.data.description}</td>
                    </tr>
                    <tr>
                      <td><b>Kort:</b></td>
                      <td>{this.state.restClassData.data.court}</td>
                      <td><b>Dzień:</b></td>
                      <td>{this.state.restClassData.data.dayOfWeek}</td>
                    </tr>
                    <tr>
                      <td><b>Godzina start:</b></td>
                      <td>{this.state.restClassData.data.hourStart}</td>
                      <td><b>Godzina koniec:</b></td>
                      <td>{this.state.restClassData.data.hourStop}</td>
                    </tr>
                    <tr>
                      <td><b>Data start:</b></td>
                      <td>{this.state.restClassData.data.dateStart}</td>
                      <td><b>Data koniec:</b></td>
                      <td>{this.state.restClassData.data.dateStop}</td>
                    </tr>
                    <tr>
                      <td><b>Trener:</b></td>
                      <td>{this.state.restClassData.data.trainer}</td>
                      <td><b>Utworzone:</b></td>
                      <td>{this.state.restClassData.data.createdAt}</td>
                    </tr>
                    <tr>

                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>


            <br /><br />

            <Link to={"/class_app/" + this.props.match.params.club + "/lesson_add/" + this.state.restClassData.data.id}>
              <Button variant="primary" type="submit">
                Dodaj trening
              </Button>&nbsp;&nbsp;
            </Link>

            <Link to={"/class_app/" + this.props.match.params.club + "/class_show_students/" + this.state.restClassData.data.id}>
              <Button variant="primary" type="submit">
                Uczestnicy
              </Button>&nbsp;&nbsp;
            </Link>

            <Link to={"/class_app/" + this.props.match.params.club + "/class_edit/" + this.state.restClassData.data.id}>
              <Button variant="primary" type="submit">
                Edytuj szkókę
              </Button>&nbsp;&nbsp;
            </Link>

            <Link to={"/class_app/" + this.props.match.params.club + "/class_delete/" + this.state.restClassData.data.id}>
              <Button variant="primary" type="submit">
                Usuń szkókę
              </Button>&nbsp;&nbsp;
            </Link>

            <Link to={"/class_app/" + this.props.match.params.club + "/class/mail_to_students/" + this.state.restClassData.data.id}>
              <Button variant="primary" type="submit">
                Wyślij maila do uczestników
              </Button>&nbsp;&nbsp;
            </Link>

            <br /><br />
            <b>Lista wszystkich zajęć ({this.state.totalRecordsCount}):</b>
            <br /><br />

            {tabAllClassLessons}

            <PageCounterNew
              elementCount={this.state.totalRecordsCount}
              urlValues={urlValues}
              urlDeafulValues={this.urlDeafulValues}
              elementOnPage={this.state.rowsCount}
              currentPage={parseInt(this.searchLessonsItem.page) + 1}
              searchName={"lesson_search"}
              linkTo={"class_app/" + this.props.match.params.club + "/class_show/" + this.state.restClassData.data.id}
            />

          </div>
        </div>


      </div>
    );
  }
}
export default PageShowClass;
