import FetchPostDataWithAuthNEW from "../services/FetchPostDataWithAuthNEW";

export default async function FechPostAddPlayer(club, league, body) {

  var url = "/league_app/" + club + "/" + league + "/admin/add_player";

  var data = await FetchPostDataWithAuthNEW(url, body)

  return data;
}
