import FetchPostDataWithAuthNEW from "../services/FetchPostDataWithAuthNEW"; 

export default function FechCreateGame(club, league, body) {
 
  var url = "/league_app/" + club + "/" + league + "/admin/add_match";
  var data = FetchPostDataWithAuthNEW(url, body);

  return data;
}

