import React, { Suspense } from 'react';

import { Spinner, Button } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl"; // the locale you want

import FechGetReservationsRegularBySearch from "../APP_RESERVATION_FECH_DATA/FechGetReservationsRegularBySearch";

import PageCounterNew from '../components/PageCounterNew';
import BeltReservationApp from '../APP_RESERVATION_COMPONENTS/BeltReservationApp'

import queryString from 'query-string'
import moment from 'moment'
import ParseLinkToArray from "../utils/ParseLinkToArray"

const AddRegularReservationPopupForm = React.lazy(() => import('../APP_RESERVATION_FORM_POPUP/AddRegularReservationPopupForm'));
const TabAllReservationRegular = React.lazy(() => import('../APP_RESERVATION_COMPONENTS/TabAllReservationRegular'));
const SearchRegularReservation = React.lazy(() => import('../APP_RESERVATION_FORMS/SearchRegularReservation'));

let plLocate = require('moment/locale/pl');
moment.locale('pl', plLocate)

//KLASA WYSWIETLA SZCZEGOLY KONKRETNYCH ZAJEC SZKOLKI
class PageRegularReservation extends React.Component {

    TRAINER_SELECT_DESC = "Wybierz trenera";
    DATE_SELECT_DESC = "Wybierz datę";
    CLASS_SELECT_DESC = "Wybierz szkółke";

    urlDeafulValues = [this.TRAINER_SELECT_DESC, this.CLASS_SELECT_DESC, this.DATE_SELECT_DESC];

    urlValue = "";

    postData = {
        clientId: '',
        trainer: '',
        dateStart: '',
        dateStop: '',
        trainer: '',
        classId: '',
        courtId: "",
        status: '',
        sortBy: '',
        searchDirection: '',
        page: '0'
    };

    constructor(props) {
        super(props);

        this.state = {
            title: "RezerwujKort.pl - Rezerwację",
            datePickerIsOpen: false,
            previousDay: "",
            refreshReservation: "",
            addStandardReservationPopupForm: "",
            restReservationData: {
                successful: false,
                status: '',
                message: '',
                data: []
            }
        }
        this.handlerRefreshReservation = this.handlerRefreshReservation.bind(this);
    }

    async componentDidMount() {

        document.title = "Rezerwacje - RezerwujKort.pl";
        registerLocale("pl", pl); // register it with the name you want
        const { location: { search } } = this.props;
        this.state.search = search;
        this.urlValue = queryString.parse(this.state.search);

        this.postData = await ParseLinkToArray(this.urlValue, this.postData, this.urlDeafulValues);

        this.setState({ restReservationData: await FechGetReservationsRegularBySearch(this.props.match.params.club, search) });
    }

    async addReservationForm() {

        this.setState({
            addStandardReservationPopupForm: <div>
                <Suspense fallback={<div>
                    <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
                </div>}>
                    <AddRegularReservationPopupForm
                        dateReservation={""}
                        club={this.props.match.params.club}
                        refresh={this.handlerRefreshReservation}
                    />
                </Suspense>
            </div>
        });
    }

    async handlerRefreshReservation() {

        const { location: { search } } = this.props;
        this.state.search = search;
        this.urlValue = queryString.parse(this.state.search);
        this.postData = await ParseLinkToArray(this.urlValue, this.postData, this.urlDeafulValues);
        //var refreash = this.props.refresh(this.props.reservationData.data.date);
        this.setState({ restReservationData: await FechGetReservationsRegularBySearch(this.props.match.params.club, search) });
    }


    render() {

        var loader = "";
        if (!this.state.restReservationData.successful) {
            loader = <div id="loader_div">
                <Spinner animation="border" />
            </div>
            totalRecords = this.state.restReservationData.data
        }

        var totalRecords = "";
        var tabAllReservationRegular = "";
        var pageCounter = "";
        var searchPanel = "";

        if (this.state.restReservationData.successful) {
            totalRecords = this.state.restReservationData.data.totalRecordsCount

            tabAllReservationRegular = <div>
                <Suspense fallback={<div>
                    <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
                </div>}>
                    <TabAllReservationRegular
                        club={this.props.match.params.club}
                        restLessonsData={this.state.restReservationData.data.reservationResources}
                        refresh={this.handlerRefreshReservation} />
                </Suspense>
            </div>

            const urlValues = new Map();
            urlValues.set('clientId', this.postData.clientId)
            urlValues.set('trainer', this.postData.trainer)
            urlValues.set('courtId', this.postData.courtId)
            urlValues.set('sortBy', this.postData.sortBy);
            urlValues.set('status', this.postData.status);
            urlValues.set('searchDirection', this.postData.searchDirection);
            urlValues.set('page', "replace");

            pageCounter = <PageCounterNew
                elementCount={totalRecords}
                urlValues={urlValues}
                urlDeafulValues={this.urlDeafulValues}
                elementOnPage={this.state.restReservationData.data.recordsOnPage}
                currentPage={parseInt(this.postData.page) + 1}
                searchName={"search"}
                linkTo={"reservation_app/" + this.props.match.params.club + "/stale_rezerwacje"}
            />

            searchPanel = <div>
                <Suspense fallback={<div>
                    <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
                </div>}>
                    <SearchRegularReservation
                        urlValue={this.postData}
                        club={this.props.match.params.club}
                    />
                </Suspense>
            </div>
        }

        return (

            <div>

                <div id="content">

                    <BeltReservationApp club={this.props.match.params.club} />

                    {searchPanel}

                    <Button href="#" variant="success" size="lg" style={{ 'margin-left': '20px' }}
                        onClick={() => this.addReservationForm()}>
                        Dodaj stałą rezerwację
                    </Button>

                    <br /><br />
                    <b>Lista wszystkich stałych rezerwacji: ({totalRecords}):</b>
                    <br /><br />

                    {this.state.addStandardReservationPopupForm}
                    {loader}

                    {tabAllReservationRegular}

                    {pageCounter}


                </div>
            </div>
        );
    }
}
export default PageRegularReservation;
