
import React from 'react'
// import {
//   BrowserRouter as Router,
//   Route,
//   withRouter,
//   Switch,
//   Link
// } from 'react-router-dom';

//UTILS
import DateTimeNow from "../utils/DateTimeNow"; 


class PageCounter extends React.Component {

  state = {
     howManyPages: "0",
     split: '',
     logInfo: "LogDebug __" + DateTimeNow() + "__ PageCounter "
  }

	render() {

        var elements=[];
        var currentPage = this.props.currentPage;
        console.log(this.state.logInfo + " this.props.linkTo:::::::: " + this.props.linkTo);
        //jesli tak to generujemy pasek, jesli nie znaczy ze elementow jest mniej niz wyswietlamy
        if ( this.props.elementCount  > this.props.elementOnPage) {

          let howManyPages = Math.ceil(this.props.elementCount / this.props.elementOnPage);
          this.state.howManyPages = howManyPages;
//console.log(this.state.logInfo + " howManyPages:::::::: " + howManyPages);    
//console.log(this.state.logInfo + " this.props.currentPage:::::::: " + currentPage);
          // if(!parseInt(this.state.test)){
          //   let split = String(this.state.howManyPages).split('.');
          //   this.state.split=split;
          //   howManyPages = Number(this.state.split[0]) + 1;
          //   this.state.howManyPages=howManyPages;
          // }

          if (this.props.currentPage == undefined) {
              currentPage = 1;
//console.log(this.state.logInfo + " jesttt:::::::: " + currentPage);
          }

          var previousPage = "0";
          previousPage = Number(currentPage)-1;
          if(previousPage < 1){
            previousPage = 1;
          }
//console.log(this.state.logInfo + " this.state.howManyPages:::::::: " + this.state.howManyPages );
//console.log(this.state.logInfo + " currentPage:::::::: " + currentPage);

          elements.push(<div class='paggination-bg'><a href={"/" + this.props.linkTo + "/1"}><div class='paggination-left'></div></a></div>)
          elements.push(<div class='paggination-bg'><a href={"/" + this.props.linkTo + "/" + previousPage}><div class='paggination-left'></div></a></div>)

          for(var i=1;i<=this.state.howManyPages;i++){
              if(i == currentPage){
                  elements.push(<div class='paggination-number current'>{i}</div>);
              }else{
                  elements.push(<a href= {"/" + this.props.linkTo + "/" + i} ><div class='paggination-number'>{i}</div></a>);
              }
          }

          var nextPage = "0";
          var lastPage = this.state.howManyPages;

          nextPage = Number(currentPage)+1;
          if(nextPage > lastPage){
            nextPage = lastPage;
          }

          elements.push(<div class='paggination-bg'><a href={"/" + this.props.linkTo + "/" + nextPage } ><div class='paggination-right'></div></a></div>);
          elements.push(<div class='paggination-bg'><a href={"/" + this.props.linkTo + "/" + lastPage } ><div class='paggination-right'></div></a></div>);

        } 



		return (
        <div style={{clear: 'both', marginTop: '35px', textAlign:'center', height: '60px', marginLeft: '50px'}}>
        <br/>
            <div >
                  {elements}
            </div> 
        </div>
		)
	}
}

export default PageCounter;