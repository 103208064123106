import React from 'react'
import axios from 'axios';
import authHeader from './auth-header';

import AuthService from "../services/auth.service";
import ErrorPage   from "../services/errorPage";

const API_URL = '/rest/api/test/';


class UserService  extends React.Component{

  constructor(props) {
      super(props);

      this.state = {
        responseStatus: ''
      };
    }


  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }

  tokenCheck(token) {
console.log("token11 " + token); //usun
    if(token === null){
      // console.log("token " + token); //usun
      // console.log("tokenCheck "); //usun
      // history.push('/login');
      // window.location.reload();
    }
  }

  checkIfLogin(token){
    var url = "/rest/login/check";
//console.log("tokeeeeen " + JSON.stringify(token)); //usun
    fetch(url, {
      method: 'GET',
      withCredentials: true,
      credentials: 'include',
      headers: {
          'Authorization': 'Bearer ' + token, 
          'Content-Type': 'application/json'
      }
      })
      .then(async response => {
          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson && await response.json();
          if (!response.ok) {
//console.log("data datapppp 3" + JSON.stringify(data)); //usun

//console.important("console.important " + data.message);
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                this.setState({responseStatus: response.status});
                return Promise.reject(error);
            }else{
              return response.json();
            }
      })
      .then(allClass => {
//console.log("allClass " + JSON.stringify(allClass)); //usun
//console.log("this.emptyItem.roles " + this.emptyItem.roles); //usun
        this.setState({ allClass });
//console.log("allClasssssss " + JSON.stringify(allClass)); //usun
        })
      .catch((error) => {
//console.log('Errorrrrrrrrrrrrrrrrrrrrr 22: ', error); //todo log
//console.log('this.state.responseStatus: ', this.state.responseStatus); //todo log
        this.setState({ errorMessage: error.toString() });
//console.error('There was an error!', error); //todo log
//AuthService.logout();
//		window.location.href = "/login";
//localStorage.removeItem("user");
//console.error('usuwamyyyy localSorage!'); //todo log
//ErrorPage.checkCodeAndGoToErrorPage(this.state.responseStatus);
        // console.log('data 11111: ', data);
        // console.log('data.message :', data.message);
      });
  }

  checkIfLoginAndGoToLoginPage(token){
    var url = "/rest/login/check";
//console.log("tokeeeeen " + JSON.stringify(token)); //usun
    fetch(url, {
      method: 'GET',
      withCredentials: true,
      credentials: 'include',
      headers: {
          'Authorization': 'Bearer ' + token, 
          'Content-Type': 'application/json'
      }
      })
      .then(async response => {
          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson && await response.json();
          if (!response.ok && response.status == 401) {
console.log("data datapppp 3" + JSON.stringify(data)); //usun

              window.location.href = "/login";
            }
      })
      .catch((error) => {
console.log('Errorrrrrrrrrrrrrrrrrrrrr 22: ', error); //todo log
//console.log('this.state.responseStatus: ', this.state.responseStatus); //todo log
        this.setState({ errorMessage: error.toString() });
console.error('There was an error!', error); //todo log
      });
  }



  checkClassAdminPermissions(token){
    
    var url = "/rest/class_app/admin/check";
console.log("url " + JSON.stringify(url)); //usun
    fetch(url, {
      method: 'GET',
      withCredentials: true,
      credentials: 'include',
      headers: {
          'Authorization': 'Bearer ' + token, 
          'Content-Type': 'application/json'
      }
      })
      .then(async response => {
          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson && await response.json();
          if (!response.ok) {
console.log("data datapppp 4" + JSON.stringify(data)); //usun

//console.important("console.important " + data.message);
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                this.setState({responseStatus: response.status});
                return Promise.reject(error);
            }else{
              return response.json();
            }
      })
      .then(allClass => {
//console.log("allClass " + JSON.stringify(allClass)); //usun
//console.log("this.emptyItem.roles " + this.emptyItem.roles); //usun
        this.setState({ allClass });
console.log("allClasssssss " + JSON.stringify(allClass)); //usun
        })
      .catch((error) => {
console.log('Errorrrrrrrrrrrrrrrrrrrrr 22: ', error); //todo log
console.log('this.state.responseStatus: ', this.state.responseStatus); //todo log
        this.setState({ errorMessage: error.toString() });
console.error('There was an error!', error); //todo log

ErrorPage.checkCodeAndGoToErrorPage(this.state.responseStatus);
        // console.log('data 11111: ', data);
        // console.log('data.message :', data.message);
      });
  }

}

export default new UserService();
