import React from 'react'

import CityClubFindForm from '../forms/CityClubFindForm';

import CreatLinkSerchByCityAndClub from "../classAppCreateLinks/CreatLinkSerchByCityAndClub";


class MapHome extends React.Component {

    render() {

        var findWroclaw = "/" + this.props.linkTo + CreatLinkSerchByCityAndClub("wroclaw", "", "1");
        var findWarszawa = "/" + this.props.linkTo + CreatLinkSerchByCityAndClub("warszawa", "", "1");
        var findLodz = "/" + this.props.linkTo + CreatLinkSerchByCityAndClub("lodz", "", "1");
        var findLubliniec = "/" + this.props.linkTo + CreatLinkSerchByCityAndClub("lubliniec", "", "1");
        var findKielce = "/" + this.props.linkTo + CreatLinkSerchByCityAndClub("kielce", "", "1");
        var findOlsztyn = "/" + this.props.linkTo + CreatLinkSerchByCityAndClub("olsztyn", "", "1");

        var desc = "";
        if (this.props.linkTo == "clubs_search") { desc = "Skorzystaj z mapy lub wyszukiwarki aby znaleźć korty do tenisa w swojej okolicy." }
        if (this.props.linkTo == "trainers_search") { desc = "Skorzystaj z mapy lub wyszukiwarki aby znaleźć trenera w swojej okolicy." }
        if (this.props.linkTo == "sparingpartnerzy_search") { desc = "Skorzystaj z mapy lub wyszukiwarki aby znaleźć sparingpartnera w swojej okolicy." }


        return (
            <div id="main">
                <div id="map-form">
                    <h3>Lokalizacje</h3>
                    <CityClubFindForm linkTo={this.props.linkTo} />

                </div>
                <div id="map">
                    <h3>Wyszukaj najbliższy wolny kort</h3>
                    {/*{% MAPA */}
                    <div class="map-position">
                        <div id="map-poland">
                            <ul class="poland">
                                {/*{% <!-- TUTAJ POBIERANA JEST LISTA WSZYSTKICH DOSTĘPNYCH WOJEWÓDZTW --> */}
                                <li class="pl1" id="pl1"><a href="#dolnoslaskie">Dolnośląskie</a></li>
                                <li class="pl5" id="pl5"><a href="#lodzkie">Łódzkie</a></li>
                                <li class="pl7" id="pl7"><a href="#mazowieckie">Mazowieckie</a></li>
                                <li class="pl12" id="pl12"><a href="#slaskie">Śląskie</a></li>
                                <li class="pl13" id="pl13"><a href="#swietokrzyskie">Świętokrzyskie</a></li>
                                <li class="pl14" id="pl14"><a href="#warminsko-mazurskie">Warmińsko-mazurskie</a></li>
                                {/*{% <!-- KONIEC LISTY DOSTĘPNYCH WOJEWÓDZTW --> */}
                            </ul>
                        </div>
                        <div id="provinces">
                            {/*{% <!--JEŻELI DANE WOJEWÓDZTWO JEST AKTYWNE TUTAJ POJAWIA SIĘ DIV Z MIASTAMI --> */}
                            <div id="province-pl1" class="province">
                                <div class="province-city" style={{ margin: '120px 0 0 225px' }}><a href={findWroclaw}> Wrocław</a></div>
                            </div>
                            <div id="province-pl2" class="province"></div>
                            <div id="province-pl3" class="province"></div>
                            <div id="province-pl4" class="province"></div>
                            <div id="province-pl5" class="province">
                                <div class="province-city" style={{ margin: '120px 0 0 165px' }}><a href={findLodz}>Łódź</a>
                                </div>
                            </div>
                            <div id="province-pl6" class="province"></div>
                            <div id="province-pl7" class="province">
                                <div class="province-city" style={{ margin: '160px 0 0 150px' }}><a href={findWarszawa}>Warszawa</a></div>
                            </div>
                            <div id="province-pl8" class="province"></div>
                            <div id="province-pl9" class="province"></div>
                            <div id="province-pl10" class="province"></div>
                            <div id="province-pl11" class="province"></div>
                            <div id="province-pl12" class="province">
                                <div class="province-city" style={{ margin: '110px 0 0 120px' }}><a href={findLubliniec}>Lubliniec</a></div>
                            </div>

                            <div id="province-pl13" class="province">
                                <div class="province-city" style={{ margin: '110px 0 0 120px' }}><a href={findKielce}>Kielce</a></div>
                            </div>
                            <div id="province-pl14" class="province">
                                <div class="province-city" style={{ margin: '70px 0 0 100px' }}><a href={findOlsztyn}>Olsztyn</a></div>
                                {/*{% <!--<div class="province-city" style="margin: 110px 0 0 220px;"><a href="https://www.rezerwujkort.pl/trenerzy_tenisa/szukaj/724">Pisz</a></div> --> */}
                            </div>
                            <div id="province-pl15" class="province"></div>
                            <div id="province-pl16" class="province"></div>
                            {/*{% <!--KONIEC--> */}
                            <div id="button-map" class="btn-more semibold">wybierz inne województwo</div>
                        </div>
                    </div>
                    {/*{%  <!--KONIEC_MAPY--> */}
                </div>
            </div>
        )
    }
}

export default MapHome;