import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';
import DateTimeNow from "../utils/DateTimeNow";
import PageCounterNew from '../components/PageCounterNew';

import FechPostTrainerBySearch from "../fechData/FechPostTrainerBySearch";
import FechGetClubBySlug from "../fechData/FechGetClubBySlug";
import FechGetCityBySlug from "../fechData/FechGetCityBySlug";

import ParseLinkToArray from "../utils/ParseLinkToArray"
import queryString from 'query-string'

const AdverbTrainerShort = React.lazy(() => import('../components/AdverbTrainerShort'));

class TrainersSearchPage extends React.Component {

  searchTrainerItem = {
    city: '',
    club: '',
    page: ''
  };
  urlDeafulValues = [];
  urlValue = "";

  state = {
    title: "Trenerzy - RezerwujKort.pl",
    logInfo: "LogDebug __" + DateTimeNow() + "__ TrainersSearchPage ",
    rowsCount: "6",
    restTrainerAdverbsData: {
      successful: false,
      status: '',
      message: '',
      data: []
    },
    restClubData: {
      successful: false,
      status: '',
      message: '',
      data: []
    },
    restCityData: {
      successful: false,
      status: '',
      message: '',
      data: []
    }
  }

  async componentDidMount() {

    document.title = this.state.title

    const { location: { search } } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.searchTrainerItem = await ParseLinkToArray(this.urlValue, this.searchTrainerItem, this.urlDeafulValues);

    this.state.restClubData = await FechGetClubBySlug(this.searchTrainerItem.club);

    this.state.restCityData = await FechGetCityBySlug(this.searchTrainerItem.city);

    this.setState({
      restTrainerAdverbsData: await FechPostTrainerBySearch(this.searchTrainerItem)
    });
  }


  render() {

    var city = "";
    if (this.state.restCityData.successful) {
      city = this.state.restCityData.data.name
    }

    var description;
    if (this.state.restClubData.successful) {
      description = "Poniżej znajduje się lista trenerów w mieście: " + city + ", ośrodku tenisowym: " + this.state.restClubData.data.name;
    } else {
      description = "Poniżej znajduje się lista trenerów w mieście: " + city;
    }

    var adverbs = "";
    if (this.state.restTrainerAdverbsData.successful &&
      typeof this.state.restTrainerAdverbsData.data.trainerResources.map === 'function') {
      adverbs = this.state.restTrainerAdverbsData.data.trainerResources.map(adverbOne => 
        <div>
        <Suspense fallback={<div>
          <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
        </div>}>
          <AdverbTrainerShort adverb={adverbOne} />
          </Suspense>
        </div>);
    }

    const urlValues = new Map();
    urlValues.set('city', this.searchTrainerItem.city);
    urlValues.set('club', this.searchTrainerItem.club);
    urlValues.set('page', "replace");

    var pageCounter = "";
    var totalRecords = 0;
    if (this.state.restTrainerAdverbsData.successful) {

      totalRecords = this.state.restTrainerAdverbsData.data.totalRecordsCount;

      pageCounter = <PageCounterNew
        elementCount={this.state.restTrainerAdverbsData.data.totalRecordsCount}
        urlValues={urlValues}
        urlDeafulValues={this.urlDeafulValues}
        elementOnPage={this.state.rowsCount}
        currentPage={parseInt(this.searchTrainerItem.page) + 1}
        searchName={""}
        linkTo={"trainers_search"}
      />
    }

    return (

      <div>

        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">
            <div class="main-subpage">
              <div class="head">

                <img src={`/images/login.png`} />
                <p>Trenerzy</p>
              </div>

              <div style={{ clear: 'both' }}>

                {description}<br />
                Ilość ogłoszeń: {totalRecords} <br /><br />
              </div>

              <div style={{ clear: 'both' }}>

                <div class="main-subpage-subtitle">


                </div>

                {adverbs}

              </div>

              <div >

                {pageCounter}

              </div>

            </div>

          </div>
        </div>

        <Footer />

      </div>

    );
  }
}

export default TrainersSearchPage;
