import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';

const ResetPasswordForm = React.lazy(() => import('../forms/ResetPasswordForm'));

class ResetPassword extends React.Component {

    state = {
        title: "RezerwujKort.pl - Rejestracja"
    }

    componentDidMount() {
        document.title = this.state.title

    }


    render() {

        var resetPasswordForm = <div>
            <Suspense fallback={<div>
                <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
            </div>}>
                <ResetPasswordForm
                    token={this.props.match.params.token} />
            </Suspense>
        </div>

        return (

            <div>

                <Belt />

                {resetPasswordForm}

                <Footer />

            </div>
        );
    }
}
export default ResetPassword;
