import React, { Suspense } from 'react';

import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';
import PageCounterNew from '../components/PageCounterNew';

import ParseLinkToArray from "../utils/ParseLinkToArray"
import FechPostClubBySearch from "../fechData/FechPostClubBySearch";
import FechGetClubBySlug from "../fechData/FechGetClubBySlug";
import FechGetCityBySlug from "../fechData/FechGetCityBySlug";

//UTILS
import DateTimeNow from "../utils/DateTimeNow";

import queryString from 'query-string'

const AdverbClubShort = React.lazy(() => import('../components/AdverbClubShort'));


class ClubsSearchPage extends React.Component {

  searchClubItem = {
    city: '',
    club: '',
    page: ''
  };
  urlDeafulValues = [];
  urlValue = "";

  state = {
    logInfo: "LogDebug __" + DateTimeNow() + "__ ClubsSearchPage ",
    title: "Kluby - RezerwujKort.pl",
    rowsCount: "6",
    restClubAdverbsData: {
      successful: false,
      status: '',
      message: '',
      data: []
    },
    restClubData: {
      successful: false,
      status: '',
      message: '',
      data: []
    },
    restCityData: {
      successful: false,
      status: '',
      message: '',
      data: []
    }
  }

  async componentDidMount() {

    document.title = this.state.title

    const { location: { search } } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.searchClubItem = await ParseLinkToArray(this.urlValue, this.searchClubItem, this.urlDeafulValues);

    this.state.restClubData = await FechGetClubBySlug(this.searchClubItem.club);

    this.state.restCityData = await FechGetCityBySlug(this.searchClubItem.city);

    this.setState({
      restClubAdverbsData: await FechPostClubBySearch(this.searchClubItem)
    });
  }

  render() {

    var city = "";
    if (this.state.restCityData.successful) {
      city = this.state.restCityData.data.name
    }

    var description;
    if (this.state.restClubData.successful) {
      description = "Poniżej znajduje się lista ośrodków, które można znaleźć w mieście: " + city + ", ośrodku tenisowym: " + this.state.restClubAdverbsData.data.clubsResources[0].name;
    } else {
      description = "Poniżej znajduje się lista ośrodków, które można znaleźć w mieście: " + city;
    }

    var adverbs = "";
    if (this.state.restClubAdverbsData.successful &&
      typeof this.state.restClubAdverbsData.data.clubsResources.map === 'function') {
      adverbs = this.state.restClubAdverbsData.data.clubsResources.map(adverbOne =>
        <div>
          <Suspense fallback={<div>
            <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
          </div>}>
            <AdverbClubShort
              adverb={adverbOne} />
          </Suspense>
        </div>
      );
    }

    const urlValues = new Map();
    urlValues.set('city', this.searchClubItem.city);
    urlValues.set('club', this.searchClubItem.club);
    urlValues.set('page', "replace");


    var pageCounter = "";
    var totalRecords = 0;
    if (this.state.restClubAdverbsData.successful) {

      totalRecords = this.state.restClubAdverbsData.data.totalRecordsCount;

      pageCounter = <PageCounterNew
        elementCount={this.state.restClubAdverbsData.data.totalRecordsCount}
        urlValues={urlValues}
        urlDeafulValues={this.urlDeafulValues}
        elementOnPage={this.state.rowsCount}
        currentPage={parseInt(this.searchClubItem.page) + 1}
        searchName={""}
        linkTo={"clubs_search"}
      />
    }

    return (

      <div>

        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">
            <div class="main-subpage">
              <div class="head">

                <img src={`/images/login.png`} />
                <p>Korty Tenisowe</p>
              </div>


              <div class="main-subpage-subtitle">

                {description}<br />
                Ilość ogłoszeń: {totalRecords} <br /><br />


              </div>

              <div style={{ clear: 'both' }}>


                <div class="main-subpage-subtitle">


                </div>
                {adverbs}

              </div>

              <div >
                {pageCounter}
              </div>

            </div>

          </div>
        </div>

        <Footer />

      </div>

    );
  }
}

export default ClubsSearchPage;
