export default function DatesBetweenDates(startDate, endDate) {

    let dates = []
    //to avoid modifying the original date
    const theDate = new Date(startDate)
    while (theDate < endDate) {

        var d = new Date(theDate);
        var mm = d.getMonth() + 1;
        mm = mm.toString();

        if (mm.length === 1) {
            mm = "0" + mm;
        }
        var dd = d.getDate().toString();
        if (dd.length === 1) {
            dd = "0" + dd;
        }
        var yy = d.getFullYear();
        dates = [...dates, yy + '-' + mm + '-' + dd]

        theDate.setDate(theDate.getDate() + 1)
    }
    return dates
}

