import React from "react";
import AuthService from "../services/auth.service";
import { Spinner, Button } from "react-bootstrap";
import DateNowYyyyMmDdPlusDay from "../utils/DateNowYyyyMmDdPlusDay";

class BeltReservationApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: "",
    };
  }

  componentDidMount() {
    //this.state.currentUser = AuthService.getCurrentUser();
  }

  logOut() {
    AuthService.logout();
  }

  render() {
    //const { currentUser } = this.state;

    var currentUser = AuthService.getCurrentUser();
    var roles = [];
    if (currentUser != null && currentUser.roles != null) {
      roles = currentUser.roles;
    }
    var admin = false;
    var trainer = false;
    var reception = false;
    if (roles.includes("ROLE_RESERVATION_APP_SUPER_ADMIN")) {
      admin = true;
    }
    if (roles.includes("ROLE_RESERVATION_APP_TRAINER")) {
      trainer = true;
    }
    if (roles.includes("ROLE_RESERVATION_APP_RECEPTION")) {
      reception = true;
    }

    return (
      <div class="pos-f-t">
        <div class="collapse" id="navbarToggleExternalContent">
          {reception || admin ? (
            <div class="bg-dark p-3">
              <Button
                href={"/reservation_app/" + this.props.club + "/calendar"}
                variant="success"
                style={{ width: "5S0%" }}
              >
                Rezerwacje
              </Button>
              <Button
                href={
                  "/reservation_app/" +
                  this.props.club +
                  "/historia_rezerwacji/search?page=1&dateStart=" +
                  DateNowYyyyMmDdPlusDay(0) +
                  "&dateStop=" +
                  DateNowYyyyMmDdPlusDay(7) +
                  "&status=1"
                }
                variant="success"
                style={{ "margin-left": "22px" }}
              >
                Historia Rezerwacji
              </Button>

              <Button
                href={
                  "/reservation_app/" +
                  this.props.club +
                  "/stale_rezerwacje/search?page=1"
                }
                variant="success"
                style={{ "margin-left": "20px" }}
              >
                Stałe rezerwacje
              </Button>
              <Button
                href={
                  "/reservation_app/" +
                  this.props.club +
                  "/klienci/search?page=1&status=0"
                }
                variant="success"
                style={{ "margin-left": "20px" }}
              >
                Klienci
              </Button>

              <Button
                href={
                  "/reservation_app/" +
                  this.props.club +
                  "/karnety/search?ticketStatus=0&page=1&ticketType=COURT"
                }
                variant="success"
                style={{ "margin-left": "20px" }}
              >
                Karnety
              </Button>
              <Button
                href={
                  "/reservation_app/" +
                  this.props.club +
                  "/zamkniecia_kortow/search?status=0&page=1"
                }
                variant="success"
                style={{ "margin-left": "20px" }}
              >
                Zamknięcia kortów
              </Button>
              <Button
                href={
                  "/reservation_app/" +
                  this.props.club +
                  "/wyslane_maile/search?page=1"
                }
                variant="success"
                style={{ "margin-left": "20px" }}
              >
                Wysłane E-maile
              </Button>
              <Button
                href="/logout"
                onClick={this.logOut}
                variant="danger"
                style={{ "margin-left": "20px", float: "right" }}
              >
                Wyloguj
              </Button>
              <Button
                href="/profile"
                variant="danger"
                style={{ "margin-left": "20px", float: "right" }}
              >
                Wyjdź
              </Button>
            </div>
          ) : (
            <div class="bg-dark p-3">
              <Button
                href={"/reservation_app/" + this.props.club + "/calendar"}
                variant="success"
                style={{ width: "5S0%" }}
              >
                Rezerwację
              </Button>
              <Button
                href="/logout"
                onClick={this.logOut}
                variant="danger"
                style={{ "margin-left": "20px", float: "right" }}
              >
                Wyloguj
              </Button>
              <Button
                href="/profile"
                variant="danger"
                style={{ "margin-left": "20px", float: "right" }}
              >
                Wyjdź
              </Button>
            </div>
          )}

          {admin ? (
            <div class="bg-dark p-3">
              <Button
                href={
                  "/reservation_app/" +
                  this.props.club +
                  "/trenerzy/search?page=1"
                }
                variant="warning"
              >
                Trenerzy
              </Button>
              <Button
                href={
                  "/reservation_app/" + this.props.club + "/administratorzy"
                }
                variant="warning"
                style={{ "margin-left": "20px" }}
              >
                Pracownicy
              </Button>
              <Button
                href={
                  "/reservation_app/" +
                  this.props.club +
                  "/ustawienia_kortow/search?page=1"
                }
                variant="warning"
                style={{ "margin-left": "20px" }}
              >
                Ustawienia kortów
              </Button>
              <Button
                href={
                  "/reservation_app/" +
                  this.props.club +
                  "/ustawienia_platnosci/club"
                }
                variant="warning"
                style={{ "margin-left": "20px" }}
              >
                Ustawienia cenników
              </Button>
              <Button
                href={
                  "/reservation_app/" +
                  this.props.club +
                  "/promocje/search?page=1&deleted=false"
                }
                variant="warning"
                style={{ "margin-left": "20px" }}
              >
                Promocje
              </Button>
              <Button
                href={"/reservation_app/" + this.props.club + "/ustawienia"}
                variant="warning"
                style={{ "margin-left": "20px" }}
              >
                Ustawienia
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>

        <nav class="navbar navbar-dark bg-dark">
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarToggleExternalContent"
            aria-controls="navbarToggleExternalContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </nav>
      </div>
    );
  }
}

export default BeltReservationApp;
