import React, { Suspense } from "react";
import { Spinner } from "react-bootstrap";

import Belt from "../components/Belt";
import BeltLogged from "../components/BeltLogged";

import Footer from "../components/Footer";
import { Form } from "react-bootstrap";

import FechGetSuperAdminRoles from "../leagueAppFechData/FechGetSuperAdminRoles";
import FechGetRanking from "../leagueAppFechData/FechGetRanking";
import FechChangePlayerPosition from "../leagueAppFechData/FechChangePlayerPosition";
import history from "../components/history";

const BeltLeague = React.lazy(() =>
  import("../leagueAppComponents/BeltLeague")
);

class PageLeagueAdminChangeRankPosition extends React.Component {
  NOTIFICATION_TITLE_OK = "Sukces!";
  NOTIFICATION_TITLE_NOT_OK = "Błąd!";
  NOTIFICATION_MSG_OK = "Pozycja gracza w rankingu została zmieniona!";
  NOTIFICATION_MSG_NOT_OK = "Wystąpił błąd! Spróbój ponownie!";

  emptyItem = {
    player: this.props.mameSlug,
    newPosition: 0,
  };
  urlDeafulValues = [];
  urlValue = "";

  constructor(props) {
    super(props);

    this.state = {
      title: "Liga - Ranking - RezerwujKort.pl",
      item: this.emptyItem,
      restRankingData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
      restLeagueAdminData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    document.title = this.state.title;

    this.setState({
      restLeagueAdminData: await FechGetSuperAdminRoles(
        this.props.match.params.club
      ),
    });

    this.setState({
      restRankingData: await FechGetRanking(
        this.props.match.params.club,
        this.props.match.params.league
      ),
    });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let item = { ...this.state.item };

    item[name] = value;
    this.setState({ item: item });
  }

  async handleSubmit(event) {
    event.preventDefault();

    this.setState({
      restChangePlayerPositionResponseData: await FechChangePlayerPosition(
        this.props.match.params.club,
        this.props.match.params.league,
        this.props.match.params.mameSlug,
        this.state.item.newPosition
      ),
    });

    var notification = ["", "", ""];
    if (this.state.restChangePlayerPositionResponseData.successful) {
      notification = [
        "success",
        this.NOTIFICATION_TITLE_OK,
        this.NOTIFICATION_MSG_OK,
      ];
    } else {
      notification = [
        "error",
        this.NOTIFICATION_TITLE_NOT_OK,
        this.NOTIFICATION_MSG_NOT_OK,
      ];
    }

    history.push(
      "/liga/" + this.props.club + "/" + this.props.league + "/admin",
      notification
    );
    window.location.reload();
  }

  render() {
    const urlValues = new Map();
    urlValues.set("page", "replace");

    var beltLeague;
    if (this.state.restLeagueAdminData.successful) {
      beltLeague = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <BeltLeague
              currentPage={"admin"}
              club={this.props.match.params.club}
              league={this.props.match.params.league}
            />
          </Suspense>
        </div>
      );
    }

    var playerList;

    if (
      this.state.restRankingData.data.players &&
      typeof this.state.restRankingData.data.players.map === "function"
    ) {
      var counter = 0;
      playerList = this.state.restRankingData.data.players.map((player) => (
        <option value={counter++}>{counter}</option>
      ));
    } else {
      playerList = "";
    }

    var test = 0;
    var playerName = "";
    var currentRamkPosition = 0;
    if (
      this.state.restRankingData.data.players &&
      typeof this.state.restRankingData.data.players.map === "function"
    ) {
      test = this.state.restRankingData.data.players.find(
        (player) => player.nameSlug == this.props.match.params.mameSlug
      );
      currentRamkPosition = test.rankingPosition;
      playerName = test.name + " " + test.surname;
    }

    return (
      <div>
        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">
            <div class="main-subpage">
              {beltLeague}

              <div style={{ width: "80%", margin: "30px" }}>
                {/* Gracz {playerName} znajduje się aktualnie na pozycji nr: {currentRamkPosition}<br /><br /> */}
                Przenies gracza na miejsce:
                <br />
                <br />
                {/* <form onSubmit={this.handleSubmit}>
                                    <Form as="select"
                                        onChange={this.handleChange}
                                        value={this.state.item.newPosition}
                                        id="newPosition"
                                        name="newPosition">
                                        <option>Wybierz pozycję</option>
                                        {playerList}
                                    </Form><br /><br />
                                    <div class="field"><label for=""></label>
                                        <input value="Przenieś gracza" type="submit"></input>
                                    </div>
                                </form> */}
              </div>

              <div></div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default PageLeagueAdminChangeRankPosition;
