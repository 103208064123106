
export default async function FetchPostDataNoAuthNEW(url, body) {

	var restData = {
		successful: false,
		status: '',
		message: '',
		data: []
	};

	url = "/rest" + url;

	await fetch(url, {
		method: 'POST',
		withCredentials: true,
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(body),
	})
		.then(async response => {
			const isJson = response.headers.get('content-type')?.includes('application/json');
			let data = [];
			data = isJson && await response.json();

			if (response.status != 200) {	
				restData.successful = false;
				restData.status = response.status;
				if (data !== undefined) {
					restData.message = data.message;
				} else {
					restData.message = "";
				}
			} else {
				restData.successful = true;
				restData.message = data.message;
				restData.status = response.status;
				restData.data = data;
			}
		}, error => {
			const resMessage =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();

			restData.successful = false;
			restData.message = resMessage;

			this.setState({
				successful: false,
				message: resMessage
			});

		});

	return restData;
}



