import React, { Suspense } from "react";

import { Spinner, Button } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl"; // the locale you want
import Belt from "../components/Belt";
import BeltLogged from "../components/BeltLogged";
import FechGetAllUserTickets from "../fechData/FechGetAllUserTickets";
import PageCounterNew from "../components/PageCounterNew";
import queryString from "query-string";

import ParseLinkToArray from "../utils/ParseLinkToArray";
import Footer from "../components/Footer";

const TabAllUserTickets = React.lazy(() =>
  import("../RESERVATION_ONLINE_COMPONENTS/TabAllUserTickets")
);

class ProfileTicketsPage extends React.Component {
  urlDeafulValues = [
    this.TRAINER_SELECT_DESC,
    this.CLASS_SELECT_DESC,
    this.DATE_SELECT_DESC,
  ];

  urlValue = "";

  postData = {
    page: "0",
  };

  constructor(props) {
    super(props);

    this.state = {
      title: "RezerwujKort.pl - Rezerwację",
      datePickerIsOpen: false,
      previousDay: "",
      refreshReservation: "",
      priceListPopupForm: "",
      restUserTicketsData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
    };
    //this.handlerRefreshReservation = this.handlerRefreshReservation.bind(this);
  }

  async componentDidMount() {
    document.title = "Rezerwacje - RezerwujKort.pl";
    registerLocale("pl", pl); // register it with the name you want
    const {
      location: { search },
    } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.postData = await ParseLinkToArray(
      this.urlValue,
      this.postData,
      this.urlDeafulValues
    );

    this.setState({
      restUserTicketsData: await FechGetAllUserTickets(this.postData.page),
    });
  }

  render() {
    var loader = "";
    if (!this.state.restUserTicketsData.successful) {
      loader = (
        <div id="loader_div">
          <Spinner animation="border" />
        </div>
      );
    }

    var tabAllUserTickets = "";
    const urlValues = new Map();
    urlValues.set("page", "replace");

    var pageCounter = "";
    if (
      this.state.restUserTicketsData.successful &&
      this.state.restUserTicketsData.data.numberOfElements > 0
    ) {
      tabAllUserTickets = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <div class="scroll">
              <TabAllUserTickets
                restUserTicketsData={
                  this.state.restUserTicketsData.data.content
                }
              />
            </div>
          </Suspense>
        </div>
      );

      pageCounter = (
        <PageCounterNew
          elementCount={this.state.restUserTicketsData.data.totalElements}
          urlValues={urlValues}
          urlDeafulValues={this.urlDeafulValues}
          elementOnPage={this.state.restUserTicketsData.data.pageable.pageSize}
          currentPage={parseInt(this.postData.page) + 1}
          searchName={"search"}
          linkTo={"profile/moje_rezerwacje"}
        />
      );
    }

    if (
      this.state.restUserTicketsData.successful &&
      this.state.restUserTicketsData.data.numberOfElements == 0
    ) {
      tabAllUserTickets = "Brak karnetów";
      loader = "";
    }

    return (
      <div>
        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">
            <div class="main-subpage-info">
              {loader}

              {tabAllUserTickets}

              {pageCounter}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
export default ProfileTicketsPage;
