import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';
import Footer from '../components/Footer';
import MapHome from '../components/MapHome';

//const MapHome = React.lazy(() => import('../components/MapHome'));
const News = React.lazy(() => import('../components/News'));
const AdverbCourtTrainerSparing = React.lazy(() => import('../components/AdverbCourtTrainerSparing'));

function HomePage() {

    var adverbCourtTrainerSparing = <div>
        <Suspense fallback={<div>
            <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
        </div>}>
            <AdverbCourtTrainerSparing />
        </Suspense>
    </div>

    // var mapHome = <div>
    //     <Suspense fallback={<div>
    //         <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
    //     </div>}>
    //         <MapHome
    //             linkTo={"clubs_search"} />
    //     </Suspense>
    // </div>

    var news = <div>
        <Suspense fallback={<div>
            <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
        </div>}>
            <News />
        </Suspense>
    </div>

    return (
        <div>

            <Belt page={"index"} />

            <div id="bottom-header">
                <div class="container steps-bar">
                    <ul>
                        <li>1.Załóż konto.</li>
                        <li><img src={process.env.PUBLIC_URL + '/images/arrow.png'} alt="" /></li>
                        <li>2.Wyszukaj kort.</li>
                        <li><img src={process.env.PUBLIC_URL + '/images/arrow.png'} alt="" /></li>
                        <li>3.Wejdź do gry!</li>
                    </ul>
                </div>
            </div>

            <BeltLogged />

            <div id="content">
                <div class="container">

                    {adverbCourtTrainerSparing}

                    <MapHome
                        linkTo={"clubs_search"} />

                    {news}

                </div>
            </div>

            <Footer />

        </div>
    );
}

export default HomePage;