import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW"; 

export default function FechGetClubPlayerList(club, league, page) {
 
  var url = "/league_app/" + club + "/" + league + "/find_all_club_players/" + page + "/0";
  var data = FetchGetDataWithAuthNEW(url);

  return data;
}

