import FetchGetDataNoAuthNEW from "../services/FetchGetDataNoAuthNEW"; 

export default function FechGetRanking(club, league) {
 
  var url = "/league_app/" + club + "/" + league + "/ranking";
  var data = FetchGetDataNoAuthNEW(url);

  return data;
}

