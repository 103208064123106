import React, { Suspense } from "react";

import { Spinner, Button } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl"; // the locale you want

import FechGetCourtsBySearch from "../APP_RESERVATION_FECH_DATA/FechGetCourtsBySearch";

import PageCounterNew from "../components/PageCounterNew";
import BeltReservationApp from "../APP_RESERVATION_COMPONENTS/BeltReservationApp";

import queryString from "query-string";
import moment from "moment";
import ParseLinkToArray from "../utils/ParseLinkToArray";

const AddCourtPopupForm = React.lazy(() =>
  import("../APP_RESERVATION_FORM_POPUP/AddCourtPopupForm")
);
const TabAllCourts = React.lazy(() =>
  import("../APP_RESERVATION_COMPONENTS/TabAllCourts")
);

let plLocate = require("moment/locale/pl");
moment.locale("pl", plLocate);

//KLASA WYSWIETLA SZCZEGOLY KONKRETNYCH ZAJEC SZKOLKI
class PageCourtsSettings extends React.Component {
  TRAINER_SELECT_DESC = "Wybierz trenera";
  DATE_SELECT_DESC = "Wybierz datę";
  CLASS_SELECT_DESC = "Wybierz szkółke";

  urlDeafulValues = [
    this.TRAINER_SELECT_DESC,
    this.CLASS_SELECT_DESC,
    this.DATE_SELECT_DESC,
  ];

  urlValue = "";

  postData = {
    dateStart: "",
    dateStop: "",
    trainer: "",
    classId: "",
    className: "",
    status: "",
    page: "0",
  };

  constructor(props) {
    super(props);

    this.state = {
      title: "RezerwujKort.pl - Rezerwację",
      datePickerIsOpen: false,
      previousDay: "",
      refreshReservation: "",
      addCourtPopupForm: "",
      restCourtsData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
    };
    this.handlerRefreshReservation = this.handlerRefreshReservation.bind(this);
  }

  async componentDidMount() {
    document.title = "Rezerwacje - RezerwujKort.pl";
    registerLocale("pl", pl); // register it with the name you want
    const {
      location: { search },
    } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.postData = await ParseLinkToArray(
      this.urlValue,
      this.postData,
      this.urlDeafulValues
    );

    this.setState({
      restCourtsData: await FechGetCourtsBySearch(
        this.props.match.params.club,
        search
      ),
    });
  }

  async addCourtForm() {
    this.setState({
      addCourtPopupForm: (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <AddCourtPopupForm
              dateReservation={""}
              club={this.props.match.params.club}
              refresh={this.handlerRefreshReservation}
            />
          </Suspense>
        </div>
      ),
    });
  }

  async handlerRefreshReservation() {
    //this.componentDidMount();
    const {
      location: { search },
    } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);
    this.postData = await ParseLinkToArray(
      this.urlValue,
      this.postData,
      this.urlDeafulValues
    );

    this.setState({
      restCourtsData: await FechGetCourtsBySearch(
        this.props.match.params.club,
        search
      ),
    });

    var cc = this.state.restCourtsData;
  }

  render() {
    var loader = "";
    if (!this.state.restCourtsData.successful) {
      loader = (
        <div id="loader_div">
          <Spinner animation="border" />
        </div>
      );
      totalRecords = this.state.restCourtsData.data;
    }

    var totalRecords = "";
    var tabAllCourts = "";
    var pageCounter = "";
    if (this.state.restCourtsData.successful) {
      totalRecords = this.state.restCourtsData.data.totalRecordsCount;

      tabAllCourts = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <TabAllCourts
              club={this.props.match.params.club}
              restLessonsData={this.state.restCourtsData.data.courtResources}
              refresh={this.handlerRefreshReservation}
            />
          </Suspense>
        </div>
      );

      const urlValues = new Map();
      urlValues.set("dateStart", this.postData.dateStart);
      urlValues.set("dateStop", this.postData.dateStop);
      urlValues.set("trainer", this.postData.trainer);
      urlValues.set("classId", this.postData.classId);
      urlValues.set("className", this.postData.className);
      urlValues.set("status", this.postData.status);
      urlValues.set("page", "replace");

      pageCounter = (
        <PageCounterNew
          elementCount={totalRecords}
          urlValues={urlValues}
          urlDeafulValues={this.urlDeafulValues}
          elementOnPage={this.state.restCourtsData.data.recordsOnPage}
          currentPage={parseInt(this.postData.page) + 1}
          searchName={"search"}
          linkTo={
            "reservation_app/" +
            this.props.match.params.club +
            "/ustawienia_kortow"
          }
        />
      );
    }

    return (
      <div>
        {this.state.addCourtPopupForm}

        <div id="content">
          <BeltReservationApp club={this.props.match.params.club} />

          {/* <br /><br />
                    <Button href="#" variant="success" size="lg" style={{ 'margin-left': '20px' }}
                        onClick={() => this.addCourtForm()}>
                        Dodaj kort
                    </Button> */}

          <br />
          <br />
          <b>Lista wszystkich kortów: ({totalRecords}):</b>
          <br />
          <br />

          {loader}

          {tabAllCourts}

          {pageCounter}
        </div>
      </div>
    );
  }
}
export default PageCourtsSettings;
