import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW";
import AuthService from "../services/auth.service";

//metoda usuwa uczestnika z calej szkolki 
export default function FechGetDeleteStudentFromClass(club, classId, studentId) {
 
  var url = "/class_app/" + club + "/admin/delete_student_from_class/delete/" + classId + "/" + studentId;

  return FetchGetDataWithAuthNEW(url);
}

