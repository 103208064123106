
import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW";

//metoda usuwa uczestnika z jednych zajec
export default function FechGetDeleteClass(club, classId) {

  var url = "/class_app/" + club + "/class/delete/" + classId;

  return FetchGetDataWithAuthNEW(url);
}

