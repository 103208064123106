import React, { Suspense } from 'react';

import { Spinner, Button } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl"; // the locale you want

import FechGetgetAllEnabledPriceList from "../APP_RESERVATION_FECH_DATA/FechGetgetAllEnabledPriceList";
import FechGetCheckAllYearHoursPrice from "../APP_RESERVATION_FECH_DATA/FechGetCheckAllYearHoursPrice.js";
import FechGetCopyPriceList from "../APP_RESERVATION_FECH_DATA/FechGetCopyPriceList.js";

import BeltReservationApp from '../APP_RESERVATION_COMPONENTS/BeltReservationApp'
import BeltReservationPriceList from '../APP_RESERVATION_COMPONENTS/BeltReservationPriceList'

import Modal from 'react-bootstrap/Modal';
import moment from 'moment'

let plLocate = require('moment/locale/pl');
moment.locale('pl', plLocate)

const PriceListPopupForm = React.lazy(() => import('../APP_RESERVATION_FORM_POPUP/PriceListPopupForm'));
const TabPriceListStandard = React.lazy(() => import('../APP_RESERVATION_COMPONENTS/TabPriceListStandard'));
const TabPriceListHoliday = React.lazy(() => import('../APP_RESERVATION_COMPONENTS/TabPriceListHoliday'));

// const PriceListPopupForm = React.lazy(() => {
//     return new Promise(resolve => setTimeout(resolve, 4 * 1000)).then(
//         () =>
//             Math.floor(Math.random() * 10) >= 4
//                 ? import("../APP_RESERVATION_FORM_POPUP/PriceListPopupForm")
//                 : Promise.reject(new Error())
//     );
// });


//KLASA WYSWIETLA SZCZEGOLY KONKRETNYCH ZAJEC SZKOLKI
class PagePaymentsSettings extends React.Component {

    pageType = "CLUB_APPLICATION";

    constructor(props) {
        super(props);

        this.state = {
            title: "RezerwujKort.pl - Rezerwację",
            datePickerIsOpen: false,
            previousDay: "",
            refreshReservation: "",
            priceListPopupForm: "",
            showConfirm: false,
            restPriceListsData: {
                successful: false,
                status: '',
                message: '',
                data: []
            },
            checkWholeYear: {
                successful: false,
                status: '',
                message: '',
                data: []
            }
        }
        this.handleCloseConfirm = this.handleCloseConfirm.bind(this);
        this.handleShowConfirm = this.handleShowConfirm.bind(this);
        this.handleCopy = this.handleCopy.bind(this);
        this.handlerRefreshReservation = this.handlerRefreshReservation.bind(this);
    }

    async componentDidMount() {

        document.title = "Rezerwacje - RezerwujKort.pl";
        registerLocale("pl", pl); // register it with the name you want

        if (this.props.match.params.id === "www") this.pageType = "RESERVATION_WWW";
        if (this.props.match.params.id === "app") this.pageType = "RESERVATION_APP";

        this.setState({ restPriceListsData: await FechGetgetAllEnabledPriceList(this.props.match.params.club, this.pageType) });
        this.setState({ checkWholeYear: await FechGetCheckAllYearHoursPrice(this.props.match.params.club, "STANDARD", this.pageType) });
    }

    async priceListStandardForm() {

        this.setState({
            priceListPopupForm: <div>
                <Suspense fallback={<div>
                    <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
                </div>}>
                    <PriceListPopupForm
                        club={this.props.match.params.club}
                        refresh={this.handlerRefreshReservation}
                        status={"Add"}
                        priceListType={"STANDARD"}
                        priceListId={"STANDARD"}
                        priceListDesc={"Dodawanie cennika Standardowego"}
                        btnName={"Dodaj Cennik"}
                    />
                </Suspense>
            </div>
        });
    }

    async priceListHolidayForm() {

        this.setState({
            priceListPopupForm: <div>
                <Suspense fallback={<div>
                    <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
                </div>}>
                    <PriceListPopupForm
                        club={this.props.match.params.club}
                        refresh={this.handlerRefreshReservation}
                        status={"Add"}
                        priceListType={"HOLIDAY"}
                        priceListId={"HOLIDAY"}
                        priceListDesc={"Dodawanie cennika Świątecznego"}
                        btnName={"Dodaj Cennik"}
                    />
                </Suspense>
            </div>
        });
    }

    async handlerRefreshReservation() {

        this.setState({ restPriceListsData: await FechGetgetAllEnabledPriceList(this.props.match.params.club, this.pageType) });
        this.setState({ checkWholeYear: await FechGetCheckAllYearHoursPrice(this.props.match.params.club, "STANDARD", this.pageType) });
        // var cc = this.state.restPriceListsData;
    }

    handleCloseConfirm() {
        this.setState({ showConfirm: false });
    }
    handleShowConfirm() {
        this.setState({ showConfirm: true });
    }

    async handleCopy() {

        var response;
        if (this.props.match.params.id === "www") {
            response = await FechGetCopyPriceList(this.props.match.params.club, "CLUB_APPLICATION", "RESERVATION_WWW");
        }
        if (this.props.match.params.id === "app") {
            response = await FechGetCopyPriceList(this.props.match.params.club, "CLUB_APPLICATION", "RESERVATION_APP");
        }
        this.setState({ restCopyPriceListData: response });
        if (response.successful == true) {
            this.handleCloseConfirm();
            window.location.reload();
        }
    }

    render() {

        var loader = "";
        if (!this.state.restPriceListsData.successful) {
            loader = <div id="loader_div">
                <Spinner animation="border" />
            </div>
            totalRecords = this.state.restPriceListsData.data
        }

        var copyData = "";
        if (this.props.match.params.id === "www" || this.props.match.params.id === "app") {
            copyData = <>
                <br />
                <Button href="#" variant="success" size="lg" style={{ 'margin-right': '20px', 'float': 'right' }}
                    onClick={() => this.handleShowConfirm()}>
                    Wyczyść i skopiuj 'Cennik Recepcja'
                </Button>
                <br /></>
        }

        var chechWholeYear = <div id="loader_div">
            <Spinner animation="border" />
        </div>;
        if (this.state.checkWholeYear.successful) {
            chechWholeYear = [];
            this.state.checkWholeYear.data.map(e => chechWholeYear.push(
                <p style={{ color: 'red' }}><br />{e}<br /></p>
            ));
        }
        if (!this.state.checkWholeYear.successful && this.state.checkWholeYear.message.includes("Więcej niż jedna")) {
            chechWholeYear = [];
            chechWholeYear.push(<p style={{ color: 'red' }}>{this.state.checkWholeYear.message}<br /></p>);
        }
        if (!this.state.checkWholeYear.successful && this.state.checkWholeYear.message.includes("W klubie nie ma zdefiniowanego")) {
            chechWholeYear = [];
            chechWholeYear.push(<p style={{ color: 'red' }}>{this.state.checkWholeYear.message}<br /></p>);
        }
        if (this.state.checkWholeYear.successful && this.state.checkWholeYear.data.length === 0) {
            chechWholeYear = [];
            chechWholeYear.push(<p style={{ color: 'green' }}><br /><b>Wszystkie godziny mają przypisane ceny w cenniku standardowym! :)</b><br /></p>);
        }

        var totalRecords = "";
        var tabPriceListStandard = "";
        var tabPriceListHoliday = "";
        if (this.state.restPriceListsData.successful) {
            totalRecords = this.state.restPriceListsData.data.content

            tabPriceListStandard = <div>
                <Suspense fallback={<div>
                    <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
                </div>}>
                    <TabPriceListStandard
                        club={this.props.match.params.club}
                        restLessonsData={this.state.restPriceListsData.data.content}
                        refresh={this.handlerRefreshReservation} />
                </Suspense>
            </div>
            tabPriceListHoliday = <div>
                <Suspense fallback={<div>
                    <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
                </div>}>
                    <TabPriceListHoliday
                        club={this.props.match.params.club}
                        restLessonsData={this.state.restPriceListsData.data.content}
                        refresh={this.handlerRefreshReservation} />
                </Suspense>
            </div>
        }

        return (

            <div>

                <div id="content">

                    <BeltReservationApp club={this.props.match.params.club} />

                    <BeltReservationPriceList
                        club={this.props.match.params.club}
                        pageType={this.props.match.params.id}
                    />

                    <Modal show={this.state.showConfirm} onHide={this.handleCloseConfirm} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Czy na pewno chcesz usunąć obecny cenniki i skopiować cennik z recepcji?</Modal.Title>
                        </Modal.Header>
                        {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
                        <Modal.Footer>
                            <Button variant="danger" onClick={this.handleCloseConfirm}>
                                Zamknij
                            </Button>
                            <Button variant="primary" onClick={this.handleCopy}>
                                Tak
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <br /><br />

                    <br /><br />
                    <h4><b>Cennik standardowy:</b></h4>
                    <Button href="#" variant="success" size="lg" style={{ 'margin-right': '20px', 'float': 'right' }}
                        onClick={() => this.priceListStandardForm()}>
                        Dodaj cennik
                    </Button>
                    <br /><br />
                    {copyData}
                    <b>Cennik standardowy powinien pokrywać wszystkie godziny na wszystkich kortach w bierzącym roku.<br />
                        Jeśli jakaś godzina nie ma ustawionej ceny, będzie ją widać poniżej:</b>
                    {chechWholeYear}
                    <br /><br />
                    {this.state.priceListPopupForm}

                    {tabPriceListStandard}
                    <br />


                    <br /><br />
                    <h4><b>Cennik święta(jeśli nie ustawiony podstawia się cena z cennika standardowego):</b></h4>
                    <Button href="#" variant="success" size="lg" style={{ 'margin-right': '20px', 'margin-bottom': '20px', 'float': 'right' }}
                        onClick={() => this.priceListHolidayForm()}>
                        Dodaj cennik świąteczny
                    </Button>
                    <br /><br />

                    {tabPriceListHoliday}

                    <br /><br />
                    {/* <h4><b>Cenniki promocyjne (Benefit,liga,trenerzy) do wyboru ręcznego przy tworzeniu rezerwacji:</b></h4>
                    <Button href="#" variant="success" size="lg" style={{ 'margin-right': '20px', 'float': 'right' }}
                    // onClick={() => this.priceListForm()}
                    >
                        Dodaj Cennik promocyjny
                    </Button>
                    <br /><br /> */}

                    {/* <br /><br />
                    <h4><b>Cenniki specjalne na konkretne dni (Dni tygodnia lub konkretne daty):</b></h4>
                    <Button href="#" variant="success" size="lg" style={{ 'margin-right': '20px', 'margin-bottom': '20px', 'float': 'right' }}
                    // onClick={() => this.priceListForm()}
                    >
                        Dodaj cennik na konretne dni
                    </Button> */}
                    <br /><br />

                    {loader}

                </div>
            </div>
        );
    }
}
export default PagePaymentsSettings;
