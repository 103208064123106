import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';

import AuthService from "../services/auth.service";
import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW";

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const EditAdmin = React.lazy(() => import('../classAppForms/EditAdmin'));

class PageShowAdmins extends React.Component {

  state = {
    currentUser: AuthService.getCurrentUser(),
    currentPage: "1",
    rowsCount: "25",
    title: "RezerwujKort.pl - Szkółki tenisowe",
    restAdminsData: {
      successful: false,
      status: '',
      message: '',
      data: []
    }
  }

  async fechGetAdmins(url) {

    var data;

    data = await FetchGetDataWithAuthNEW(url);
    if (data) {
      this.setState({ restAdminsData: data });
    }
  }

  async componentDidMount() {
    document.title = this.state.title;

    var url = "/class_app/" + this.props.club + "/admin/admins";
    if (this.props.match.params.page != null) {
      url = url + "/" + this.props.match.params.page + "/" + this.state.rowsCount;
      this.setState({ currentPage: this.props.match.params.page });
    }

    await this.fechGetAdmins(url);
  }


  render() {

    var beltClassApp = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp club={this.props.match.params.club} />
      </Suspense>
    </div>

    var editAdmin = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <EditAdmin
          club={this.props.match.params.club}
          id={this.props.match.params.id}
        />
      </Suspense>
    </div>

    return (

      <div>
         {beltClassApp}

        <div id="content">
          <div class="container">
           
            <br />
            <br />
            <b>Edycja danych administratora</b>
            <br />
            <br />
            {editAdmin}

            <br />
            <br />
          </div>
        </div>

      </div>

    );
  }
}
export default PageShowAdmins;
