import React from 'react'

class BeltClub extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentUser: []
        };
    }

    componentDidMount() {

    }

    render() {

        var infoCurrentPage = "";
        var reservation1CurrentPage = "";
        var reservation2CurrentPage = "";
        var reservation3CurrentPage = "";
        var reservation4CurrentPage = "";
        var trainerCurrentPage = "";
        var sparingpartnerCurrentPage = "";
        var tournamentCurrentPage = ""
        var leagueCurrentPage = "";

        if (this.props.currentPage === "info") { infoCurrentPage = "current" };
        if (this.props.currentPage === "reservation1") { reservation1CurrentPage = "current" };
        if (this.props.currentPage === "reservation2") { reservation2CurrentPage = "current" };
        if (this.props.currentPage === "reservation3") { reservation3CurrentPage = "current" };
        if (this.props.currentPage === "reservation4") { reservation4CurrentPage = "current" };

        // if (this.props.reservation1CurrentPage === "reservation11") { reservation1CurrentPage = "current"}; 
        // if (this.props.reservation2CurrentPage === "info") { reservation2CurrentPage = "current"}; 
        // if (this.props.reservation3CurrentPage === "info") { reservation3CurrentPage = "current"}; 
        // if (this.props.reservation4CurrentPage === "info") { reservation4CurrentPage = "current"}; 
        if (this.props.currentPage === "trainer") { trainerCurrentPage = "current" };
        if (this.props.currentPage === "sparingpartner") { sparingpartnerCurrentPage = "current" };
        if (this.props.currentPage === "tournamentCurrentPage") { tournamentCurrentPage = "current" };
        if (this.props.currentPage === "league") { leagueCurrentPage = "current" };

        var reservationPage2 = [];
        //var test=[];
        //test.push(<div class='paggination-bg'><a href={"/" + this.props.linkTo + "/1"}><div class='paggination-left'></div></a></div>)

        //test.push(<li style={{width: 170}} class={reservation1CurrentPage}><a href={"/klub/" + this.props.dataClubInfo.addressWww + "/rezerwacja_kortu_111/"}>  {this.props.dataClubInfo.reservationPage2Name}  </a></li>);
        //var test2 = '<li style={{width: 170}} class={infoCurrentPage}><a href={\"/klub/' + this.props.dataClubInfo.addressWww + '\"rezerwacja_kortu_1/}>{' + this.props.dataClubInfo.reservationPage1Name + '</a></li>'; 
        var reservationPage3 = [];
        var reservationPage4 = [];

        if (this.props.dataClubInfo.reservationPage2Name != undefined && this.props.dataClubInfo.reservationPage2Name != "") {
            if(this.props.dateReservation !== undefined){
                reservationPage2.push(<li style={{ width: this.props.dataClubInfo.beltElementWidth[2] + '%' }} class={reservation2CurrentPage}>
                <a href={"/klub/" + this.props.dataClubInfo.addressWww + "/rezerwacja_kortu_2/" + this.props.dateReservation}>
                    {this.props.dataClubInfo.reservationPage2Name}  </a></li>);
            }else{
                reservationPage2.push(<li style={{ width: this.props.dataClubInfo.beltElementWidth[2] + '%' }} class={reservation2CurrentPage}>
                <a href={"/klub/" + this.props.dataClubInfo.addressWww + "/rezerwacja_kortu_2"}>
                    {this.props.dataClubInfo.reservationPage2Name}  </a></li>);
            }
            
        }
        if (this.props.dataClubInfo.reservationPage3Name != undefined && this.props.dataClubInfo.reservationPage3Name != "") {
            if(this.props.dateReservation !== undefined){
                reservationPage3.push(<li style={{ width: this.props.dataClubInfo.beltElementWidth[3] + '%' }} class={reservation3CurrentPage}>
                <a href={"/klub/" + this.props.dataClubInfo.addressWww + "/rezerwacja_kortu_3/" + this.props.dateReservation}>
                    {this.props.dataClubInfo.reservationPage3Name}  </a></li>);
            }else{
                reservationPage3.push(<li style={{ width: this.props.dataClubInfo.beltElementWidth[3] + '%' }} class={reservation3CurrentPage}>
                <a href={"/klub/" + this.props.dataClubInfo.addressWww + "/rezerwacja_kortu_3"}>
                    {this.props.dataClubInfo.reservationPage3Name}  </a></li>);
            }
            
        }
        if (this.props.dataClubInfo.reservationPage4Name != undefined && this.props.dataClubInfo.reservationPage4Name != "") {
            if(this.props.dateReservation !== undefined){
                reservationPage4.push(<li style={{ width: this.props.dataClubInfo.beltElementWidth[4] + '%' }} class={reservation4CurrentPage}>
                <a href={"/klub/" + this.props.dataClubInfo.addressWww + "/rezerwacja_kortu_4/" + this.props.dateReservation}>
                    {this.props.dataClubInfo.reservationPage4Name}  </a></li>);
            }else{
                reservationPage4.push(<li style={{ width: this.props.dataClubInfo.beltElementWidth[4] + '%' }} class={reservation4CurrentPage}>
                <a href={"/klub/" + this.props.dataClubInfo.addressWww + "/rezerwacja_kortu_4"}>
                    {this.props.dataClubInfo.reservationPage4Name}  </a></li>);
            }
        }
        var li =<li style={{ width: this.props.dataClubInfo.beltElementWidth[1] + '%' }} class={reservation1CurrentPage}><a href={"/klub/" + this.props.dataClubInfo.addressWww + "/rezerwacja_kortu_1"}>{this.props.dataClubInfo.reservationPage1Name}</a></li>
        if(this.props.dateReservation !== undefined){
            li =<li style={{ width: this.props.dataClubInfo.beltElementWidth[1] + '%' }} class={reservation1CurrentPage}><a href={"/klub/" + this.props.dataClubInfo.addressWww + "/rezerwacja_kortu_1/" + this.props.dateReservation}>{this.props.dataClubInfo.reservationPage1Name}</a></li>
        }

        return (
            <div class="top-menu">
                <ul id="horizontal-style">
                    <li class={infoCurrentPage} style={{ width: this.props.dataClubInfo.beltElementWidth[0] + '%' }}><a href={"/klub/" + this.props.dataClubInfo.addressWww}>Profil ośrodka</a></li>
                    {li}
                    {reservationPage2}
                    {reservationPage3}
                    {reservationPage4}
                    <li class={trainerCurrentPage}><a href={"/klub/" + this.props.dataClubInfo.addressWww + "/trenerzy"}>Znajdź trenera</a></li>
                    <li class={sparingpartnerCurrentPage}><a href={"/klub/" + this.props.dataClubInfo.addressWww + "/sparingpartnerzy"}>Znajdź sparingpartnera</a></li>
                    {/* <li class={tournamentCurrentPage}><a href={"/klub/" + this.props.dataClubInfo.addressWww + "/turnieje"}>Turnieje</a></li> */}
                    <li class={leagueCurrentPage}><a href={"/klub/" + this.props.dataClubInfo.addressWww + "/liga"}>Liga</a></li>
                </ul>
            </div>
        )

    }
}

export default BeltClub;