import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class AddPromotionPopupFormInfo extends Component {
  static promoNameForUser() {
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="tooltip-text-two">
            Nazwa promocji widziana przez użytkownika podczas rezerwacji. Jeśli
            pole będzie puste nazwa promocji będzie taka sama jak dla
            administratora.
          </Tooltip>
        }
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/info_small.png`}
          alt=""
          style={{ marginLeft: "10px" }}
        />
      </OverlayTrigger>
    );
  }

  static promotionValue() {
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="tooltip-text-two">
            W przypadku promocji z rabate procentowym należy podać procent
            zniżki. Dla promocji z rabatem kwotowym wysokość w złotych o ile
            będzie tańsza rezerwacja.
          </Tooltip>
        }
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/info_small.png`}
          alt=""
          style={{ marginLeft: "10px" }}
        />
      </OverlayTrigger>
    );
  }

  static priority() {
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="tooltip-text-two">
            Każdy użytkownik podczas rezerwacji widzi tylko jedną promocję, tą
            która ma NAJNIŻSZY priorytet i jest do niej zakwalifikowany, czyli
            promocja jest dla wszystkich lub jest w grupie osób dla których
            promocja jest skierowana.
            <br />
            <br />
            Promocje można łączyć (po dodaniu) wtedy jest opcja aby użytkownik
            widział kilka promocji i mógł wybrać z której chce skorzystać (lub z
            kilku jednocześnie)
            <br />
            <br />
            Np. Istnieje promocja "Karta Benefit" dostępna dla wszystkich o
            priorytecie 50
            <br />
            oraz druga pormocja "Dla stałych klubowiczów" o priorytecie 70
            <br />
            W takim przypadku użytkownik podczas rezerwacji zobaczy tylko
            promocję "Karta Benefit"
            <br />
            Jeśli promocję zostaną połączone może zobaczyć je obie i zdecydować
            z której chce skorzystać, lub z obu jednocześnie jeśli przy łączeniu
            będzie zaznaczona taka opcja.
            <br />
            <br />
            Jeżeli promocja "Karta Benefit" będzie miała priorytet 70 a promocja
            "Dla stałych klubowiczów" 50 zobaczy tylko promocję "Dla stałych
            klubowiczów" (chyba że promocje zostaną połączone, wtedy widoczne
            będą obie)
          </Tooltip>
        }
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/info_small.png`}
          alt=""
          style={{ marginLeft: "10px" }}
        />
      </OverlayTrigger>
    );
  }

  static clientsType() {
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="tooltip-text-two">
            Promocja może być widoczna dla wszystkich klientów lub tylko
            wybranych.
            <br /> Klientów wybranych dodaje się po dodaniu pormocji.
          </Tooltip>
        }
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/info_small.png`}
          alt=""
          style={{ marginLeft: "10px" }}
        />
      </OverlayTrigger>
    );
  }

  static promotionType() {
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="tooltip-text-two">
            Rabat procentowy obniży kwotę rezerwacji o określony procent.
            <br />
            Rabat kwotowy obniży kwotę rezerwacji o określoną kwotę.
          </Tooltip>
        }
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/info_small.png`}
          alt=""
          style={{ marginLeft: "10px" }}
        />
      </OverlayTrigger>
    );
  }

  static promoFromWholeReservation() {
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="tooltip-text-two">
            Promocja może być widoczna dla wszystkich klientów lub tylko
            wybranych.
            <br /> Klientów wybranych dodaje się po dodaniu pormocji.
          </Tooltip>
        }
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/info_small.png`}
          alt=""
          style={{ marginLeft: "10px" }}
        />
      </OverlayTrigger>
    );
  }

  static howManyTimesCanBeUsed() {
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="tooltip-text-two">
            0 oznacza że nie ma limitu i pormocja może być używana nie
            ograniczoną ilośc razy.
          </Tooltip>
        }
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/info_small.png`}
          alt=""
          style={{ marginLeft: "10px" }}
        />
      </OverlayTrigger>
    );
  }

  static dateStartForReservation() {
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="tooltip-text-two">
            Jeżeli zostanie wybrana data to tylko w określonym czasie promocja
            będzie mogła być widoczna dla klienta.
            <br />
          </Tooltip>
        }
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/info_small.png`}
          alt=""
          style={{ marginLeft: "10px" }}
        />
      </OverlayTrigger>
    );
  }

  static dateStartForUse() {
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="tooltip-text-two">
            Jeżeli zostanie wybrana data to oznacza, że promocja będzie widoczna
            tylko dla rezerwacji które mieszczą się w danym okresie.
          </Tooltip>
        }
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/info_small.png`}
          alt=""
          style={{ marginLeft: "10px" }}
        />
      </OverlayTrigger>
    );
  }

  static promoFromWholeReservation() {
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="tooltip-text-two">
            Promocja może dotyczyć całej rezerwacji bez względu na jej długość
            lub każdej jej części (każde pół godziny)
            <br />
            <br />
            Np. Rezerwacja od 10:00 do 12:00 o wartości 100zł
            <br />
            <br />
            - promocja A: wartość 10 zł, zniżka dla całej rezerwacji. Cena
            Rezerawacji po uwzględnieniu zniżki = 90zł
            <br />
            <br />
            - promocja B: wartość 10 zł, zniżka dla każdej pół godziny. Cena
            Rezerwacji po uwzględnieniu zniżki = 60zł
            <br />
          </Tooltip>
        }
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/info_small.png`}
          alt=""
          style={{ marginLeft: "10px" }}
        />
      </OverlayTrigger>
    );
  }

  render() {
    // Ten komponent może być pusty, jeśli używasz tylko metod statycznych
    return null;
  }
}

export default AddPromotionPopupFormInfo;
