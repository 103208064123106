import React from 'react';

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';

const TurnamentsPage = () => {
  return (
        <div>

            <Belt />

            <BeltLogged />

            <div id="content">
              <div class="container">
                <div class="main-subpage">
                <div class="head">
                  
                  <img src={`/images/login.png`} />
                  <p>Turnieje</p> 
                </div>

                <div style={{clear: 'both'}}>
                        <div class="main-subpage-subtitle"></div>

                            <br/><br/>
                            Brak zaplanowanych turniejów.
                    </div>

                </div>

              </div>
            </div>

          <Footer />

        </div>
    );
}

export default TurnamentsPage;
