import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';
import AuthService from "../services/auth.service";
import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW";

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const AddStudentPayment = React.lazy(() => import('../classAppForms/AddStudentPayment'));

class PageShowStudentClassPayment extends React.Component {

    state = {
        currentUser: AuthService.getCurrentUser(),
        title: "RezerwujKort.pl - Szkółki tenisowe",
        restStudentData: {
            successful: false,
            status: '',
            message: '',
            data: []
        },
        restClassData: {
            successful: false,
            status: '',
            message: '',
            data: []
        }
    }

    async fechGetStudent(url) {

        var data;

        data = await FetchGetDataWithAuthNEW(url);
        if (data) {
            this.setState({ restStudentData: data });
        }
        console.log("restStudentData " + JSON.stringify(this.state.restStudentData)); //usun
    }

    async fechGetClass(url) {

        var data;

        data = await FetchGetDataWithAuthNEW(url);
        if (data) {
            this.setState({ restClassData: data });
        }
        console.log("restClassData " + JSON.stringify(this.state.restClassData)); //usun
    }

    async componentDidMount() {

        document.title = this.state.title;

        var url = "/class_app/" + this.props.match.params.club + "/admin/student/show/" + this.props.match.params.studentId;
        console.log("url " + JSON.stringify(url)); //usun
        await this.fechGetStudent(url);

        var classUrl = "/class_app/" + this.props.match.params.club + "/class/show/" + this.props.match.params.classId;
        if (this.props.match.params.page != null) {
            classUrl = classUrl + "/" + this.props.match.params.page + "/" + this.state.rowsCount;
            this.setState({ currentPage: this.props.match.params.page });
        }
        await this.fechGetClass(classUrl);

    }

    render() {

        var desc;
        if (this.props.match.params.type === "0")
            desc = "Dodawanie płatności dla szkółki:"
        else {
            desc = "Dodawanie wpłaty klienta dla szkółki:"
        }

        var beltClassApp = <div>
            <Suspense fallback={<div>
                <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
            </div>}>
                <BeltClassNewApp club={this.props.match.params.club} />
            </Suspense>
        </div>

        var addStudentPayment = <div>
            <Suspense fallback={<div>
                <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
            </div>}>
                <AddStudentPayment
                    club={this.props.match.params.club}
                    studentId={this.props.match.params.studentId}
                    classId={this.props.match.params.classId}
                    type={this.props.match.params.type}
                />
            </Suspense>
        </div>

        return (

            <div>
                {beltClassApp}

                <div id="content">
                    <div class="container">

                        

                        <br /><br />
                        <b>{desc} {this.state.restClassData.data.name}</b>
                        <br /><br />
                        Imie: {this.state.restStudentData.data.name}<br />
                        Nazwisko: {this.state.restStudentData.data.surname}<br />
                        Email: {this.state.restStudentData.data.email}<br />
                        Kiedy dodany: {this.state.restStudentData.data.createdAt}<br /><br />

                        <br />

                        {addStudentPayment}

                        <br /><br />
                    </div>
                </div>

            </div>
        );
    }
}
export default PageShowStudentClassPayment;
