import React from 'react';

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';

//UTILS
import DateTimeNow from "../utils/DateTimeNow";


class AppStart extends React.Component {

    searchClubItem = {
        city: '',
        club: '',
        page: ''
    };
    urlDeafulValues = [];
    urlValue = "";

    state = {
        logInfo: "LogDebug __" + DateTimeNow() + "__ ClubsSearchPage ",
        title: "RezerwujKort.pl"
    }

    async componentDidMount() {

        document.title = this.state.title

    }

    render() {


        return (
            <div>


            </div>
        );
    }
}

export default AppStart;
