import FetchPostDataWithAuthNEW from "../services/FetchPostDataWithAuthNEW";

export default async function FechPostDeleteAccount(body) {

  var url = "/delete_user";

  var data = await FetchPostDataWithAuthNEW(url, body)

  return data;
}
