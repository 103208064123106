import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';
import PageCounterNew from '../components/PageCounterNew';
import Footer from '../components/Footer';

import FechPostNewsBySearch from "../fechData/FechPostNewsBySearch";

import ParseLinkToArray from "../utils/ParseLinkToArray"
import queryString from 'query-string'

const NewsOneLong = React.lazy(() => import('../components/NewsOneLong'));

class NewsPage extends React.Component {

  searchNewsItem = {
    page: ''
  };
  urlDeafulValues = [];
  urlValue = "";

  state = {
    title: "Aktualności - RezerwujKort.pl",
    rowsCount: "6",
    restNewsAdverbsData: {
      successful: false,
      status: '',
      message: '',
      data: []
    }
  }

  async componentDidMount() {

    document.title = this.state.title

    const { location: { search } } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.searchNewsItem = await ParseLinkToArray(this.urlValue, this.searchNewsItem, this.urlDeafulValues);

    this.setState({
      restNewsAdverbsData: await FechPostNewsBySearch(this.searchNewsItem)
    });

  }

  render() {

    var adverbs = "";
    if (this.state.restNewsAdverbsData.successful &&
      typeof this.state.restNewsAdverbsData.data.newsResources.map === 'function') {
      adverbs = this.state.restNewsAdverbsData.data.newsResources.map(newsOne =>
        <div>
          <Suspense fallback={<div>
            <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
          </div>}>
            <NewsOneLong info={newsOne} />
          </Suspense>
        </div>);
    }

    const urlValues = new Map();
    urlValues.set('page', "replace");

    var pageCounter = "";
    if (this.state.restNewsAdverbsData.successful) {
      pageCounter = <PageCounterNew
        elementCount={this.state.restNewsAdverbsData.data.totalRecordsCount}
        urlValues={urlValues}
        urlDeafulValues={this.urlDeafulValues}
        elementOnPage={this.state.rowsCount}
        currentPage={parseInt(this.searchNewsItem.page) + 1}
        searchName={"news_search"}
        linkTo={"aktualnosci"}
      />
    }

    return (

      <div>

        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">
            <div class="main-subpage">
              <div class="head">
                <img src="" alt="" />
                <p>Aktualności</p>
              </div>

              {adverbs}

              {pageCounter}


            </div>

          </div>
        </div>

        <Footer />

      </div>
    );
  }
}
export default NewsPage;
