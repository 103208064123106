import React from 'react';

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';

//UTILS
import DateTimeNow from "../utils/DateTimeNow";


class Template404 extends React.Component {

    searchClubItem = {
        city: '',
        club: '',
        page: ''
    };
    urlDeafulValues = [];
    urlValue = "";

    state = {
        logInfo: "LogDebug __" + DateTimeNow() + "__ ClubsSearchPage ",
        title: "RezerwujKort.pl"
    }

    async componentDidMount() {

        document.title = this.state.title

    }

    render() {


        return (
            <div>

                <Belt />

                <BeltLogged />

                <div id="content">
                    <div class="container">
                        <div class="container">
                            <div class="main-subpage-info">
                                <p>
                                    Przepraszamy<br />
                                    Nie odnaleziono strony... :(
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

                <Footer />

            </div>
        );
    }
}

export default Template404;
