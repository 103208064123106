import Alert from 'react-bootstrap/Alert';

function ErrorPopup(title, message) {
  return (
    <Alert variant="danger">
      <Alert.Heading>{title}</Alert.Heading>
      <p>
        {message}
      </p>
    </Alert>
  );
}

export default ErrorPopup;