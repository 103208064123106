import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';

const EditProfileForm = React.lazy(() => import('../forms/EditProfileForm'));

class ProfileEditPage extends React.Component {

  state = {

    data: [],
    newsCount: "",
    currentPage: "1",
    title: "RezerwujKort.pl - Profil"
  }

  componentDidMount() {

    document.title = this.state.title
  }

  render() {

    var editProfile = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <EditProfileForm />
      </Suspense>
    </div>

    return (

      <div>

        <Belt />

        <BeltLogged />

        {editProfile}

        <Footer />

      </div>
    );
  }
}
export default ProfileEditPage;
