import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';

import AuthService from "../services/auth.service";

import { Link } from 'react-router-dom'
import { Button } from "react-bootstrap";

import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW";

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const AddStudentToClassPayment = React.lazy(() => import('../classAppForms/AddStudentToClassPayment'));

class PageStudentAddToClassPayment extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      newsCount: "",
      currentPage: "1",
      rowsCount: "25",
      restClassData: {
        successful: false,
        status: '',
        message: '',
        data: []
      }
    }
  }

  async componentDidMount() {

    document.title = this.state.title;

    var url = "/class_app/" + this.props.match.params.club + "/class/show/" + this.props.match.params.id;
    if (this.props.match.params.page != null) {
      url = url + "/" + this.props.match.params.page + "/" + this.state.rowsCount;
      this.setState({ currentPage: this.props.match.params.page });
    }
    await this.fechGetClass(url);

    console.log(" componentDidMount this.props.location.stateee " + JSON.stringify(this.props.location.state)); //usun

  }

  async fechGetClass(url) {

    var data;

    data = await FetchGetDataWithAuthNEW(url);
    if (data) {
      this.setState({ restClassData: data });
    }
  }

  render() {

    var msg = this.props.location.state;
    var button = "";
    if (msg === undefined) {
      button = "";
    } else {
      button = <div class="tenis-center-content-info">+  {msg}  + </div>;
    }

    var beltClassApp = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp club={this.props.match.params.club} />
      </Suspense>
    </div>

    var addStudentToClassPayment = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <AddStudentToClassPayment
          club={this.props.match.params.club}
          classId={this.props.match.params.id}
          studentId={this.props.location.state[0]}
          studentName={this.props.location.state[1]}
          dateStart={this.props.location.state[3]}
          dateStop={this.props.location.state[4]}
        />
      </Suspense>
    </div>

    return (

      <div>
        {beltClassApp}

        <div id="content">
          <div class="container">

            <br /><br />

            {button}

            <Link to={"/class_app/" + this.props.match.params.club + "/class_show_students/" + this.state.restClassData.data.id}>
              <Button variant="primary" type="submit">
                Powrót
              </Button>&nbsp;&nbsp;
            </Link>

            <br /><br />
            <b>Szczegóły szkólki: </b>
            <br />
            Nazwa: {this.state.restClassData.data.name}
            <br />
            Opis: {this.state.restClassData.data.description}
            <br />
            Kort: {this.state.restClassData.data.court}
            <br />
            Dzień: {this.state.restClassData.data.dayOfWeek}
            <br />
            Godzina start: {this.state.restClassData.data.hourStart}
            <br />
            Godzina koniec: {this.state.restClassData.data.hourStop}
            <br />
            Data start: {this.state.restClassData.data.dateStart}
            <br />
            Data koniec: {this.state.restClassData.data.dateStop}
            <br />
            Trener: {this.state.restClassData.data.trainer}
            <br />
            Utworzone: {this.state.restClassData.data.createdAt}
            <br />
            <br />

            {addStudentToClassPayment}

            <br /><br />

          </div>
        </div>

      </div>
    );
  }
}
export default PageStudentAddToClassPayment;


