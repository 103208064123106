import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import { Button, Card, Table } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';

import DateTimeNow from "../utils/DateTimeNow";
import history from "../components/history";

import FechGetClass from "../classAppFechData/FechGetClass";
import FechGetDeleteClass from "../classAppFechData/FechGetDeleteClass";

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));

class PageShowClassDelete extends React.Component {

    NOTIFICATION_TITLE_OK = "Sukces!"
    NOTIFICATION_TITLE_NOT_OK = "Błąd!"
    NOTIFICATION_MSG_OK = "Szkółka została usunięta!"
    NOTIFICATION_MSG_NOT_OK = "Wystąpił błąd! Spróbój ponownie!"

    constructor(props) {
        super(props);

        this.state = {
            title: "RezerwujKort.pl - Szkółki tenisowe",
            logInfo: "LogDebug __" + DateTimeNow() + "__ PageShowClassDelete ",
            restClassData: {
                successful: false,
                status: '',
                message: '',
                data: []
            },
            restDeleteClassResponseData: {
                successful: false,
                status: '',
                message: '',
                data: []
            }
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {

        document.title = this.state.title;

        this.setState({
            restClassData: await FechGetClass(this.props.match.params.club,
                this.props.match.params.id)
        });
    }

    async handleSubmit(event) {

        event.preventDefault();

        this.setState({
            restDeleteClassResponseData: await FechGetDeleteClass(this.props.match.params.club,
                this.props.match.params.id)
        });

        var notification = ['', '', ''];
        if (this.state.restDeleteClassResponseData.successful) {
            notification = ['success', this.NOTIFICATION_TITLE_OK, this.NOTIFICATION_MSG_OK];
        } else {
            notification = ['error', this.NOTIFICATION_TITLE_NOT_OK, this.NOTIFICATION_MSG_NOT_OK];
        }

        history.push('/class_app/' + this.props.match.params.club + '/class/class_search', notification);
        window.location.reload(false);
    }

    render() {

        var beltClassApp = <div>
            <Suspense fallback={<div>
                <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
            </div>}>
                <BeltClassNewApp club={this.props.match.params.club} />
            </Suspense>
        </div>

        return (

            <div>
                {beltClassApp}

                <div id="content">
                    <div class="container">

                        

                        <br />

                        <Card style={{ width: '100%' }}>
                            <Card.Body>
                                <Card.Title>Informację podstawowe o szkółce:</Card.Title>
                                <Table responsive="sm">

                                    <tbody>
                                        <tr>
                                            <td><b>Nazwa szkółki:</b></td>
                                            <td>{this.state.restClassData.data.name}</td>
                                            <td><b>Opis:</b></td>
                                            <td>{this.state.restClassData.data.description}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Kort:</b></td>
                                            <td>{this.state.restClassData.data.court}</td>
                                            <td><b>Dzień:</b></td>
                                            <td>{this.state.restClassData.data.dayOfWeek}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Godzina start:</b></td>
                                            <td>{this.state.restClassData.data.hourStart}</td>
                                            <td><b>Godzina koniec:</b></td>
                                            <td>{this.state.restClassData.data.hourStop}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Data start:</b></td>
                                            <td>{this.state.restClassData.data.dateStart}</td>
                                            <td><b>Data koniec:</b></td>
                                            <td>{this.state.restClassData.data.dateStop}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Trener:</b></td>
                                            <td>{this.state.restClassData.data.trainer}</td>
                                            <td><b>Utworzone:</b></td>
                                            <td>{this.state.restClassData.data.createdAt}</td>
                                        </tr>
                                        <tr>

                                        </tr>
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>

                        <br /><br />
                        <b>Czy na pewno chcesz usunąć szkółkę oraz wszystkie przypisane do nie zajęcia?</b>
                        <br /><br />

                        <Button variant="primary" type="submit" onClick={this.handleSubmit}>
                            Usuń szkókę
                        </Button>&nbsp;&nbsp;

                    </div>
                </div>


            </div>
        );
    }
}
export default PageShowClassDelete;
