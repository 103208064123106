import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW"; 

export default function FechChangePlayerPosition(club, league, slug, newPosition) {
 
  var url = "/league_app/" + club + "/" + league + "/change_player_rank_position/" + slug + "/" + newPosition;
  var data = FetchGetDataWithAuthNEW(url);

  return data;
}

