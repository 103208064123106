import React from "react";
import AuthService from "../services/auth.service";

import UserService from "../services/user.service";

class BeltLogged extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: [],
    };
  }

  logOut() {
    AuthService.logout();
  }

  componentDidMount() {
    this.setState({ currentUser: AuthService.getCurrentUser() });
  }

  render() {
    const { currentUser } = this.state;

    if (
      currentUser !== null &&
      this.state.currentUser.accessToken != undefined
    ) {
      return (
        <div id="bottom-header" class="logged-bar">
          <div class="container">
            <div class="hello-name"></div>
            <ul class="logged-menu">
              <li class="">
                <a href={"/profile"}>Start</a>
              </li>
              <li class="dropdown">
                <a href="#">
                  Profil<span class="dropdown-icon"></span>
                </a>
                <ul class="dropdown-ul">
                  <li>
                    <a href={"/profile/info"}>Twoje dane</a>
                  </li>
                  <li>
                    <a href={"/profile/edycja"}>Edytuj profil</a>
                  </li>
                  {/* <li><a href={"/profile/kluby"}>Ulubione ośrodki</a></li> */}
                  <li>
                    <a href={"/profile/zmiana_hasla"}>Zmiana hasła</a>
                  </li>
                  <li>
                    <a href={"/profile/kasowania_konta"}>Kasowanie konta</a>
                  </li>
                </ul>
              </li>
              <li class="dropdown">
                <a href="#">
                  Rezerwacje<span class="dropdown-icon"></span>
                </a>
                <ul class="dropdown-ul">
                  <li>
                    <a href={"/profile/moje_rezerwacje?page=1"}>Rezerwacje</a>
                  </li>
                  <li>
                    <a href={"/profile/moje_karnety?page=1"}>Karnety</a>
                  </li>
                </ul>
              </li>
              <li class="dropdown">
                <a href="#">
                  Sparingpartnerzy<span class="dropdown-icon"></span>
                </a>
                <ul class="dropdown-ul">
                  <li>
                    <a href={"/profile/sparingpartnerzy"}>Moje ogłoszenia</a>
                  </li>
                  <li>
                    <a href={"/profile/sparingpartnerzy/dodaj"}>
                      Dodaj ogłoszenie
                    </a>
                  </li>
                </ul>
              </li>
              {/* {# <li class="dropdown">
                            <a href="#">Trenerzy<span class="dropdown-icon"></span></a>
                            <ul class="dropdown-ul">
                                <li><a href="{{ path('MyFrontendBundle_trenerzy_szukajpoid') }}">Moje ogłoszenia</a></li>
                                <li><a href="{{ path('ogltrener_new') }}">Dodaj ogłoszenie</a></li>
                            </ul>
                        </li> #} */}
            </ul>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default BeltLogged;
