import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';
import PageCounter from '../components/PageCounter';

import AuthService from "../services/auth.service";
import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW";
import { Link } from 'react-router-dom'
import { Form, Button } from "react-bootstrap";

import Select from 'react-select';
import history from "../components/history";
import PropTypes from 'prop-types'

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const TabAllStudents = React.lazy(() => import('../classAppComponents/TabAllStudents'));


////////////////////////////////
///////////////////////////////

// KLASA CHYBA NIE UZYWANA ////////////
////////////////////////////
///////////////////////

class PageStudentsSearch extends React.Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  emptyItem = {
    student_id: '',
    class_id: ''
  };

  constructor(props) {

    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      //allStudents: [],
      item: this.emptyItem,
      currentPage: "1",
      rowsCount: "25",
      title: "RezerwujKort.pl - Szkółki tenisowe",
      restStudentsData: {
        successful: false,
        status: '',
        message: '',
        data: []
      },
      restStudentsCountData: {
        successful: false,
        status: '',
        message: '',
        data: []
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async fechGetStudents(url) {

    var data;

    data = await FetchGetDataWithAuthNEW(url);
    if (data) {
      this.setState({ restStudentsData: data });
    }
  }

  async fechGetStudentsCount(url) {

    var data;

    data = await FetchGetDataWithAuthNEW(url);
    if (data) {
      this.setState({ restStudentsCountData: data });
    }
  }

  async componentDidMount() {

    document.title = this.state.title;

    var url = this.props.match.params.club + "/class_app/students"
    if (this.props.match.params.page != null) {
      url = url + "/" + this.props.match.params.page + "/" + this.state.rowsCount;
      this.setState({ currentPage: this.props.match.params.page });
    }
    console.log("url " + JSON.stringify(url));  //usun

    await this.fechGetStudents(url);
    await this.fechGetStudentsCount(this.props.match.params.club + "/class_app/students/count");

    url = this.props.match.params.club + "/class_app/admin/all_class_students_list";

    await this.fechGetStudentsCount(url);
  }

  handleChange(event) {

    let item = { ...this.state.item };
    item.student_id = event.value;
    item.class_id = this.props.classid;


    this.setState({ item });
    this.setState({ item: item });
  }

  async handleSubmit(event) {

    event.preventDefault();

    console.log(" poszloooo: " + this.state.currentUser.accessToken); //usun
    console.log(" item posttt : " + JSON.stringify(this.state.item));
    history.push('/class_app/admin/student/stat/' + this.state.item.student_id, "Nowy uczeń został dodanyy");
    window.location.reload();
  }

  render() {

    var msg = this.props.location.state;
    var button = "";
    if (msg === undefined) {
      button = "";
    } else {
      button = <div class="tenis-center-content-info">+  {msg}  + </div>;
    }

    var start = "1"
    if (this.state.currentPage > 1) {
      start = this.state.rowsCount * (this.state.currentPage - 1);
    }

    //jesli pusta tabela
    var studentsList;
    if (typeof this.state.allStudents.map === 'function') {

      studentsList = this.state.allStudents.find(obj => obj.value === this.state.selectedValue);
    } else {
      studentsList = "";
    }

    var beltClassApp = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp club={this.props.match.params.club} />
      </Suspense>
    </div>

    var tabAllStudents = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <TabAllStudents
          data={this.state.data}
          start={start}
          page={this.state.currentPage} />
      </Suspense>
    </div>

    return (

      <div>
        {beltClassApp}

        <div id="content">
          <div class="container">

            <br /><br />

            {button}


            <br /><br />

            <Link to={"/class_app/admin/student/add"}>
              <Button variant="primary">Dodaj nowego uczestnika zajęć</Button>
            </Link>

            <br /><br />
            <b>Wyszukaj uczestnika:</b>
            <br /><br />

            <Form onSubmit={this.handleSubmit}>

              <div className="App">
                Wybierz uczestnika z listy<br /><br />

                <Select
                  placeholder="Select Option"
                  value={studentsList} // set selected value
                  options={this.state.allStudents} // set list of the data
                  onChange={this.handleChange} // assign onChange function
                />

                {this.state.selectedValue && <div style={{ marginTop: 20, lineHeight: '25px' }}>
                  <div><b>Selected Value: </b> {this.state.selectedValue}</div>
                </div>}
              </div>
              <br /><br />

              <Button variant="primary" type="submit">
                Szukaj
              </Button>
            </Form>


            <br /><br />
            <b>Lista wszystkich uczestników:</b>
            <br /><br />

            {tabAllStudents}

            <PageCounter elementCount={this.state.studentsCount} 
            elementOnPage={"25"} 
            currentPage={this.state.currentPage} 
            linkTo={"class_app/admin/admins"} />

          </div>
        </div>

      </div>
    );
  }
}
export default PageStudentsSearch;