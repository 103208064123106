import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';
import PageCounterNew from '../components/PageCounterNew';

import AuthService from "../services/auth.service";

import FechGetClassTrainer from "../classAppFechData/FechGetClassTrainer";
import FechGetClassNames from "../classAppFechData/FechGetClassNames";
import FechGetClassLessonDateRange from "../classAppFechData/FechGetClassLessonDateRange";
import FechPostLessonsClass from '../classAppFechData/FechPostLessonsClass'

import DateTimeNow from "../utils/DateTimeNow";
import ParseLinkToArray from "../utils/ParseLinkToArray"

import queryString from 'query-string'

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const TabAllLessons = React.lazy(() => import('../classAppComponents/TabAllLessons'));
const SearchLessonAll = React.lazy(() => import('../forms/SearchLessonAll'));

class PageLessonAll extends React.Component {

  TRAINER_SELECT_DESC = "Wybierz trenera";
  DATE_SELECT_DESC = "Wybierz datę";
  CLASS_SELECT_DESC = "Wybierz szkółke";

  urlDeafulValues = [this.TRAINER_SELECT_DESC, this.CLASS_SELECT_DESC, this.DATE_SELECT_DESC];

  urlValue = "";

  postData = {
    dateStart: '',
    dateStop: '',
    trainer: '',
    classId: '',
    className: '',
    status: '',
    page: '0'
  };

  state = {
    logInfo: "LogDebug __" + DateTimeNow() + "__ PageLessonAll ",
    title: "RezerwujKort.pl - Profil",
    currentUser: AuthService.getCurrentUser(),
    data: [],
    urlValue: "",
    totalRecordsCount: "",
    // currentPage: "1",
    rowsCount: "25",
    title: "RezerwujKort.pl - Szkółki tenisowe",
    restClassLessonDateRangeData: {
      successful: false,
      status: '',
      message: '',
      data: []
    },
    restClassTrainerData: {
      successful: false,
      status: '',
      message: '',
      data: []
    },
    restClassNamesData: {
      successful: false,
      status: '',
      message: '',
      data: []
    },
    restLessonsData: {
      successful: false,
      status: '',
      message: '',
      data: []
    }
  }

  async componentDidMount() {

    document.title = this.state.title;

    const { location: { search } } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.postData = await ParseLinkToArray(this.urlValue, this.postData, this.urlDeafulValues);

    this.state.restClassLessonDateRangeData = await FechGetClassLessonDateRange(this.props.match.params.club);
    this.state.restClassTrainerData = await FechGetClassTrainer(this.props.match.params.club);
    this.state.restClassNamesData = await FechGetClassNames(this.props.match.params.club);

    if (this.postData.dateStart === "") {
      if (typeof this.state.restClassLessonDateRangeData.data.map === 'function') {
        this.postData.dateStart = this.state.restClassLessonDateRangeData.data[0];
      }
    }

    if (this.postData.dateStop == "") {
      if (typeof this.state.restClassLessonDateRangeData.data.map === 'function') {
        this.postData.dateStop = this.state.restClassLessonDateRangeData.data[this.state.restClassLessonDateRangeData.data.length - 1];
      }
    }

    this.setState({ restLessonsData: await FechPostLessonsClass(this.props.match.params.club, this.postData) });

    if (typeof this.state.restLessonsData.successful) {
      this.setState({ totalRecordsCount: this.state.restLessonsData.data.totalElements })
    }
  }


  render() {

    var msg = this.props.location.state;
    var button = "";
    if (msg === undefined) {
      button = "";
    } else {
      button = <div class="tenis-center-content-info">+  {msg}  + </div>;
    }

    var start = "1"

    const urlValues = new Map();
    urlValues.set('dateStart', this.postData.dateStart);
    urlValues.set('dateStop', this.postData.dateStop);
    urlValues.set('trainer', this.postData.trainer);
    urlValues.set('classId', this.postData.classId);
    urlValues.set('className', this.postData.className);
    urlValues.set('status', this.postData.status);
    urlValues.set('page', "replace");

    var allLessons;
    if (typeof this.state.restLessonsData.data !== 'undefined') {
      allLessons = this.state.restLessonsData.data.content;
    }

    var loader = "";
    if (!this.state.restLessonsData.successful) {
      loader = <div id="loader_div">
        <Spinner animation="border" />
      </div>
    }

    var searchPanel = "";
    if (typeof this.state.restClassLessonDateRangeData.data !== 'undefined' &&
      this.state.restClassLessonDateRangeData.successful) {
      searchPanel = <div>
        <Suspense fallback={<div>
          <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
        </div>}>
          <SearchLessonAll
            urlValue={this.postData}
            club={this.props.match.params.club}
            restClassLessonDateRangeData={this.state.restClassLessonDateRangeData}
            restClassTrainerData={this.state.restClassTrainerData}
            restClassNamesData={this.state.restClassNamesData}
          />
        </Suspense>
      </div>
    }

    var beltClassApp = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp club={this.props.match.params.club} />
      </Suspense>
    </div>

    var tabAllLessons;
    if (this.state.restLessonsData.successful) {
      tabAllLessons = <div>
        <Suspense fallback={<div>
          <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
        </div>}>
          <TabAllLessons club={this.props.match.params.club}
            start={start}
            restLessonsData={allLessons} />
        </Suspense>
      </div>
    }

    return (

      <div>
        {beltClassApp}

        <div id="content">
          <div style={{ paddingTop: '55px' }}>



            <br /><br />

            {button}

            <br /><br />

            {searchPanel}

            <br /><br />

            <br /><br />
            <b>Lista wszystkich zajęć ({this.state.totalRecordsCount}):</b>
            <br /><br />

            {tabAllLessons}

            <PageCounterNew
              elementCount={this.state.totalRecordsCount}
              urlValues={urlValues}
              urlDeafulValues={this.urlDeafulValues}
              elementOnPage={this.state.restLessonsData.data.size}
              currentPage={parseInt(this.postData.page) + 1}
              searchName={"lesson_search"}
              linkTo={"class_app/" + this.props.match.params.club + "/lesson_all"}
            />

            {loader}

          </div>
        </div>


      </div>
    );
  }
}
export default PageLessonAll;
