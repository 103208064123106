import FetchPostDataWithAuthNEW from "../services/FetchPostDataWithAuthNEW";

export default async function FechPostEditClubSettings(club, body) {

  var url = "/reservation/edit_club_settings/" + club;

  var data = await FetchPostDataWithAuthNEW(url, body)

  return data;
}
