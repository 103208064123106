import React from "react";

import { Form } from "react-bootstrap";
import Belt from "../components/Belt";
import BeltLogged from "../components/BeltLogged";
import Footer from "../components/Footer";

import history from "../components/history";
import MailContact from "../mailNotification/MailContact";

import ValidationFormField from "../utils/ValidationFormField";

class ContactPage extends React.Component {
  mailData = {
    name: "",
    phone: "",
    email: "",
    description: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      title: "Kontakt - RezerwujKort.pl",
      item: this.mailData,
      mailResponseData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
      isError: {
        name: "",
        phone: "",
        email: "",
        description: "",
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    document.title = this.state.title;
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let item = { ...this.state.item };
    item[name] = target.value;

    this.setState({ item: item });

    this.fieldValidation(name, value);
  }

  async handleSubmit(event) {
    event.preventDefault();

    for (var key in this.state.item) {
      this.fieldValidation(key, this.state.item[key]);
    }

    if (ValidationFormField.formValid(this.state.isError)) {
      this.state.mailResponseData = await MailContact(this.state.item);

      history.push("/info", "Wiadomość została wysłana");
      window.location.reload();
    }
  }

  fieldValidation(name, value, compareValue) {
    let isError = { ...this.state.isError };

    switch (name) {
      case "name":
        isError.name = ValidationFormField.validClassName("Imię ", value);
        break;
      case "phone":
        isError.phone = ValidationFormField.validPhoneNotRequired(
          "Telefon",
          value
        );
        break;
      case "email":
        isError.email = ValidationFormField.validEmail("Email", value);
        break;
      case "description":
        isError.description = ValidationFormField.validLongDescription(
          "Treść wiadomości",
          value
        );
        break;
      default:
        isError.password = "";
        break;
    }
    this.setState({
      isError,
      [name]: value,
    });
    this.state.isError[name] = isError[name];
  }

  render() {
    const { item } = this.state;
    let isError = { ...this.state.isError };

    return (
      <div>
        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">
            <div class="main-subpage" id="mail_kontakt">
              <div class="head">
                <img src={`/images/login.png`} />
                <p>Kontakt</p>
              </div>
              <div class=" tenis-content-info">
                Skontaktuj się z nami bez wahania. Odpowiemy na pytania
                najlepiej jak potrafimy i udzielimy potrzebnych informacji.{" "}
                <br />
                <br />
                {/* Telefonicznie: 660 916 097 (Numery telefonów do wszystkich klubów znajdują się nad grafikiem rezerwacji)
                <br /><br />
                lub wypełniając poniższy formularz:<br /> */}
              </div>

              <div class="main-subpage-content subpage-contact">
                <Form onSubmit={this.handleSubmit} id="mail_sugestia">
                  <div class="form-err">
                    {/* {% if form_errors(form.nazwaklubu) != "" %}
                                          <tr><td colspan="6">{{ form_errors(form.nazwaklubu) }}</td> </tr>
                                  {#{% elseif form_errors(form.wojewodztwo) != "" %}
                                          <tr><td colspan="6">{{ form_errors(form.wojewodztwo) }}</td> </tr>
                                  {% elseif form_errors(form.miasto) != "" %}
                                          <tr><td colspan="6">{{ form_errors(form.miasto) }}</td> </tr>#}
                                  {% elseif form_errors(form.telefon) != "" %}
                                          <tr><td colspan="6">{{ form_errors(form.telefon) }}</td> </tr>
                                  {% elseif form_errors(form.emaile) != "" %}
                                          <tr><td colspan="6">{{ form_errors(form.emaile) }}</td> </tr>
                                  {#{% elseif form_errors(form.ilekortow) != "" %}
                                          <tr><td colspan="6">{{ form_errors(form.ilekortow) }}</td> </tr>#}
                                  {% elseif form_errors(form.dodatkoweinformacje) != "" %}
                                          <tr><td colspan="6">{{ form_errors(form.dodatkoweinformacje) }}</td> </tr>
                                  {% elseif form_errors(form) != "" %}
                                          <tr><td colspan="6">{{ form_errors(form) }}</td> </tr>
                                  {% endif %} */}
                  </div>
                  <div class="contact-item">
                    <label>
                      Imię i nazwisko<span style={{ color: "#86b606" }}>*</span>
                      :
                    </label>
                    <Form.Group controlId="formGridAddress1">
                      <Form.Control
                        placeholder=""
                        id="name"
                        name="name"
                        value={item.name || ""}
                        onChange={this.handleChange}
                      />
                      {this.state.isError.name.length > 0 && (
                        <span className="invalid-feedback-contakt">
                          {this.state.isError.name}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                  <div class="contact-item">
                    <label>Telefon:</label>
                    <Form.Group controlId="formGridAddress1">
                      <Form.Control
                        placeholder=""
                        id="phone"
                        name="phone"
                        value={item.phone || ""}
                        onChange={this.handleChange}
                      />
                      {this.state.isError.phone.length > 0 && (
                        <span className="invalid-feedback-contakt">
                          {this.state.isError.phone}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                  <div class="contact-item">
                    <label>
                      E-mail<span style={{ color: "#86b606" }}>*</span>:
                    </label>
                    <Form.Group controlId="formGridAddress1">
                      <Form.Control
                        placeholder=""
                        id="email"
                        name="email"
                        onChange={this.handleChange}
                      />
                      {this.state.isError.email.length > 0 && (
                        <span className="invalid-feedback-contakt">
                          {this.state.isError.email}
                        </span>
                      )}
                    </Form.Group>
                  </div>

                  <div class="contact-item">
                    <label>
                      Treść<span style={{ color: "#86b606" }}>*</span>:
                    </label>
                    <Form.Group controlId="formGridAddress1">
                      <Form.Control
                        as="textarea"
                        placeholder=""
                        id="description"
                        name="description"
                        onChange={this.handleChange}
                      />
                      {this.state.isError.description.length > 0 && (
                        <span className="invalid-feedback-contakt">
                          {this.state.isError.description}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                  <div class="contact-button">
                    <span class="register-confirm-alert">
                      Pole oznaczone <span style={{ color: "#86b606" }}>*</span>{" "}
                      są wymagane
                    </span>
                    <span>
                      <input
                        class="btn-more semibold"
                        value="Wyślij"
                        type="submit"
                      />
                    </span>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default ContactPage;
