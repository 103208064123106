import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';

import AuthService from "../services/auth.service";

import { Link } from 'react-router-dom'
import { Form, Button } from "react-bootstrap";

import history from "../components/history";
import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW";

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));

//KLASA WYSWIETLA SZCZEGOLY KONKRETNYCH ZAJEC SZKOLKI
class PageLessonDelete extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      currentPage: "1",
      rowsCount: "25",
      restLessonData: {
        successful: false,
        status: '',
        message: '',
        data: []
      },
      restLessonDeleteData: {
        successful: false,
        status: '',
        message: '',
        data: []
      }
    }
    this.deleteLesson = this.deleteLesson.bind(this);
  }

  async componentDidMount() {

    document.title = this.state.title;

    var url = "/class_app/" + this.props.match.params.club + "/admin/lesson/" + this.props.match.params.id;

    await this.fechGetLessons(url);
  }

  async fechGetLessons(url) {

    var data;

    data = await FetchGetDataWithAuthNEW(url);
    if (data) {
      this.setState({ restLessonData: data });
    }
  }

  async deleteLesson() {

    //console.log(" this.props.id 22: "); //usun
    console.log(" this.props.id 11111: " + JSON.stringify(this.props.match.params.id)); //usun

    var url = '/class_app/' + this.props.match.params.club + '/admin/lessons/delete_lessons/' + this.props.match.params.id;

    var data;

    data = await FetchGetDataWithAuthNEW(url);
    if (data) {
      this.setState({ restLessonDeleteData: data });
    }
    history.push('/class_app/' + this.props.match.params.club + '/class_show/' + this.state.restLessonData.data.classId, "Trening został usunięty");
    window.location.reload();
  }

  render() {

    var msg = this.props.location.state;
    var button = "";
    if (msg === undefined) {
      button = "";
    } else {
      button = <div class="tenis-center-content-info">+  {msg}  + </div>;
    }

    var beltClassApp = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp club={this.props.match.params.club} />
      </Suspense>
    </div>
    
    return (

      <div>
        {beltClassApp}

        <div id="content">
          <div class="container">

            

            <br /><br />

            {button}

            <br /><br />
            <b>Szczegóły </b>
            <br />
            Nazwa: {this.state.restLessonData.data.name}
            <br />
            Opis: {this.state.restLessonData.data.description}
            <br />
            Kort: {this.state.restLessonData.data.court}
            <br />
            Dzień: {this.state.restLessonData.data.dayOfWeek}
            <br />
            Godzina start: {this.state.restLessonData.data.hourStart}
            <br />
            Godzina koniec: {this.state.restLessonData.data.hourStop}
            <br />
            Data start: {this.state.restLessonData.data.dateStart}
            <br />
            Data koniec: {this.state.restLessonData.data.dateStop}
            <br />
            Trener: {this.state.restLessonData.data.trainer}
            <br />
            Utworzone: {this.state.restLessonData.data.createdAt}
            <br />
            <br />
            <br /><br />



            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Dodaj komentarz:</Form.Label>
              <Form.Control as="textarea" rows={3} />
            </Form.Group>


            <Link to={"/xxx"}>
              <Button variant="primary">Dodaj</Button>
            </Link>

            <br /><br />

            Usunięcie treningu powoduje, że nie będzie on wydoczny w kalendarzu ani statystykach<br /><br />

            <Button variant="primary" onClick={this.deleteLesson}>
              Usuń trening
            </Button>


          </div>
        </div>


      </div>
    );
  }
}
export default PageLessonDelete;
