import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';

import AuthService from "../services/auth.service";

import { Link } from 'react-router-dom'
import { Button, Table, Card } from "react-bootstrap";

import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW";

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const TabAllClassStudents = React.lazy(() => import('../classAppComponents/TabAllClassStudents'));

//KLASA WYSWIETLA WSZYSTKICH UCZESTNIKOW DANEJ SZKOLKI
//KTORZY AKTUALNIE ZAPISANI SA W BAZIE
class PageShowClassStudents extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      title: "RezerwujKort.pl - Szkółki tenisowe",
      currentUser: AuthService.getCurrentUser(),
      newsCount: "",
      currentPage: "1",
      rowsCount: "25",
      restClassData: {
        successful: false,
        status: '',
        message: '',
        data: []
      }
    }
  }

  async componentDidMount() {

    document.title = this.state.title;

    var url = "/class_app/" + this.props.match.params.club + "/class/show/" + this.props.match.params.id;
    if (this.props.match.params.page != null) {
      url = url + "/" + this.props.match.params.page + "/" + this.state.rowsCount;
      this.setState({ currentPage: this.props.match.params.page });
    }
    await this.fechGetClass(url);
  }

  async fechGetClass(url) {

    var data;

    data = await FetchGetDataWithAuthNEW(url);
    if (data) {
      this.setState({ restClassData: data });
    }
  }

  render() {

    var msg = this.props.location.state;
    var button = "";
    if (msg === undefined) {
      button = "";
    } else {
      button = <div class="tenis-center-content-info">+  {msg}  + </div>;
    }

    //console.log(" eewwe: " + JSON.stringify(this.props.match.params.id)); //usun
    var start = "1"
    if (this.state.currentPage > 1) {
      start = this.state.rowsCount * (this.state.currentPage - 1);
    }

    var beltClassApp = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp club={this.props.match.params.club} />
      </Suspense>
    </div>

    var tabAllClassStudents = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <TabAllClassStudents
          club={this.props.match.params.club}
          data={this.state.data}
          start={start}
          page={this.state.currentPage}
          classid={this.props.match.params.id} />
      </Suspense>
    </div>

    return (

      <div>
       {beltClassApp}

        <div id="content">
          <div class="container">

            <br /><br /><br /><br /><br />

            {button}
            <div>

              <Card style={{ width: '100%' }}>
                <Card.Body>
                  <Card.Title>Uczestnicy szkółki:</Card.Title>
                  <Table responsive="sm">

                    <tbody>
                      <tr>
                        <td><b>Nazwa szkółki:</b></td>
                        <td>{this.state.restClassData.data.name}</td>
                        <td><b>Opis:</b></td>
                        <td>{this.state.restClassData.data.description}</td>
                      </tr>
                      <tr>
                        <td><b>Kort:</b></td>
                        <td>{this.state.restClassData.data.court}</td>
                        <td><b>Dzień:</b></td>
                        <td>{this.state.restClassData.data.dayOfWeek}</td>
                      </tr>
                      <tr>
                        <td><b>Godzina start:</b></td>
                        <td>{this.state.restClassData.data.hourStart}</td>
                        <td><b>Godzina koniec:</b></td>
                        <td>{this.state.restClassData.data.hourStop}</td>
                      </tr>
                      <tr>
                        <td><b>Data start:</b></td>
                        <td>{this.state.restClassData.data.dateStart}</td>
                        <td><b>Data koniec:</b></td>
                        <td>{this.state.restClassData.data.dateStop}</td>
                      </tr>
                      <tr>
                        <td><b>Trener:</b></td>
                        <td>{this.state.restClassData.data.trainer}</td>
                        <td><b>Utworzone:</b></td>
                        <td>{this.state.restClassData.data.createdAt}</td>
                      </tr>
                      <tr>

                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>

            </div>

            <br /><br />

            <Link to={"/class_app/" + this.props.match.params.club + "/class_show/" + this.state.restClassData.data.id}>
              <Button variant="primary" type="submit">
                Powrót
              </Button>&nbsp;&nbsp;
            </Link>

            <Link to={"/class_app/" + this.props.match.params.club + "/admin/student/add_to_class/" + this.state.restClassData.data.id}>
              <Button variant="primary" type="submit">
                Dodaj uczestnika do klasy
              </Button>&nbsp;&nbsp;
            </Link>


            <br /><br />
            <b>Lista wszystkich uczestników:</b>
            <br /><br />

            {tabAllClassStudents}

          </div>
        </div>


      </div>
    );
  }
}
export default PageShowClassStudents;


