import React from "react";

import { registerLocale } from "react-datepicker";
import { Spinner } from "react-bootstrap";
import Belt from "../components/Belt";
import BeltLogged from "../components/BeltLogged";
import queryString from "query-string";
import pl from "date-fns/locale/pl";

import Footer from "../components/Footer";
import FechGetUpdateTicketStatusPayment from "../fechData/FechGetUpdateTicketStatusPayment";

import "react-datepicker/dist/react-datepicker.css";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

class UpdateTicketConfirmPaymentPage extends React.Component {
  constructor(props) {
    super(props);
    // this.printAdresse = this.printAdresse.bind(this);
  }

  spiner = (
    <>
      <p>
        Oczekiwanie na potwierdzenie płatności
        <br />
      </p>
      <Spinner
        style={{ borderStyle: "dotted", marginLeft: "50%" }}
        animation="border"
      />
    </>
  );

  state = {
    startDate: new Date(),
    spiner: "",
    paymentStatus: {
      successful: false,
      status: "",
      message: "",
      data: [],
    },
  };

  async componentDidMount() {
    document.title = "Rezerwacja kortu - RezerwujKort.pl";
    registerLocale("pl", pl);

    this.setState({
      spiner: (
        <>
          <p>
            Oczekiwanie na potwierdzenie płatności
            <br />
          </p>
          <Spinner
            style={{ borderStyle: "dotted", marginLeft: "50%" }}
            animation="border"
          />
        </>
      ),
    });

    await delay(2000);
    this.setState({
      paymentStatus: await FechGetUpdateTicketStatusPayment(
        this.props.match.params.token
      ),
    });

    if (
      this.state.paymentStatus.successful &&
      this.state.paymentStatus.data.payment === false &&
      this.state.paymentStatus.data.rejected === false
    ) {
      for (let i = 0; i < 10; i++) {
        await delay(3000);
        this.setState({
          paymentStatus: await FechGetUpdateTicketStatusPayment(
            this.props.match.params.token
          ),
        });
        if (
          this.state.paymentStatus.successful &&
          (this.state.paymentStatus.data.payment === true ||
            this.state.paymentStatus.data.rejected === true)
        ) {
          this.setState({
            spiner: (
              <p>
                {this.state.paymentStatus.data.paymentDescription}
                <br />
              </p>
            ),
          });
          break;
        }
      }
    } else if (
      this.state.paymentStatus.successful &&
      (this.state.paymentStatus.data.payment === true ||
        this.state.paymentStatus.data.rejected === true)
    ) {
      this.setState({
        spiner: (
          <p>
            {this.state.paymentStatus.data.paymentDescription}
            <br />
          </p>
        ),
      });
    }
  }

  render() {
    return (
      <div>
        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">
            <div class="main-subpage tennis-center">
              <div class="main-subpage-content">
                <div class="tenis-center-content">
                  <div class="tennis-center-coach">{this.state.spiner}</div>
                </div>
              </div>
            </div>
          </div>

          <div></div>
        </div>
        <div style={{ clear: "both" }}></div>

        <Footer />
      </div>
    );
  }
}

export default UpdateTicketConfirmPaymentPage;
