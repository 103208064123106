import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';
import AuthService from "../services/auth.service";

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const AddPriceType = React.lazy(() => import('../classAppForms/AddPriceType'));

class PagePriceTypeAdd extends React.Component {

    state = {
        currentUser: AuthService.getCurrentUser(),
        title: "RezerwujKort.pl - Szkółki tenisowe"
    }

    async componentDidMount() {
        document.title = this.state.title;
    }

    render() {

        var msg = this.props.location.state;
        var actionMsg = "";
        if (msg === undefined) {
            actionMsg = "";
        } else {
            actionMsg = <div class="tenis-center-content-info"> + {msg} + </div>;
        }

        var beltClassApp = <div>
            <Suspense fallback={<div>
                <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
            </div>}>
                <BeltClassNewApp club={this.props.match.params.club} />
            </Suspense>
        </div>

        var addPriceType = <div>
            <Suspense fallback={<div>
                <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
            </div>}>
                <AddPriceType club={this.props.match.params.club} />
            </Suspense>
        </div>

        return (
            <div>
                {beltClassApp}

                <div id="content">
                    <div class="container">

                        

                        <br /><br />

                        {actionMsg}

                        <br /><br />
                        <b>Dodawanie nowego rodzaju szkółki</b>
                        <br /><br />

                        {addPriceType}

                    </div>
                </div>


            </div>
        );
    }
}
export default PagePriceTypeAdd;
