import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW"; 

export default function FechGetChallenges(club, league) {
 
  var url = "/league_app/" + club + "/" + league + "/find_all_challenges";
  var data = FetchGetDataWithAuthNEW(url);

  return data;
}

