import React from 'react';

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';
import BeltClub from '../components/BeltClub';

import Footer from '../components/Footer';

import FechGetClubLongDescBySlug from "../fechData/FechGetClubLongDescBySlug";

import DateTimeNow from "../utils/DateTimeNow";

class ClubPage extends React.Component {

    constructor(props) {
        super(props);
        this.printHtml = this.printHtml.bind(this);
    }

    state = {
        currentPage: "1",
        logInfo: "LogDebug __" + DateTimeNow() + "__ ClubPage ",
        restClubData: {
            successful: false,
            status: '',
            message: '',
            data: []
        }
    }

    async componentDidMount() {

        this.setState({
            restClubData: await FechGetClubLongDescBySlug(this.props.match.params.slug)
        });
        document.title = this.state.restClubData.data.name + " - RezerwujKort.pl"
    }

    printHtml() {
        return { __html: this.state.restClubData.data.description };
    }

    render() {

        var desc;
        var beltClub;
        if (this.state.restClubData.successful) {
            desc = <div dangerouslySetInnerHTML={this.printHtml()} />
            beltClub = <BeltClub
                dataClubInfo={this.state.restClubData.data}
                currentPage="info" />
        }

        return (
            <div>

                <Belt />

                <BeltLogged />

                <div id="content">
                    <div class="container">

                        <div class="main-subpage tennis-center">
                            <div class="head">
                                <img src={`/images/login.png`} alt="" />
                                <p>Korty Tenisowe</p>
                            </div>
                            <div class="main-subpage-content">

                                {beltClub}

                                {desc}

                                <div style={{ clear: 'both' }}></div>
                            </div>
                            <div style={{ clear: 'both' }}></div>
                        </div>

                    </div>
                </div>

                <Footer />

            </div>
        );
    }
}


export default ClubPage;
