import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';
import PageCounterNew from '../components/PageCounterNew';

import FechPostClassAllBySearch from "../classAppFechData/FechPostClassAllBySearch";
import FechGetClassTrainer from "../classAppFechData/FechGetClassTrainer";
import FechGetClassNames from "../classAppFechData/FechGetClassNames";
import FechGetClassDateRange from "../classAppFechData/FechGetClassDateRange";

import NotificationPrint from '../Notification/NotificationPrint'

import queryString from 'query-string'

import ParseLinkToArray from "../utils/ParseLinkToArray"

import { Link } from 'react-router-dom'
import { Button } from "react-bootstrap";

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const TabAllClass = React.lazy(() => import('../classAppComponents/TabAllClass'));
const SearchClassAll = React.lazy(() => import('../forms/SearchClassAll'));

class PageClass extends React.Component {

  TRAINER_SELECT_DESC = "Wybierz trenera";
  DATE_SELECT_DESC = "Wybierz datę";
  CLASS_SELECT_DESC = "Wybierz szkółke";

  urlDeafulValues = [this.TRAINER_SELECT_DESC, this.CLASS_SELECT_DESC, this.DATE_SELECT_DESC];

  urlValue = "";

  urlDeafulValues = [];

  postData = {
    dateStart: '',
    dateStop: '',
    trainer: '',
    className: '',
    status: '',
    page: ''
  };

  state = {
    data: [],
    currentPage: "1",
    rowsCount: "25",
    totalRecordsCount: "",
    title: "RezerwujKort.pl - Szkółki tenisowe",
    restAllClassData: {
      successful: false,
      status: '',
      message: '',
      data: []
    },
    restClassDateRangeData: {
      successful: false,
      status: '',
      message: '',
      data: []
    },
    restClassTrainerData: {
      successful: false,
      status: '',
      message: '',
      data: []
    },
    restClassNamesData: {
      successful: false,
      status: '',
      message: '',
      data: []
    }
  }

  async componentDidMount() {

    document.title = this.state.title;

    const { location: { search } } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.postData = await ParseLinkToArray(this.urlValue, this.postData, this.urlDeafulValues);

    this.state.restClassDateRangeData = await FechGetClassDateRange(this.props.match.params.club);
    this.state.restClassTrainerData = await FechGetClassTrainer(this.props.match.params.club);
    this.state.restClassNamesData = await FechGetClassNames(this.props.match.params.club);

    if (this.postData.dateStart === "") {
      if (typeof this.state.restClassDateRangeData.data.map === 'function') {
        this.postData.dateStart = this.state.restClassDateRangeData.data[0];
      }
    }

    if (this.postData.dateStop == "") {
      if (typeof this.state.restClassDateRangeData.data.map === 'function') {
        this.postData.dateStop = this.state.restClassDateRangeData.data[this.state.restClassDateRangeData.data.length - 1];
      }
    }

    this.setState({ restAllClassData: await FechPostClassAllBySearch(this.props.match.params.club, this.postData) });
  }

  render() {

    NotificationPrint.printOnNewPage(this.props.location.state);

    if (typeof this.state.restAllClassData.data.classResources) {
      this.state.totalRecordsCount = this.state.restAllClassData.data.totalRecordsCount;
    }

    var start = "1"
    if (this.state.currentPage > 1) {
      start = this.state.rowsCount * (this.state.currentPage - 1);
    }

    const urlValues = new Map();
    urlValues.set('page', "replace");

    var tabAllClass = '';
    var pageCounterNew = '';
    var searchPanel = "";
    if (this.state.restAllClassData.successful) {

      tabAllClass = <div>
        <Suspense fallback={<div>
          <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
        </div>}>
          <TabAllClass
            club={this.props.match.params.club}
            start={start}
            page={this.state.currentPage}
            classDat={this.state.restAllClassData.data.content}
          />
        </Suspense>
      </div>;

      pageCounterNew = <PageCounterNew
        elementCount={this.state.restAllClassData.data.totalRecordsCount}
        urlValues={urlValues}
        urlDeafulValues={this.urlDeafulValues}
        elementOnPage={this.state.rowsCount}
        currentPage={parseInt(this.postData.page) + 1}
        searchName={"class_search"}
        linkTo={"class_app/" + this.props.match.params.club + "/class"}
      />

      searchPanel = <div>
        <Suspense fallback={<div>
          <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
        </div>}>
          <SearchClassAll
            urlValue={this.postData}
            club={this.props.match.params.club}
            restClassDateRangeData={this.state.restClassDateRangeData}
            restClassTrainerData={this.state.restClassTrainerData}
            restClassNamesData={this.state.restClassNamesData}
          />
        </Suspense>
      </div>
    }

    var loader = "";
    if (!this.state.restAllClassData.successful) {
      loader = <div id="loader_div">
        <Spinner animation="border" />
      </div>
    }

    var beltClassApp = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp club={this.props.match.params.club} />
      </Suspense>
    </div>

    return (

      <div>
        {beltClassApp}

        <div id="content">
        <div style={{ paddingTop: '55px'}}>

            <br /><br /><br /><br />

            <Link to={"/class_app/" + this.props.match.params.club + "/admin/class_add/"}>
              <Button variant="primary" type="submit">
                Dodaj nową szkółkę
              </Button>&nbsp;&nbsp;
            </Link>

            <br /><br />
            {searchPanel}

            <br /><br />
            <b>Lista wszystkich szkółekcc ({this.state.totalRecordsCount}):</b>
            <br /><br />

            {tabAllClass}

            {pageCounterNew}

            {loader}

          </div>
        </div>


      </div>
    );
  }
}
export default PageClass;
