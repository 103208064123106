import FetchPostDataNoAuthNEW from "../services/FetchPostDataNoAuthNEW";

export default async function MailCooperation(body) {

  var url = "/notification/cooperation_form";

  var data = await FetchPostDataNoAuthNEW(url, body)

  return data;
}
