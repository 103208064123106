import React, { Suspense } from 'react';

import { Spinner } from "react-bootstrap";

import FechGetClubLongDescBySlug from "../fechData/FechGetClubLongDescBySlug";
import BeltClub from '../components/BeltClub';

const ClubReservationOnlinePage = React.lazy(() => import('../pageDefault/ClubReservationOnlinePage'));

class ClubReservationPage extends React.Component {

  constructor(props) {
    super(props);
  }

  state = {
    currentPage: "1",
    restClubData: {
      successful: false,
      status: '',
      message: '',
      data: []
    }
  }


  async componentDidMount() {

    this.setState({
      restClubData: await FechGetClubLongDescBySlug(this.props.match.params.slug)
    });
  }

  render() {

    var returnValue = '';
    var beltClub;
    if (this.state.restClubData.successful) {

      var cur = "reservation" + (this.props.match.params.page)
      beltClub = <BeltClub
        dataClubInfo={this.state.restClubData.data}
        dateReservation={this.props.match.params.date}
        currentPage={cur}
      />

      returnValue = <div>
        <Suspense fallback={<div>
          <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
        </div>}>
          <ClubReservationOnlinePage
            date={this.props.match.params.date}
            page={this.props.match.params.page}
            restClubData={this.state.restClubData}
            beltClub={beltClub}
            slug={this.props.match.params.slug} />
        </Suspense>
      </div>;
    }

    return (
      <>
        {returnValue}
      </>
    );
  }
}

export default ClubReservationPage;
