import FetchPostDataNoAuthNEW from "../services/FetchPostDataNoAuthNEW";

export default async function FechPostNewsBySearch(body) {

  var url = "/news_page_search";

  var data = await FetchPostDataNoAuthNEW(url, body)

  return data;
}
