
import React from 'react'

import CreatLinkSerch from "../utils/CreatLinkSerch"

class PageCounterNew extends React.Component {

  state = {
    howManyPages: "0",
    split: '',
  }

  render() {

    var elements = [];
    var currentPage = this.props.currentPage;

    var findEventsUrl = CreatLinkSerch(this.props.searchName, this.props.urlValues, this.props.urlDeafulValues);

    //jesli tak to generujemy pasek, jesli nie znaczy ze elementow jest mniej niz wyswietlamy
    if (this.props.elementCount > this.props.elementOnPage) {

      let howManyPages = Math.ceil(this.props.elementCount / this.props.elementOnPage);
      this.state.howManyPages = howManyPages;
   

      if (this.props.currentPage == undefined) {
        currentPage = 1;

      }
      
      var previousPage = "0";
      previousPage = Number(currentPage) - 1;
      if (previousPage < 1) {
        previousPage = 1;
      }

      elements.push(<div class='paggination-bg'><a href={"/" + this.props.linkTo + "/" +
        findEventsUrl.replace("replace", "1")}><div class='paggination-left'></div></a></div>)
      elements.push(<div class='paggination-bg'><a href={"/" + this.props.linkTo + "/" +
        findEventsUrl.replace("replace", previousPage)}><div class='paggination-left'></div></a></div>)


      for (var i = 1; i <= this.state.howManyPages; i++) {
        if (i == currentPage) {
          elements.push(<div class='paggination-number current'>{i}</div>);
        } else {
          var currentPageTemp = parseInt(currentPage);
          if ((currentPageTemp + 5) == i && i > currentPageTemp && i != 1) {
            elements.push(<div class='paggination-number'>...</div>);
          }
          if ((currentPageTemp + 5) > i && i > currentPageTemp && i != this.state.howManyPages) {
            elements.push(<a href={"/" + this.props.linkTo + "/" +
              findEventsUrl.replace("replace", i)} ><div class='paggination-number'>{i}</div></a>);
          }
          if ((currentPageTemp - 5) < i && i < currentPageTemp && i != 1) {
            elements.push(<a href={"/" + this.props.linkTo + "/" +
              findEventsUrl.replace("replace", i)} ><div class='paggination-number'>{i}</div></a>);
          }
          if (i == this.state.howManyPages) {
            elements.push(<a href={"/" + this.props.linkTo + "/" +
              findEventsUrl.replace("replace", i)} ><div class='paggination-number'>{i}</div></a>);
          }
          if (i == 1) {
            elements.push(<a href={"/" + this.props.linkTo + "/" +
              findEventsUrl.replace("replace", i)} ><div class='paggination-number'>{i}</div></a>);
          }
          if ((currentPageTemp - 5) == i && i < currentPageTemp) {
            elements.push(<div class='paggination-number'>...</div>);
          }
        }
      }

      var nextPage = 0;
      var lastPage = this.state.howManyPages;

      nextPage = Number(currentPage) + 1;
      if (nextPage > lastPage) {
        nextPage = lastPage;
      }

      elements.push(<div class='paggination-bg'><a href={"/" + this.props.linkTo + "/"
        + findEventsUrl.replace("replace", nextPage)} ><div class='paggination-right'></div></a></div>);
      elements.push(<div class='paggination-bg'><a href={"/" + this.props.linkTo + "/"
        + findEventsUrl.replace("replace", lastPage)} ><div class='paggination-right'></div></a></div>);

    }

    return (
      <div style={{ clear: 'both', marginTop: '35px', textAlign: 'center', height: '60px', marginLeft: '50px' }}>
        <br />
        <div >
          {elements}
        </div>
      </div>
    )
  }
}

export default PageCounterNew;