import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW";

export default function FechGetOneDayReservations(club, date, page, sportId) {

  var url = "/reservation/one_day_reservation_calendar/" + club + "/" + date;
  if(page != null){
    url = "/reservation/one_day_reservation_calendar/" + club + "/" + date + "/" + page + "/" + sportId;
  }

  return FetchGetDataWithAuthNEW(url);
}

