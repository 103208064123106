import React from 'react';

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';
import Footer from '../components/Footer';

const AboutPage = () => {
  return (

    <div>

      <Belt />

      <BeltLogged />

      	<div id="content">
          <div class="container">

              <div class="main-subpage">
                  <div class="head">
                      <img src={`/images/login.png`} />
                      <p>Współpraca</p>
                  </div>
                  <div class="tenis-content-info">
                      <br/>
                      
                      Początki portalu sięgają 2010 roku, kiedy jeszcze jako projekt studencki powstała pierwsza wersja Aplikacji do zarządzania klubem tenisowym.
                      <br/><br/>
                      W 2011 r. uruchomiliśmy stronę internetową www.RezerwujKort.pl, a program trafił na testy do pierwszego klubu.
                      <br/><br/>
                      Od tego czasu, zarówno portal, jak i program „Rezerwator”, są regularnie udoskonalane i zdobywają nowych użytkowników.
                      {/* <br/><br/>
                      Od początku projekt rozwijany był hobbystycznie, dlatego od współpracujących z nami klubów nie pobieramy żadnych opłat za korzystanie z oprogramowania. */}
                      <br/><br/>
                      System sprawdza się zarówno w małych klubach posiadających klika kortów, jak i tych dużych. Wśród użytkowników systemu są kluby zajmujące czołowe pozycje w klasyfikacji prowadzonej przez Polski Związek Tenisowy m.in. MKT Łódź czy WTS DeSki.
                      <br/><br/>
                      Projekt rozwijany jest przez grupę pasjonatów zdobywających doświadczenie zawodowe tworząc oprogramowanie dla największych banków w Europie. Nasze doświadczenie gwarantuje, że kluby otrzymują produkt najwyższej jakości.
                      {/* , nie ponosząc przy tym żadnych kosztów. */}
                      <br/><br/>
                      Potrzebujesz więcej informacji lub chcesz dowiedzieć się szczegółów o naszej ofercie??
                      <br/><br/>
                      Zapraszamy na stronę <a href={"/wspolpraca"}>www.rezerwujkort.pl/wspolpraca</a>

                  </div>
              </div>
          </div>
        </div>
      <Footer />

    </div>
    
  );
}

export default AboutPage;
