import queryString from 'query-string'

export default function CreatLinkSerchByCityAndClub(city, club, page) {
//city=1&club=20&page=1
	var url = "?"
	if(city != ""){
		url = url + "city=" + city 
	}
	if(club != "" && city === ""){
		url = url + "club=" + club 
	}
	if(club != "" && city != ""){
		url = url + "&club=" + club 
	}
	url = url + "&page=" + page

	return url;
}

