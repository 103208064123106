import React, { Suspense } from "react";

import { Spinner, Button } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl"; // the locale you want

import FechGetTicketsBySearch from "../APP_RESERVATION_FECH_DATA/FechGetTicketsBySearch";
import PageCounterNew from "../components/PageCounterNew";
import BeltReservationApp from "../APP_RESERVATION_COMPONENTS/BeltReservationApp";

import queryString from "query-string";
import moment from "moment";
import ParseLinkToArray from "../utils/ParseLinkToArray";

const SearchTickets = React.lazy(() =>
  import("../APP_RESERVATION_FORMS/SearchTickets")
);
const AddTicketPopupForm = React.lazy(() =>
  import("../APP_RESERVATION_FORM_POPUP/AddTicketPopupForm")
);
const TabAllTickets = React.lazy(() =>
  import("../APP_RESERVATION_COMPONENTS/TabAllTickets")
);

let plLocate = require("moment/locale/pl");
moment.locale("pl", plLocate);

//KLASA WYSWIETLA SZCZEGOLY KONKRETNYCH ZAJEC SZKOLKI
class PageTickets extends React.Component {
  TRAINER_SELECT_DESC = "Wybierz trenera";
  DATE_SELECT_DESC = "Wybierz datę";
  CLASS_SELECT_DESC = "Wybierz szkółke";

  urlDeafulValues = [
    this.TRAINER_SELECT_DESC,
    this.CLASS_SELECT_DESC,
    this.DATE_SELECT_DESC,
  ];

  urlValue = "";

  postData = {
    clientId: "",
    clientType: "",
    sortBy: "",
    searchDirection: "",
    ticketUsedMoreThenValue: "",
    ticketStatus: "",
    ticketType: "",
    page: "0",
  };

  constructor(props) {
    super(props);

    this.state = {
      title: "RezerwujKort.pl - Rezerwację",
      datePickerIsOpen: false,
      previousDay: "",
      refreshReservation: "",
      addTicketPopupForm: "",
      restTicketsData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
    };
    this.handlerRefreshReservation = this.handlerRefreshReservation.bind(this);
  }

  async componentDidMount() {
    document.title = "Rezerwacje - RezerwujKort.pl";
    registerLocale("pl", pl); // register it with the name you want
    const {
      location: { search },
    } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.postData = await ParseLinkToArray(
      this.urlValue,
      this.postData,
      this.urlDeafulValues
    );

    this.setState({
      restTicketsData: await FechGetTicketsBySearch(
        this.props.match.params.club,
        search
      ),
    });
  }

  async addClientForm() {
    this.setState({
      addTicketPopupForm: (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <AddTicketPopupForm
              dateReservation={""}
              club={this.props.match.params.club}
              refresh={this.handlerRefreshReservation}
            />
          </Suspense>
        </div>
      ),
    });
  }

  async handlerRefreshReservation() {
    //this.componentDidMount();
    const {
      location: { search },
    } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);
    this.postData = await ParseLinkToArray(
      this.urlValue,
      this.postData,
      this.urlDeafulValues
    );

    this.setState({
      restTicketsData: await FechGetTicketsBySearch(
        this.props.match.params.club,
        search
      ),
    });
  }

  render() {
    var loader = "";
    if (!this.state.restTicketsData.successful) {
      loader = (
        <div id="loader_div">
          <Spinner animation="border" />
        </div>
      );
      totalRecords = this.state.restTicketsData.data;
    }

    var totalRecords = "";
    var tabAllTickets = "";
    var pageCounter = "";
    var searchPanel = "";
    if (this.state.restTicketsData.successful) {
      totalRecords = this.state.restTicketsData.data.totalRecordsCount;

      tabAllTickets = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <TabAllTickets
              club={this.props.match.params.club}
              restLessonsData={
                this.state.restTicketsData.data.reservationResources
              }
              refresh={this.handlerRefreshReservation}
            />
          </Suspense>
        </div>
      );

      const urlValues = new Map();
      urlValues.set("clientId", this.postData.clientId);
      urlValues.set("clientType", this.postData.clientType);
      urlValues.set("sortBy", this.postData.sortBy);
      urlValues.set("searchDirection", this.postData.searchDirection);
      urlValues.set(
        "ticketUsedMoreThenValue",
        this.postData.ticketUsedMoreThenValue
      );
      urlValues.set("ticketStatus", this.postData.ticketStatus);
      urlValues.set("ticketType", this.postData.ticketType);
      urlValues.set("page", "replace");

      pageCounter = (
        <PageCounterNew
          elementCount={totalRecords}
          urlValues={urlValues}
          urlDeafulValues={this.urlDeafulValues}
          elementOnPage={this.state.restTicketsData.data.recordsOnPage}
          currentPage={parseInt(this.postData.page) + 1}
          searchName={"search"}
          linkTo={
            "reservation_app/" + this.props.match.params.club + "/karnety"
          }
        />
      );

      searchPanel = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <SearchTickets
              urlValue={this.postData}
              club={this.props.match.params.club}
            />
          </Suspense>
        </div>
      );
    }

    return (
      <div>
        <div id="content">
          <BeltReservationApp club={this.props.match.params.club} />

          {searchPanel}

          <Button
            href="#"
            variant="success"
            size="lg"
            style={{ "margin-left": "20px" }}
            onClick={() => this.addClientForm()}
          >
            Dodaj karnet
          </Button>

          <br />
          <br />
          <b>Lista wszystkich karnetów: ({totalRecords}):</b>
          <br />
          <br />

          {this.state.addTicketPopupForm}
          {loader}

          {tabAllTickets}

          {pageCounter}
        </div>
      </div>
    );
  }
}
export default PageTickets;
