import FetchPostDataNoAuthNEW from "../services/FetchPostDataNoAuthNEW";

export default async function FechPostClubBySearch(body) {

  var url = "/clubs_adverb_search_page";

  var data;
  if (body != undefined) {
    data = await FetchPostDataNoAuthNEW(url, body)
  }

  return data;
}
