import React from 'react'
import Nav from 'react-bootstrap/Nav';

class BeltReservationPriceList extends React.Component {

    active = '';

    constructor(props) {
        super(props);

        this.state = {
            currentUser: ''
        };
    }

    componentDidMount() {
        
    }

    render() {

        if(this.props.pageType === "club") this.active = '/reservation_app/' + this.props.club + '/ustawienia_platnosci/club';
        if(this.props.pageType === "app") this.active = '/reservation_app/' + this.props.club + '/ustawienia_platnosci/app';
        if(this.props.pageType === "www") this.active = '/reservation_app/' + this.props.club + '/ustawienia_platnosci/www';
        if(this.props.pageType === "graphic_club") this.active = '/reservation_app/' + this.props.club + '/ustawienia_platnosci_grafik/graphic_club';
        if(this.props.pageType === "graphic_app") this.active = '/reservation_app/' + this.props.club + '/ustawienia_platnosci_grafik/graphic_app';
        if(this.props.pageType === "graphic_www") this.active = '/reservation_app/' + this.props.club + '/ustawienia_platnosci_grafik/graphic_www';
        
        return (

            <div style={{ marginTop: 20, lineHeight: '25px' }}>
                <Nav variant="pills" defaultActiveKey={this.active}>
                    <Nav.Item>
                        <Nav.Link href={'/reservation_app/' + this.props.club + '/ustawienia_platnosci/club'}>Cennik Recepcja</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href={'/reservation_app/' + this.props.club + '/ustawienia_platnosci/www'}>Cennik Strona Internetowa</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href={'/reservation_app/' + this.props.club + '/ustawienia_platnosci/app'}>Cennik Aplikacja Mobilna</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href={'/reservation_app/' + this.props.club + '/ustawienia_platnosci_grafik/graphic_club'}>Podgląd cen Recepcja</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href={'/reservation_app/' + this.props.club + '/ustawienia_platnosci_grafik/graphic_www'}>Podgląd cen Strona Internetowa</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href={'/reservation_app/' + this.props.club + '/ustawienia_platnosci_grafik/graphic_app'}>Podgląd cen Aplikacja Mobilna</Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>

        )
    }
}

export default BeltReservationPriceList;