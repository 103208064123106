import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW"; 

export default function FechGetAdminRoles(club, league) {
 
  var url = "/league_app/user_privileges/league_admin/" + club + "/" + league;
  var data = FetchGetDataWithAuthNEW(url);

  return data;
}

