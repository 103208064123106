import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW";

export default function FechGetReservationOnlineInfoSummaryUtils(
  clubWww,
  search
) {
  var url =
    "/reservation/reservation_online_info_summary_utils/" +
    clubWww +
    "/RESERVATION_WWW" +
    search;

  return FetchGetDataWithAuthNEW(url);
}
