import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';

import FetchGetDataWithAuthNEW from '../services/FetchGetDataWithAuthNEW';

import AuthService from "../services/auth.service";

//UTILS
import DateTimeNow from "../utils/DateTimeNow";

const AdverbUserSparingParnerShort = React.lazy(() => import('../components/AdverbUserSparingParnerShort'));

class ProfileSparingPage extends React.Component {

    state = {
        currentUser: AuthService.getCurrentUser(),
        title: "RezerwujKort.pl - Profil",
        logInfo: "LogDebug __" + DateTimeNow() + "__ SparingpartnersPage ",
        restUserAdverbData: {
            successful: false,
            status: '',
            message: '',
            data: []
        },
    }

    async componentDidMount() {
        document.title = this.state.title

        var url = "/sparingpartner_adverb_by_user";
        await this.fechSpraingPartnerAdverb(url);
    }

    async fechSpraingPartnerAdverb(url) {

        var data;

        data = await FetchGetDataWithAuthNEW(url);
        if (data) {
            this.setState({ restUserAdverbData: data });
        }
        console.log(this.state.logInfo + "restUserAdverbData: data " + JSON.stringify(this.state.restUserAdverbData)); //usun
    }


    render() {

        var adverbs;
        if (typeof this.state.restUserAdverbData.data.map === 'function') {
            adverbs = this.state.restUserAdverbData.data.map(adverbOne =>
                <div>
                    <Suspense fallback={<div>
                        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
                    </div>}>
                        <AdverbUserSparingParnerShort adverb={adverbOne} />
                    </Suspense>
                </div>);
        } else {
            adverbs = "";
        }

        return (

            <div>

                <Belt />

                <BeltLogged />

                <div id="content">
                    <div class="container">

                        <div class="main-subpage">
                            <div class="head">

                                <img src={`/images/login.png`} />
                                <p>Sparingpartnerzy</p>
                            </div>


                            <div style={{ clear: 'both' }}>
                                <div class="main-subpage-subtitle">Sparingpartnerzy</div>

                                {adverbs}

                            </div>


                        </div>


                    </div>
                </div>

                <Footer />

            </div>
        );
    }
}
export default ProfileSparingPage;
