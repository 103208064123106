import React, { Suspense } from 'react';

import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';
import PageCounterNew from '../components/PageCounterNew';
import BeltClub from '../components/BeltClub';

import ParseLinkToArray from "../utils/ParseLinkToArray"
import queryString from 'query-string'

import FechPostSparingBySearch from "../fechData/FechPostSparingBySearch";
import FechGetClubLongDescBySlug from "../fechData/FechGetClubLongDescBySlug";

import "react-datepicker/dist/react-datepicker.css";

import DateTimeNow from "../utils/DateTimeNow";

const AdverbSparingParnerShort = React.lazy(() => import('../components/AdverbSparingParnerShort'));

class ClubSparingPage extends React.Component {

  searchSparingItem = {
    city: '',
    club: '',
    page: ''
  };
  urlDeafulValues = [];
  urlValue = "";

  constructor(props) {
    super(props);
    this.printAdresse = this.printAdresse.bind(this);
  }

  state = {
    logInfo: "LogDebug __" + DateTimeNow() + "__ ClubSparingPage ",
    startDate: new Date(),
    rowsCount: "6",
    restClubData: {
      successful: false,
      status: '',
      message: '',
      data: []
    },
    restSparingAdverbsData: {
      successful: false,
      status: '',
      message: '',
      data: []
    }
  }

  printAdresse() {
    return { __html: this.state.restClubData.data.descriptionShort };
  }



  async componentDidMount() {

    const { location: { search } } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.searchSparingItem = await ParseLinkToArray(this.urlValue,
      this.searchSparingItem,
      this.urlDeafulValues);

    this.state.restClubData = await FechGetClubLongDescBySlug(this.props.match.params.slug);
    document.title = this.state.restClubData.data.name + " Sparingpartnerzy - RezerwujKort.pl";

    this.searchSparingItem.club = this.props.match.params.slug;

    this.setState({
      restSparingAdverbsData: await FechPostSparingBySearch(this.searchSparingItem)
    });
  }


  render() {


    var noAdverb = [];
    if (this.state.restSparingAdverbsData.data.totalRecordsCount != undefined &&
      this.state.restSparingAdverbsData.data.totalRecordsCount === 0) {
      noAdverb.push(<div class='tenis-content-info'>Brak ogłoszeń</div>);
    }


    var adverbs = "";
    if (this.state.restSparingAdverbsData.successful &&
      typeof this.state.restSparingAdverbsData.data.sparingResources.map === 'function') {
      adverbs = this.state.restSparingAdverbsData.data.sparingResources.map(adverbOne =>
        <div>
          <Suspense fallback={<div>
            <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
          </div>}>
            <AdverbSparingParnerShort
              adverb={adverbOne} />
          </Suspense>
        </div>);
    }

    var noAdverb = [];
    if (this.state.restSparingAdverbsData.data.totalRecordsCount === 0) {
      noAdverb.push(<div class='tenis-content-info'>Brak ogłoszeń</div>);
    }

    var beltClub;
    if (this.state.restClubData.successful) {
      var cur = "sparingpartner";
      beltClub = <BeltClub
        dataClubInfo={this.state.restClubData.data}
        currentPage={cur} />
    }

    const urlValues = new Map();
    urlValues.set('page', "replace");

    var pageCounter;
    if (this.state.restSparingAdverbsData.successful) {
      pageCounter = <PageCounterNew
        elementCount={this.state.restSparingAdverbsData.data.totalRecordsCount}
        urlValues={urlValues}
        urlDeafulValues={this.urlDeafulValues}
        elementOnPage={this.state.rowsCount}
        currentPage={parseInt(this.searchSparingItem.page) + 1}
        searchName={"sparing_search"}
        linkTo={"klub/" + this.props.match.params.slug + "/sparingpartnerzy"}
      />
    }

    return (

      <div>

        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">

            <div class="main-subpage tennis-center">
              <div class="head">
                <img src={`/images/login.png`} alt="" />
                <p>Korty Tenisowe</p>
              </div>
              <div class="main-subpage-content">

                {beltClub}

                <div class="tenis-center-content">
                  <ss dangerouslySetInnerHTML={this.printAdresse()} />

                  <div class="tennis-center-coach">

                    {adverbs}
                  </div>

                  {pageCounter}

                </div>
              </div>

            </div>

          </div>

          <div >

          </div>

        </div>
        <div style={{ clear: 'both' }}>
        </div>

        <Footer />

      </div>
    );
  }
}

export default ClubSparingPage;
