import React, { Suspense } from "react";
import { Spinner } from "react-bootstrap";

import Belt from "../components/Belt";
import BeltLogged from "../components/BeltLogged";

import Footer from "../components/Footer";
import PageCounterNew from "../components/PageCounterNew";
import { Form } from "react-bootstrap";

import FechGetSuperAdminRoles from "../leagueAppFechData/FechGetSuperAdminRoles";
import DateTimeNow from "../utils/DateTimeNow";
import FechGetPlayers from "../leagueAppFechData/FechGetPlayers";

import NotificationPrint from "../Notification/NotificationPrint";
import ParseLinkToArray from "../utils/ParseLinkToArray";
import queryString from "query-string";

const BeltLeague = React.lazy(() =>
  import("../leagueAppComponents/BeltLeague")
);

class PageLeagueAdmin extends React.Component {
  emptyItem = {
    player: this.props.trainer,
  };

  searchSparingItem = {
    page: "",
  };
  urlDeafulValues = [];
  urlValue = "";

  constructor(props) {
    super(props);

    this.state = {
      title: "Liga - Ranking - RezerwujKort.pl",
      logInfo: "LogDebug __" + DateTimeNow() + "__ PageLeagueAdmin ",
      rowsCount: "6",
      item: this.emptyItem,
      restSparingAdverbsData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
      restLeagueAdminData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
      restUsersData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    document.title = this.state.title;

    const {
      location: { search },
    } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.searchSparingItem = await ParseLinkToArray(
      this.urlValue,
      this.searchSparingItem,
      this.urlDeafulValues
    );

    this.setState({
      restLeagueAdminData: await FechGetSuperAdminRoles(
        this.props.match.params.club
      ),
    });

    this.setState({
      restUsersData: await FechGetPlayers(
        this.props.match.params.club,
        this.props.match.params.league
      ),
    });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let item = { ...this.state.item };

    item[name] = value;
    this.setState({ item: item });
  }

  render() {
    NotificationPrint.printOnNewPage(this.props.location.state);

    const urlValues = new Map();
    urlValues.set("page", "replace");

    var pageCounter = "";
    if (this.state.restSparingAdverbsData.successful) {
      pageCounter = (
        <PageCounterNew
          elementCount={
            this.state.restSparingAdverbsData.data.totalRecordsCount
          }
          urlValues={urlValues}
          urlDeafulValues={this.urlDeafulValues}
          elementOnPage={this.state.rowsCount}
          currentPage={parseInt(this.searchSparingItem.page) + 1}
          searchName={"sparing_search"}
          linkTo={"sparingpartnerzy"}
        />
      );
    }

    var beltLeague;
    if (this.state.restLeagueAdminData.successful) {
      beltLeague = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <BeltLeague
              currentPage={"admin"}
              club={this.props.match.params.club}
              league={this.props.match.params.league}
            />
          </Suspense>
        </div>
      );
    }

    var playerList;
    if (typeof this.state.restUsersData.data.map === "function") {
      playerList = this.state.restUsersData.data.map((player) => (
        <option value={player.nameSlug}>{player.name}</option>
      ));
    } else {
      playerList = "";
    }

    return (
      <div>
        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">
            <div class="main-subpage">
              {beltLeague}

              {/* <div class="admin_box_sm" >
                <div class="admin_box_tx">
                  Dodaj nowego uczestnika do ligi
                </div>
                <div class="admin_box_btn">
                  <a href={'/liga/' + this.props.match.params.club + "/" + this.props.match.params.league + '/admin/dodaj_uczestnika'}><button type="button">Dodaj</button>
                  </a>
                </div>
              </div> */}

              {/* <div class="admin_box_sm" >
                <div class="admin_box_tx">
                  Baza danych uczestników ligi
                </div>
                <div class="admin_box_btn">
                  <a href={'/liga/' + this.props.match.params.club + "/" + this.props.match.params.league + '/admin/all_club_players/players_search'}><button type="button">Pokaż</button>
                  </a>
                </div>
              </div> */}

              {/* <div class="admin_box_sm" >
                <div class="admin_box_tx">
                  Usuń gracza z rankingu

                  <form onSubmit={this.handleSubmit}>
                    <Form as="select"
                      onChange={this.handleChange}
                      value={this.state.item.player}
                      id="player"
                      name="player">
                      <option>Wybierz gracza</option>
                      {playerList}
                    </Form>

                  </form>
                </div>

                <div class="admin_box_btn">
                  <a href={'/liga/' + this.props.match.params.club + "/" + this.props.match.params.league + '/admin/usun_uczestnika/' + this.state.item.player}><button type="button">Usuń</button>
                  </a>
                </div>
              </div> */}

              {/* <div class="admin_box_sm" >
                <div class="admin_box_tx">
                  Zmień pozycje gracza w rankingu

                  <form onSubmit={this.handleSubmit}>
                    <Form as="select"
                      onChange={this.handleChange}
                      value={this.state.item.player}
                      id="player"
                      name="player">
                      <option>Wybierz gracza</option>
                      {playerList}
                    </Form>

                  </form>
                </div>

                <div class="admin_box_btn">
                  <a href={'/liga/' + this.props.match.params.club + "/" + this.props.match.params.league + '/admin/zmien_pozycje_w_rankingu/' + this.state.item.player}><button type="button">Przenieś gracza</button>
                  </a>
                </div>
              </div> */}

              {/* <div class="admin_box_sm" >
                <div class="admin_box_tx">
                  Utwórz wyzwanie Gracz Wyzywający:

                  <form onSubmit={this.handleSubmit}>
                    <Form as="select"
                      onChange={this.handleChange}
                      value={this.state.item.player}
                      id="player"
                      name="player">
                      <option>Wybierz gracza</option>
                      {playerList}
                    </Form>

                  </form>
                </div>

                <div class="admin_box_btn">
                  <a href={'/liga/' + this.props.match.params.club + "/" + this.props.match.params.league + '/admin/utworz_wyzwanie/' + this.state.item.player}><button type="button">Utwórz wyzwanie</button>
                  </a>
                </div>
              </div> */}

              <div></div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default PageLeagueAdmin;
