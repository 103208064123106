import React from 'react'

import { Col, Form, Button } from "react-bootstrap";
import history from "../components/history";
import MailSuggestion from "../mailNotification/MailSuggestion";

import ValidationFormField from "../utils/ValidationFormField";

class Footer extends React.Component {

	mailData = {
		name: '',
		email: '',
		description: ''
	};

	constructor(props) {
		super(props);

		this.state = {
			item: this.mailData,
			mailResponseData: {
				successful: false,
				status: '',
				message: '',
				data: []
			},
			isError: {
				name: '',
				email: '',
				description: ''
			}
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	async componentDidMount() {
	}

	async handleSubmit(event) {

		event.preventDefault();

		for (var key in this.state.item) {
			this.fieldValidation(key, this.state.item[key]);
		}

		if (ValidationFormField.formValid(this.state.isError)) {

			this.state.mailResponseData = await MailSuggestion(this.state.item);

			history.push('/info', "Wiadomość została wysłana");
			window.location.reload();
		}
	}

	handleChange(event) {

		const target = event.target;
		const value = target.value;
		const name = target.name;

		let item = { ...this.state.item };

		item[name] = value;
		this.setState({ item: item });

		this.fieldValidation(name, value);
	}

	fieldValidation(name, value, compareValue) {

		let isError = { ...this.state.isError };

		switch (name) {
			case "name":
				isError.name = ValidationFormField.validClassName("Imię", value);
				break;
			case "description":
				isError.description = ValidationFormField.validLongDescription("Treść wiadomości", value);
				break;
			case "email":
				isError.email = ValidationFormField.validEmail("Email", value);
				break;
			default:
				isError.password = "";
				break;
		}
		this.setState({
			isError,
			[name]: value
		})
		this.state.isError[name] = isError[name];
	}

	render() {

		const { isError } = this.state.isError;

		return (
			<div>
				<div id="footer">
					<div class="container">
						<a href="https://www.facebook.com/RezerwujKort"><img src={`/images/facebook-patch.png`} class="facebook" /></a>

						<div id="contact">
							{/* <p><span class="extralight">Chcesz nam podsunąć jakiś pomysł? Zwrócić na coś uwagę?</span></p> */}
							<p><span class="medium1">Aplikacja Mobilna</span></p>

							<br />
							<a href="https://play.google.com/store/apps/details?id=pl.avroit.rezerwujkort.pl&hl=pl"> <img src={`/images/google.png`} /></a>
							
							<a href="https://apps.apple.com/us/app/rezerwujkort-pl/id6475010687?platform=iphone"> <img src={`/images/ios.png`} /></a>
							<br />
							{/* <Form onSubmit={this.handleSubmit} method="post" id="mail_sugestia">

								<Form.Group controlId="formGridAddress1">
									<Form.Control
										placeholder="Imię i Nazwisko"
										id="name"
										name="name"
										onChange={this.handleChange}
									/>
									{this.state.isError.name.length > 0 && (
										<span className="invalid-feedback-contakt">{this.state.isError.name}</span>
									)}
								</Form.Group>

								<Form.Group controlId="formGridAddress1">
									<Form.Control
										placeholder="Email"
										id="email"
										name="email"
										onChange={this.handleChange}
									/>
									{this.state.isError.email.length > 0 && (
										<span className="invalid-feedback-contakt">{this.state.isError.email}</span>
									)}
								</Form.Group>
								<Form.Group controlId="formGridAddress1">
									<Form.Control as="textarea"
										placeholder="Treść wiadomości"
										id="description"
										name="description"
										onChange={this.handleChange}
									/>
									{this.state.isError.description.length > 0 && (
										<span className="invalid-feedback-contakt">{this.state.isError.description}</span>
									)}
								</Form.Group>

								<button class="btn-more semibold" variant="primary" type="submit">wyślij wiadomość</button>
							</Form> */}
						</div>
						<div id="menu-foot" style={{'text-decoration': 'none'}}>
							<h2>Menu:</h2>
							<ul>
								<li class="prim">
									<ul>
										<li><a href="/">- Strona główna</a></li>
										<li><a href="/trenerzy">- Trenerzy</a></li>
										<li><a href="/sparingpartnerzy">- Sparingpartnerzy</a></li>
										<li><a href="/kluby">- Kluby</a></li>
										<li><a href="/turnieje">- Turnieje</a></li>
									</ul>
								</li>
								<li class="prim">
									<ul>
										<li><a href="/login">- Zaloguj się</a></li>
										<li><a href="/register">- Załóż konto</a></li>
										<li><a href="/regulamin">- Regulamin</a></li>
										<li><a href="/polityka_cookie">- Polityka cookies</a></li>
									</ul>
								</li>
								<li class="prim">
									<ul>
										<li><a href="/o_nas">- O nas</a></li>
										<li><a href="/wspolpraca">- Współpraca</a></li>
										<li><a href="/aplikacja_mobilna">- Aplikacja mobilna</a></li>
										<li><a href="/kontakt">- Kontakt</a></li>

									</ul>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div id="legal">
					<div class="container">
					<img src={`/images/logo-small.png`} /><p class="left">Copyright &copy; Rezerwujkort.pl Wszelkie prawa zastrzeżone</p>
						
					</div>
				</div>

			</div>
		)
	}
}

export default Footer;