import FetchPostDataWithAuthNEW from "../services/FetchPostDataWithAuthNEW";

export default async function FechPostLessonsClass(club, body) {

  var url = "/class_app/" + club + "/admin/lessons/show_lesson_search";

  var data = await FetchPostDataWithAuthNEW(url, body)

  return data;
}
