import React from 'react'

import history from "../components/history";
import AuthService from "../services/auth.service";

class ErrorPage extends React.Component{

  checkCodeAndGoToErrorPage(code){

    switch (code) {
      case 500:
        console.log('swich 500');
        this.goToError500()
        break;
      case 404:
        console.log('swich 404');
        this.goToError404()
        break;
      case 401:
        this.goToLoginPage()
        console.log('swich 401');
        break;
      default:
        break;
    }
  }

  goToError500() {
    console.log("goToError500 "); //usun
    history.push('/class_app/error500');
    window.location.reload();
  }

  goToError404() {
    console.log("goToError404 "); //usun
    history.push('/class_app/error500');
    window.location.reload();
  }

  goToLoginPage() {
    // AuthService.logout();
    // console.log("goToLoginPage "); //usun
    // history.push('/login');
    // window.location.reload();
  }

}

export default new ErrorPage();
