import React, { Suspense } from "react";

import { Spinner, Button, Table, Card } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl"; // the locale you want

import FechGetPromotionLogsById from "../APP_RESERVATION_FECH_DATA/FechGetPromotionLogsById";
import FechGetPromotionById from "../APP_RESERVATION_FECH_DATA/FechGetPromotionById";

import BeltReservationApp from "../APP_RESERVATION_COMPONENTS/BeltReservationApp";

import queryString from "query-string";
import moment from "moment";
import ParseLinkToArray from "../utils/ParseLinkToArray";
import CreatLinkSerch from "../utils/CreatLinkSerch";
import HeadersCsv from "../APP_RESERVATION_UTILS/HeadersCsv";
import { CSVLink } from "react-csv";

//UTILS
import DateTimeNow from "../utils/DateTimeNow";

const TabAllPromotionChangeLogs = React.lazy(() =>
  import("../APP_RESERVATION_COMPONENTS/TabAllPromotionChangeLogs")
);

let plLocate = require("moment/locale/pl");
moment.locale("pl", plLocate);

class PagePromotionsDetails extends React.Component {
  TRAINER_SELECT_DESC = "Wybierz trenera";
  DATE_SELECT_DESC = "Wybierz datę";
  CLASS_SELECT_DESC = "Wybierz szkółke";

  urlDeafulValues = [
    this.TRAINER_SELECT_DESC,
    this.CLASS_SELECT_DESC,
    this.DATE_SELECT_DESC,
  ];

  headers = HeadersCsv.reservationStandard();
  deafulValues = [
    this.TRAINER_SELECT_DESC,
    this.CLASS_SELECT_DESC,
    this.DATE_SELECT_DESC,
    this.STATUS_SELECT_DESC,
    this.DEAFULT_SELECT_DESC,
  ];

  postData = {
    promotionId: "",
    sortBy: "",
    page: "1",
  };

  constructor(props) {
    super(props);

    this.state = {
      title: "RezerwujKort.pl - Rezerwację",
      errorToManyRecordsToExport: false,
      data: [],
      restPromotionData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
      restPromotionLogsData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
    };
    this.csvLinkEl = React.createRef();
  }

  async componentDidMount() {
    document.title = "Rezerwacje - RezerwujKort.pl";
    registerLocale("pl", pl); // register it with the name you want
    const {
      location: { search },
    } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.postData = await ParseLinkToArray(
      this.urlValue,
      this.postData,
      this.urlDeafulValues
    );

    this.postData.promotionId = this.urlValue.promotionId;
    var findEventsUrl = CreatLinkSerch(
      "",
      this.preapareValue(),
      this.deafulValues
    );

    var responsePromotion = await FechGetPromotionById(
      this.props.match.params.club,
      this.urlValue.promotionId
    );

    var responseLogs = await FechGetPromotionLogsById(
      this.props.match.params.club,
      findEventsUrl
    );
    this.setState({
      restPromotionData: responsePromotion,
      restPromotionLogsData: responseLogs,
    });
    var ff = this.restPromotionData;
  }

  preapareValue() {
    const values = new Map();
    values.set("promotionId", this.postData.promotionId);
    values.set("page", this.postData.page);

    return values;
  }
  render() {
    var fileName = "raport " + DateTimeNow();
    const { data } = this.state;

    var loader = "";
    if (!this.state.restPromotionData.successful) {
      loader = (
        <div id="loader_div">
          <Spinner animation="border" />
        </div>
      );
    }

    var totalRecords = "";
    var totalRecordsLogs = "";
    var tabAllPromotionChangeLogs = "";
    var searchPanel = "";
    var baseInfo = "";
    if (this.state.restPromotionData.successful) {
      var days = this.state.restPromotionData.data.days
        .map((item) => item.name)
        .join(", ");
      var salesChannels = this.state.restPromotionData.data.salesChannel
        .map((item) => item.name)
        .join(", ");
      totalRecordsLogs = this.state.restPromotionLogsData.data.totalElements;
      tabAllPromotionChangeLogs = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <TabAllPromotionChangeLogs
              club={this.props.match.params.club}
              promotionLogs={this.state.restPromotionLogsData.data}
              currentPromotion={this.state.restPromotionData.data}
            />
          </Suspense>
        </div>
      );

      baseInfo = (
        <Card style={{ width: "100%" }}>
          <Card.Body>
            <Card.Title>Informacje o promocji:</Card.Title>
            <Table responsive="sm">
              <tbody>
                <tr>
                  <td>
                    <b>Nazwa promocji:</b>
                  </td>
                  <td>{this.state.restPromotionData.data.nameAdmin}</td>
                  <td>
                    <b>Priorytet:</b>
                  </td>
                  <td>{this.state.restPromotionData.data.priority}</td>
                </tr>
                <tr>
                  <td>
                    <b>Nazwa jaką zobaczy klient:</b>
                  </td>
                  <td>
                    {this.state.restPromotionData.data.nameUser != "" ? (
                      <>
                        <br />
                        {this.state.restPromotionData.data.nameUser}
                      </>
                    ) : (
                      <>{this.state.restPromotionData.data.nameAdmin}</>
                    )}
                  </td>
                  <td>
                    <b>Czy aktywna:</b>
                  </td>
                  <td>
                    {this.state.restPromotionData.data.enabled ? (
                      <>Tak</>
                    ) : (
                      <>Nie</>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Korty:</b>
                  </td>
                  <td>{this.state.restPromotionData.data.courtDesc}</td>
                  <td>
                    <b>Dla kogo promocja:</b>
                  </td>
                  <td>
                    {this.state.restPromotionData.data.clientsType === "ALL" ? (
                      <>Wszyscy</>
                    ) : (
                      <>Wybrani klienci</>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dni tygodnia:</b>
                  </td>
                  <td>{days}</td>
                  <td>
                    <b>Typ promocji:</b>
                  </td>
                  <td>
                    {this.state.restPromotionData.data.typeId === 0 ? (
                      <>Obniża wartość rezerwacji o wybrany procent.</>
                    ) : (
                      <>Obniża wartość rezerwacji o wybraną kwotę.</>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Godzina start:</b>
                  </td>
                  <td>{this.state.restPromotionData.data.hourStart}</td>
                  <td>
                    <b>Wysokość obniżki:</b>
                  </td>
                  <td>{this.state.restPromotionData.data.valueDiscount}</td>
                </tr>
                <tr>
                  <td>
                    <b>Godzina koniec:</b>
                  </td>
                  <td>{this.state.restPromotionData.data.hourStop}</td>
                  <td>
                    <b>Kanał sprzedaży:</b>
                  </td>
                  <td>{salesChannels}</td>
                </tr>
                <tr>
                  <td>
                    <b>Czy usunięty:</b>
                  </td>
                  <td>
                    {this.state.restPromotionData.data.deleted != "" ? (
                      <>Tak</>
                    ) : (
                      <>Nie</>
                    )}
                  </td>
                  <td>
                    <b>Ile razy może być użyta:</b>
                  </td>
                  <td>
                    {this.state.restPromotionData.data.howManyTimesCanBeUsed ===
                    0 ? (
                      <>Bez ograniczeń</>
                    ) : (
                      <>
                        {
                          this.state.restPromotionData.data
                            .howManyTimesCanBeUsed
                        }
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Promocja dla całości rezerwacji czy części:</b>
                  </td>
                  <td>
                    {this.state.restPromotionData.data
                      .promoFromWholeReservation === true ? (
                      <>Cała rezerwacja</>
                    ) : (
                      <>Każde pół godziny</>
                    )}
                  </td>
                  <td>
                    <b>Wiadomość wysłana do klienta po rezerwacji:</b>
                  </td>
                  <td>
                    {this.state.restPromotionData.data.msgToUseInEmail ===
                    "" ? (
                      <>--</>
                    ) : (
                      <>{this.state.restPromotionData.data.msgToUseInEmail}</>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Dodana:</b>
                  </td>
                  <td>
                    {this.state.restPromotionData.data.createdAt} &nbsp;
                    {this.state.restPromotionData.data.createdByEmail}
                  </td>
                  <td>
                    <b>Usunięta:</b>
                  </td>
                  <td>
                    {this.state.restPromotionData.data.deletedAt} &nbsp;
                    {this.state.restPromotionData.data.deletedByEmail}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      Wiadomość wysłana do klienta po skorzystaniu z promocji:
                    </b>
                  </td>
                  <td>{this.state.restPromotionData.data.msgToUserInEmail}</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      );
      var errorToManyRecordsToExport;
      if (this.state.errorToManyRecordsToExport) {
        errorToManyRecordsToExport = (
          <div style={{ marginTop: 20, lineHeight: "25px", color: "red" }}>
            <b>
              Maksymalnie można eksportować:{" "}
              {this.state.restUtilsData.data.maxCsvRecords} rekordów!
            </b>
          </div>
        );
      }
    }

    return (
      <div>
        <div id="content">
          <BeltReservationApp club={this.props.match.params.club} />

          {baseInfo}

          <br />
          <br />
          <b>Lista wszystkich zmian w promocji: ({totalRecordsLogs}):</b>
          <br />
          <br />

          {tabAllPromotionChangeLogs}

          <br />
          <br />
          <Button
            href={
              "/reservation_app/" +
              this.props.match.params.club +
              "/historia_rezerwacji/search?page=1&promotion=" +
              this.postData.promotionId
            }
            variant="success"
            size="lg"
          >
            Pokaż wszystkie rezerwację z promocji
          </Button>
          {/* <b>Lista wszystkich rezerwacji w promocji: ({totalRecords}):</b>

          {errorToManyRecordsToExport}

          <CSVLink
            headers={this.headers}
            filename={fileName}
            data={data}
            ref={this.csvLinkEl}
            separator={";"}
          /> */}
          <br />
          <br />
          <br />
          <br />
          {loader}
        </div>
      </div>
    );
  }
}
export default PagePromotionsDetails;
