import React, { Suspense } from "react";

import { Spinner } from "react-bootstrap";
import Belt from "../components/Belt";
import BeltLogged from "../components/BeltLogged";

import Footer from "../components/Footer";
import AuthService from "../services/auth.service";
import FechGetCheckIfSuperAdmin from "../fechData/FechGetCheckIfSuperAdmin";
import FechGetLoggedUserData from "../fechData/FechGetLoggedUserData";
import FechGetAllClubsReservationPrivileges from "../fechData/FechGetAllClubsReservationPrivileges";
import FechGetAllUserReservationOnline from "../fechData/FechGetAllUserReservationOnline";

import DateTimeNow from "../utils/DateTimeNow";

const TabAllUserReservations = React.lazy(() =>
  import("../RESERVATION_ONLINE_COMPONENTS/TabAllUserReservations")
);

class ProfilePage extends React.Component {
  state = {
    currentUser: AuthService.getCurrentUser(),
    title: "RezerwujKort.pl - Profil",
    logInfo: "LogDebug __" + DateTimeNow() + "__ ProfilePage ",
    currentUser: "",
    restClassSuperAdminbData: {
      successful: false,
      status: "",
      message: "",
      data: [],
    },
    restUserReservationsData: {
      successful: false,
      status: "",
      message: "",
      data: [],
    },
    restReservationClubData: {
      successful: false,
      status: "",
      message: "",
      data: [],
    },
    restUserData: {
      successful: false,
      status: "",
      message: "",
      data: [],
    },
  };

  async componentDidMount() {
    document.title = this.state.title;

    this.state.restUserData = await FechGetLoggedUserData();
    this.state.currentUser = AuthService.getCurrentUser();

    this.setState({
      restUserReservationsData: await FechGetAllUserReservationOnline(
        "?page=1"
      ),
    });
    this.setState({
      restClassSuperAdminbData: await FechGetCheckIfSuperAdmin(),
    });
    this.setState({
      restReservationClubData: await FechGetAllClubsReservationPrivileges(),
    });
  }

  render() {
    var loader = "";
    if (!this.state.restUserReservationsData.successful) {
      loader = (
        <div id="loader_div">
          <Spinner animation="border" />
        </div>
      );
    }

    var tabAllUserReservations = "";
    if (
      this.state.restUserReservationsData.successful &&
      this.state.restUserReservationsData.data.numberOfElements > 0
    ) {
      var firstReservation = [];
      firstReservation.push(
        this.state.restUserReservationsData.data.content[0]
      );
      tabAllUserReservations = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <div class="scroll">
              <TabAllUserReservations
                restUserReservationsData={firstReservation}
              />
            </div>
          </Suspense>
          <div
            style={{
              clear: "both",
              marginTop: "5px",
              textAlign: "right",
              height: "60px",
              marginLeft: "50px",
            }}
          >
            <a href={"/profile/moje_rezerwacje?page=1"}>Pokaż więcej</a>
          </div>
        </div>
      );
    }

    if (
      this.state.restUserReservationsData.successful &&
      this.state.restUserReservationsData.data.numberOfElements == 0
    ) {
      var firstReservation = [];
      firstReservation.push(
        this.state.restUserReservationsData.data.content[0]
      );
      tabAllUserReservations = (
        <div class="prof-inf">
          <br />- Brak rezerwacji
        </div>
      );
    }

    if (
      this.state.currentUser != null &&
      this.state.currentUser != "" &&
      this.state.currentUser.roles.includes("ROLE_RESERVATION_APP_SUPER_ADMIN")
    ) {
      tabAllUserReservations = (
        <div class="prof-inf">
          <br />- Brak rezerwacji
        </div>
      );
    }

    var adverbs = [];
    for (var courtName in this.state.restClassSuperAdminbData.data) {
      adverbs.push(
        <div>
          <a
            href={
              "/class_app/" +
              this.state.restClassSuperAdminbData.data[courtName].clubsName +
              "/panel_admin"
            }
          >
            Aplikacja Szkółki tenisowe
          </a>
          <br />
        </div>
      );
    }

    for (var courtName in this.state.restReservationClubData.data) {
      adverbs.push(
        <div>
          <a
            href={
              "/reservation_app/" +
              this.state.restReservationClubData.data[courtName].clubsName +
              "/calendar"
            }
          >
            Rezerwacje{" "}
            {this.state.restReservationClubData.data[courtName].clubsName}
          </a>
          <br />
        </div>
      );
    }

    var app = "";
    if (adverbs.length > 0) {
      app = (
        <>
          <div class="main-pp">Aplikacje</div>
          <div class="prof-inf">
            <br />
            {adverbs}
          </div>
        </>
      );
    }

    var phoneConfirmed = [];
    if (this.state.restUserData.successful) {
      if (this.state.restUserData.data.phoneVerification) {
        phoneConfirmed.push(<b>(Zweryfikowany!)</b>);
      } else {
        phoneConfirmed.push(
          <b style={{ "font-style": "italic", color: "red" }}>
            (Nie zweryfikowany!
            <a
              style={{ "font-style": "italic", color: "red" }}
              href={"/profile/weryfikacja_numeru_telefonu"}
            >
              {" "}
              Potwierdź numer telefonu
            </a>
            )
          </b>
        );
      }
    }

    return (
      <div>
        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">
            <div class="main-subpage-info">
              <div class="main-pp">Twoje dane</div>
              <br />
              <div class="prof-inf">
                <div class="prof-inf-in">Imie:</div>
                <div class="prof-inf-in">
                  {this.state.restUserData.data.name}
                </div>
                <div class="prof-inf-in">Nazwisko:</div>
                <div class="prof-inf-in">
                  {this.state.restUserData.data.surname}
                </div>
                <div class="prof-inf-in">Telefon:</div>
                <div class="prof-inf-in">
                  {this.state.restUserData.data.phone} {phoneConfirmed}
                </div>
                <div class="prof-inf-in">Email:</div>
                <div class="prof-inf-in">
                  {this.state.restUserData.data.email}
                </div>
              </div>
              <div class="main-pp">Rezerwacje</div>

              <div class="main-subpage-info1">
                {loader}
                {tabAllUserReservations}
              </div>

              {app}

              <div class="main-pp">Ulubione Ośrodki Tenisowe</div>
              <div class="prof-inf">
                <br />- Brak
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
export default ProfilePage;
