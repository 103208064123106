import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';
import AuthService from "../services/auth.service";
import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW";

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const EditPriceType0 = React.lazy(() => import('../classAppForms/EditPriceType0'));
const EditPriceType1 = React.lazy(() => import('../classAppForms/EditPriceType1'));

class PagePriceSettings extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            currentUser: AuthService.getCurrentUser(),
            restPriceTypeData: {
                successful: false,
                status: '',
                message: '',
                data: []
            }
        };
        //this.myFunction = this.myFunction.bind(this);
    }

    async componentDidMount() {
        document.title = this.state.title;

        var url = "/class_app/" + this.props.match.params.club + "/admin/class_type_show/" + this.props.match.params.id;
        console.log(" url : " + JSON.stringify(url));
        await this.fechGetClassType(url);
    }

    async fechGetClassType(url) {

        var data;

        data = await FetchGetDataWithAuthNEW(url);
        if (data) {
            this.setState({ restPriceTypeData: data });
        }
    }


    render() {

        var msg = this.props.location.state;
        var actionMsg = "";
        if (msg === undefined) {
            actionMsg = "";
        } else {
            actionMsg = <div class="tenis-center-content-info"> + {msg} + </div>;
        }

        const renderPriceTypeForm = () => {
            if (this.state.restPriceTypeData.data.priceType == 0) {
                return <div>
                    <Suspense fallback={<div>
                        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
                    </div>}>
                        <EditPriceType0
                            club={this.props.match.params.club}
                            priceTypeData={this.state.restPriceTypeData.data}
                        />
                    </Suspense>
                </div>;
            } else {
                return <div>
                    <Suspense fallback={<div>
                        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
                    </div>}>
                        <EditPriceType1
                            club={this.props.match.params.club}
                            priceTypeData={this.state.restPriceTypeData.data}
                        />
                    </Suspense>
                </div>;
            }
        }

        var beltClassApp = <div>
            <Suspense fallback={<div>
                <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
            </div>}>
                <BeltClassNewApp club={this.props.match.params.club} />
            </Suspense>
        </div>

        return (
            <div>
               {beltClassApp}

                <div id="content">
                    <div class="container">

                        

                        <br /><br />

                        {actionMsg}

                        <p><b>Ustawienia Płatności dla szkółki: {this.state.restPriceTypeData.data.name}  </b></p>
                        <br />

                        {renderPriceTypeForm()}

                    </div>
                </div>


            </div>
        );
    }
}
export default PagePriceSettings;
