import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';
import PageCounterNew from '../components/PageCounterNew';

import FechGetSuperAdminRoles from "../leagueAppFechData/FechGetSuperAdminRoles";
import DateTimeNow from "../utils/DateTimeNow";

import ParseLinkToArray from "../utils/ParseLinkToArray";
import queryString from 'query-string';

const BeltLeague = React.lazy(() => import('../leagueAppComponents/BeltLeague'));

class PageLeagueStatute extends React.Component {

  searchSparingItem = {
    page: ''
  };
  urlDeafulValues = [];
  urlValue = "";

  state = {
    title: "Liga - Ranking - RezerwujKort.pl",
    logInfo: "LogDebug __" + DateTimeNow() + "__ PageLeagueStatute ",
    rowsCount: "6",
    restSparingAdverbsData: {
      successful: false,
      status: '',
      message: '',
      data: []
    },
    restLeagueAdminData: {
      successful: false,
      status: '',
      message: '',
      data: []
    }
  }

  async componentDidMount() {

    document.title = this.state.title

    const { location: { search } } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.searchSparingItem = await ParseLinkToArray(this.urlValue, this.searchSparingItem, this.urlDeafulValues);

    this.setState({
      restLeagueAdminData: await FechGetSuperAdminRoles(this.props.match.params.club)
    });

  }

  render() {

    const urlValues = new Map();
    urlValues.set('page', "replace");

    var pageCounter = "";
    if (this.state.restSparingAdverbsData.successful) {
      pageCounter = <PageCounterNew
        elementCount={this.state.restSparingAdverbsData.data.totalRecordsCount}
        urlValues={urlValues}
        urlDeafulValues={this.urlDeafulValues}
        elementOnPage={this.state.rowsCount}
        currentPage={parseInt(this.searchSparingItem.page) + 1}
        searchName={"sparing_search"}
        linkTo={"sparingpartnerzy"}
      />
    }

    var beltLeague;
    if (this.state.restLeagueAdminData.successful) {
      beltLeague = <div>
        <Suspense fallback={<div>
          <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
        </div>}>
          <BeltLeague
            currentPage={"statute"}
            club={this.props.match.params.club}
            league={this.props.match.params.league}
          />
        </Suspense>
      </div>
    }

    return (

      <div>

        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">
            <div class="main-subpage">

              {beltLeague}

              <div >

              </div>

            </div>

          </div>
        </div>

        <Footer />

      </div>
    );
  }
}

export default PageLeagueStatute;
