import React from "react";
import { Redirect } from "react-router-dom";

import { Col, Form, Spinner } from "react-bootstrap";
import AuthService from "../services/auth.service";
import FechPostResetPasswordToken from "../fechData/FechPostResetPasswordToken";
import history from "../components/history";
//UTILS
import DateTimeNow from "../utils/DateTimeNow";

import ValidationFormField from "../utils/ValidationFormField";


class ForgotPasswordForm extends React.Component {

  emptyItem = {
    email: '',
    urlConfirm: window.location.href
      .replace('/forgot_password', '/reset_password')
  };

  //export default class Login extends Component {
  constructor(props) {
    super(props);
    this.handleSend = this.handleSend.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      logInfo: "LogDebug __" + DateTimeNow() + "__ ForgotPasswordForm ",
      currentUser: AuthService.getCurrentUser(),
      item: this.emptyItem,
      loader: false,
      isError: {
        email: ''
      }
    };
  }

  handleChange(event) {

    const target = event.target;
    const value = target.value;
    const name = target.name;

    let item = { ...this.state.item };

    item[name] = value;
    this.setState({ item: item });

    //this.fieldValidation(name, value);
  }

  async handleSend(event) {

    event.preventDefault();

    for (var key in this.state.item) {
      this.fieldValidation(key, this.state.item[key]);
    }

    if (ValidationFormField.formValid(this.state.isError)) {

      this.setState({ loader: true });

      var restLoginResponseData = await FechPostResetPasswordToken(this.state.item);

      let isError = { ...this.state.isError };

      if (restLoginResponseData.successful) {
        history.push('/info', "Jeśli wpisany przez Ciebie adres e-mail pasuje do tego, " +
          "który mamy w bazie, wyślemy Ci adres internetowy pod którym możliwa będzie zmiana hasła.");
        window.location.reload();
      }else if(restLoginResponseData.message === 'Error: Konto usunięte!'){
        this.setState({ loader: false });
        isError.email = "Konto usunięte.";
        this.setState({ isError: isError });
      } else {
        this.setState({ loader: false });
        isError.email = "Wystąpił błąd.";
        this.setState({ isError: isError });
      }

    } else {
      console.log("walid : Form is invalid!"); //usun
    }
  }

  fieldValidation(name, value, compareValue) {

    let isError = { ...this.state.isError };

    switch (name) {
      case "email":
        isError.email = ValidationFormField.validNotEmpty("Email", value);
        break;
      default:
        break;
    }
    this.setState({
      isError,
      [name]: value
    })
    this.state.isError[name] = isError[name];
  }

  render() {

    const { item } = this.state;
    const { isError } = this.state;
    const { currentUser } = this.state;

    var loader = "";
    if (this.state.loader) {
      loader = <div id="loader_div">
        <Spinner animation="border" />
      </div>
    } else {
      loader = <div id="login">
        <Form onSubmit={this.handleSend} ref={c => { this.form = c; }}>
          <input type="hidden" name="_csrf_token" value="" />
          <div class="head">
            <img src={process.env.PUBLIC_URL + '/images/login.png'} alt="" />
            <p>Przypomnij hasło</p>
          </div>
          <div class="sub-content">
            <div class="login-item">
              <div class="login-icon">
                <input type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="e-mail"
                  value={item.email || ''}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <br />
            <div class="login-button">
              <input type="submit" class="button_nieb"
                value="Wyślij nowe hasło"></input>

            </div>

          </div>
        </Form>
      </div>
    }

    if (currentUser !== null) {
      return <Redirect to="/profile" />
    } else {

      return (
        <div id="content">
          <div class="container">

            {isError.email.length > 0 && (
              <div>
                <br />
                <div className="alert alert-danger" role="alert">{isError.email}</div>
              </div>
            )}

            {loader}

          </div>
        </div>
      );
    }
  }
}

export default ForgotPasswordForm;