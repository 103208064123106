import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pl from "date-fns/locale/pl";
import Modal from "react-bootstrap/Modal";
import makeAnimated from "react-select/animated";

import {
  Col,
  Form,
  Button,
  Container,
  Row,
  Table,
  Card,
} from "react-bootstrap";
import Select from "react-select";

import AddPromotionPopupFormInfo from "./AddPromotionPopupForm_Info";

import FechGetConnectPromotionUtils from "../../APP_RESERVATION_FECH_DATA/FechGetConnectPromotionUtils";
import FechGetAllConnectPromotions from "../../APP_RESERVATION_FECH_DATA/FechGetAllConnectPromotions";
import FechPostAddPromotionConnection from "../../APP_RESERVATION_FECH_DATA/FechPostAddPromotionConnection.js";
import FechGetDelecteConnectPromotion from "../../APP_RESERVATION_FECH_DATA/FechGetDelecteConnectPromotion";

import ParseUtilsToField from "../../utils/ParseUtilsToField";
import ValidationFormField from "../../utils/ValidationFormField";
import ErrorPopup from "../../utils/ErrorPopup";

const animatedComponents = makeAnimated();

class ConnectPromotionsPopupForm extends React.Component {
  emptyItem = {
    promotion1Id: "",
    promotion1Name: "",
    promotion2Id: "",
    promotion2Name: "",
    connectionsAvailable: "",
  };

  emptyItemError = {
    promotion1Id: "",
    promotion1Name: "",
    promotion2Id: "",
    promotion2Name: "",
    connectionsAvailable: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      summaryResponse: "",
      loading: false,
      promotionsGet: false,
      errrorShow: "",
      isError: this.emptyItemError,
      emptyItem: this.emptyItem,
      restUtilsData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
      restConnectionsData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
    };

    this.handleChangeField = this.handleChangeField.bind(this);
    this.handleSubmitCheckPromotion =
      this.handleSubmitCheckPromotion.bind(this);
    this.handleSubmitDelete = this.handleSubmitDelete.bind(this);
    this.handleCloseReservationFrom =
      this.handleCloseReservationFrom.bind(this);
    this.handleChangePromotion1 = this.handleChangePromotion1.bind(this);
    this.handleChangePromotion2 = this.handleChangePromotion2.bind(this);
  }

  async componentDidMount() {
    this.cleanEmptyItem();
    this.cleanEmptyItemError();
    this.setState({ errrorShow: "" });
    var response = await FechGetConnectPromotionUtils(this.props.club);
    var responseConnections = await FechGetAllConnectPromotions(
      this.props.club
    );

    this.setState({
      restUtilsData: response,
      showEditForm: true,
      restConnectionsData: responseConnections,
    });
  }

  async componentDidUpdate() {
    if (
      this.state.showEditForm != true &&
      this.state.afterCloseAddClientForm === false
    ) {
      this.cleanEmptyItem();
      this.cleanEmptyItemError();
      var response = await FechGetConnectPromotionUtils(this.props.club);
      var responseConnections = await FechGetAllConnectPromotions(
        this.props.club
      );

      this.setState({
        restUtilsData: response,
        restConnectionsData: responseConnections,
        showEditForm: true,
      });
    }
    this.state.afterCloseAddClientForm = false;
  }

  cleanEmptyItem() {
    this.emptyItem.promotion1Id = "";
    this.emptyItem.promotion1Name = "";
    this.emptyItem.promotion2Id = "";
    this.emptyItem.promotion2Name = "";
    this.emptyItem.connectionsAvailable = "";
  }

  cleanEmptyItemError() {
    this.emptyItemError.promotion1Id = "";
    this.emptyItemError.promotion1Name = "";
    this.emptyItemError.promotion2Id = "";
    this.emptyItemError.promotion2Name = "";
    this.emptyItemError.connectionsAvailable = "";
  }

  validateAndPreapreObjectToSent() {
    for (var key in this.emptyItem) {
      if (key != "promotion1Id") {
        //this.fieldValidation(key, this.emptyItem[key]);
      }
    }

    if (ValidationFormField.formValid(this.state.isError)) {
      return true;
    }
  }

  async handleSubmitCheckPromotion() {
    if (this.validateAndPreapreObjectToSent()) {
      var response = await FechPostAddPromotionConnection(
        this.props.club,
        this.state.emptyItem
      );

      if (response.successful == true) {
        this.cleanEmptyItem();
        this.cleanEmptyItemError();
        var responseConnections = await FechGetAllConnectPromotions(
          this.props.club
        );
        this.setState({
          promotionsGet: true,
          errrorShow: "",
          restConnectionsData: responseConnections,
        });
      } else {
        this.setState({
          errrorShow: ErrorPopup("Wystąpił błąd!", response.message),
        });
      }
    }
  }

  async handleSubmitDelete(connectionId) {
    const isConfirmed = window.confirm(
      "Czy na pewno chcesz usunąć tę promocję?"
    );
    if (isConfirmed) {
      if (this.validateAndPreapreObjectToSent()) {
        var response = await FechGetDelecteConnectPromotion(
          this.props.club,
          connectionId
        );
        if (response.successful == true) {
          var responseConnections = await FechGetAllConnectPromotions(
            this.props.club
          );
          this.setState({
            summaryResponse: response,
            errrorShow: "",
            restConnectionsData: responseConnections,
          });
        } else {
          this.setState({
            errrorShow: ErrorPopup("Wystąpił błąd!", response.message),
          });
        }
      }
    }
  }

  fieldValidation(name, value, compareValue) {
    let isError = { ...this.state.isError };

    var nameTemp = name;
    if (name === "dayOfWeek") {
      nameTemp = "dayOfWeekList";
    }

    var resultValidation = ValidationFormField.formFieldValid(
      isError,
      nameTemp,
      value,
      compareValue,
      this.emptyItem
    );

    this.emptyItemError[name] = resultValidation[name];
    this.setState({ isError: this.emptyItemError });
  }

  handleCloseReservationFrom() {
    this.cleanEmptyItem();
    this.cleanEmptyItemError();
    this.setState({ isError: this.emptyItemError });
    this.state.afterCloseAddClientForm = true;
    this.setState({ showEditForm: false });
  }

  handleChangeField = (event) => {
    const { name, value, type, checked } = event.target;
    var item = this.emptyItem;
    item[name] = value;
    this.emptyItem = item;
    this.setState({
      emptyItem: item,
    });

    this.fieldValidation(name, value);
  };

  handleChangePromotion1(event) {
    this.emptyItem["promotion1Id"] = event.value;
    this.emptyItem["promotion1Name"] = event.label;
    this.setState({ emptyItem: this.emptyItem });
  }

  handleChangePromotion2(event) {
    this.emptyItem["promotion2Id"] = event.value;
    this.emptyItem["promotion2Name"] = event.label;
    this.setState({ emptyItem: this.emptyItem });
  }

  render() {
    const { isError } = this.state;

    var promotionsList;
    var currentPromotions1Choose;
    var currentPromotions2Choose;

    if (this.state.restUtilsData.successful) {
      promotionsList = ParseUtilsToField.promotionsField(
        this.state.restUtilsData.data
      );
      currentPromotions1Choose = ParseUtilsToField.currentPromotionChooseNew(
        this.state.restUtilsData.data,
        this.emptyItem.promotion1Name,
        this.emptyItem.promotion1Id
      );
      if (currentPromotions1Choose != undefined) {
        this.emptyItem.promotion1Id = currentPromotions1Choose.value;
        this.emptyItem.promotion1Name = currentPromotions1Choose.label;
      }
      currentPromotions2Choose = ParseUtilsToField.currentPromotionChooseNew(
        this.state.restUtilsData.data,
        this.emptyItem.promotion2Name,
        this.emptyItem.promotion2Id
      );
      if (currentPromotions2Choose != undefined) {
        this.emptyItem.promotion2Id = currentPromotions2Choose.value;
        this.emptyItem.promotion2Name = currentPromotions2Choose.label;
      }
    }

    var selectedPromotion1;
    if (this.emptyItem.promotion1Name) {
      selectedPromotion1 = (
        <div style={{ marginTop: 20, lineHeight: "25px" }}>
          <b>Wybrana promocja: </b> {this.emptyItem.promotion1Name}
        </div>
      );
    }

    var selectedPromotion2;
    if (this.emptyItem.promotion2Name) {
      selectedPromotion2 = (
        <div style={{ marginTop: 20, lineHeight: "25px" }}>
          <b>Wybrana promocja: </b> {this.emptyItem.promotion2Name}
        </div>
      );
    }

    var priceData;
    var club = "--";
    var promotionElements;
    var promotionsHtml = "";
    if (this.state.summaryResponse.successful) {
      if (priceData != undefined) {
        if (priceData.promotions != null && priceData.promotions.length > 0) {
          // Tworzenie listy elementów JSX z nazwami promocji i wartościami zniżek
          promotionElements = priceData.promotions.map((promotion, index) => (
            <div key={index}>
              {promotion.promotionName} (Zniżka {promotion.discountInZloty} zł)
            </div> // Użycie <div> dla nowej linii
          ));
        }
      } else {
        var blad = 3;
      }
    }
    if (this.state.restUtilsData.successful) {
      club = this.state.restUtilsData.data.clubName;
    }

    return (
      <>
        <Modal
          show={this.state.showEditForm}
          onHide={this.handleCloseReservationFrom}
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Łączenie promocji</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.loading && (
              <div className="loading-indicator">Ładowanie...</div>
            )}
            <Container fluid>
              {this.state.errrorShow}
              <Row>
                <Col>
                  <div className="App">
                    <Form.Label>
                      Wybierz promocje które chcesz połączyć
                    </Form.Label>
                    <Select
                      placeholder="Wybierz klienta"
                      id="promotion1Id"
                      name="promotion1Id"
                      disabled="true"
                      value={currentPromotions1Choose} // set selected value
                      options={promotionsList} // set list of the data
                      onChange={this.handleChangePromotion1} // assign onChange function
                    />
                    {selectedPromotion1}
                  </div>
                </Col>
                <Col>
                  <div className="App">
                    <Form.Label>&#20;&#20;</Form.Label>
                    <Select
                      placeholder="Wybierz klienta"
                      id="promotion2Id"
                      name="client2d"
                      disabled="true"
                      value={currentPromotions2Choose} // set selected value
                      options={promotionsList} // set list of the data
                      onChange={this.handleChangePromotion2} // assign onChange function
                    />
                    {selectedPromotion2}
                  </div>
                </Col>
              </Row>
              <br />
              <div
                style={{ borderTop: "1px solid #ccc", margin: "20px 0" }}
              ></div>

              <Row className="mb-3">
                <Col>
                  <Form.Label>
                    Sposób łączenia promocji
                    {AddPromotionPopupFormInfo.clientsType()}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    className={
                      isError.connectionsAvailable.length > 0
                        ? "is-invalid form-control"
                        : "form-control"
                    }
                    name="connectionsAvailable"
                    value={this.emptyItem.connectionsAvailable}
                    onChange={this.handleChangeField}
                  >
                    <option value="">Wybierz...</option>
                    <option value="true">Promocje mogą być użyte razem</option>
                    <option value="false">
                      Tylko jedna z promocji może być wybrana przez klienta
                    </option>
                  </Form.Control>
                  {isError.connectionsAvailable.length > 0 && (
                    <span className="invalid-feedback">
                      {isError.connectionsAvailable}
                    </span>
                  )}
                </Col>
              </Row>

              <Row>
                <Col>
                  <Button
                    variant="primary"
                    type="submit"
                    className="mt-4 w-100"
                    onClick={() => this.handleSubmitCheckPromotion()}
                  >
                    Połącz promocję
                  </Button>
                </Col>
              </Row>

              {promotionsHtml}

              <Row>
                <Col>
                  <div
                    style={{ borderTop: "1px solid #ccc", margin: "20px 0" }}
                  ></div>

                  <div className="table-clients-scrollable">
                    <Form.Label>
                      Lista połączonych promocji. (
                      {this.state.restConnectionsData.data.length})
                    </Form.Label>
                    <Table striped bordered hover size="sm" className="mt-4">
                      <thead>
                        <tr>
                          <th>Promocja 1</th>
                          <th>Promocja 2</th>
                          <th>Czy mogą być użyte razem</th>
                          <th>Akcja</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.restConnectionsData.data.map(
                          (onePromotion, index) => (
                            <tr key={index}>
                              <td>{onePromotion.promotion1Name}</td>
                              <td>{onePromotion.promotion2Name}</td>
                              <td>
                                {onePromotion.connectionsAvailable
                                  ? "tak"
                                  : "nie"}
                              </td>
                              <td>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() =>
                                    this.handleSubmitDelete(onePromotion.id)
                                  }
                                >
                                  Usuń
                                </Button>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ConnectPromotionsPopupForm;
