import React, { Suspense } from 'react';

import { Spinner, Button } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl"; // the locale you want

import FechGetPriceGraphic from "../APP_RESERVATION_FECH_DATA/FechGetPriceGraphic";

import BeltReservationApp from '../APP_RESERVATION_COMPONENTS/BeltReservationApp'
import BeltReservationPriceList from '../APP_RESERVATION_COMPONENTS/BeltReservationPriceList'

import moment from 'moment'

import DatePicker from "react-datepicker";
import ButtonCalendar from "./ButtonCalendar"

import GraphicHeader from "../APP_RESERVATION_COMPONENTS/GraphicHeader";
import DateNowYyyyMmDd from "../utils/DateNowYyyyMmDd";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DateNowYyyyMmDdFromDate from "../utils/DateNowYyyyMmDdFromDate";

let plLocate = require('moment/locale/pl');
moment.locale('pl', plLocate)

const GraphicPrice = React.lazy(() => import('../APP_RESERVATION_COMPONENTS/GraphicPrice'));

//KLASA WYSWIETLA SZCZEGOLY KONKRETNYCH ZAJEC SZKOLKI
class PagePaymentsOnlineGraphic extends React.Component {

    pageType = "CLUB_APPLICATION";

    constructor(props) {
        super(props);

        this.state = {
            title: "RezerwujKort.pl - Rezerwację",
            datePickerIsOpen: false,
            previousDay: "",
            refreshReservation: "",
            priceListPopupForm: "",
            oneDayReservationData: {
                successful: false,
                status: '',
                message: '',
                data: []
            },
            restCalendarDataTemp: {
                successful: false,
                status: '',
                message: '',
                data: []
            }
        }
        this.openDatePicker = this.openDatePicker.bind(this);
        this.changDateByCalendar = this.changDateByCalendar.bind(this);
        this.changDateByBtn = this.changDateByBtn.bind(this);
        this.handlerRefreshReservation = this.handlerRefreshReservation.bind(this);
    }

    async componentDidMount() {

        document.title = "Rezerwacje - RezerwujKort.pl";
        registerLocale("pl", pl); // register it with the name you want

        if (this.props.match.params.id === "graphic_www") this.pageType = "RESERVATION_WWW";
        if (this.props.match.params.id === "graphic_app") this.pageType = "RESERVATION_APP";

        this.setState({ oneDayReservationData: await FechGetPriceGraphic(this.props.match.params.club, DateNowYyyyMmDd(), this.pageType) });
        //this.state.restCalendarDataTemp = await FechGetCalendar(this.props.match.params.club, this.postData);

        var cc = this.state.oneDayReservationData;
    }

    async handlerRefreshReservation(dateReservation) {

        this.setState({ oneDayReservationData: await FechGetPriceGraphic(this.props.match.params.club, dateReservation, this.pageType) });
    }

    openDatePicker() {

        this.setState({
            datePickerIsOpen: !this.state.datePickerIsOpen,
        });
    };

    async changDateByCalendar(event) {

        var dateReservation = DateNowYyyyMmDdFromDate(event);
        this.setState({ oneDayReservationData: await FechGetPriceGraphic(this.props.match.params.club, dateReservation, this.pageType) });
        var s = 44;
    }

    async changDateByBtn(dateReservation) {

        this.setState({ oneDayReservationData: await FechGetPriceGraphic(this.props.match.params.club, dateReservation, this.pageType) });
        var s = 44;
    }

    render() {

        var graphic;
        var graphicHeader;
        var currentDayDate = Date.now();
        var dayOfTheWeek = "";
        var dateString = "";
        var today = "";
        var nextDay = "";
        var previousDay = "";

        if (this.state.oneDayReservationData.successful) {

            graphic = <div>
                <Suspense fallback={<div>
                    <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
                </div>}>
                    <GraphicPrice
                        reservationData={this.state.oneDayReservationData}
                        club={this.props.match.params.club}
                        refresh={this.handlerRefreshReservation}
                    />
                </Suspense>
            </div>

            graphicHeader = <GraphicHeader
                reservationData={this.state.oneDayReservationData}
            />

            dayOfTheWeek = this.state.oneDayReservationData.data.dateOfWeek;
            dateString = this.state.oneDayReservationData.data.date;

            var date = new Date(dateString);
            date.setDate(date.getDate() + 1);
            var day = date.getDate();
            if (day < 10) {
                day = "0" + day;
            }
            var month = (date.getMonth() + 1);
            if (month < 10) {
                month = "0" + month;
            }
            nextDay = date.getFullYear() + "-" + month + "-" + day;

            date = new Date(dateString);
            date.setDate(date.getDate() - 1);
            day = date.getDate();
            if (day < 10) {
                day = "0" + day;
            }
            var month = (date.getMonth() + 1);
            if (month < 10) {
                month = "0" + month;
            }
            previousDay = date.getFullYear() + "-" + month + "-" + day;

            today = DateNowYyyyMmDd();

            currentDayDate = new Date(dateString);
        }

        var calendar = "";
        if (!this.state.restCalendarDataTemp.successful) {

            calendar = <div id="loader_div">
                <Spinner animation="border" />
            </div>

        } else {
            calendar = ""
        }



        return (

            <div>

                <div id="content">

                    <BeltReservationApp club={this.props.match.params.club} />

                    <BeltReservationPriceList
                        club={this.props.match.params.club}
                        pageType={this.props.match.params.id}
                    />

                    <br /><br />

   
                    <br /><br />

                    <div style={{ position: 'fixed', backgroundColor: 'white', width: '100%' }}>
                        <BeltReservationApp
                            club={this.props.match.params.club} />

                        <table class="table table-bordered table-condensed table-hover table-grafik m-bot-10">
                            <thead>
                                <tr class="bez_obramowania">
                                    <th width="3%">
                                        Dzień:
                                    </th>
                                    <th width="10%">
                                        {dayOfTheWeek}							</th>

                                    <th class="tytul" width="3%">
                                        Data:
                                    </th>
                                    <th width="10%">
                                        {dateString}
                                    </th>

                                    <th width="15%">
                                        <button onClick={() => this.changDateByBtn(previousDay)} class="reservationNextDayButton">Poprzedni dzień</button>
                                    </th>
                                    <th width="15%">
                                        <button onClick={() => this.changDateByBtn(today)} class="reservationNextDayButton">Dziś</button>
                                    </th>
                                    <th width="15%">
                                        <button onClick={() => this.changDateByBtn(nextDay)} class="reservationNextDayButton">Następny dzień</button>
                                    </th>
                                    <th width="15%">
                                        <div>
                                            <DatePicker
                                                calendarStartDay={1}
                                                locale="pl"
                                                selected={currentDayDate}
                                                onChange={this.changDateByCalendar}
                                                customInput={<ButtonCalendar />}
                                                placeholderText="Kalendarz"
                                            />
                                        </div>
                                    </th>

                                </tr>

                            </thead>
                        </table>
                        {graphicHeader}
                    </div>
                    <div style={{ paddingTop: '130px' }}>


                        {graphic}
                    </div>

                </div>
            </div>
        );
    }
}
export default PagePaymentsOnlineGraphic;
