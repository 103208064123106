import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';
import FetchGetDataWithAuth from "../services/FetchGetDataWithAuth";

//UTILS
import DateTimeNow from "../utils/DateTimeNow";
import AuthService from "../services/auth.service";

class SparingpartnerPage extends React.Component {

  state = {
    title: "Sparingpartnerzy - RezerwujKort.pl",
    currentUser: AuthService.getCurrentUser(),
    dataAdverbSparinParner: [],
    adverbCount: "",
    currentPage: "1",
    logInfo: "LogDebug __" + DateTimeNow() + "__ SparingpartnerPage "
  }

  async fechSpraingPartnerAdverb(url) {
    var data;
    var token = "";
    if (this.state.currentUser != null) {
      token = this.state.currentUser.accessToken;
    }

    data = await FetchGetDataWithAuth(url, token);
    if (data !== undefined) {
      this.setState({ dataAdverbSparinParner: data });
    }
  }

  componentDidMount() {

    document.title = this.state.title

    var url = "/sparingpartner/" + this.props.match.params.slug;
    this.fechSpraingPartnerAdverb(url)

  }

  render() {

    return (

      <div>

        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">
            <div class="main-subpage subpage-user">
              <div class="head">
                <img src={`/images/login.png`} />
                <p>Sparingpartnerzy</p>
              </div>
              <div class="main-subpage-content">
                <div id="user-box-left">
                  <div class="user-fullname">{this.state.dataAdverbSparinParner.userName} {' '}
                    </div>
                  <div class="user-photo">
                    {this.state.dataAdverbSparinParner.image ? (
                      <img src={`/images/sparinguploads/${this.state.dataAdverbSparinParner.image}`} style={{ maxHeight: 300, maxWidth: 365 }} /> //todo dodax max foto width="300" height="365"
                    ) : (
                      <img src={`/images/sparinguploads/default_sparing.png`} />  //todo dodax max foto width="300" height="365"
                    )}

                    <div class="user-status">
                      {this.state.dataAdverbSparinParner.gameLevel}
                    </div>
                  </div>

                  <div class="user-icon-location user-icon semibold">{this.state.dataAdverbSparinParner.userCity} {' '}
                    ({this.state.dataAdverbSparinParner.userProvince})</div>
                  <div class="user-icon-contact user-icon semibold">
                    {this.state.dataAdverbSparinParner.userPhone}
                  </div>
                  <div class="user-icon-mail user-icon semibold">
                    {this.state.dataAdverbSparinParner.userEmail}
                  </div>
                  <div style={{ clear: 'both' }}></div>
                </div>
                <div id="user-box-right">
                  <div class="user-box-right-nag">Sparingpartner o sobie:</div>
                  <div class="user-box-right-desc">{this.state.dataAdverbSparinParner.description}</div>
                  <div class="user-box-right-nag">Miejsce gry:</div>
                  <div class="user-box-right-desc">1. <a href={"/klub/" + this.state.dataAdverbSparinParner.clubWww}>{this.state.dataAdverbSparinParner.clubName}</a> {/*  //todo link do klubu  */}
                    {this.state.dataAdverbSparinParner.club2Name != this.state.dataAdverbSparinParner.club1Name ? (
                      <c><br /><a href="xxx">  {this.state.dataAdverbSparinParner.club2Name}</a></c>
                    ) : (
                      <a></a>
                    )}

                    {(this.state.dataAdverbSparinParner.club3Name != this.state.dataAdverbSparinParner.club1Name) &&
                      (this.state.dataAdverbSparinParner.club3Name != this.state.dataAdverbSparinParner.club2Name) ? (
                      <c><br /><a href="xxx">  {this.state.dataAdverbSparinParner.club3Name}</a></c>
                    ) : (
                      <a></a>
                    )}
                  </div>
                  <div class="user-box-right-nag">Preferowane terminy gry:</div>
                  <div class="user-box-right-desc">{this.state.dataAdverbSparinParner.preferredDates}</div>
                </div>
                <div style={{ clear: 'both' }}></div>
              </div>
            </div>
          </div>
        </div>

        <Footer />

      </div>

    );
  }
}

export default SparingpartnerPage;
