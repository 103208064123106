import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW"; 

export default function FechGetPlayer(club, league, slug) {
 
  var url = "/league_app/" + club + "/" + league + "/find_player_by_slug/" + slug;
  var data = FetchGetDataWithAuthNEW(url);

  return data;
}

