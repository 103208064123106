import FetchPostDataNoAuthNEW from "../services/FetchPostDataNoAuthNEW";

export default async function FechPostSparingBySearch(body) {

  var url = "/sparing_adverb_search_page";

  var data;
  if (body != undefined) {
    data = await FetchPostDataNoAuthNEW(url, body)
  }
  return data;
}
