import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';

const LoginFormNew = React.lazy(() => import('../forms/LoginFormNew'));

class LoginPage extends React.Component {

  state = {
    data: [],
    newsCount: "",
    currentPage: "1",
    title: "RezerwujKort.pl - Zaloguj"
  }

  componentDidMount() {
    document.title = this.state.title

  }

  render() {

    var msg = this.props.location.state;
    var info = "";
    if (msg === undefined) {
      info = "";
    } else {
      info = <div class="main-subpage-info">
        <p> {msg} </p>
      </div>;
    }

    var loginForm = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <LoginFormNew />
      </Suspense>
    </div>

    return (

      <div>

        <Belt check={"tak"} /> {/* //todo usun wylaczenie*/}

        {info}

        {loginForm}

        <Footer />

      </div>
    );
  }
}
export default LoginPage;
