import FetchGetDataNoAuthNEW from "../services/FetchGetDataNoAuthNEW"; 

export default function FechGetClubLongDescBySlug(slug) {
 
  var url = "/club_long/" + slug; 
  var data;
  if(slug != undefined && slug != "undefined"){
    data = FetchGetDataNoAuthNEW(url);
  }
  return data;
}

