import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';
import PageCounterNew from '../components/PageCounterNew';
import BeltClub from '../components/BeltClub';

import FechPostTrainerBySearch from "../fechData/FechPostTrainerBySearch";
import FechGetClubLongDescBySlug from "../fechData/FechGetClubLongDescBySlug";

import ParseLinkToArray from "../utils/ParseLinkToArray"
import queryString from 'query-string'

import "react-datepicker/dist/react-datepicker.css";

import DateTimeNow from "../utils/DateTimeNow";

const AdverbTrainerShort = React.lazy(() => import('../components/AdverbTrainerShort'));

class ClubTrainersPage extends React.Component {

  searchTrainerItem = {
    club: '',
    page: ''
  };
  urlDeafulValues = [];
  urlValue = "";

  constructor(props) {
    super(props);
    this.printAdresse = this.printAdresse.bind(this);
  }

  state = {
    logInfo: "LogDebug __" + DateTimeNow() + "__ ClubTrainersPage ",
    startDate: new Date(),
    rowsCount: "6",
    restClubData: {
      successful: false,
      status: '',
      message: '',
      data: []
    },
    restTrainerAdverbsData: {
      successful: false,
      status: '',
      message: '',
      data: []
    }
  }

  printAdresse() {
    return { __html: this.state.restClubData.data.descriptionShort };
  }

  async componentDidMount() {

    const { location: { search } } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.searchTrainerItem = await ParseLinkToArray(this.urlValue,
      this.searchTrainerItem,
      this.urlDeafulValues);

    this.searchTrainerItem.club = this.props.match.params.slug;

    this.state.restClubData = await FechGetClubLongDescBySlug(this.props.match.params.slug);
    document.title = this.state.restClubData.data.name + " Trenerzy - RezerwujKort.pl";

    this.setState({
      restTrainerAdverbsData: await FechPostTrainerBySearch(this.searchTrainerItem)
    });

  }


  render() {

    var adverbs = "";
    if (this.state.restTrainerAdverbsData.successful &&
      typeof this.state.restTrainerAdverbsData.data.trainerResources.map === 'function') {
      adverbs = this.state.restTrainerAdverbsData.data.trainerResources.map(adverbOne =>
        <div>
          <Suspense fallback={<div>
            <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
          </div>}>
            <AdverbTrainerShort
              adverb={adverbOne} />
          </Suspense>
        </div>);
    }

    var noAdverb = [];
    if (this.state.restTrainerAdverbsData.data.totalRecordsCount === 0) {
      noAdverb.push(<div class='tenis-content-info'>Brak ogłoszeń</div>);
    }

    var beltClub;
    if (this.state.restClubData.successful) {
      var cur = "trainer";
      beltClub = <BeltClub
        dataClubInfo={this.state.restClubData.data}
        currentPage={cur} />
    }

    const urlValues = new Map();
    urlValues.set('page', "replace");

    var pageCounter;
    if (this.state.restTrainerAdverbsData.successful) {
      pageCounter = <PageCounterNew
        elementCount={this.state.restTrainerAdverbsData.data.totalRecordsCount}
        urlValues={urlValues}
        urlDeafulValues={this.urlDeafulValues}
        elementOnPage={this.state.rowsCount}
        currentPage={parseInt(this.searchTrainerItem.page) + 1}
        searchName={"trainers_search"}
        linkTo={"klub/" + this.props.match.params.slug + "/trenerzy"}
      />
    }


    return (

      <div>

        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">

            <div class="main-subpage tennis-center">
              <div class="head">
                <img src={`/images/login.png`} alt="" />
                <p>Korty Tenisowe</p>
              </div>
              <div class="main-subpage-content">

                {beltClub}

                <div class="tenis-center-content">
                  <ss dangerouslySetInnerHTML={this.printAdresse()} />

                  <div class="tennis-center-coach">

                    {adverbs}
                  </div>

                  {pageCounter}

                </div>
              </div>

            </div>

          </div>

          <div >

          </div>

        </div>
        <div style={{ clear: 'both' }}>
        </div>

        <Footer />

      </div>
    );
  }
}

export default ClubTrainersPage;