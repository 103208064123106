import React from 'react';

import Belt from '../components/Belt';
import Footer from '../components/Footer';
import BeltLogged from '../components/BeltLogged';


class InfoPage extends React.Component {

  state = {
    data: [],
    newsCount: "",
    currentPage: "1",
    title: "RezerwujKort.pl - Wylogowany"
  }

  render() {

    var msg = this.props.location.state;
    var info = "";
    if (msg === undefined) {
      info = "";
    } else {
      info = <p> {msg} </p>;
    }

    return (

      <div>

        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">
            <div class="main-subpage-info">
              <p> {info} </p>
            </div>
          </div>
        </div>

        <Footer />

      </div>
    );
  }
}
export default InfoPage;
