import React from 'react';

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';
import FechGetAppReservationMkt from "../APP_RESERVATION_FECH_DATA/FechGetAppReservationMkt";
import Footer from '../components/Footer';

//UTILS
import DateTimeNow from "../utils/DateTimeNow";


class AppReservationMkt extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "RezerwujKort.pl - Profil",
            item: this.emptyItem,
            restReservationData: {
                successful: false,
                status: '',
                message: '',
                data: []
            },
            isError: {
                passwordCurrent: '',
                passwordNew: '',
                passwordNewConfirm: '',
                editResponse: ''
            }
        }

    }

    async componentDidMount() {
        document.title = this.state.title

        var response = await FechGetAppReservationMkt(this.props.match.params.data);

        this.setState({ restReservationData: response });
    }

    render() {

        var response;
        if (this.state.restReservationData.successful) {
            response = this.state.restReservationData.data.label;
        }

        var responsddde;
        return (
            <>
                {response}

            </>
        );
    }
}

export default AppReservationMkt;
