import React, { Suspense } from "react";
import { Spinner } from "react-bootstrap";

import Belt from "../components/Belt";
import BeltLogged from "../components/BeltLogged";

import Footer from "../components/Footer";
import PageCounterNew from "../components/PageCounterNew";
import FechGetSuperAdminRoles from "../leagueAppFechData/FechGetSuperAdminRoles";
import DateTimeNow from "../utils/DateTimeNow";
import FechGetClubPlayerList from "../leagueAppFechData/FechGetClubPlayerList";

import ParseLinkToArray from "../utils/ParseLinkToArray";
import queryString from "query-string";

const BeltLeague = React.lazy(() =>
  import("../leagueAppComponents/BeltLeague")
);
const TabAllClubPlayers = React.lazy(() =>
  import("../leagueAppTab/TabAllClubPlayers")
);

class PageLeagueAllPlayers extends React.Component {
  searchPlayerItem = {
    page: "",
  };
  urlDeafulValues = [];
  urlValue = "";

  state = {
    title: "Liga - Ranking - RezerwujKort.pl",
    logInfo: "LogDebug __" + DateTimeNow() + "__ PageLeagueAdminAddUser ",
    rowsCount: "6",
    restSparingAdverbsData: {
      successful: false,
      status: "",
      message: "",
      data: [],
    },
    restLeagueAdminData: {
      successful: false,
      status: "",
      message: "",
      data: [],
    },
    restPlayerData: {
      successful: false,
      status: "",
      message: "",
      data: [],
    },
  };

  async componentDidMount() {
    document.title = this.state.title;

    const {
      location: { search },
    } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.searchPlayerItem = await ParseLinkToArray(
      this.urlValue,
      this.searchPlayerItem,
      this.urlDeafulValues
    );

    this.setState({
      restLeagueAdminData: await FechGetSuperAdminRoles(
        this.props.match.params.club
      ),
    });

    this.setState({
      restPlayerData: await FechGetClubPlayerList(
        this.props.match.params.club,
        this.props.match.params.league,
        this.searchPlayerItem.page
      ),
    });
  }

  render() {
    const urlValues = new Map();
    urlValues.set("page", "replace");

    var beltLeague;
    if (this.state.restLeagueAdminData.successful) {
      beltLeague = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <BeltLeague
              currentPage={"admin"}
              club={this.props.match.params.club}
              league={this.props.match.params.league}
            />
          </Suspense>
        </div>
      );
    }

    var tabPlayers;
    var pageCounter;
    if (this.state.restPlayerData.successful) {
      tabPlayers = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <TabAllClubPlayers
              club={this.props.match.params.club}
              league={this.props.match.params.league}
              data={this.state.restPlayerData.data}
            />
          </Suspense>
        </div>
      );

      pageCounter = (
        <PageCounterNew
          elementCount={this.state.restPlayerData.data.totalRecordsCount}
          urlValues={urlValues}
          urlDeafulValues={this.urlDeafulValues}
          elementOnPage={this.state.rowsCount}
          currentPage={parseInt(this.searchPlayerItem.page) + 1}
          searchName={"players_search"}
          linkTo={
            "liga/" +
            this.props.match.params.club +
            "/" +
            this.props.match.params.league +
            "/admin/all_club_players"
          }
        />
      );
    }

    return (
      <div>
        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">
            <div class="main-subpage">
              {beltLeague}

              <br />
              <b>
                Poniżej znajduje się lista wszystkich uczestników którzy chociaż
                raz brali udział w rozgrywkach.
              </b>
              <br />
              <br />

              {/* {tabPlayers}

                            {pageCounter} */}

              <div></div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default PageLeagueAllPlayers;
