import React, { Suspense } from "react";

import { Spinner, Button } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl"; // the locale you want

import FechGetOneDayReservations from "../APP_RESERVATION_FECH_DATA/FechGetOneDayReservations";

import BeltReservationApp from "../APP_RESERVATION_COMPONENTS/BeltReservationApp";
import ButtonCalendar from "./ButtonCalendar";

import GraphicHeader from "../APP_RESERVATION_COMPONENTS/GraphicHeader";
import DateNowYyyyMmDd from "../utils/DateNowYyyyMmDd";
import queryString from "query-string";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

//UTILS
import DateTimeNow from "../utils/DateTimeNow";
import DateNowYyyyMmDdFromDate from "../utils/DateNowYyyyMmDdFromDate";

const Graphic = React.lazy(() =>
  import("../APP_RESERVATION_COMPONENTS/Graphic")
);

let plLocate = require("moment/locale/pl");
moment.locale("pl", plLocate);

//KLASA WYSWIETLA SZCZEGOLY KONKRETNYCH ZAJEC SZKOLKI
class PageReservationCalendar extends React.Component {
  urlValue = "";
  page = null;
  sportId = null;

  constructor(props) {
    super(props);

    this.state = {
      logInfo: "LogDebug __" + DateTimeNow() + "__ PageReservationCalendar ",
      title: "RezerwujKort.pl - Rezerwację",
      dateReservation: "",
      datePickerIsOpen: false,
      previousDay: "",
      refreshReservation: "",
      loading: false,
      oneDayReservationData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
    };
    this.openDatePicker = this.openDatePicker.bind(this);
    this.changDateByCalendar = this.changDateByCalendar.bind(this);
    this.changDateByBtn = this.changDateByBtn.bind(this);
    this.handlerRefreshReservation = this.handlerRefreshReservation.bind(this);
  }

  async componentDidMount() {
    document.title = "Rezerwacje - RezerwujKort.pl";
    registerLocale("pl", pl); // register it with the name you want
    const {
      location: { search },
    } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    var dateReservation = DateNowYyyyMmDd();

    if (this.urlValue.day) {
      this.setState({ dateReservation: this.urlValue.day });
      dateReservation = this.urlValue.day;
    } else {
      this.setState({ dateReservation: dateReservation });
    }

    this.setState({
      loading: true,
    });
    var oneDayReservationDataTemp = await FechGetOneDayReservations(
      this.props.match.params.club,
      dateReservation,
      this.page,
      this.sportId
    );

    this.setState({
      oneDayReservationData: oneDayReservationDataTemp,
      loading: false,
      refreshReservation: 0,
    });
  }

  async handlerRefreshReservation(dateReservation) {
    var oneDayReservationDataTemp = await FechGetOneDayReservations(
      this.props.match.params.club,
      dateReservation,
      this.page,
      this.sportId
    );
    this.setState({
      oneDayReservationData: oneDayReservationDataTemp,
    });
  }

  openDatePicker() {
    this.setState({
      datePickerIsOpen: !this.state.datePickerIsOpen,
    });
  }

  async changDateByCalendar(event) {
    this.setState({
      loading: true,
    });
    var dateReservation = DateNowYyyyMmDdFromDate(event);
    var oneDayReservationDataTemp = await FechGetOneDayReservations(
      this.props.match.params.club,
      dateReservation,
      this.page,
      this.sportId
    );
    this.setState({
      oneDayReservationData: oneDayReservationDataTemp,
      loading: false,
    });
  }

  async changDateByBtn(dateReservation) {
    this.setState({
      loading: true,
    });
    var oneDayReservationDataTemp = await FechGetOneDayReservations(
      this.props.match.params.club,
      dateReservation,
      this.page,
      this.sportId
    );
    this.setState({
      oneDayReservationData: oneDayReservationDataTemp,
      loading: false,
    });
  }

  async changSportBtn(dateReservation, page, sportId) {
    this.setState({
      loading: true,
    });
    this.page = page;
    this.sportId = sportId;
    var oneDayReservationDataTemp = await FechGetOneDayReservations(
      this.props.match.params.club,
      dateReservation,
      this.page,
      this.sportId
    );
    this.setState({
      oneDayReservationData: oneDayReservationDataTemp,
      loading: false,
    });
  }

  render() {
    const { loading } = this.state;
    var graphic;
    var graphicHeader;
    var currentDayDate = Date.now();
    var dayOfTheWeek = "";
    var dateString = "";
    var today = "";
    var nextDay = "";
    var previousDay = "";

    if (this.state.oneDayReservationData.successful) {
      graphic = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <Graphic
              reservationData={this.state.oneDayReservationData}
              club={this.props.match.params.club}
              refresh={this.handlerRefreshReservation}
            />
          </Suspense>
        </div>
      );

      graphicHeader = (
        <GraphicHeader reservationData={this.state.oneDayReservationData} />
      );

      dayOfTheWeek = this.state.oneDayReservationData.data.dateOfWeek;
      dateString = this.state.oneDayReservationData.data.date;

      var date = new Date(dateString);
      date.setDate(date.getDate() + 1);
      var day = date.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      var month = date.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      nextDay = date.getFullYear() + "-" + month + "-" + day;

      date = new Date(dateString);
      date.setDate(date.getDate() - 1);
      day = date.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      var month = date.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      previousDay = date.getFullYear() + "-" + month + "-" + day;

      today = DateNowYyyyMmDd();

      currentDayDate = new Date(dateString);

      var btnPage_1;
      var btnPage_2;
      var btnPage_3;
      if (this.state.oneDayReservationData.data.pages.length === 2) {
        var style_1 = "reservationSportButton";
        var style_2 = "reservationSportButton";
        if (this.page === null) style_1 = "reservationSportActiveButton";
        if (this.page === this.state.oneDayReservationData.data.pages[0].page)
          style_1 = "reservationSportActiveButton";
        if (this.page === this.state.oneDayReservationData.data.pages[1].page)
          style_2 = "reservationSportActiveButton";
        btnPage_1 = (
          <th width="10%">
            <button
              onClick={() =>
                this.changSportBtn(
                  dateString,
                  this.state.oneDayReservationData.data.pages[0].page,
                  this.state.oneDayReservationData.data.pages[0].sportId
                )
              }
              class={style_1}
            >
              {this.state.oneDayReservationData.data.pages[0].sportBtn}
            </button>
          </th>
        );
        btnPage_2 = (
          <th width="10%">
            <button
              onClick={() =>
                this.changSportBtn(
                  dateString,
                  this.state.oneDayReservationData.data.pages[1].page,
                  this.state.oneDayReservationData.data.pages[1].sportId
                )
              }
              class={style_2}
            >
              {this.state.oneDayReservationData.data.pages[1].sportBtn}
            </button>
          </th>
        );
      }
      if (this.state.oneDayReservationData.data.pages.length === 3) {
        var style_1 = "reservationSportButton";
        var style_2 = "reservationSportButton";
        var style_3 = "reservationSportButton";
        if (this.page === null) style_1 = "reservationSportActiveButton";
        if (
          this.page === this.state.oneDayReservationData.data.pages[0].page &&
          this.sportId ===
            this.state.oneDayReservationData.data.pages[0].sportId
        )
          style_1 = "reservationSportActiveButton";
        if (
          this.page === this.state.oneDayReservationData.data.pages[1].page &&
          this.sportId ===
            this.state.oneDayReservationData.data.pages[1].sportId
        )
          style_2 = "reservationSportActiveButton";
        if (
          this.page === this.state.oneDayReservationData.data.pages[2].page &&
          this.sportId ===
            this.state.oneDayReservationData.data.pages[2].sportId
        )
          style_3 = "reservationSportActiveButton";
        btnPage_1 = (
          <th width="10%">
            <button
              onClick={() =>
                this.changSportBtn(
                  dateString,
                  this.state.oneDayReservationData.data.pages[0].page,
                  this.state.oneDayReservationData.data.pages[0].sportId
                )
              }
              class={style_1}
            >
              {this.state.oneDayReservationData.data.pages[0].sportBtn}
            </button>
          </th>
        );
        btnPage_2 = (
          <th width="10%">
            <button
              onClick={() =>
                this.changSportBtn(
                  dateString,
                  this.state.oneDayReservationData.data.pages[1].page,
                  this.state.oneDayReservationData.data.pages[1].sportId
                )
              }
              class={style_2}
            >
              {this.state.oneDayReservationData.data.pages[1].sportBtn}
            </button>
          </th>
        );
        btnPage_3 = (
          <th width="10%">
            <button
              onClick={() =>
                this.changSportBtn(
                  dateString,
                  this.state.oneDayReservationData.data.pages[2].page,
                  this.state.oneDayReservationData.data.pages[2].sportId
                )
              }
              class={style_3}
            >
              {this.state.oneDayReservationData.data.pages[2].sportBtn}
            </button>
          </th>
        );
      }
    }

    return (
      <div>
        <div id="content">
          <div
            style={{
              position: "fixed",
              backgroundColor: "white",
              width: "100%",
            }}
          >
            <BeltReservationApp club={this.props.match.params.club} />

            <table class="table table-bordered table-condensed table-hover table-grafik m-bot-10">
              <thead>
                <tr class="bez_obramowania">
                  <th width="3%">Dzień:</th>
                  <th width="10%">{dayOfTheWeek} </th>

                  <th class="tytul" width="3%">
                    Data:
                  </th>
                  <th width="10%">{dateString}</th>
                  {btnPage_1}
                  {btnPage_2}
                  {btnPage_3}
                  <th width="15%">
                    <Button
                      onClick={() =>
                        !loading && this.changDateByBtn(previousDay)
                      }
                      className={`reservationNextDayButton ${
                        loading ? "disabled" : ""
                      }`}
                      disabled={loading}
                      style={{ border: "none" }}
                    >
                      Poprzedni dzień
                    </Button>
                  </th>
                  <th width="15%">
                    <Button
                      onClick={() => !loading && this.changDateByBtn(today)}
                      className={`reservationNextDayButton ${
                        loading ? "disabled" : ""
                      }`}
                      disabled={loading}
                      style={{ border: "none" }}
                    >
                      Dziś
                    </Button>
                  </th>

                  <th width="15%">
                    <Button
                      onClick={() => !loading && this.changDateByBtn(nextDay)}
                      className={`reservationNextDayButton ${
                        loading ? "disabled" : ""
                      }`}
                      disabled={loading}
                      style={{ border: "none" }}
                    >
                      Następny dzień
                    </Button>
                  </th>
                  <th width="15%">
                    <div>
                      <DatePicker
                        calendarStartDay={1}
                        locale="pl"
                        selected={currentDayDate}
                        onChange={this.changDateByCalendar}
                        customInput={<ButtonCalendar />}
                        placeholderText="Kalendarz"
                        style={{ border: "none" }}
                        disabled={loading}
                      />
                    </div>
                  </th>
                  <th width="5%">
                    {loading ? (
                      <div style={{ position: "relative", height: "30px" }}>
                        {loading && (
                          <div
                            style={{
                              position: "absolute",
                              top: "30%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              maxHeight: "80%",
                              maxWidth: "80%",
                            }}
                          >
                            <Spinner animation="border" />
                          </div>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </th>
                </tr>
              </thead>
            </table>
            {graphicHeader}
          </div>

          <div style={{ paddingTop: "130px" }}>{graphic}</div>
        </div>
      </div>
    );
  }
}
export default PageReservationCalendar;
