import DateTimeNow from "../utils/DateTimeNow";
import axios from "axios";


export default async function FetchGetDataNoAuthNEW(url) {

	var restData = {
		successful: false,
		status: '',
		message: '',
		data: []
	};

	url = "/rest" + url;

	var logInfo = "LogDebug __" + DateTimeNow() + "__ FetchGetDataNoAuthNEW ";

	await fetch(url, {
		method: 'GET',
		withCredentials: true,
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json'
		}
	})
		.then(async response => {
			const isJson = response.headers.get('content-type')?.includes('application/json');
			let data = [];
			data = isJson && await response.json();

			if (response.status != 200) {
				//console.log(logInfo + "response.ok:::::: " + response.status);	
				restData.successful = false;
				restData.status = response.status;
				if (data !== undefined) {
					restData.message = data.message;
				} else {
					restData.message = "";
				}
			} else {
				//console.log(logInfo + "response.NIEok:::::: ");	
				restData.successful = true;
				restData.message = data.message;
				restData.status = response.status;
				restData.data = data;
			}
			//console.log(logInfo + "restData111:::::: " + JSON.stringify(restData));	
			//console.log(logInfo + "response:::::: " + JSON.stringify(response));
 

		}, error => {
			const resMessage =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();

			restData.successful = false;
			restData.message = resMessage;

			this.setState({
				successful: false,
				message: resMessage
			});
		});
	//console.log(logInfo + 'restData1 ::::', restData); //todo log
	return restData;
}



