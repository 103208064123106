import React from 'react';

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';
import CreatLinkSerch from "../utils/CreatLinkSerch"
import BeltClub from '../components/BeltClub';

import FechGetClubLongDescBySlug from "../fechData/FechGetClubLongDescBySlug";
import FechGetActiveLeagueBySlug from "../leagueAppFechData/FechGetActiveLeagueBySlug";
import FechGetSuperAdminRoles from "../leagueAppFechData/FechGetSuperAdminRoles";
import "react-datepicker/dist/react-datepicker.css";
import AdverbLeagueShort from "../leagueAppComponents/AdverbLeagueShort";

import DateTimeNow from "../utils/DateTimeNow";


class ClubLeaguePage extends React.Component {

  constructor(props) {
    super(props);
    this.printAdresse = this.printAdresse.bind(this);
  }

  state = {
    title: "Liga - RezerwujKort.pl",
    logInfo: "LogDebug __" + DateTimeNow() + "__ ClubTrainersPage ",
    restClubData: {
      successful: false,
      status: '',
      message: '',
      data: []
    },
    restLeaugsData: {
      successful: false,
      status: '',
      message: '',
      data: []
    },
    restAdminData: {
      successful: false,
      status: '',
      message: '',
      data: []
    },
    restLeagueAdminData: {
      successful: false,
      status: '',
      message: '',
      data: []
    }
  }

  printAdresse() {
    return { __html: this.state.restClubData.data.descriptionShort };
  }

  async componentDidMount() {

    document.title = this.state.title
    const deafulValues = [];

    const values = new Map()
    values.set('club', this.props.match.params.slug)
    if (this.props.match.params.page != undefined) {
      values.set('page', this.props.match.params.page)
    }

    var findLeagueUrl = CreatLinkSerch("", values, deafulValues);

    this.setState({
      restLeagueAdminData: await FechGetSuperAdminRoles(this.props.match.params.slug)
    });

    this.setState({
      restLeaugsData: await FechGetActiveLeagueBySlug(findLeagueUrl)
    });

    this.setState({
      restClubData: await FechGetClubLongDescBySlug(this.props.match.params.slug)
    });
    document.title = this.state.restClubData.data.name + " Liga - RezerwujKort.pl";
  }

  render() {


    var noAdverb = [];
    if (this.state.restLeaugsData.successful &&
      typeof this.state.restLeaugsData.data.leagues.map === 'function' &&
      this.state.restLeaugsData.data.totalRecordsCount === 0) {
      noAdverb.push(<div class='tenis-content-info'>Brak Lig</div>);
    } else {
      noAdverb.push(<div class=''><br />Wybierz Ligę:</div>);

    }

    var adverbs = "";
    if (this.state.restLeaugsData.successful &&
      typeof this.state.restLeaugsData.data.leagues.map === 'function') {
      //noAdverb.push(<div class='tenis-content-info'>Brak Lig</div>);
      adverbs = this.state.restLeaugsData.data.leagues.map(adverbOne => 
      <AdverbLeagueShort 
      club ={this.props.match.params.slug}
      adverb={adverbOne} />);
    }

    var beltClub;
    if (this.state.restClubData.successful) {
      var cur = "league";
      beltClub = <BeltClub
        dataClubInfo={this.state.restClubData.data}
        currentPage={cur} />
    }

    var admin;
    if (this.state.restLeagueAdminData.successful &&
      this.state.restLeagueAdminData.data) {
      admin = <div class="" >
        <br /><br />

        <a href="" class="btnUtworzLige">   utwórz nową lige w formule challenge</a>
        <br /><br />
        <a href="" class="btnUtworzLige">   utwórz nową lige w formule wielu kolejek</a>
        <br /><br />
      </div>
    }

    return (

      <div>

        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">


            <div class="main-subpage tennis-center">
              <div class="head">
                <img src={`/images/login.png`} alt="" />
                <p>Korty Tenisowe</p>
              </div>
              <div class="main-subpage-content">

                {beltClub}

                <div class="tenis-center-content">
                  <ss dangerouslySetInnerHTML={this.printAdresse()} />


                </div>
                {noAdverb}
                {adverbs}

              </div>

              {admin}
            </div>


          </div>



          <div >


          </div>


        </div>
        <div style={{ clear: 'both' }}>
        </div>

        <Footer />

      </div>
    );
  }
}

export default ClubLeaguePage;
