import React, { Suspense } from "react";
import { Spinner } from "react-bootstrap";

import Belt from "../components/Belt";
import BeltLogged from "../components/BeltLogged";

import Footer from "../components/Footer";

import FechGetSuperAdminRoles from "../leagueAppFechData/FechGetSuperAdminRoles";
import FechGetPlayer from "../leagueAppFechData/FechGetPlayer";
import DateTimeNow from "../utils/DateTimeNow";

import ParseLinkToArray from "../utils/ParseLinkToArray";
import queryString from "query-string";

const EditPlayerLeague = React.lazy(() =>
  import("../leagueAppFroms/EditPlayerLeague")
);
const BeltLeague = React.lazy(() =>
  import("../leagueAppComponents/BeltLeague")
);

class PageLeagueAdminEditUser extends React.Component {
  searchSparingItem = {
    page: "",
  };
  urlDeafulValues = [];
  urlValue = "";

  state = {
    title: "Liga - Ranking - RezerwujKort.pl",
    logInfo: "LogDebug __" + DateTimeNow() + "__ PageLeagueAdminAddUser ",
    rowsCount: "6",
    restPlayerData: {
      successful: false,
      status: "",
      message: "",
      data: [],
    },
    restLeagueAdminData: {
      successful: false,
      status: "",
      message: "",
      data: [],
    },
  };

  async componentDidMount() {
    document.title = this.state.title;

    const {
      location: { search },
    } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.searchSparingItem = await ParseLinkToArray(
      this.urlValue,
      this.searchSparingItem,
      this.urlDeafulValues
    );

    this.setState({
      restLeagueAdminData: await FechGetSuperAdminRoles(
        this.props.match.params.club
      ),
    });

    this.setState({
      restPlayerData: await FechGetPlayer(
        this.props.match.params.club,
        this.props.match.params.league,
        this.props.match.params.mameSlug
      ),
    });
  }

  render() {
    var beltLeague;
    if (this.state.restLeagueAdminData.successful) {
      beltLeague = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <BeltLeague
              currentPage={"admin"}
              club={this.props.match.params.club}
              league={this.props.match.params.league}
            />
          </Suspense>
        </div>
      );
    }

    var editUserForm;
    if (this.state.restPlayerData.successful) {
      editUserForm = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <EditPlayerLeague
              club={this.props.match.params.club}
              league={this.props.match.params.league}
              player={this.state.restPlayerData}
            />
          </Suspense>
        </div>
      );
    }

    return (
      <div>
        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">
            <div class="main-subpage">
              {beltLeague}
              <br />
              <br />
              <b>Dodanie nowgo uczestnika ligi</b>
              <br />

              {/* <div style={{ 'width': '80%', 'margin': '30px' }}>

                                {editUserForm}
                            </div> */}

              <div></div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default PageLeagueAdminEditUser;
