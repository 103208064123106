import FetchPostDataWithAuthNEW from "../services/FetchPostDataWithAuthNEW";

export default async function FechPostClassAllBySearch(club, body) {

    var url = "/class_app/" + club + "/class_search";

    var data = await FetchPostDataWithAuthNEW(url, body)
    //console.log(" pobiera dane restLessonsData 1" + JSON.stringify(data));

    return data;
}

