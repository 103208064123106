import queryString from 'query-string'

//fields - tablica wszystkich skladkikow linka
//deafulValues - nazwy pol domyslne czyli np "wybierz" jesli na nie trafi to nie daje do linka
export default function CreatLinkSerch(url, fields, deafulValues) {

    var start = false;
    var separatorStart = "?"
    var separatorNext = "&"
    if (fields !== undefined) {
        for (const [key, value] of fields.entries()) {
            if (!deafulValues.includes(value) && !!value && Boolean(value)) {
                if (!start) {
                    url = url + separatorStart + key + "=" + value
                    start = true;
                } else {
                    url = url + separatorNext + key + "=" + value
                }
            }
        }
    }

    return url;
}

