import React from 'react';

import Belt from '../components/Belt';
import Footer from '../components/Footer';

import AuthService from "../services/auth.service";

class PageError500 extends React.Component {

  state = {
    currentUser: AuthService.getCurrentUser(),
    data: [],
    currentPage: "1",
    rowsCount: "25",
    title: "RezerwujKort.pl - Szkółki tenisowe"
  }

  componentDidMount() {
    document.title = this.state.title;

  }

  render() {


    return (

      <div>
        <Belt />

        <div id="content">
          <div class="container">

            <br /><br />

            <br /><br />

            <br /><br />
            <b>Błąd 500:</b>
            <br /><br />


          </div>
        </div>

        <Footer />

      </div>
    );
  }
}
export default PageError500;
