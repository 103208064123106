import React, { Suspense } from 'react';

import { Spinner, Button } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl"; // the locale you want

import FechGetAppReservationUsers from "../APP_RESERVATION_FECH_DATA/FechGetAppReservationUsers";

import PageCounterNew from '../components/PageCounterNew';
import BeltReservationApp from '../APP_RESERVATION_COMPONENTS/BeltReservationApp';

import queryString from 'query-string'
import moment from 'moment'
import ParseLinkToArray from "../utils/ParseLinkToArray"

const AddAdminPopupForm = React.lazy(() => import('../APP_RESERVATION_FORM_POPUP/AddAdminPopupForm'));
const TabAllReservationAppUsers = React.lazy(() => import('../APP_RESERVATION_COMPONENTS/TabAllReservationAppUsers'));

let plLocate = require('moment/locale/pl');
moment.locale('pl', plLocate)

//KLASA WYSWIETLA SZCZEGOLY KONKRETNYCH ZAJEC SZKOLKI
class PageReservationUsers extends React.Component {

    TRAINER_SELECT_DESC = "Wybierz trenera";
    DATE_SELECT_DESC = "Wybierz datę";
    CLASS_SELECT_DESC = "Wybierz szkółke";

    urlDeafulValues = [this.TRAINER_SELECT_DESC, this.CLASS_SELECT_DESC, this.DATE_SELECT_DESC];

    urlValue = "";

    postData = {
        dateStart: '',
        dateStop: '',
        trainer: '',
        classId: '',
        className: '',
        status: '',
        page: '0'
    };

    constructor(props) {
        super(props);

        this.state = {
            title: "RezerwujKort.pl - Rezerwację",
            datePickerIsOpen: false,
            previousDay: "",
            refreshReservation: "",
            addAdminPopupForm: "",
            restUsersData: {
                successful: false,
                status: '',
                message: '',
                data: []
            }
        }
        this.handlerRefreshAdmins = this.handlerRefreshAdmins.bind(this);
    }

    async componentDidMount() {

        document.title = "Rezerwacje - RezerwujKort.pl";
        registerLocale("pl", pl); // register it with the name you want
        const { location: { search } } = this.props;
        this.state.search = search;
        this.urlValue = queryString.parse(this.state.search);

        this.postData = await ParseLinkToArray(this.urlValue, this.postData, this.urlDeafulValues);

        this.setState({ restUsersData: await FechGetAppReservationUsers(this.props.match.params.club) });

        var cc = this.state.restUsersData;

    }

    async handlerRefreshAdmins() {

        this.setState({ restUsersData: await FechGetAppReservationUsers(this.props.match.params.club) });
    }

    async addAdminForm() {

        this.setState({
            addAdminPopupForm: <div>
                <Suspense fallback={<div>
                    <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
                </div>}>
                    <AddAdminPopupForm
                        club={this.props.match.params.club}
                        refresh={this.handlerRefreshAdmins}
                    />
                </Suspense>
            </div>
        });
    }


    render() {

        var loader = "";
        if (!this.state.restUsersData.successful) {
            loader = <div id="loader_div">
                <Spinner animation="border" />
            </div>
            totalRecords = this.state.restUsersData.data
        }

        var totalRecords = "";
        var tabAllReservation = "";
        var pageCounter = "";
        if (this.state.restUsersData.successful) {
            totalRecords = this.state.restUsersData.data.totalRecordsCount

            tabAllReservation = <div>
                <Suspense fallback={<div>
                    <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
                </div>}>
                    <TabAllReservationAppUsers
                        club={this.props.match.params.club}
                        restLessonsData={this.state.restUsersData.data.usersResources}
                        refresh={this.handlerRefreshAdmins} />
                </Suspense>
            </div>

            const urlValues = new Map();
            urlValues.set('dateStart', this.postData.dateStart);
            urlValues.set('dateStop', this.postData.dateStop);
            urlValues.set('trainer', this.postData.trainer);
            urlValues.set('classId', this.postData.classId);
            urlValues.set('className', this.postData.className);
            urlValues.set('status', this.postData.status);
            urlValues.set('page', "replace");

            pageCounter = <PageCounterNew
                elementCount={totalRecords}
                urlValues={urlValues}
                urlDeafulValues={this.urlDeafulValues}
                elementOnPage={this.state.restUsersData.data.recordsOnPage}
                currentPage={parseInt(this.postData.page) + 1}
                searchName={"search"}
                linkTo={"reservation_app/" + this.props.match.params.club + "/administratorzy"}
            />
        }

        return (

            <div>

                <div id="content">

                    <BeltReservationApp club={this.props.match.params.club} />



                    <br /><br />
                    <Button href="#" variant="success" size="lg" style={{ 'margin-left': '20px' }}
                        onClick={() => this.addAdminForm()}>
                        Dodaj administratora
                    </Button>

                    <br /><br />
                    <b>Lista wszystkich administratorów: ({totalRecords}):</b>
                    <br /><br />

                    {this.state.addAdminPopupForm}

                    {loader}

                    {tabAllReservation}

                    {pageCounter}


                </div>
            </div>
        );
    }
}
export default PageReservationUsers;
