import React from 'react';

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';
import FechGetLoggedUserData from '../fechData/FechGetLoggedUserData';

class ProfileInfoPage extends React.Component {

    state = {
        data: [],
        newsCount: "",
        currentPage: "1",
        title: "RezerwujKort.pl - Profil",
        restUserData: {
            successful: false,
            status: '',
            message: '',
            data: []
        }
    }

    async componentDidMount() {

        document.title = this.state.title

        this.setState({
            restUserData: await FechGetLoggedUserData()
        });
    }

    render() {
        return (

            <div>

                <Belt /> {/* //todo usun wylaczenie*/}

                <BeltLogged />

                <div id="content">
                    <div class="container">
                        <div class="main-subpage-info">

                            <div class="main-pp">
                                Twoje dane
                            </div><br />
                            <div class="prof-inf">
                                <div class="prof-inf-in">
                                    Imie:
                                </div>
                                <div class="prof-inf-in">
                                    {this.state.restUserData.data.name}
                                </div>
                                <div class="prof-inf-in">
                                    Nazwisko:
                                </div>
                                <div class="prof-inf-in">
                                {this.state.restUserData.data.surname}
                                </div>
                                <div class="prof-inf-in">
                                    Telefon:
                                </div>
                                <div class="prof-inf-in">
                                {this.state.restUserData.data.phone}
                                </div>
                                <div class="prof-inf-in">
                                    Email:
                                </div>
                                <div class="prof-inf-in">
                                {this.state.restUserData.data.email}
                                </div>
                                {/* <div class="prof-inf-in">
                                    Województwo:
                                </div>
                                <div class="prof-inf-in">
                                {this.state.restUserData.data.province}
                                </div>
                                <div class="prof-inf-in">
                                    Miasto:
                                </div>
                                <div class="prof-inf-in">
                                {this.state.restUserData.data.city}
                                </div> */}

                            </div>


                        </div>
                    </div>
                </div>

                <Footer />

            </div>
        );
    }
}
export default ProfileInfoPage;
