import FetchGetDataNoAuthNEW from "../services/FetchGetDataNoAuthNEW"; 

export default function FechGetActiveLeagueBySlug(search) {
 
  var url = "/league_app/find_enabled_league" + search;
  var data = FetchGetDataNoAuthNEW(url);

  return data;
}

