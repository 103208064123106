import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';

import FechGetSuperAdminRoles from "../leagueAppFechData/FechGetSuperAdminRoles";
import FechGetChallenge from "../leagueAppFechData/FechGetChallenge";
import DateTimeNow from "../utils/DateTimeNow";

import ParseLinkToArray from "../utils/ParseLinkToArray";
import queryString from 'query-string';

const BeltLeague = React.lazy(() => import('../leagueAppComponents/BeltLeague'));
const TabChallenge = React.lazy(() => import('../leagueAppTab/TabChallenge'));

class PageLeagueShowChallenge extends React.Component {

    searchSparingItem = {
        page: ''
    };
    urlDeafulValues = [];
    urlValue = "";

    state = {
        title: "Liga - Mecze - RezerwujKort.pl",
        logInfo: "LogDebug __" + DateTimeNow() + "__ PageLeagueGame ",
        rowsCount: "6",
        restSparingAdverbsData: {
            successful: false,
            status: '',
            message: '',
            data: []
        },
        restLeagueAdminData: {
            successful: false,
            status: '',
            message: '',
            data: []
        },
        restChallengeData: {
            successful: false,
            status: '',
            message: '',
            data: []
        }
    }

    async componentDidMount() {

        document.title = this.state.title

        const { location: { search } } = this.props;
        this.state.search = search;
        this.urlValue = queryString.parse(this.state.search);

        this.searchSparingItem = await ParseLinkToArray(this.urlValue, this.searchSparingItem, this.urlDeafulValues);

        this.setState({
            restLeagueAdminData: await FechGetSuperAdminRoles(this.props.match.params.club)
        });

        this.setState({
            restChallengeData: await FechGetChallenge(this.props.match.params.club, this.props.match.params.league, this.props.match.params.id) //FechGetGames
        });

    }

    render() {

        const urlValues = new Map();
        urlValues.set('page', "replace");

        var beltLeague;
        if (this.state.restLeagueAdminData.successful) {
            beltLeague = <div>
                <Suspense fallback={<div>
                    <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
                </div>}>
                    <BeltLeague
                        currentPage={"challenge"}
                        club={this.props.match.params.club}
                        league={this.props.match.params.league}
                    />
                </Suspense>
            </div>
        }

        var challengeTab = "";
        if (this.state.restChallengeData.successful) {

            challengeTab = <div>
                <Suspense fallback={<div>
                    <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
                </div>}>
                    <TabChallenge
                        club={this.props.match.params.club}
                        league={this.props.match.params.league}
                        gamesData={this.state.restChallengeData}
                        admin={this.state.restLeagueAdminData}
                    />
                </Suspense>
            </div>
        }

        return (

            <div>

                <Belt />

                <BeltLogged />

                <div id="content">
                    <div class="container">
                        <div class="main-subpage">

                            {beltLeague}

                            {challengeTab}

                        </div>

                    </div>
                </div>

                <Footer />

            </div>
        );
    }
}

export default PageLeagueShowChallenge;
