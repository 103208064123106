import React from 'react';

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';


class ProfileClubsPage extends React.Component {
  
  state = {
      data: [],
      newsCount: "",
      currentPage: "1",
      title:"RezerwujKort.pl - Profil"
    }

  componentDidMount() {
    document.title = this.state.title

    }


  render() {
    return (

      <div>

      <Belt />

      <BeltLogged />

      <div id="content">
        <div class="container">
            <div class="main-subpage-info">
                
                <div class = "main-pp">
                    Twoje dane 
                </div><br/>
                <div class = "prof-inf">
                    <div class = "prof-inf-in">
                        Imie:
                    </div>
                    <div class = "prof-inf-in">
                        {/* {{ user.imie }} */}
                    </div>
                    <div class = "prof-inf-in">
                        Nazwisko:
                    </div>
                    <div class = "prof-inf-in">
                         {/* {{ user.nazwisko }} */}
                    </div>
                    <div class = "prof-inf-in">
                        Telefon:
                    </div>
                    <div class = "prof-inf-in">
                         {/* {{ user.telefon }} */}
                    </div>
                    <div class = "prof-inf-in">
                        Email:
                    </div>
                    <div class = "prof-inf-in">
                         {/* {{ user.email }} */}
                    </div>
                    <div class = "prof-inf-in">
                        Województwo:
                    </div>
                    <div class = "prof-inf-in">
                         {/* {{ woj[0].wojewodztwo }} */}
                    </div>
                    <div class = "prof-inf-in">
                        Miasto:
                    </div>
                    <div class = "prof-inf-in">
                         {/* {{ miasto[0].miasto }} */}
                    </div>
                    <div class = "prof-inf-in">
                        Wiek:
                    </div>
                    <div class = "prof-inf-in">
                         {/* {{ user.rokUrodzenia }} */}
                    </div>
                    <div class = "prof-inf-in">
                        Płeć:
                    </div>
                    <div class = "prof-inf-in">
                         {/* {% if user.plec == 'm' %} */}
                             Mężczyzna
                         {/* {% else %} */}
                             Kobieta
                         {/*  {% endif %}  */}
                    </div>
                </div>

                
            </div>
        </div>
    </div>

      <Footer />

    </div>
    );
  }
}
export default ProfileClubsPage;
