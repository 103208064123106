import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';

import AuthService from "../services/auth.service";

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const AddStudent = React.lazy(() => import('../classAppForms/AddStudent'));

class PageStudentAdd extends React.Component {

  state = {
    currentUser: AuthService.getCurrentUser(),
    rowsCount: "25",
    title: "RezerwujKort.pl - Szkółki tenisowe"
  }


  async componentDidMount() {

    document.title = this.state.title;

  }

  render() {

    var beltClassApp = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp club={this.props.match.params.club} />
      </Suspense>
    </div>

    var addStudent = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}><AddStudent club={this.props.match.params.club} />
      </Suspense>
    </div>

    return (

      <div>
        {beltClassApp}

        <div id="content">
          <div class="container">

            <br /><br />
            <b>Dodaj uczestnika zajęć</b>
            <br /><br />

            {addStudent}

            <br /><br />
          </div>
        </div>

      </div>
    );
  }
}
export default PageStudentAdd;
