import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';
import moment from "moment";

import TimeTable from "react-timetable-events";
import { Nav } from "react-bootstrap";

import { EventPreviewProps } from '../classAppComponents/types.ts'
import format from "date-fns/format";
import FechGetClassToGraphicsNew from '../classAppFechData/FechGetClassToGraphicsNew'

import DateTimeNow from "../utils/DateTimeNow";

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));

class PageClassGraphics extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      logInfo: "LogDebug __" + DateTimeNow() + "__ PageCalendar ",
      title: "RezerwujKort.pl - Profil",
      DEFAULT_HOURS_INTERVAL: { from: 5, to: 24 },
      restClassGraphicsData: {
        successful: false,
        status: '',
        message: '',
        data: []
      },
      restClassGraphicsDataNew: {
        successful: false,
        status: '',
        message: '',
        data: []
      }
    }
  }

  async componentDidMount() {

    this.setState({ restClassGraphicsDataNew: await FechGetClassToGraphicsNew(this.props.match.params.club, this.props.match.params.dayOfWeek) });

  }


  render() {

    var EventPreview = ({
      event,
      defaultAttributes,
      classNames,
    }: EventPreviewProps) => {
      return (
        <div
          {...defaultAttributes}
          style={{
            ...defaultAttributes.style,
            background: event.color,
            'font-color': 'red',
            'font-weight': 'bold',
            'border-width': '1px',
            'border-style':'solid',
            'border-color': 'black'
          }}
          title={event.name}
          key={event.id}
          className={`${classNames.event}`}
        ><a href={("/class_app/" + this.props.match.params.club + "/class_show/" + event.id)} style={{
          'margin': 'auto',
          'color': "black",
        }}>
            <span className={classNames.event_info}>   {event.name}  </span><br />
            <span className={classNames.event_info}>
              {format(event.startTime, "HH:mm")} - {format(event.endTime, "HH:mm")}
            </span></a>
        </div>
      );
    };


    if (this.state.restClassGraphicsDataNew.data !== undefined && this.state.restClassGraphicsDataNew.successful) {

      var eventsNew = this.state.restClassGraphicsDataNew.data.events;

      for (var key in eventsNew) {
        for (var key1 in eventsNew[key]) {
          eventsNew[key][key1]['endTime'] = moment(eventsNew[key][key1]['endTime']).toDate()
          eventsNew[key][key1]['startTime'] = moment(eventsNew[key][key1]['startTime']).toDate()
        }
      }

      var timetable1 = <TimeTable
        events={eventsNew}
        renderEvent={EventPreview}
        hoursInterval={this.state.DEFAULT_HOURS_INTERVAL}
        style={{ height: '900px', border: '1px solid black' }}
      />;
    }

    var beltClassApp = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp club={this.props.match.params.club} />
      </Suspense>
    </div>

    return (

      <div>
        {beltClassApp}

        {/* <div id="content">
          <div class="container"> */}
            <div class="main-subpage-info">

            {/* {beltClassApp} */}

            <br /><br /><br /><br />

            Grafik zajęć

            <br /><br /><br /><br />

            {/* <div style={{'align':'right','border': '3px solid green', 'width': '50%'}}>dde */}
            <Nav variant="pills"

              defaultActiveKey={"/class_app/" + this.props.match.params.club + "/class_graphics/" + this.props.match.params.dayOfWeek} >
              <Nav.Item>
                <Nav.Link href={"/class_app/" + this.props.match.params.club + "/class_graphics/1"}>Poniedziałek</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href={"/class_app/" + this.props.match.params.club + "/class_graphics/2"}>Wtorek</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href={"/class_app/" + this.props.match.params.club + "/class_graphics/3"}>Środa</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href={"/class_app/" + this.props.match.params.club + "/class_graphics/4"}>Czwartek</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href={"/class_app/" + this.props.match.params.club + "/class_graphics/5"}>Piątek</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href={"/class_app/" + this.props.match.params.club + "/class_graphics/6"}>Sobota</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href={"/class_app/" + this.props.match.params.club + "/class_graphics/7"}>Niedziela</Nav.Link>
              </Nav.Item>
            </Nav>
            {/* </div> */}
            <br />


            {timetable1}

            </div>
          {/* </div>
        </div> */}


      </div>
    );
  }
}
export default PageClassGraphics;
