import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';

const RegisterFormNew = React.lazy(() => import('../forms/RegisterFormNew'));

class RegisterPage extends React.Component {

  state = {
    title: "RezerwujKort.pl - Rejestracja"
  }

  componentDidMount() {
    document.title = this.state.title

  }


  render() {

    var registerFormNew = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <RegisterFormNew />
      </Suspense>
    </div>

    return (

      <div>

        <Belt />

        {registerFormNew}

        <Footer />

      </div>
    );
  }
}
export default RegisterPage;
