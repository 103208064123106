import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW";

export default async function FechGetStudentAll(club) {

    var url = "/class_app/" + club + "/studentsAll";

    var data;
    data = FetchGetDataWithAuthNEW(url);
    //console.log(" pobiera dane restLessonsData 1" + JSON.stringify(data));

    return data;
}
