import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';

import FechGetSuperAdminRoles from "../leagueAppFechData/FechGetSuperAdminRoles";
import FechGetGame from "../leagueAppFechData/FechGetGame";
import DateTimeNow from "../utils/DateTimeNow";

import ParseLinkToArray from "../utils/ParseLinkToArray";
import queryString from 'query-string';

const BeltLeague = React.lazy(() => import('../leagueAppComponents/BeltLeague'));

class PageLeagueShowGame extends React.Component {

    searchSparingItem = {
        page: ''
    };
    urlDeafulValues = [];
    urlValue = "";

    state = {
        title: "Liga - Mecze - RezerwujKort.pl",
        logInfo: "LogDebug __" + DateTimeNow() + "__ PageLeagueGame ",
        rowsCount: "6",
        restLeagueAdminData: {
            successful: false,
            status: '',
            message: '',
            data: []
        },
        restGameData: {
            successful: false,
            status: '',
            message: '',
            data: []
        }
    }

    async componentDidMount() {

        document.title = this.state.title

        const { location: { search } } = this.props;
        this.state.search = search;
        this.urlValue = queryString.parse(this.state.search);

        this.searchSparingItem = await ParseLinkToArray(this.urlValue,
            this.searchSparingItem,
            this.urlDeafulValues);

        this.setState({
            restLeagueAdminData: await FechGetSuperAdminRoles(this.props.match.params.club)
        });

        this.setState({
            restGameData: await FechGetGame(this.props.match.params.club,
                this.props.match.params.league,
                this.props.match.params.id) //FechGetGames
        });

    }

    render() {

        const urlValues = new Map();
        urlValues.set('page', "replace");

        var beltLeague;
        if (this.state.restLeagueAdminData.successful) {
            beltLeague = <div>
                <Suspense fallback={<div>
                    <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
                </div>}>
                    <BeltLeague
                        currentPage={"game"}
                        club={this.props.match.params.club}
                        league={this.props.match.params.league}
                    />
                </Suspense>
            </div>
        }

        return (

            <div>

                <Belt />

                <BeltLogged />

                <div id="content">
                    <div class="container">
                        <div class="main-subpage">

                            {beltLeague}

                            <div class="main-subpage subpage-user">
                                <div style={{ 'clear': 'both' }}></div>
                                <div id='wyzwanie'>
                                    <div class='box_wyzwanie' id='box_wyzywajacy'>
                                        <div class="user-fullname">cxcxc<br /><i class="pod_st">

                                            Wyzywający:
                                        </i></div>
                                        <div class="user-photo"><img src={`/images/default_profile.png`} alt="" /></div>

                                    </div>

                                    <div class='box_wyzwanie' id='box_opis'>

                                    </div>

                                    <div class='box_wyzwanie' id='box_wyzywany'>
                                        <div class="user-fullname">ssss<br /><i class="pod_st">

                                            Wyzywany:
                                        </i></div>

                                        <div class="user-photo"><img src={`/images/default_profile.png`} alt="" /></div>
                                    </div>
                                </div>
                            </div>
                            <div class="stat_hdr1">
                                <div class="">
                                    <span>Szczegóły wyzwania</span>
                                </div>
                            </div>


                            <table class="footable" data-page-size="3">
                                <thead>

                                </thead>
                                <tbody>
                                    <tr>

                                        <td>
                                            Wyzywający:
                                        </td>
                                        <td>
                                            <a href=""> {this.state.restGameData.data.name} sssssssssssss</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Wyzywany:
                                        </td>
                                        <td>
                                            <a href="">aktualneWyzwanie[0].wyzywanyImie   aktualneWyzwanie[0].wyzywanyNazwisko </a>
                                        </td>
                                    </tr>

                                    <tr>

                                        <td>
                                            Utworzone:
                                        </td>
                                        <td>
                                            aktualneWyzwanie[0].ktoUtworzylData
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Termin spotkania:

                                        </td>
                                        <td>
                                            aktualneWyzwanie[0].dataGry  aktualneWyzwanie[0].godzGry
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Kort:

                                        </td>
                                        <td>
                                            aktualneWyzwanie[0].kort
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Wynik:
                                        </td>
                                        <td>
                                            aktualneWyzwanie[0].wynik

                                            --
                                            wynik1 / wynik2

                                        </td>
                                    </tr>


                                    <tr>
                                        <td>
                                            Punkty Set 1:
                                        </td>
                                        <td>
                                            aktualneWyzwanie[0]['graczWyzywajacySet1Punkty'] / aktualneWyzwanie[0]['graczWyzywanySet1Punkty']

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Punkty Set 2:
                                        </td>
                                        <td>
                                            aktualneWyzwanie[0]['graczWyzywajacySet2Punkty'] / aktualneWyzwanie[0]['graczWyzywanySet2Punkty']

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Punkty Set 3:
                                        </td>
                                        <td>
                                            aktualneWyzwanie[0]['graczWyzywajacySet3Punkty'] /aktualneWyzwanie[0]['graczWyzywanySet3Punkty']
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            Zwycięzca:
                                        </td>
                                        <td>
                                            zwyciezca

                                        </td>

                                    </tr>

                                    <tr>
                                        <td>
                                            Kto utworzył wyzwanie:
                                        </td>
                                        <td>
                                            ktoUtworzyl

                                        </td>

                                    </tr>


                                    <tr>
                                        <td>
                                            Kto zakończył wyzwanie:
                                        </td>
                                        <td>
                                            ktoZakonczyl

                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            Data zakończenia wyzwania:
                                        </td>
                                        <td>
                                            aktualneWyzwanie[0].zakonczoneData

                                        </td>

                                    </tr>

                                </tbody>
                            </table>

                        </div>

                    </div>
                </div>

                <Footer />

            </div>
        );
    }
}

export default PageLeagueShowGame;
