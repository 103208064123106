export default function DateNowYyyyMmDdPlusDay(addDays) {
    
    var today = new Date();
    //today = today.addDays(addDays)
    today.setDate(today.getDate() + addDays)
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;

	return today;
}

