import React from 'react';

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';
import Footer from '../components/Footer';

const CookiePage = () => {
  return (

    <div>

      <Belt />

      <BeltLogged />

      <div id="content">
        <div class="container">

          <div class="main-subpage" style={{'font-size':'11px'}}>
            <h3 style={{'margin-left': '35%', 'font-size':'11px'}}>Polityka cookies</h3> <br />

            <br />
            Serwis może stosować pliki cookies, tj. informacje zapisywane przez serwery
            na urządzeniu końcowym użytkownika, które serwery mogą odczytać przy
            każdorazowym połączeniu się z tego urządzenia końcowego.<br />
            Pliki cookies (tzw. ciasteczka) stanowią dane informatyczne, w szczególności pliki
            tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i
            przeznaczone są do korzystania ze stron internetowych Serwisu. Pliki cookies
            zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania
            ich na urządzeniu końcowym oraz unikalny numer.<br />
            Wydawcą Serwisu, w którym mogą być używane pliki cookies, jest ESOR - Elektroniczne
            Systemy Obsługi Rezerwacji z siedzibą w 
            {/* {#przy ul. Traugutta 27, 27-200#}  */}
            Starachowicach.<br />
            Pliki cookies mogą być wykorzystywane w celu:<br />
            1. dostosowania zawartości stron internetowych Serwisu do preferencji Użytkownika
            oraz optymalizacji korzystania ze stron internetowych; w szczególności pliki
            te pozwalają rozpoznać urządzenie Użytkownika Serwisu i odpowiednio wyświetlić
            stronę internetową, dostosowaną do jego indywidualnych potrzeb;<br />
            2. tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy
            Serwisu korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury
            i zawartości;<br />
            3. utrzymanie sesji Użytkownika Serwisu (po zalogowaniu), dzięki której Użytkownik nie
            musi na każdej podstronie Serwisu ponownie wpisywać loginu i hasła;<br />
            4. dostarczania Użytkownikowi Serwisu treści reklamowych bardziej dostosowanych do ich
            zainteresowań.
            <br /><br />
            W ramach Serwisu stosowane są następujące rodzaje plików cookies:<br />
            1. „niezbędne” pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach
            Serwisu, np. uwierzytelniające pliki cookies wykorzystywane do usług wymagających
            uwierzytelniania w ramach Serwisu;<br />
            2. pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania
            nadużyć w zakresie uwierzytelniania w ramach Serwisu;<br />
            3. „wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o sposobie
            korzystania ze stron internetowych Serwisu;<br />
            4. „funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez Użytkownika
            ustawień i personalizację interfejsu Użytkownika, np. w zakresie wybranego języka lub
            regionu, z którego pochodzi Użytkownik, rozmiaru czcionki, wyglądu strony internetowej
            itp.;<br />
            5. „reklamowe” pliki cookies, umożliwiające dostarczanie Użytkownikom treści reklamowych
            bardziej dostosowanych do ich zainteresowań.<br />
            W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych
            (przeglądarka internetowa) domyślnie dopuszcza przechowywanie plików cookies w
            urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać w każdym czasie
            zmiany ustawień dotyczących plików cookies. Ustawienia te mogą zostać zmienione w
            szczególności w taki sposób, aby blokować automatyczną obsługę plików cookies w
            ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym
            zamieszczeniu w urządzeniu Użytkownika Serwisu. Szczegółowe informacje o możliwości
            i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania
            (przeglądarki internetowej).<br />
            Wydawca Serwisu informuje, że ograniczenia stosowania plików cookies mogą wpłynąć
            na niektóre funkcjonalności dostępne na stronach internetowych Serwisu.<br />
            Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu i wykorzystywane
            mogą być również przez współpracujących z wydawcą Serwisu reklamodawców oraz partnerów.<br />
            Więcej informacji na temat plików cookies dostępnych jest pod adresem
            http://wszystkoociasteczkach.pl/ lub w sekcji „Pomoc” w menu przeglądarki internetowej.
          </div>
        </div>
      </div>
      <Footer />

    </div>

  );
}

export default CookiePage;
