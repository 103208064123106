import React from 'react';

import Belt from '../components/Belt';
import Footer from '../components/Footer';

import DateTimeNow from "../utils/DateTimeNow";

import LoginFormNew from '../forms/LoginFormNew';

import FechGetConfirmRegistration from "../fechData/FechGetConfirmRegistration";
import { Spinner } from "react-bootstrap";

class RegisterConfirmPage extends React.Component {

  constructor(props) {
    super(props);
  }

  state = {
    logInfo: "LogDebug __" + DateTimeNow() + "__ RegisterConfirmPage ",
    currentPage: "1",
    title: "RezerwujKort.pl - Zaloguj",
    restData: {
      successful: false,
      status: '',
      message: ''
    }
  }

  async componentDidMount() {

    document.title = this.state.title
    this.setState({ restData: await FechGetConfirmRegistration(this.props.match.params.token, ) });

  }


  render() {

    var msg;
    if (this.state.restData.successful === true) {
      msg = "Twoje konto jest już aktywne. Możesz się zalogować";
    } else if (this.state.restData.message != '') {
      msg = "Konto nie zostało aktywowane. Błędny adres e-mail i/lub kod aktywacyjny.";
    }

    var loader = "";
    if(!this.state.restData.successful){
      loader = <div id="loader_div">
        <Spinner animation="border" />
        </div>
    }

    return (

      <div>

        <Belt />

        <div class="main-subpage-info">
          <p> {msg}</p>
        </div>

        {this.state.restData.successful ? (
          <LoginFormNew />
        ) : (
          <c></c>
        )}

        {loader}

        <Footer />

      </div>
    );
  }
}
export default RegisterConfirmPage;
