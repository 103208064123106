import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';
import AuthService from "../services/auth.service";
import { Link } from 'react-router-dom'
import { Button } from "react-bootstrap";

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const TabAllClassType = React.lazy(() => import('../classAppComponents/TabAllClassType'));

class PagePriceType extends React.Component {

    state = {
        currentUser: AuthService.getCurrentUser(),
        title: "RezerwujKort.pl - Szkółki tenisowe"
    }

    async componentDidMount() {
        document.title = this.state.title;
    }


    render() {

        var msg = this.props.location.state;
        var actionMsg = "";
        if (msg === undefined) {
            actionMsg = "";
        } else {
            actionMsg = <div class="tenis-center-content-info"> + {msg} + </div>;
        }

        var beltClassApp = <div>
            <Suspense fallback={<div>
                <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
            </div>}>
                <BeltClassNewApp club={this.props.match.params.club} />
            </Suspense>
        </div>

        var tabAllClassType = <div>
            <Suspense fallback={<div>
                <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
            </div>}><TabAllClassType
                    club={this.props.match.params.club}
                />
            </Suspense>
        </div>

        return (
            <div>
                {beltClassApp}

                <div id="content">

                
                <div style={{ paddingTop: '55px'}}>
                    <br /><br />

                    {actionMsg}

                    <p><b>Ustawienia Płatności za szkółki tenisowe </b></p>
                    <br />

                    <Button href={"/class_app/" + this.props.match.params.club + "/admin/price_type_add"} variant="primary" >
                        Dodaj nowy rodzaj szkółki
                    </Button>&nbsp;&nbsp;


                    <br /><br />
                    <b>Lista wszystkich rodzajów szkółek w klubie:</b>
                    <br /><br />

                    {tabAllClassType}

                </div>
</div>

            </div>
        );
    }
}
export default PagePriceType;
