import React, { Suspense } from "react";

import { Spinner, Button } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl"; // the locale you want

import FechGetPromotionsBySearch from "../APP_RESERVATION_FECH_DATA/FechGetPromotionsBySearch";
import PageCounterNew from "../components/PageCounterNew";
import BeltReservationApp from "../APP_RESERVATION_COMPONENTS/BeltReservationApp";
import ConnectPromotionsPopupForm from "../APP_RESERVATION_FORM_POPUP/PromotionPopupForm/ConnectPromotionsPopupForm";

import queryString from "query-string";
import moment from "moment";
import ParseLinkToArray from "../utils/ParseLinkToArray";

const SearchPromotions = React.lazy(() =>
  import("../APP_RESERVATION_FORMS/SearchPromotions")
);
const AddPromotionPopupForm = React.lazy(() =>
  import(
    "../APP_RESERVATION_FORM_POPUP/PromotionPopupForm/AddPromotionPopupForm"
  )
);
const TabAllPromotions = React.lazy(() =>
  import("../APP_RESERVATION_COMPONENTS/TabAllPromotions")
);

let plLocate = require("moment/locale/pl");
moment.locale("pl", plLocate);

//KLASA WYSWIETLA SZCZEGOLY KONKRETNYCH ZAJEC SZKOLKI
class PagePromotions extends React.Component {
  TRAINER_SELECT_DESC = "Wybierz trenera";
  DATE_SELECT_DESC = "Wybierz datę";
  CLASS_SELECT_DESC = "Wybierz szkółke";

  urlDeafulValues = [
    this.TRAINER_SELECT_DESC,
    this.CLASS_SELECT_DESC,
    this.DATE_SELECT_DESC,
  ];

  urlValue = "";

  postData = {
    deleted: "",
    enabled: "",
    page: "1",
  };

  constructor(props) {
    super(props);

    this.state = {
      title: "RezerwujKort.pl - Promocje",
      datePickerIsOpen: false,
      previousDay: "",
      refreshReservation: "",
      addTicketPopupForm: "",
      restPromotionsData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
    };
    this.handlerRefreshReservation = this.handlerRefreshReservation.bind(this);
  }

  async componentDidMount() {
    document.title = "Promocje - RezerwujKort.pl";
    registerLocale("pl", pl);
    const {
      location: { search },
    } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);
    this.postData.deleted = this.urlValue.deleted;
    this.postData.enabled = this.urlValue.enabled;
    this.postData.page = this.urlValue.page;

    var response = await FechGetPromotionsBySearch(
      this.props.match.params.club,
      search
    );
    this.setState({
      restPromotionsData: response,
    });
    var dd = 332;
  }

  async addClientForm() {
    this.setState({
      addTicketPopupForm: (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <AddPromotionPopupForm
              dateReservation={""}
              club={this.props.match.params.club}
              refresh={this.handlerRefreshReservation}
            />
          </Suspense>
        </div>
      ),
    });
  }

  async connectPromotionsPopupForm() {
    this.setState({
      addTicketPopupForm: (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <ConnectPromotionsPopupForm
              dateReservation={""}
              club={this.props.match.params.club}
              refresh={this.handlerRefreshReservation}
            />
          </Suspense>
        </div>
      ),
    });
  }

  async handlerRefreshReservation() {
    //this.componentDidMount();
    const {
      location: { search },
    } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);
    this.postData = await ParseLinkToArray(
      this.urlValue,
      this.postData,
      this.urlDeafulValues
    );

    this.setState({
      restPromotionsData: await FechGetPromotionsBySearch(
        this.props.match.params.club,
        search
      ),
    });
  }

  render() {
    var loader = "";
    if (!this.state.restPromotionsData.successful) {
      loader = (
        <div id="loader_div">
          <Spinner animation="border" />
        </div>
      );
      totalRecords = 0;
    }

    var totalRecords = "";
    var tabAllTickets = "";
    var pageCounter = "";
    var searchPanel = "";
    if (this.state.restPromotionsData.successful) {
      totalRecords = this.state.restPromotionsData.data.totalElements;

      tabAllTickets = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <TabAllPromotions
              club={this.props.match.params.club}
              restPromotionsData={this.state.restPromotionsData.data.content}
              refresh={this.handlerRefreshReservation}
            />
          </Suspense>
        </div>
      );

      const urlValues = new Map();
      urlValues.set("enabled", this.postData.enabled);
      urlValues.set("deleted", this.postData.deleted);
      urlValues.set("page", "replace");

      pageCounter = (
        <PageCounterNew
          elementCount={this.state.restPromotionsData.data.totalElements}
          urlValues={urlValues}
          urlDeafulValues={this.urlDeafulValues}
          elementOnPage={this.state.restPromotionsData.data.size}
          currentPage={parseInt(this.postData.page)}
          searchName={"search"}
          linkTo={
            "reservation_app/" + this.props.match.params.club + "/promocje"
          }
        />
      );

      searchPanel = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <SearchPromotions
              urlValue={this.urlValue}
              club={this.props.match.params.club}
            />
          </Suspense>
        </div>
      );
    }

    return (
      <div>
        <div id="content">
          <BeltReservationApp club={this.props.match.params.club} />

          {searchPanel}

          <Button
            href="#"
            variant="success"
            size="lg"
            style={{ "margin-left": "20px" }}
            onClick={() => this.addClientForm()}
          >
            Dodaj promocje
          </Button>

          <Button
            href="#"
            variant="success"
            size="lg"
            style={{ "margin-left": "20px" }}
            onClick={() => this.connectPromotionsPopupForm()}
          >
            Łączenie promocji
          </Button>

          <br />
          <br />
          <b>Lista wszystkich promocji: ({totalRecords}):</b>
          <br />
          <br />

          {this.state.addTicketPopupForm}
          {loader}

          {tabAllTickets}

          {pageCounter}
        </div>
      </div>
    );
  }
}
export default PagePromotions;
