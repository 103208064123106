import React, { Suspense } from "react";
import { Spinner } from "react-bootstrap";

import Belt from "../components/Belt";
import BeltLogged from "../components/BeltLogged";

import Footer from "../components/Footer";
import { Form } from "react-bootstrap";
import DatesBetweenDates from "../utils/DatesBetweenDates";
import FechGetAdminRoles from "../leagueAppFechData/FechGetAdminRoles";
import FechGetRanking from "../leagueAppFechData/FechGetRanking";
import FechCreateGame from "../leagueAppFechData/FechCreateGame";
import history from "../components/history";

const BeltLeague = React.lazy(() =>
  import("../leagueAppComponents/BeltLeague")
);

class PageLeagueAdminCreateMatch extends React.Component {
  NOTIFICATION_TITLE_OK = "Sukces!";
  NOTIFICATION_TITLE_NOT_OK = "Błąd!";
  NOTIFICATION_MSG_OK = "Wyzwanie zostało utworzone!";
  NOTIFICATION_MSG_NOT_OK = "Wystąpił błąd! Spróbój ponownie!";

  hourDefaultValue = "Wybierz godzinę";
  dateDefaultValue = "Wybierz datę";

  emptyItem = {
    clubSlug: this.props.match.params.club,
    leagueSlug: this.props.match.params.league,
    playerChallengingSlug: this.props.match.params.mameSlug,
    playerChallengedSlug: "",
    gameDate: "",
    gameTime: "",
    court: "",
    mail: "",
    infoPlayerChallenging: "",
    infoPlayerChallenged: "",
  };
  urlDeafulValues = [];
  urlValue = "";

  constructor(props) {
    super(props);

    this.state = {
      title: "Liga - Ranking - RezerwujKort.pl",
      item: this.emptyItem,
      hours: [
        "00:00",
        "00:30",
        "01:00",
        "01:30",
        "02:00",
        "02:30",
        "03:00",
        "03:30",
        "04:00",
        "04:30",
        "05:00",
        "05:30",
        "06:00",
        "06:30",
        "07:00",
        "07:30",
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30",
      ],
      restRankingData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
      restLeagueAdminData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
      restCreateGameResponseData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
      isError: {
        playerChallengedSlug: "",
        gameDate: "",
        gameTime: "",
        court: "",
        infoPlayerChallenging: "",
        infoPlayerChallenged: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    document.title = this.state.title;

    this.setState({
      restLeagueAdminData: await FechGetAdminRoles(
        this.props.match.params.club
      ),
    });

    this.setState({
      restRankingData: await FechGetRanking(
        this.props.match.params.club,
        this.props.match.params.league
      ),
    });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let item = { ...this.state.item };

    item[name] = value;

    if (name === "mail") {
      item.mail = event.target.checked;
    }
    if (name === "description") {
      item.infoPlayerChallenging = value;
      item.infoPlayerChallenged = value;
    }

    this.setState({ item: item });
  }

  async handleSubmit(event) {
    event.preventDefault();

    this.setState({
      restCreateGameResponseData: await FechCreateGame(
        this.props.match.params.club,
        this.props.match.params.league,
        this.state.item
      ),
    });

    var notification = ["", "", ""];
    if (this.state.restCreateGameResponseData.successful) {
      notification = [
        "success",
        this.NOTIFICATION_TITLE_OK,
        this.NOTIFICATION_MSG_OK,
      ];
    } else {
      notification = [
        "error",
        this.NOTIFICATION_TITLE_NOT_OK,
        this.NOTIFICATION_MSG_NOT_OK,
      ];
    }

    history.push(
      "/liga/" +
        this.props.match.params.club +
        "/" +
        this.props.match.params.league +
        "/admin",
      notification
    );
    window.location.reload();
  }

  render() {
    const today = new Date();

    const threedaysFromNow = new Date(today);
    threedaysFromNow.setDate(threedaysFromNow.getDate() + 20);

    var allDays = DatesBetweenDates(today, threedaysFromNow);
    const urlValues = new Map();
    urlValues.set("page", "replace");

    var beltLeague;
    if (this.state.restLeagueAdminData.successful) {
      beltLeague = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <BeltLeague
              currentPage={"admin"}
              club={this.props.match.params.club}
              league={this.props.match.params.league}
            />
          </Suspense>
        </div>
      );
    }

    var playerList;

    if (
      this.state.restRankingData.data.players &&
      typeof this.state.restRankingData.data.players.map === "function"
    ) {
      var counter = 0;
      playerList = this.state.restRankingData.data.players.map((player) => (
        <option value={player.nameSlug}>
          {player.name} + {player.surname} (Pozycja: {player.rankingPosition})
        </option>
      ));
    } else {
      playerList = "";
    }

    var test = 0;
    var playerName = "";
    var currentRamkPosition = 0;
    if (
      this.state.restRankingData.data.players &&
      typeof this.state.restRankingData.data.players.map === "function"
    ) {
      test = this.state.restRankingData.data.players.find(
        (player) => player.nameSlug == this.props.match.params.mameSlug
      );
      currentRamkPosition = test.rankingPosition;
      playerName = test.name + " " + test.surname;
    }

    const { isError } = this.state;

    return (
      <div>
        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">
            <div class="main-subpage">
              {beltLeague}

              <div class="wyzwanieNowe">
                {/* <form class="form" action="" method="post" onSubmit={this.handleSubmit}>
                                    <h1>Nowe wyzwanie</h1>
                                    <div class="content">
                                        <span style={{ 'color': 'red' }}>


                                        </span>

                                        <div id="section0" >
                                        </div>

                                        <div id="section1" >
                                            <div class="field"><label for="Sugerowanadatagry">

                                                Osoba wyzywająca: {playerName} (Aktualna pozycja w rankingu: {currentRamkPosition})</label>

                                            </div>
                                            <div class="field"><label for="Sugerowanadatagry">

                                                Osoba wyzywana:<br />
                                                <Form as="select"
                                                    className={isError.playerChallengedSlug.length > 0 ? "is-invalid form-control" : "form-control"}
                                                    onChange={this.handleChange}
                                                    value={this.state.item.playerChallengedSlug}
                                                    id="playerChallengedSlug"
                                                    name="playerChallengedSlug">
                                                    <option>Wybierz przeciwnika</option>
                                                    {playerList}
                                                </Form><br />
                                            </label>

                                            </div>
                                            <div class="field"><label for="Sugerowanadatagry">Sugerowana data gry:</label>
                                                <Form.Control as="select"
                                                    className={isError.gameDate.length > 0 ? "is-invalid form-control1" : "form-control"}
                                                    onChange={this.handleChange}
                                                    id="gameDate"
                                                    value={this.state.item.gameDate}
                                                    name="gameDate">
                                                    <option>{this.dateDefaultValue}</option>
                                                    {allDays.map(day => (
                                                        <option>{day}</option>
                                                    ))}
                                                </Form.Control>
                                                {isError.gameDate.length > 0 && (
                                                    <span className="invalid-feedback">{isError.gameDate}</span>
                                                )}
                                            </div><br />
                                            <div class="field"><label for="Sugerowanagodzinagry">Sugerowana godzina gry:</label>

                                                <Form.Control as="select"
                                                    className={isError.gameTime.length > 0 ? "is-invalid form-control" : "form-control"}
                                                    onChange={this.handleChange}
                                                    value={this.state.item.hou}
                                                    id="gameTime"
                                                    name="gameTime">
                                                    <option>{this.hourDefaultValue}</option>
                                                    {this.state.hours.map(hour => (
                                                        <option>{hour}</option>
                                                    ))}


                                                </Form.Control>
                                                {isError.gameTime.length > 0 && (
                                                    <span className="invalid-feedback">{isError.gameTime}</span>
                                                )}
                                            </div><br />


                                            <div class="field"><label for="Sugerowanagodzinagry">Jeśli pole poniżej będzie zaznaczone wysłane zostaną maile do uczestników wyzwania:</label>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="mail"
                                                    className="mb-2"
                                                    name="mail"
                                                    onChange={this.handleChange}
                                                    value={this.state.item.mail || ''}
                                                />
                                            </div>

                                            <div class="field" id="ukryj">
                                                <div class="edit-options">
                                                    <div class="edit"></div>
                                                    <div class="delete">
                                                    </div>
                                                </div>
                                                <label for="Informacjadlaosobyktrwyzywasz">W polu ponieżej możesz zamieścić tekst który zostanie załączony do maila : </label>

                                                <Form.Control as="textarea"
                                                    id="description"
                                                    name="description"
                                                    value={this.state.item.description || ''}
                                                    onChange={this.handleChange}
                                                    autoComplete="description" />
                                            </div>

                                            <div class="field"><label for=""></label>
                                                <input value="Utwórz wyzwanie" type="submit"></input>
                                            </div>
                                        </div>
                                    </div>
                                </form> */}
              </div>

              <div></div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default PageLeagueAdminCreateMatch;
