import React from 'react'

import AuthService from "../services/auth.service";
import { Form, Button } from "react-bootstrap";

import FechPostAddPlayer from "../leagueAppFechData/FechPostAddPlayer";

import history from "../components/history";

import ValidationFormField from "../utils/ValidationFormField";

class AddNewUserToLeague extends React.Component {

  NOTIFICATION_TITLE_OK = "Sukces!"
  NOTIFICATION_TITLE_NOT_OK = "Błąd!"
  NOTIFICATION_MSG_OK = "Nowy uczestnik został dodany!"
  NOTIFICATION_MSG_NOT_OK = "Wystąpił błąd! Spróbój ponownie!"

  emptyItem = {
    name: '',
    surname: '',
    email: '',
    phone: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      successful: false,
      message: "",
      item: this.emptyItem,
      name: '',
      email: '',
      password: '',
      isError: {
        name: '',
        surname: '',
        phone: '',
        email: '',
        password: '',
      },
      newUser: {
        name: '',
        email: '',
        age: '',
        gender: '',
        expertise: '',
        about: '',
        skills: []
      },
      restAddAdminResponseData: {
        successful: false,
        status: '',
        message: '',
        data: []
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSkillsCheckBox = this.handleSkillsCheckBox.bind(this)
  }

  async componentDidMount() {

  }


  handleChange(event) {

    var { name, value } = event.target;

    let item = { ...this.state.item };
    item[name] = value;

    this.setState({ item: item });

    this.fieldValidation(name, value);
  }

  fieldValidation(name, value) {

    let isError = { ...this.state.isError };

    switch (name) {
      case "name":
        isError.name = ValidationFormField.validName("Imię", value);
        break;
      case "surname":
        isError.surname = ValidationFormField.validSurname("Nazwisko", value);
        break;
      case "email":
        isError.email = ValidationFormField.validEmail("Email", value)
        break;
      case "phone":
        isError.phone = ValidationFormField.validPhone("Telefon", value)
        break;
      default:
        isError.password = "";
        break;
    }
    this.setState({
      isError,
      [name]: value
    })
    this.state.isError[name] = isError[name];
  }


  async handleSubmit(event) {

    event.preventDefault();

    for (var key in this.state.item) {
      this.fieldValidation(key, this.state.item[key]);
    }

    if (ValidationFormField.formValid(this.state.isError)) {

      this.setState({
        restAddAdminResponseData: await FechPostAddPlayer(this.props.club, this.props.league,
          this.state.item)
      });

      var notification = ['', '', ''];
      if (this.state.restAddAdminResponseData.successful) {
        notification = ['success', this.NOTIFICATION_TITLE_OK, this.NOTIFICATION_MSG_OK];
      } else {
        notification = ['error', this.NOTIFICATION_TITLE_NOT_OK, this.NOTIFICATION_MSG_NOT_OK];
      }

      //var refreash = this.props.handler();
      history.push('/liga/' + this.props.club + '/' + this.props.league + "/admin", notification);
      window.location.reload();

    } else {
      console.log("walidddd : Form is invalid!"); //usun
    }
  }


  handleSkillsCheckBox(e) {

    const newSelection = e.target.value;
    let newSelectionArray;

    if (this.state.newUser.skills.indexOf(newSelection) > -1) {
      newSelectionArray = this.state.newUser.skills.filter(s => s !== newSelection)
    } else {
      newSelectionArray = [...this.state.newUser.skills, newSelection];
    }

    this.setState(prevState => ({
      newUser:
        { ...prevState.newUser, skills: newSelectionArray }
    })
    )


    this.setState(prevState => ({
      newUser:
        { ...prevState.newUser, skills: newSelectionArray }
    })
    )

    //console.log(" name: " + JSON.stringify(e.target.name)); //usun
    //console.log(" newSelectionArray: " + JSON.stringify(this.state.newUser)); //usun
  }



  render() {

    const { item } = this.state;
    const { isError } = this.state;

    return (

      <Form onSubmit={this.handleSubmit}>

        <Form.Group controlId="formGridAddress1">
          <Form.Label>Imie</Form.Label>
          <Form.Control
            className={isError.name.length > 0 ? "is-invalid form-control" : "form-control"}
            placeholder=""
            id="name"
            name="name"
            value={item.name || ''}
            onChange={this.handleChange}
          />
          {isError.name.length > 0 && (
            <span className="invalid-feedback">{isError.name}</span>
          )}
        </Form.Group>

        <Form.Group
          controlId="formGridAddress2">
          <Form.Label>Nazwisko</Form.Label>
          <Form.Control
            className={isError.surname.length > 0 ? "is-invalid form-control" : "form-control"}
            placeholder=""
            id="surname"
            name="surname"
            value={item.surname || ''}
            onChange={this.handleChange}
            autoComplete="surname" />
          {isError.surname.length > 0 && (
            <span className="invalid-feedback">{isError.surname}</span>
          )}
        </Form.Group>

        <Form.Group controlId="formGridAddress2">
          <Form.Label>Telefon</Form.Label>
          <Form.Control placeholder=""
            className={isError.phone.length > 0 ? "is-invalid form-control" : "form-control"}
            id="phone"
            name="phone"
            value={item.phone || ''}
            onChange={this.handleChange}
            autoComplete="phone" />
          {isError.phone.length > 0 && (
            <span className="invalid-feedback">{isError.phone}</span>
          )}
        </Form.Group>

        <Form.Group controlId="formGridAddress2">
          <Form.Label>E-mail</Form.Label>
          <Form.Control placeholder=""
            className={isError.email.length > 0 ? "is-invalid form-control" : "form-control"}
            id="email"
            name="email"
            value={item.email || ''}
            onChange={this.handleChange}
            autoComplete="email" />
          {isError.email.length > 0 && (
            <span className="invalid-feedback">{isError.email}</span>
          )}
        </Form.Group>

        <Button variant="primary" type="submit">
          Dodaj
        </Button>

      </Form>

    )
  }
}

export default AddNewUserToLeague;