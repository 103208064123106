import React from 'react'
//import ReactDOM from "react-dom";

import CreatLinkSerchByCityAndClub from "../classAppCreateLinks/CreatLinkSerchByCityAndClub";

class CityClubFindForm extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			valueCity: '',
			valueClub: '',
			data: [],
			errors: '',
			clubsInCity: []
		};

		this.changeCity = this.changeCity.bind(this);
		this.changeClub = this.changeClub.bind(this);
	}

	async componentDidMount() {

		var url = "/rest/all_available_cities_page";
		var info = {};
		info[0] = { "id": 999999, "addressWww": "0", "club": "--Wybierz Miasto--" };
		info[1] = { "id": "999999", "nameWww": "--Wybierz Miasto--", "city": "--Wybierz Miasto--" };

		const cityResponse = await fetch(url).then(res => res.json());
		cityResponse.unshift(info[1])
		this.setState({ data: cityResponse });

		this.setState({ valueCity: "--Wybierz Miasto--" });

		const data = [];
		data.push(info[0]);
		this.setState({ clubsInCity: data });

	}


	handleSubmit = (event) => {

		event.preventDefault();

		if (new String(this.state.valueCity).valueOf() === new String("--Wybierz Miasto--").valueOf()) {
			this.setState({
				errors: 'error'
			});

		} else {

			this.setState({
				errors: ''
			});
			if (this.state.valueClub !== "--Wybierz Miasto--") {
				window.location.href = "/" + this.props.linkTo + CreatLinkSerchByCityAndClub(this.state.valueCity, this.state.valueClub, "1");
			} else {
				window.location.href = "/" + this.props.linkTo + CreatLinkSerchByCityAndClub(this.state.valueCity, "", "1");
			}
		}
	}

	render() {
		return (

			<form class="klas" onSubmit={this.handleSubmit} >

				<div class="form-item-error">
					{this.state.errors === 'error'
						? <div>Wybierz miasto</div>
						: null}
				</div>

				<div class="form-item">
					<label>Miasto:</label>
					<select placeholder="Country" id="MiastoOsrodek_task" name="MiastoOsrodek[task]"
						onChange={this.changeCity}>
						{this.state.data.map((e, key) => {
							return <option value={e.nameWww}>{e.city}</option>;
						})}
					</select>
				</div>
				<div class="form-item">
					<label>Klub:</label>
					<select placeholder="State"
						onChange={this.changeClub}>
						{this.state.clubsInCity.map((e, key) => {
							return <option value={e.addressWww}>{e.club}</option>;
						})}
					</select>

				</div>
				<div>
					<input class="btn-more semibold" type="submit" value="wyszukaj" />
				</div>
			</form>
		);
	}


	async changeCity(event) {

		var info = {};

		if (event.target.value === "--Wybierz Miasto--") {
			info[0] = { "id": 999999, "addressWww": "0", "club": "--Wybierz Miasto--" };
			const data = [];
			data.push(info[0]);
			this.setState({ clubsInCity: data });
			this.setState({ valueCity: event.target.value });

		} else {

			var url = "/rest/all_available_clubs_in_cities_page";
			if (event.target.value != null) {
				url = url + "/" + event.target.value;
			}

			this.setState({ valueCity: event.target.value });

			info[0] = { "id": 999999, "addressWww": "0", "club": "Wszystkie" };

			this.state.clubsInCity.push(info)

			const clubsResponse = await fetch(url).then(res => res.json());
			clubsResponse.unshift(info[0])

			this.setState({ clubsInCity: clubsResponse });
		}
	}

	changeClub(event) {

		this.setState({
			valueClub: event.target.value
		});
	}

}

export default CityClubFindForm;