import React from 'react';

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';
import PageCounter from '../components/PageCounter';
import FechGetClubLongDescBySlug from "../fechData/FechGetClubLongDescBySlug";

import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl";

import FetchGetDataNoAuth from "../services/FetchGetDataNoAuth";

import "react-datepicker/dist/react-datepicker.css";

//UTILS
import DateTimeNow from "../utils/DateTimeNow";


class ClubTournamentsPage extends React.Component {

  constructor(props) {
    super(props);
    this.printAdresse = this.printAdresse.bind(this);
  }

  state = {
    startDate: new Date(),
    dataClubInfo: {
      successful: false,
      status: '',
      message: '',
      data: []
  },
    dataAdverbTrainer: [],
    adverbCount: "",
    currentPage: "1",
    logInfo: "LogDebug __" + DateTimeNow() + "__ ClubTrainersPage "
  }



  printAdresse() {
    if(this.state.dataClubInfo.successful === true){
      return { __html: this.state.dataClubInfo.data.descriptionShort };
    }
  }

  async componentDidMount() {

    registerLocale("pl", pl);
    document.title = this.state.title

    if (this.props.match.params.page === undefined) {

      this.setState({ currentPage: 1 });
    } else {
      this.state.currentPage = this.props.match.params.page;
    }

    this.setState({
      dataClubInfo: await FechGetClubLongDescBySlug(this.state.tt) //this.props.match.params.slug
    });
    var tt = this.state.dataClubInfo;
  }


  render() {

    var noAdverb = [];
    noAdverb.push(<div class='tenis-content-info'>Brak ogłoszeń</div>);

    return (

      <div>

        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">


            <div class="main-subpage tennis-center">
              <div class="head">
                <img src={`/images/login.png`} alt="" />
                <p>Korty Tenisowe</p>
              </div>
              <div class="main-subpage-content">

                {/* <BeltClub dataClubInfo={this.state.dataClubInfo} currentPage="tournamentCurrentPage" /> */}

                <div class="tenis-center-content">
                  <ss dangerouslySetInnerHTML={this.printAdresse()} />


                  <div class="tennis-center-coach">

                    {noAdverb}
                  </div>

                  <PageCounter elementCount={this.state.adverbCount.count}
                    elementOnPage={"6"} currentPage={this.state.currentPage}
                    linkTo={"klub/" + this.props.match.params.slug + "/trenerzy"} />
                </div>
              </div>

            </div>


          </div>



          <div >


          </div>


        </div>
        <div style={{ clear: 'both' }}>
        </div>

        <Footer />

      </div>
    );
  }
}

export default ClubTournamentsPage;
