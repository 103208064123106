import FetchPostDataNoAuthNEW from "../services/FetchPostDataNoAuthNEW";

export default async function FechPostResetPasswordToken(body) {

  var url = "/api/auth/resetPassword";

  var data = await FetchPostDataNoAuthNEW(url, body)

  return data;
}
