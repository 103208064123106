import React from 'react'
import AuthService from "../services/auth.service";

class Belt extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentUser: AuthService.getCurrentUser()
        };
    }

    componentDidMount() {

    }


    logOut() {
        AuthService.logout();
    }

    render() {
        var page = this.props.page;
        var div = '<div id="header" class="head-logged">'
        const { currentUser } = this.state;
        if (page === "index") {
            return (

                <div id="stretcher">
                    <div id="header">
                        <div class="container">
                            <div id="brand" class="animated-fast fadeInLeftBig">
                                <div id="logo">
                                    <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="logo" />
                                    <div id="logo-text">
                                        <p id="text-big"><span class="semibold">rezerwuj</span><span class="book">kort</span></p>
                                        <p id="text-green"><span class="book">system rezerwacji online</span></p>
                                    </div>
                                </div>
                                {currentUser ? (
                                    <a href="/logout" onClick={this.logOut}><div id="logout"></div></a>
                                ) : (
                                    <a href="/login"><div id="login"></div></a>
                                )}


                            </div>

                            <div id="menu" class="animated-fast fadeInRightBig">
                                <ul>
                                    <li id="home-icon">
                                        <a href="/">
                                            <div id="home-href"></div>
                                        </a>
                                    </li>

                                    <li><a href="/trenerzy">trenerzy</a></li>
                                    <li><a href="/sparingpartnerzy">sparingpartnerzy</a></li>
                                    <li><a href="/kluby">kluby</a></li>
                                    <li><a href="/turnieje">turnieje</a></li>
                                    <li><a href="/o_nas">o nas</a></li>

                                </ul>


                                <div id="menu-icon"></div>
                            </div>
                            <div id="menu-mobile"><a href="#"></a></div>
                            <div class="bar animated-fast fadeInLeftBig" id="top-bar"><p><span class="extralight">prosto </span><span class="medium">do celu</span></p></div>
                            <div class="bar animated-fast fadeInRightBig" id="bottom-bar"><p><span class="extralight">ośrodki tenisowe, trenerzy, sparingpartnerzy</span></p></div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (

                <div id="stretcher">
                    <div id="header" class="head-logged">
                        <div class="container">
                            <div id="brand" class="animated-fast fadeInLeftBig">
                                <div id="logo">
                                    <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="logo" />
                                    <div id="logo-text">
                                        <p id="text-big"><span class="semibold">rezerwuj</span><span class="book">kort</span></p>
                                        <p id="text-green"><span class="book">system rezerwacji online</span></p>
                                    </div>
                                </div>
                                {currentUser ? (
                                    <a href="/logout" onClick={this.logOut}><div id="logout"></div></a>
                                ) : (
                                    <a href="/login"><div id="login"></div></a>
                                )}


                            </div>

                            <div id="menu" class="animated-fast fadeInRightBig">
                                <ul>
                                    <li id="home-icon">
                                        <a href="/">
                                            <div id="home-href"></div>
                                        </a>
                                    </li>

                                    <li><a href="/trenerzy">trenerzy</a></li>
                                    <li><a href="/sparingpartnerzy">sparingpartnerzy</a></li>
                                    <li><a href="/kluby">kluby</a></li>
                                    <li><a href="/turnieje">turnieje</a></li>
                                    <li><a href="/o_nas">o nas</a></li>

                                </ul>


                                <div id="menu-icon"></div>
                            </div>
                            <div id="menu-mobile"><a href="#"></a></div>


                        </div>
                    </div>
                </div>
            )
        }


    }
}

export default Belt;