import FetchPostDataNoAuthNEW from "../services/FetchPostDataNoAuthNEW";

export default async function FechPostTrainerBySearch(body) {

  var url = "/trainers_adverb_search_page";

  var data = await FetchPostDataNoAuthNEW(url, body)

  return data;
}
