import React, { Suspense } from 'react';

import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';
import FechPostClubBySearch from "../fechData/FechPostClubBySearch";

import PageCounterNew from '../components/PageCounterNew';

import ParseLinkToArray from "../utils/ParseLinkToArray"
import queryString from 'query-string'

import MapSearch from "../components/MapSearch";
import DateTimeNow from "../utils/DateTimeNow";

const AdverbClubShort = React.lazy(() => import('../components/AdverbClubShort'));

class ClubsPage extends React.Component {

  searchClubItem = {
    page: ''
  };
  urlDeafulValues = [];
  urlValue = "";

  state = {
    title: "Kluby - RezerwujKort.pl",
    logInfo: "LogDebug __" + DateTimeNow() + "__ ClubsPage ",
    rowsCount: "6",
    restClubAdverbsData: {
      successful: false,
      status: '',
      message: '',
      data: []
    }
  }

  async componentDidMount() {

    document.title = this.state.title

    const { location: { search } } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.searchClubItem = await ParseLinkToArray(this.urlValue, this.searchClubItem, this.urlDeafulValues);

    this.setState({
      restClubAdverbsData: await FechPostClubBySearch(this.searchClubItem)
    });
  }

  render() {

    var adverbs = "";
    if (this.state.restClubAdverbsData.successful &&
      typeof this.state.restClubAdverbsData.data.clubsResources.map === 'function') {
      adverbs = this.state.restClubAdverbsData.data.clubsResources.map(adverbOne =>
        <div>
          <Suspense fallback={<div>
            <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
          </div>}>
            <AdverbClubShort adverb={adverbOne} />
          </Suspense>
        </div>);
    }

    const urlValues = new Map();
    urlValues.set('page', "replace");

    var pageCounter = "";
    if (this.state.restClubAdverbsData.successful) {
      pageCounter = <PageCounterNew
        elementCount={this.state.restClubAdverbsData.data.totalRecordsCount}
        urlValues={urlValues}
        urlDeafulValues={this.urlDeafulValues}
        elementOnPage={this.state.rowsCount}
        currentPage={parseInt(this.searchClubItem.page) + 1}
        searchName={"clubs_search"}
        linkTo={"kluby"}
      />
    }
    var maps =  <MapSearch linkTo={"clubs_search"} />

    return (
      <div>

        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">
            <div class="main-subpage">
              <div class="head">

                <img src={`/images/login.png`} />
                <p>Korty Tenisowe</p>
              </div>

              {maps}

              <div style={{ clear: 'both' }}>
                <div class="main-subpage-subtitle">Kluby</div>

                {adverbs}

              </div>

              <div >
                {pageCounter}
              </div>

            </div>

          </div>
        </div>

        <Footer />

      </div>
    );
  }
}

export default ClubsPage;
