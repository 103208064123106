import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW"; 

export default function FechGetSuperAdminRoles(club) {
 
  var url = "/league_app/user_privileges/super_admin/" + club;
  var data = FetchGetDataWithAuthNEW(url);

  return data;
}

