import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';

import AuthService from "../services/auth.service";

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const EditStudent = React.lazy(() => import('../classAppForms/EditStudent'));

class PageShowStudent extends React.Component {

  state = {
    currentUser: AuthService.getCurrentUser(),
    title: "RezerwujKort.pl - Szkółki tenisowe"
  }

  componentDidMount() {

    document.title = this.state.title;
  }

  render() {

    var beltClassApp = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp club={this.props.match.params.club} />
      </Suspense>
    </div>

    var editStudent = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <EditStudent
          club={this.props.match.params.club}
          id={this.props.match.params.id} />
      </Suspense>
    </div>

    return (

      <div>
        {beltClassApp}

        <div id="content">
          <div class="container">

            

            <br /><br />
            <b>Edycja uczestnika zejęć</b>
            <br /><br />

            {editStudent}

            <br /><br />
          </div>
        </div>

        <Footer />

      </div>
    );
  }
}
export default PageShowStudent;
