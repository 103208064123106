import React from 'react';

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';
import Footer from '../components/Footer';

const MobileAppPage = () => {
  return (

    <div>

      <Belt />

      <BeltLogged />

      <div id="content">
        <div class="container">

          <div class="main-subpage">
            <div class="head">
              {/* <img src="{{ asset('images/login.png') }}" alt="" /> */}
              <img src={`/images/mobile.png`} alt="" />
              <p>Aplikacja mobilna</p>
            </div>
            <div class="tenis-content-info">
              <br />
              <div class="main-subpage-desc">
                Aplikacja RezerwujKort.pl jest już dostepna
              </div><br />

              <img src="/images/korty_1.png" alt="" width="250" style={{ 'marginRight': '20px'}}/>  
              <img src="/images/korty_2.png" alt="" width="250" style={{ 'marginRight': '20px'}}/>
              <img src="/images/korty_3.png" alt="" width="250" />

              <br /><br /><br />
              Oficjalna aplikacja portalu www.RezerwujKort.pl jest już dostępna!<br />
              Dzięki niej z łatwością sprawdzisz dostępność kortów lub dokonasz rezerwacji w swoim ulubionym klubie.<br />

              Miłego korzystania!
              <br /><br />
              Pobierz i sprawdź!<br /><br />
              <a href="https://play.google.com/store/apps/details?id=pl.avroit.rezerwujkort.pl&hl=pl"> <img src={`/images/google.png`} /></a>
              <br />
              <a href="https://apps.apple.com/us/app/rezerwujkort-pl/id6475010687?platform=iphone"> <img src={`/images/ios.png`} /></a>
              <br />
            </div>

          </div>
        </div>
      </div>
      <Footer />

    </div>

  );
}

export default MobileAppPage;
