import FetchGetDataNoAuthNEW from "../services/FetchGetDataNoAuthNEW"; 

export default function FechGetClubReservationOnline(clubWww, dateResrvation, page, sportId) {

  var url = "/reservation/one_day_client_reservation_calendar/" + clubWww + "/" + dateResrvation + "/" + page + "/" + sportId;
  if(sportId === undefined) {
    url = "/reservation/one_day_client_reservation_calendar/" + clubWww + "/" + dateResrvation + "/" + page + "/0";
  };

  return FetchGetDataNoAuthNEW(url);
}

