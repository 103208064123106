import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';

import AuthService from "../services/auth.service";

import { Link } from 'react-router-dom'
import { Button, Table, Card } from "react-bootstrap";

import FechGetClass from "../classAppFechData/FechGetClass";

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const AddStudentToClass = React.lazy(() => import('../classAppForms/AddStudentToClass'));

class PageStudentAddToClass extends React.Component {

  state = {
    title: "RezerwujKort.pl - Szkółki tenisowe"
  }

  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      newsCount: "",
      currentPage: "1",
      rowsCount: "25",
      restClassData: {
        successful: false,
        status: '',
        message: '',
        data: []
      }
    }
  }

  async componentDidMount() {

    document.title = this.state.title;

    this.setState({ restClassData: await FechGetClass(this.props.match.params.club, this.props.match.params.id) });
  }

  render() {

    var msg = this.props.location.state;
    var button = "";
    if (msg === undefined) {
      button = "";
    } else {
      button = <div class="tenis-center-content-info">+  {msg}  + </div>;
    }

    var addStudentToClass = '';
    if (this.state.restClassData.data.dateStop !== undefined) {
      addStudentToClass = <div>
        <Suspense fallback={<div>
          <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
        </div>}>
          <AddStudentToClass
            club={this.props.match.params.club}
            classid={this.props.match.params.id}
            classData={this.state.restClassData} />
        </Suspense>
      </div>
    }

    var beltClassApp = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp club={this.props.match.params.club} />
      </Suspense>
    </div>


    return (

      <div>
         {beltClassApp}

        <div id="content">
          <div class="container">

           

            <br /><br /><br /><br /><br />

            {button}

            <Link to={"/class_app/" + this.props.match.params.club + "/class_show_students/" + this.state.restClassData.data.id}>
              <Button variant="primary" type="submit">
                Powrót
              </Button>&nbsp;&nbsp;
            </Link><br /><br />

            <Card style={{ width: '100%' }}>
              <Card.Body>
                <Card.Title>Informację podstawowe o szkółce:</Card.Title>
                <Table responsive="sm">

                  <tbody>
                    <tr>
                      <td><b>Nazwa szkółki:</b></td>
                      <td>{this.state.restClassData.data.name}</td>
                      <td><b>Opis:</b></td>
                      <td>{this.state.restClassData.data.description}</td>
                    </tr>
                    <tr>
                      <td><b>Kort:</b></td>
                      <td>{this.state.restClassData.data.court}</td>
                      <td><b>Dzień:</b></td>
                      <td>{this.state.restClassData.data.dayOfWeek}</td>
                    </tr>
                    <tr>
                      <td><b>Godzina start:</b></td>
                      <td>{this.state.restClassData.data.hourStart}</td>
                      <td><b>Godzina koniec:</b></td>
                      <td>{this.state.restClassData.data.hourStop}</td>
                    </tr>
                    <tr>
                      <td><b>Data start:</b></td>
                      <td>{this.state.restClassData.data.dateStart}</td>
                      <td><b>Data koniec:</b></td>
                      <td>{this.state.restClassData.data.dateStop}</td>
                    </tr>
                    <tr>
                      <td><b>Trener:</b></td>
                      <td>{this.state.restClassData.data.trainer}</td>
                      <td><b>Utworzone:</b></td>
                      <td>{this.state.restClassData.data.createdAt}</td>
                    </tr>
                    <tr>

                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card><br /><br />

            {addStudentToClass}

            <br /><br />

          </div>
        </div>

      </div>
    );
  }
}
export default PageStudentAddToClass;


