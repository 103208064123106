import React from 'react';

import Belt from '../components/Belt';
import Footer from '../components/Footer';

import ForgotPasswordForm from '../forms/ForgotPasswordForm';

class ForgotPassword extends React.Component {

    state = {
        title: "RezerwujKort.pl - Rejestracja"
    }

    componentDidMount() {
        document.title = this.state.title

    }


    render() {
        return (

            <div>

                <Belt />

                <ForgotPasswordForm />

                <Footer />

            </div>
        );
    }
}
export default ForgotPassword;
