import FetchPostDataNoAuthNEW from "../services/FetchPostDataNoAuthNEW";

export default async function MailContact(body) {

  var url = "/notification/contact_form";

  var data = await FetchPostDataNoAuthNEW(url, body)

  return data;
}
