import React, { Suspense } from 'react';

import { Spinner, Button } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl"; // the locale you want

import FechGetTrainersBySearch from "../APP_RESERVATION_FECH_DATA/FechGetTrainersBySearch";

import PageCounterNew from '../components/PageCounterNew';
import BeltReservationApp from '../APP_RESERVATION_COMPONENTS/BeltReservationApp'

import queryString from 'query-string'
import moment from 'moment'
import ParseLinkToArray from "../utils/ParseLinkToArray"

const AddTrainerPopupForm = React.lazy(() => import('../APP_RESERVATION_FORM_POPUP/AddTrainerPopupForm'));
const TabAllTrainers = React.lazy(() => import('../APP_RESERVATION_COMPONENTS/TabAllTrainers'));

let plLocate = require('moment/locale/pl');
moment.locale('pl', plLocate)

//KLASA WYSWIETLA SZCZEGOLY KONKRETNYCH ZAJEC SZKOLKI
class PageTrainers extends React.Component {

    TRAINER_SELECT_DESC = "Wybierz trenera";
    DATE_SELECT_DESC = "Wybierz datę";
    CLASS_SELECT_DESC = "Wybierz szkółke";

    urlDeafulValues = [this.TRAINER_SELECT_DESC, this.CLASS_SELECT_DESC, this.DATE_SELECT_DESC];

    urlValue = "";

    postData = {
        dateStart: '',
        dateStop: '',
        trainer: '',
        classId: '',
        className: '',
        status: '',
        page: '0'
    };

    constructor(props) {
        super(props);

        this.state = {
            title: "RezerwujKort.pl - Rezerwację",
            datePickerIsOpen: false,
            previousDay: "",
            refreshReservation: "",
            addTrainerPopupForm: "",
            restTrainersData: {
                successful: false,
                status: '',
                message: '',
                data: []
            }
        }
        this.handlerRefreshReservation = this.handlerRefreshReservation.bind(this);
    }

    async componentDidMount() {

        document.title = "Rezerwacje - RezerwujKort.pl";
        registerLocale("pl", pl); // register it with the name you want
        const { location: { search } } = this.props;
        this.state.search = search;
        this.urlValue = queryString.parse(this.state.search);

        this.postData = await ParseLinkToArray(this.urlValue, this.postData, this.urlDeafulValues);

        this.setState({ restTrainersData: await FechGetTrainersBySearch(this.props.match.params.club, search) });


        var cc = this.state.restTrainersData;

    }

    async addClientForm() {

        this.setState({
            addTrainerPopupForm: <div>
                <Suspense fallback={<div>
                    <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
                </div>}>
                    <AddTrainerPopupForm
                        dateReservation={""}
                        club={this.props.match.params.club}
                        refresh={this.handlerRefreshReservation}
                    />
                </Suspense>
            </div>
        });
    }

    async handlerRefreshReservation() {

        //this.componentDidMount();
        const { location: { search } } = this.props;
        this.state.search = search;
        this.urlValue = queryString.parse(this.state.search);
        this.postData = await ParseLinkToArray(this.urlValue, this.postData, this.urlDeafulValues);

        this.setState({ restTrainersData: await FechGetTrainersBySearch(this.props.match.params.club, search) });

        var cc = this.state.restTrainersData;
    }


    render() {

        var loader = "";
        if (!this.state.restTrainersData.successful) {
            loader = <div id="loader_div">
                <Spinner animation="border" />
            </div>
            totalRecords = this.state.restTrainersData.data
        }

        var totalRecords = "";
        var tabAllTrainers = "";
        var pageCounter = "";
        if (this.state.restTrainersData.successful) {
            totalRecords = this.state.restTrainersData.data.totalRecordsCount

            tabAllTrainers = <div>
                <Suspense fallback={<div>
                    <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
                </div>}>
                    <TabAllTrainers
                        club={this.props.match.params.club}
                        restLessonsData={this.state.restTrainersData.data.trainersResources}
                        refresh={this.handlerRefreshReservation} />
                </Suspense>
            </div>

            const urlValues = new Map();
            urlValues.set('dateStart', this.postData.dateStart);
            urlValues.set('dateStop', this.postData.dateStop);
            urlValues.set('trainer', this.postData.trainer);
            urlValues.set('classId', this.postData.classId);
            urlValues.set('className', this.postData.className);
            urlValues.set('status', this.postData.status);
            urlValues.set('page', "replace");

            pageCounter = <PageCounterNew
                elementCount={totalRecords}
                urlValues={urlValues}
                urlDeafulValues={this.urlDeafulValues}
                elementOnPage={this.state.restTrainersData.data.recordsOnPage}
                currentPage={parseInt(this.postData.page) + 1}
                searchName={"search"}
                linkTo={"reservation_app/" + this.props.match.params.club + "/trenerzy"}
            />
        }

        return (

            <div>

                <div id="content">

                    <BeltReservationApp club={this.props.match.params.club} />

                    {/* <SearchClients
                        urlValue={this.postData}
                        club={this.props.match.params.club}
                    /> */}

                    <br /><br />
                    <Button href="#" variant="success" size="lg" style={{ 'margin-left': '20px' }}
                        onClick={() => this.addClientForm()}>
                        Dodaj trenera
                    </Button>

                    <br /><br />
                    <b>Lista wszystkich zmian: ({totalRecords}):</b>
                    <br /><br />

                    {this.state.addTrainerPopupForm}
                    {loader}

                    {tabAllTrainers}

                    {pageCounter}

                </div>
            </div>
        );
    }
}
export default PageTrainers;
