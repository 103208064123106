import { store } from "react-notifications-component";
import NotificationDraft from '../Notification/NotificationDraft'

class NotificationPrint {


  printOnNewPage(notification) {

    if (notification !== undefined &&
        notification[0] !== '' &&
        notification[1] !== '' &&
        notification[2] !== undefined) {
        if (notification[0] === 'success') {
          NotificationDraft.success(notification[1], notification[2]);
        } else {
          NotificationDraft.error(notification[1], notification[2]);
        }
      }
  }

}

export default new NotificationPrint();