import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';
import PageCounterNew from '../components/PageCounterNew';

import DateTimeNow from "../utils/DateTimeNow";
import AuthService from "../services/auth.service";
import FechGetAdmins from "../classAppFechData/FechGetAdmins";
import ParseLinkToArray from "../utils/ParseLinkToArray"

import queryString from 'query-string'

const TabAllAdmins = React.lazy(() => import('../classAppComponents/TabAllAdmins'));
const AddAdmin = React.lazy(() => import('../classAppForms/AddAdmin'));
const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));

class PageAdmins extends React.Component {

  urlValue = "";

  urlDeafulValues = [];

  postData = {
    page: ''
  };

  state = {
    logInfo: "LogDebug __" + DateTimeNow() + "__ PageAdmins ",
    currentUser: AuthService.getCurrentUser(),
    currentPage: "1",
    title: "RezerwujKort.pl - Szkółki tenisowe",
    totalRecordsCount: '',
    rowsCount: "5",
    restAdminsData: {
      successful: false,
      status: '',
      message: '',
      data: []
    }
  }


  async componentDidMount() {

    document.title = this.state.title;

    const { location: { search } } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.postData = await ParseLinkToArray(this.urlValue, this.postData, this.urlDeafulValues);

    console.log(this.state.logInfo + " componentDidMount 2a this.restAdminsData " +
      JSON.stringify(this.postData));

    this.setState({
      restAdminsData: await FechGetAdmins(this.props.match.params.club,
        this.postData)
    });

    if (typeof this.state.restAdminsData.data.map === 'function') {
      if (this.state.restAdminsData.data.length > 0) {
        //this.totalRecordsCount = this.state.restAdminsData.data[0]["totalRecordsCount"];
        //this.totalPages = this.state.restAdminsData.data[0]["totalPages"];
        this.setState({ totalRecordsCount: this.state.restAdminsData.data[0]["totalRecordsCount"] })
      }
    }

  }


  render() {

    var msg = this.props.location.state;
    var button = "";
    if (msg === undefined) {
      button = "";
    } else {
      button = <div class="tenis-center-content-info">+  {msg}  + </div>;
    }

    var start = "1"
    if (this.state.currentPage > 1) {
      start = this.state.rowsCount * (this.state.currentPage - 1);
    }

    const urlValues = new Map();
    urlValues.set('page', "replace");


    var beltClassApp = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp club={this.props.match.params.club} />
      </Suspense>
    </div>

    var tabAllAdmins = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <TabAllAdmins
          club={this.props.match.params.club}
          data={this.state.restAdminsData.data}
        />
      </Suspense>
    </div>

    var addAdmin = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <AddAdmin club={this.props.match.params.club} />
      </Suspense>
    </div>

    return (

      <div>
        {/* <Belt /> */}

        {beltClassApp}
        <div id="content">
          <div class="container">

            {/* {beltClassApp} */}

            <br /><br />

            {button}

            <br /><br />
            <b>Dodaj nowego administratora </b>
            <br /><br />

            {addAdmin}

            <br /><br />
            <b>Lista wszystkich administratorów ({this.state.totalRecordsCount}):</b>
            <br /><br />

            {tabAllAdmins}

            <PageCounterNew
              elementCount={this.state.totalRecordsCount}
              urlValues={urlValues}
              urlDeafulValues={this.urlDeafulValues}
              elementOnPage={this.state.rowsCount}
              currentPage={parseInt(this.postData.page) + 1}
              searchName={"admins_search"}
              linkTo={"class_app/" + this.props.match.params.club + "/admin/admins"}
            />

          </div>
        </div>

        <Footer />

      </div>
    );
  }
}

export default PageAdmins;
