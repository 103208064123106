import React from 'react';

import Belt from '../components/Belt';
import Footer from '../components/Footer';

class LogoutPage extends React.Component {

  state = {
    data: [],
    newsCount: "",
    currentPage: "1",
    title: "RezerwujKort.pl - Wylogowany"
  }



  render() {
    return (

      <div>

        <Belt />

        <div id="content">
          <div class="container">
            <div class="main-subpage-info">
              <p>Dziękujemy za Twoją wizytę!<br /><br />
                Zespół RezerwujKort.pl</p>
            </div>
          </div>
        </div>

        <Footer />

      </div>
    );
  }
}
export default LogoutPage;
