import React from 'react'

import DateTimeNow from "../utils/DateTimeNow";

class GraphicHeader extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            logInfo: "LogDebug __" + DateTimeNow() + "__ Graphic ",
            editReservationPopupForm: "",
            addStandardReservationPopupForm: ""
        };
    }


    render() {

        var thead;
        var theadEmpty;
        var hoursShow = [];
        var graphicTab = [];
        var usedReservation = [];
        if (typeof this.props.reservationData.data.courts.map === 'function') {

            var columnLength = 100 / this.props.reservationData.data.courts.length;
            columnLength = columnLength + "%";

            thead = this.props.reservationData.data.courts.map(court => (

                <th width={columnLength} >
                    {court.courtName}					<br />
                    <small>
                        <span>
                        {court.courtDescription}						</span>
                    </small>
                    <span class="" title="Dodatkowe informacje" data-toggle="popover" data-placement="bottom" data-html="true" data-content="&lt;strong&gt;Nawierzchnia&lt;/strong&gt;:&lt;br&gt; mączka ceglana&lt;br&gt;&lt;strong&gt;Oświetlenie&lt;/strong&gt;:&lt;br&gt; ?&lt;br&gt;&lt;strong&gt;Zadaszenie&lt;/strong&gt;:&lt;br&gt; ? ">
                        <i class="fa fa-question-circle fa-fw text-success"></i>
                    </span>
                </th>
            ));

        } else {
            thead = "";
        }

        return (
            <>
               
                    <table class="table table-striped table-bordered table-sm table-hover"
                        >
                        <thead style={{ display: 'table-header-group' }}>
                            <tr class="obiekty" scope="row" style={{ backgroundColor: '#D4D4D4' }}>
                                <th width="3%"><i class="fa fa-clock-o"></i></th>
                                {thead}
                            </tr>
                        </thead>
                    </table>
                    
              
            </>
        )
    }
}

export default GraphicHeader;