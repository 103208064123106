import React, { Suspense } from 'react';

import Belt from '../components/Belt';
import Footer from '../components/Footer';
import PageCounterNew from '../components/PageCounterNew';
import AuthService from "../services/auth.service";

import { Link } from 'react-router-dom'
import { Form, Button, Spinner } from "react-bootstrap";

import Select from 'react-select';
import history from "../components/history";

import FechGetClassTrainer from "../classAppFechData/FechGetClassTrainer";
import FechGetClassNames from "../classAppFechData/FechGetClassNames";
import FechGetClassStudentsAddDateRange from "../classAppFechData/FechGetClassStudentsAddDateRange";
import FechGetStudentsClass from "../classAppFechData/FechGetStudentsClass";
import FechGetStudentAll from "../classAppFechData/FechGetStudentAll";

import queryString from 'query-string'
import DateTimeNow from "../utils/DateTimeNow";
import ParseLinkToArray from "../utils/ParseLinkToArray"

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const TabAllStudents = React.lazy(() => import('../classAppComponents/TabAllStudents'));
const SearchStudentAll = React.lazy(() => import('../forms/SearchStudentAll'));

class PageStudents extends React.Component {

  TRAINER_SELECT_DESC = "Wybierz trenera";
  DATE_SELECT_DESC = "Wybierz datę";
  CLASS_SELECT_DESC = "Wybierz szkółke";

  urlDeafulValues = [this.TRAINER_SELECT_DESC, this.CLASS_SELECT_DESC, this.DATE_SELECT_DESC];

  urlValue = "";

  postData = {
    dateStart: '',
    dateStop: '',
    trainer: '',
    className: '',
    studentId: '',
    page: ''
  };

  emptyItem = {
    student_id: '',
    class_id: ''
  };

  constructor(props) {

    super(props);

    this.state = {
      logInfo: "LogDebug __" + DateTimeNow() + "__ PageStudents ",
      currentUser: AuthService.getCurrentUser(),
      item: this.emptyItem,
      currentPage: "1",
      title: "RezerwujKort.pl - Szkółki tenisowe",
      restClassStudentsAddData: {
        successful: false,
        status: '',
        message: '',
        data: []
      },
      restClassTrainerData: {
        successful: false,
        status: '',
        message: '',
        data: []
      },
      restClassNamesData: {
        successful: false,
        status: '',
        message: '',
        data: []
      },
      restLessonsData: {
        successful: false,
        status: '',
        message: '',
        data: []
      },
      restStudentsData: {
        successful: false,
        status: '',
        message: '',
        data: []
      },
      restStudentsAllData: {
        successful: false,
        status: '',
        message: '',
        data: []
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {

    document.title = this.state.title;

    const { location: { search } } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.postData = await ParseLinkToArray(this.urlValue, this.postData, this.urlDeafulValues);

    this.state.restClassStudentsAddData = await FechGetClassStudentsAddDateRange(this.props.match.params.club);
    this.state.restClassTrainerData = await FechGetClassTrainer(this.props.match.params.club);
    this.state.restClassNamesData = await FechGetClassNames(this.props.match.params.club);

    if (this.postData.dateStart === "") {
      if (typeof this.state.restClassStudentsAddData.data.map === 'function') {
        this.postData.dateStart = this.state.restClassStudentsAddData.data[0];
      }
    }

    if (this.postData.dateStop == "") {
      if (typeof this.state.restClassStudentsAddData.data.map === 'function') {
        this.postData.dateStop = this.state.restClassStudentsAddData.data[this.state.restClassStudentsAddData.data.length - 1];
      }
    }

    this.setState({ restStudentsData: await FechGetStudentsClass(this.props.match.params.club, this.postData) });
    this.setState({ restStudentsAllData: await FechGetStudentAll(this.props.match.params.club) });

    // if (this.state.restStudentsData.successful) {
    //   if (this.state.restStudentsData.data.length > 0) {
    //     this.setState({ totalRecordsCount: this.state.restStudentsData.data[0]["totalRecordsCount"] })
    //   }
    // }
  }

  handleChange(event) {

    let item = { ...this.state.item };
    item.student_id = event.value;
    item.class_id = this.props.classid;

    this.setState({ item });
    this.setState({ item: item });

  }

  async handleSubmit(event) {

    event.preventDefault();

    history.push('/class_app/' + this.props.match.params.club + '/admin/student/stat/' + this.state.item.student_id, "Nowy uczeń został dodanyy");
    window.location.reload();
  }

  render() {

    var msg = this.props.location.state;
    var button = "";
    if (msg === undefined) {
      button = "";
    } else {
      button = <div class="tenis-center-content-info">+  {msg}  + </div>;
    }

    const urlValues = new Map();
    urlValues.set('dateStart', this.postData.dateStart);
    urlValues.set('dateStop', this.postData.dateStop);
    urlValues.set('trainer', this.postData.trainer);
    urlValues.set('className', this.postData.className);
    urlValues.set('studentId', this.postData.studentId);
    urlValues.set('page', "replace");


    //jesli pusta tabela
    var studentsList;
    if (typeof this.state.restStudentsAllData.data.map === 'function') {

      studentsList = this.state.restStudentsAllData.data.find(obj => obj.value === this.state.selectedValue);
    } else {
      studentsList = "";
    }

    var loader = "";
    if (!this.state.restStudentsAllData.successful) {
      loader = <div id="loader_div">
        <Spinner animation="border" />
      </div>
    }

    var beltClassApp = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp club={this.props.match.params.club} />
      </Suspense>
    </div>

    var select = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <Select
          placeholder="Select Option"
          //value={studentsList} // set selected value
          options={this.state.restStudentsAllData.data} // set list of the data
          onChange={this.handleChange} // assign onChange function
        />
      </Suspense>
    </div>

    var searchStudentAll = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <SearchStudentAll urlValue={this.urlValue}
          club={this.props.match.params.club}
          restClassLessonDateRangeData={this.state.restClassStudentsAddData}
          restClassTrainerData={this.state.restClassTrainerData}
          restClassNamesData={this.state.restClassNamesData}
        />
      </Suspense>
    </div>

    var tabAllStudents = "";
    if (this.state.restStudentsData.successful) {
      tabAllStudents = <div>
        <Suspense fallback={<div>
          <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
        </div>}>
          <TabAllStudents
            club={this.props.match.params.club}
            data={this.state.restStudentsData.data.content}
            page={this.state.currentPage}
          />
        </Suspense>
      </div>
    }


    return (

      <div>
        {beltClassApp}

        <div id="content">
          <div class="container">

            <br /><br />

            {button}

            <br /><br />

            <Link to={"/class_app/" + this.props.match.params.club + "/admin/student/add"}>
              <Button variant="primary">Dodaj nowego uczestnika zajęć</Button>
            </Link>

            <br /><br />
            <b>Wyszukaj uczestnika:</b>
            <br /><br />

            <Form onSubmit={this.handleSubmit}>

              <div className="App">
                Wybierz uczestnika z listy<br /><br />

                {select}

                {this.state.selectedValue && <div style={{ marginTop: 20, lineHeight: '25px' }}>
                  <div><b>Selected Value: </b> {this.state.selectedValue}</div>
                </div>}
              </div>
              <br />

              <Button variant="primary" type="submit">
                Szukaj
              </Button>
            </Form>

            <br /><br />

            {searchStudentAll}

            <br /><br />
            <b>Lista wszystkich uczestników({this.state.restStudentsData.data.totalElements}):</b>
            <br /><br />

            {tabAllStudents}

            <PageCounterNew
              elementCount={this.state.restStudentsData.data.totalElements}
              urlValues={urlValues}
              urlDeafulValues={this.urlDeafulValues}
              elementOnPage={this.state.restStudentsData.data.size}
              currentPage={parseInt(this.postData.page) + 1}
              searchName={"students_search"}
              linkTo={"class_app/" + this.props.match.params.club + "/students"}
            />

            {loader}

          </div>
        </div>


      </div>
    );
  }
}
export default PageStudents;