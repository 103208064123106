import React, { Suspense } from 'react';

import { Spinner, Button } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl"; // the locale you want
import queryString from 'query-string'
import ParseLinkToArray from "../utils/ParseLinkToArray"

import FechGetSentEmailsBySearch from "../APP_RESERVATION_FECH_DATA/FechGetSentEmailsBySearch";

import PageCounterNew from '../components/PageCounterNew';
import BeltReservationApp from '../APP_RESERVATION_COMPONENTS/BeltReservationApp'

import moment from 'moment'

const TabAllSentEmails = React.lazy(() => import('../APP_RESERVATION_COMPONENTS/TabAllSentEmails'));

let plLocate = require('moment/locale/pl');
moment.locale('pl', plLocate)

//KLASA WYSWIETLA SZCZEGOLY KONKRETNYCH ZAJEC SZKOLKI
class PageSentEmails extends React.Component {

    TRAINER_SELECT_DESC = "Wybierz trenera";
    DATE_SELECT_DESC = "Wybierz datę";
    CLASS_SELECT_DESC = "Wybierz szkółke";

    deafulValues = [this.TRAINER_SELECT_DESC, this.CLASS_SELECT_DESC, this.DATE_SELECT_DESC];

    urlValue = "";

    emptyItem = {
        clientId: '',
        page: '1'
    };

    constructor(props) {
        super(props);

        this.state = {
            title: "RezerwujKort.pl - Rezerwację",
            datePickerIsOpen: false,
            previousDay: "",
            refreshReservation: "",
            addCloseOpenPopupForm: "",
            item: this.emptyItem,
            restSentEmailsData: {
                successful: false,
                status: '',
                message: '',
                data: []
            }
        }
        //this.handlerRefreshReservation = this.handlerRefreshReservation.bind(this);
    }

    async componentDidMount() {

        document.title = "Rezerwacje - RezerwujKort.pl";
        registerLocale("pl", pl); // register it with the name you want

        const { location: { search } } = this.props;
        this.state.search = search;
        this.urlValue = queryString.parse(search);
        this.emptyItem = await ParseLinkToArray(this.urlValue, this.emptyItem, this.deafulValues);

        this.setState({ restSentEmailsData: await FechGetSentEmailsBySearch(this.props.match.params.club, search) });
    }

    render() {

        var loader = "";
        if (!this.state.restSentEmailsData.successful) {
            loader = <div id="loader_div">
                <Spinner animation="border" />
            </div>
        }

        var totalRecords = "";
        var tabAllSentEmails = "";
        var pageCounter = "";
        if (this.state.restSentEmailsData.successful) {
            totalRecords = this.state.restSentEmailsData.data.totalElements;

            tabAllSentEmails = <div>
                <Suspense fallback={<div>
                    <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
                </div>}>
                    <TabAllSentEmails
                        club={this.props.match.params.club}
                        restLessonsData={this.state.restSentEmailsData.data.content} />
                </Suspense>
            </div>


            const urlValues = new Map();
            urlValues.set('clientId', this.emptyItem.clientId);
            urlValues.set('page', "replace");

            pageCounter = <PageCounterNew
                elementCount={totalRecords}
                urlValues={urlValues}
                urlDeafulValues={this.deafulValues}
                elementOnPage={this.state.restSentEmailsData.data.size}
                currentPage={parseInt(this.emptyItem.page)+1}
                searchName={"search"}
                linkTo={"reservation_app/" + this.props.match.params.club + "/wyslane_maile"}
            />
        }

        return (

            <div>

                <div id="content">

                    <BeltReservationApp club={this.props.match.params.club} />

                    {/* <SearchClients
                        urlValue={this.postData}
                        club={this.props.match.params.club}
                    /> */}

                    <br /><br />
     

                    <br /><br />
                    <b>Lista wszystkich wiadomości Email wysłanych do klientów przez system: ({totalRecords}):</b>
                    <br /><br />

                    {this.state.addCloseOpenPopupForm}
                    {loader}

                    {tabAllSentEmails}

                    {pageCounter}


                </div>
            </div>
        );
    }
}
export default PageSentEmails;
