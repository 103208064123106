import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import FechGetCourts from "../classAppFechData/FechGetCourts";
import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW";

import AuthService from "../services/auth.service";

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const TabAllCourts = React.lazy(() => import('../classAppComponents/TabAllCourts'));
const AddCourt = React.lazy(() => import('../classAppForms/AddCourt'));

class PageCourts extends React.Component {

  state = {
    currentUser: AuthService.getCurrentUser(),
    title: "RezerwujKort.pl - Szkółki tenisowe",
    restCourtsData: {
      successful: false,
      status: '',
      message: '',
      data: []
    }
  }

  async componentDidMount() {

    document.title = this.state.title;

    this.setState({ restCourtsData: await FechGetCourts(this.props.match.params.club) });
  }


  render() {

    var msg = this.props.location.state;
    var actionMsg = "";
    if (msg === undefined) {
      actionMsg = "";
    } else {
      actionMsg = <div class="tenis-center-content-info"> + {msg} + </div>;
    }

    var beltClassApp = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp club={this.props.match.params.club} />
      </Suspense>
    </div>

    var addCourt = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <AddCourt club={this.props.match.params.club} />
      </Suspense>
    </div>

    var tabAllCourts = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <TabAllCourts
          club={this.props.match.params.club}
          data={this.state.restCourtsData.data} start="1" />
      </Suspense>
    </div>

    return (
      <div>
        {beltClassApp}

        <div id="content">
          <div class="container">

            <br /><br />

            {actionMsg}

            <br /><br />
            <b>Dodaj nowy Kort </b>
            <br /><br />

            {addCourt}

            <br /><br />
            <b>Lista wszystkich kortów:</b>
            <br /><br />

            {tabAllCourts}

          </div>
        </div>


      </div>
    );
  }
}
export default PageCourts;
