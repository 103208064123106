import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW";
import AuthService from "../services/auth.service";

export default async function FechGetLessonClassStudentsStat(club, classId, studentId, page) {

  var url = "/class_app/" + club + "/lessons_student_stat/"
    + classId + "/" + studentId + "/" + page;

  var data = await FetchGetDataWithAuthNEW(url);

  return data;
}
