import { store } from "react-notifications-component";

class NotificationDraft {


  success(title, msg) {

    return store.addNotification({
        title: title,
        message: msg,
        type: "success", // 'default', 'success', 'info', 'warning'
        container: "bottom-left", // where to position the notifications
        animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
        animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
        dismiss: {
          duration: 4000
        }
      })
  }

  error(title, msg) {
    store.addNotification({
        title: title,
        message: msg,
        type: "danger", // 'default', 'success', 'info', 'warning'
        container: "bottom-left", // where to position the notifications
        animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
        animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
        dismiss: {
          duration: 6000
        }
      })
  }

}

export default new NotificationDraft();