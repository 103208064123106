import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));

class PageAdminPanel extends React.Component {

  state = {
    currentUser: AuthService.getCurrentUser(),
    data: [],
    newsCount: "",
    currentPage: "1",
    title: "RezerwujKort.pl - Szkółki tenisowe"
  }

  componentDidMount() {

    document.title = this.state.title

    UserService.tokenCheck(this.state.currentUser);
    UserService.checkClassAdminPermissions(this.state.currentUser);
  }

  render() {

    var beltClassAppshow = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp
          club={this.props.match.params.club}
          active="/panel_admin" />
      </Suspense>
    </div>

    return (
      <div>

        {beltClassAppshow}

        <div id="content">
          <div class="container">
            {/* {beltClassAppshow} */}

            <div class="main-subpage tennis-center" >

              <div style={{ display: 'flex',marginTop: '50px'  }}>
                <Card style={{ width: '18rem', margin: '10px' }}>
                  {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                  <Card.Body>
                    <Card.Title>Pracownicy/Trenerzy</Card.Title>
                    <Card.Text>
                      Zarządzanie osobami mającymi dostęp do systemu.<br /><br />
                    </Card.Text>
                    <a href={'/class_app/' + this.props.match.params.club + '/admin/admins'}>
                      <Button variant="primary" size="lg">Otwórz</Button>
                    </a>
                  </Card.Body>
                </Card>

                <Card style={{ width: '18rem', margin: '10px' }}>
                  {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                  <Card.Body>
                    <Card.Title>Płatności</Card.Title>
                    <Card.Text>
                      Ustawienia Płatności za szkółki tenisowe.<br /><br /><br />
                    </Card.Text>
                    <a href={'/class_app/' + this.props.match.params.club + '/admin/price_type'}>
                      <Button variant="primary" size="lg">Otwórz</Button>
                    </a>
                  </Card.Body>
                </Card>

                <Card style={{ width: '18rem', margin: '10px' }}>
                  {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                  <Card.Body>
                    <Card.Title>Ustawienia kortów</Card.Title>
                    <Card.Text>
                      Zarządzanie kortami tenisowymi.<br /><br />
                    </Card.Text>
                    <a href={"/class_app/" + this.props.match.params.club + "/admin/courts"}>
                      <Button variant="primary" size="lg">Otwórz</Button>
                    </a>
                  </Card.Body>
                </Card>

              </div>

            </div>

          </div>
        </div>

        {/* <Footer /> */}

      </div>
    );
  }
}
export default PageAdminPanel;