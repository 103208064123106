import React from 'react'

const ButtonCalendar = React.forwardRef((props,ref) => {

    return (
       <button onClick={props.onClick} ref={ref} class="reservationNextDayButton">
         {props.placeholder}
       </button>
    )

})

export default ButtonCalendar;