import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';
import PageCounterNew from '../components/PageCounterNew';

import FechPostSparingBySearch from "../fechData/FechPostSparingBySearch";
import FechGetClubBySlug from "../fechData/FechGetClubBySlug";
import FechGetCityBySlug from "../fechData/FechGetCityBySlug";

import DateTimeNow from "../utils/DateTimeNow";

import ParseLinkToArray from "../utils/ParseLinkToArray"
import queryString from 'query-string'

const AdverbSparingParnerShort = React.lazy(() => import('../components/AdverbSparingParnerShort'));

class SparingpartnersSearchPage extends React.Component {

  searchSparingItem = {
    city: '',
    club: '',
    page: ''
  };
  urlDeafulValues = [];
  urlValue = "";

  state = {
    title: "Sparingpartnerzy - RezerwujKort.pl",
    logInfo: "LogDebug __" + DateTimeNow() + "__ SparingpartnersSearchPage ",
    rowsCount: "6",
    restSparingAdverbsData: {
      successful: false,
      status: '',
      message: '',
      data: []
    },
    restClubData: {
      successful: false,
      status: '',
      message: '',
      data: []
    },
    restCityData: {
      successful: false,
      status: '',
      message: '',
      data: []
    }
  }

  async componentDidMount() {

    document.title = this.state.title

    const { location: { search } } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.searchSparingItem = await ParseLinkToArray(this.urlValue, this.searchSparingItem, this.urlDeafulValues);

    this.state.restClubData = await FechGetClubBySlug(this.searchSparingItem.club);

    this.state.restCityData = await FechGetCityBySlug(this.searchSparingItem.city);

    this.setState({
      restSparingAdverbsData: await FechPostSparingBySearch(this.searchSparingItem)
    });
  }


  render() {

    var city = "";
    if (this.state.restCityData.successful) {
      city = this.state.restCityData.data.name
    }

    var description = "";
    if (this.state.restClubData.successful) {
      description = "Poniżej znajduje się lista osób poszukuących partnerów do gry w mieście: " + city + ", ośrodku tenisowym: " + this.state.restClubData.data.name;
    } else {
      description = "Poniżej znajduje się lista osób poszukuących partnerów do gry w mieście: " + city;
    }

    var adverbs = "";
    if (this.state.restSparingAdverbsData.successful &&
      typeof this.state.restSparingAdverbsData.data.sparingResources.map === 'function') {
      adverbs = this.state.restSparingAdverbsData.data.sparingResources.map(adverbOne =>
        <div>
          <Suspense fallback={<div>
            <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
          </div>}>
            <AdverbSparingParnerShort adverb={adverbOne} />
          </Suspense>
        </div>);
    }

    const urlValues = new Map();
    urlValues.set('city', this.searchSparingItem.city);
    urlValues.set('club', this.searchSparingItem.club);
    urlValues.set('page', "replace");

    var pageCounter = "";
    var totalRecords = 0;
    if (this.state.restSparingAdverbsData.successful) {

      totalRecords = this.state.restSparingAdverbsData.data.totalRecordsCount;

      pageCounter = <PageCounterNew
        elementCount={this.state.restSparingAdverbsData.data.totalRecordsCount}
        urlValues={urlValues}
        urlDeafulValues={this.urlDeafulValues}
        elementOnPage={this.state.rowsCount}
        currentPage={parseInt(this.searchSparingItem.page) + 1}
        searchName={""}
        linkTo={"sparingpartnerzy_search"}
      />
    }

    return (

      <div>

        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">
            <div class="main-subpage">
              <div class="head">

                <img src={`/images/login.png`} />
                <p>Sparingpartnerzy</p>
              </div>


              <div class="main-subpage-subtitle">

                {description}<br />
                Ilość ogłoszeń: {totalRecords} <br /><br />


              </div>

              <div style={{ clear: 'both' }}>


                <div class="main-subpage-subtitle">


                </div>
                {adverbs}

              </div>

              <div style={{ clear: 'both' }}>
                <br /><br /><br />
                <b class="add_adv">
                  <a href="" class="rollover_edit">
                    <div class="btn-more semibold">Dodaj bezpłatne ogłoszenie
                    </div>
                  </a></b>
              </div>

              <div >
                {pageCounter}
              </div>

            </div>

          </div>
        </div>

        <Footer />

      </div>

    );
  }
}

export default SparingpartnersSearchPage;
