import React from 'react';

import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl"; // the locale you want

import moment from 'moment'
import history from "../components/history";

let plLocate = require('moment/locale/pl');
moment.locale('pl', plLocate)

//KLASA WYSWIETLA SZCZEGOLY KONKRETNYCH ZAJEC SZKOLKI
class ClubReservationRedirectPage extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            title: "RezerwujKort.pl - Rezerwację"
        }
    }

    async componentDidMount() {

        document.title = "Rezerwacje - RezerwujKort.pl";
        registerLocale("pl", pl); 
        history.push('/klub/Warszawskie_Towarzystwo_Sportowe_DeSki/rezerwacja_kortu_1');
        window.location.reload();
    }

    render() {

        return (

            <div>

            </div>
        );
    }
}
export default ClubReservationRedirectPage;
