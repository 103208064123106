import DateTimeNow from "../utils/DateTimeNow";
import AuthService from "../services/auth.service";

export default async function FetchGetDataWithAuth(url, token) {

	let data = [];
	var responseStatus;
	var errorMessage;
	var test;

	url = "/rest" + url;
	
	var logInfo = "LogDebug __" + DateTimeNow() + "__ FetchGetDataNoAuth ";

	console.log(logInfo + " Start FetchGetDataNoAuth url " + JSON.stringify(url)); //usun
	await fetch(url, {
		method: 'GET',
		withCredentials: true,
		credentials: 'include',
		headers: {
			'Authorization': 'Bearer ' + token,
			'Content-Type': 'application/json',
		}
	})
		.then(async response => {
			const isJson = response.headers.get('content-type')?.includes('application/json');
			data = isJson && await response.json();
			//console.log(logInfo + " response.ok " + response.ok); //usun
			if (!response.ok) {
				const error = (data && data.message) || response.status;
				responseStatus = response.status;
				return Promise.reject(error);
			} else {
				//console.log(logInfo + " response.ok FetchGetDataNoAuth url " + JSON.stringify(url) + " data: " + JSON.stringify(data)); //usun
				return data;
			}
		})
		.catch((error) => {
			console.log(logInfo + 'Errorrrrrrrrrrrrrrrrrrrrr 22: ', error); //todo log
			console.log(logInfo + 'this.state.responseStatus: ', responseStatus); //todo log
			errorMessage = error.toString();
			console.log(logInfo + 'There was an error!', error); //todo log
		});

	return data;
}



