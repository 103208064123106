import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';

import { Link } from 'react-router-dom'
import { Button, Table, Card } from "react-bootstrap";
import DateTimeNow from "../utils/DateTimeNow";

import history from "../components/history";
import FechGetLessonClassStudentsStat from "../classAppFechData/FechGetLessonClassStudentsStat";
import FechGetDeleteStudentFromClass from "../classAppFechData/FechGetDeleteStudentFromClass";
import PageCounterNewGet from '../components/PageCounterNewGet';

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const TabAllLessons = React.lazy(() => import('../classAppComponents/TabAllLessons'));

//KLASA WYSWIETLA DANE UCZESTNIKA W KONKRETNEJ SZKOLCE
//SZKOLKA-UCZESTNICY-SZCZEGOLY
class PageShowClassStudentsOne extends React.Component {

  emptyItem = {
    id: this.props.id,
    name: this.props.name,
    surname: this.props.surname,
    email: this.props.email,
    phone: this.props.phone
  };

  constructor(props) {
    super(props);

    this.state = {
      title: "RezerwujKort.pl - Szkółki tenisowe",
      logInfo: "LogDebug __" + DateTimeNow() + "__ PageShowClass ",
      data: [],
      studentData: [],
      newsCount: "",
      rowsCount: "25",
      restClassData: {
        successful: false,
        status: '',
        message: '',
        data: []
      },
      restLessonsData: {
        successful: false,
        status: '',
        message: '',
        data: []
      }
    }
    this.deleteFromClass = this.deleteFromClass.bind(this);
  }

  async componentDidMount() {

    document.title = this.state.title;

    var currentPage = 1;
    if (this.props.match.params.page !== undefined) {
      currentPage = this.props.match.params.page;
    }

    this.setState({
      restLessonsData: await FechGetLessonClassStudentsStat(this.props.match.params.club,
        this.props.match.params.id,
        this.props.match.params.idstudent,
        currentPage - 1)
    });
  }

  async deleteFromClass() {

    const answer = window.confirm("Czy napewno chcesz usunąć uczestnika ze szkółki?");
    if (answer) {
      // Save it!
      this.setState({
        restClassData: await FechGetDeleteStudentFromClass(this.props.match.params.club,
          this.props.match.params.id,
          this.props.match.params.idstudent)
      });

      history.push("/class_app/" + this.props.match.params.club + "/class_show_students/" + this.props.match.params.id, "Uczestnik szkółki został usunięty");
      window.location.reload();
    } else {
      console.log("Thing was not saved to the database.");
    }
  }

  render() {

    var msg = this.props.location.state;
    var button = "";
    if (msg === undefined) {
      button = "";
    } else {
      button = <div class="tenis-center-content-info">+  {msg}  + </div>;
    }

    var allLessons;
    if (typeof this.state.restLessonsData.data !== 'undefined') {
      if (typeof this.state.restLessonsData.data.lessonsStudent !== 'undefined') {
        allLessons = this.state.restLessonsData.data.lessonsStudent;
      }
    }

    var url = "class_app/" + this.props.match.params.club + "/admin/class_show_students_edit/" +
      this.props.match.params.id + "/" + this.props.match.params.idstudent + "/replace";

    var totalUserLessonsInClass = 0;
    var totalUserLessonsInClassPages = 0;
    var className;
    var classTrainer;
    var pastClassesCount;
    var futureClassesCount;
    var totalLessonsInClass;
    var presence;
    var absence;
    var studentName;
    var studentSurname;
    var studentPhone;
    var studentEmail;
    if (typeof this.state.restLessonsData.data !== 'undefined') {
      totalUserLessonsInClass = this.state.restLessonsData.data["totalUserLessonsInClass"];
      totalUserLessonsInClassPages = this.state.restLessonsData.data["totalUserLessonsInClassPages"];
      className = this.state.restLessonsData.data["className"];
      classTrainer = this.state.restLessonsData.data["classTrainer"];
      studentName = this.state.restLessonsData.data["studentName"];
      studentSurname = this.state.restLessonsData.data["studentSurname"];
      studentPhone = this.state.restLessonsData.data["studentPhone"];
      studentEmail = this.state.restLessonsData.data["studentEmail"];
      totalLessonsInClass = this.state.restLessonsData.data["totalLessonsInClass"];
      pastClassesCount = this.state.restLessonsData.data["pastClassesCount"];
      futureClassesCount = this.state.restLessonsData.data["futureClassesCount"];
      presence = this.state.restLessonsData.data["presence"];
      absence = this.state.restLessonsData.data["absence"];
      //console.log(" this.state.restLessonsData.data 11: " + JSON.stringify(this.state.restLessonsData.data)); //usun
      //console.log(" totalUserLessonsInClassPages 11: " + JSON.stringify(totalUserLessonsInClassPages)); //usun
    }

    var currentPage = 1;
    if (this.props.match.params.page) {
      currentPage = parseInt(this.props.match.params.page)
    }

    var beltClassApp = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp club={this.props.match.params.club} />
      </Suspense>
    </div>

    var tabAllLessons = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <TabAllLessons club={this.props.match.params.club}
          restLessonsData={allLessons}
          showLessonPresence={1} />
      </Suspense>
    </div>

    var pageCounter = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <PageCounterNewGet
          elementCount={totalUserLessonsInClass}
          elementOnPage={this.state.rowsCount}
          currentPage={parseInt(this.props.match.params.page)}
          linkTo={url}
        />
      </Suspense>
    </div>

    return (

      <div>
        {beltClassApp}

        <div id="content">
          <div class="container">

            

            <br /><br /><br /><br />

            {button}

            <Card style={{ width: '100%' }}>
              <Card.Body>
                <Card.Title>Szkółka: {className}</Card.Title>
                <Card.Title>Trener: {classTrainer}</Card.Title>
              </Card.Body>
            </Card>

            <br />
            <Card style={{ width: '100%' }}>
              <Card.Body>
                <Card.Title>Szczegóły Uczestnika:</Card.Title>
                <Table responsive="sm">

                  <tbody>
                    <tr>
                      <td><b>Imię:</b> </td>
                      <td>{studentName}</td>
                      <td><b>Nazwisko:</b> </td>
                      <td>{studentSurname}</td>
                    </tr>
                    <tr>
                      <td><b>Telefon:</b></td>
                      <td>{studentPhone}</td>
                      <td><b>Email:</b></td>
                      <td>{studentEmail}</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            <br />
            <Card style={{ width: '100%' }}>
              <Card.Body>
                <Card.Title>Podsumowanie:</Card.Title>
                <Table responsive="sm">

                  <tbody>
                    <tr>
                      <td><b>Liczba wszystkicha zajęć w szkółcę:</b></td>
                      <td>{totalLessonsInClass}</td>
                      <td><b>Liczba zajęć w których zapisany jest uczestnik: </b></td>
                      <td>{totalUserLessonsInClass}</td>
                    </tr>
                    <tr>
                      <td><b>Liczba zajęć które się odbyły:</b></td>
                      <td>{pastClassesCount}</td>
                      <td><b>Liczba zaplanowanych zajęć:</b></td>
                      <td>{futureClassesCount}</td>
                    </tr>
                    <tr>
                      <td><b>Obecności (do dziś):</b></td>
                      <td>{presence}</td>
                      <td><b>Nieobecności (do dziś):</b></td>
                      <td>{absence}</td>
                    </tr>

                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            <br />

            <Link to={"/class_app/" + this.props.match.params.club + "/class_show_students/" + this.props.match.params.id}>
              <Button variant="primary" type="submit">
                Powrót
              </Button>&nbsp;&nbsp;
            </Link>
            

            <Button variant="primary" onClick={this.deleteFromClass}>
              Usuń ucznia ze szkółki "{className}""
            </Button>&nbsp;&nbsp;

            <br /><br />
            <b>Lista wszystkich zajęć w których zapisany jest uczestnik ({totalUserLessonsInClass}):</b>
            <br /><br />

            {tabAllLessons}

            <br />

            {pageCounter}

            <br />

            

            {/* <Button variant="primary" type="submit">
              Dodaj komentarz
            </Button>&nbsp;&nbsp; */}

            {/* <br /><br />
            <b>Lista wszystkich uczestników:</b>
            <br /><br /> */}
          </div>
        </div>


      </div>
    );
  }
}
export default PageShowClassStudentsOne;


