import React, { Suspense } from "react";

import { Spinner, Button, Table, Card } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl"; // the locale you want

import FechGetOneTicket from "../APP_RESERVATION_FECH_DATA/FechGetOneTicket";
import FechGetOneTicketLogs from "../APP_RESERVATION_FECH_DATA/FechGetOneTicketLogs";

import BeltReservationApp from "../APP_RESERVATION_COMPONENTS/BeltReservationApp";

import queryString from "query-string";
import moment from "moment";
import ParseLinkToArray from "../utils/ParseLinkToArray";
import HeadersCsv from "../APP_RESERVATION_UTILS/HeadersCsv";
import { CSVLink } from "react-csv";

//UTILS
import DateTimeNow from "../utils/DateTimeNow";

const TabAllTicketReservations = React.lazy(() =>
  import("../APP_RESERVATION_COMPONENTS/TabAllTicketReservations")
);
const TabAllTicketChangeLogs = React.lazy(() =>
  import("../APP_RESERVATION_COMPONENTS/TabAllTicketChangeLogs")
);

let plLocate = require("moment/locale/pl");
moment.locale("pl", plLocate);

//KLASA WYSWIETLA SZCZEGOLY KONKRETNYCH ZAJEC SZKOLKI
class PageTicketDetails extends React.Component {
  TRAINER_SELECT_DESC = "Wybierz trenera";
  DATE_SELECT_DESC = "Wybierz datę";
  CLASS_SELECT_DESC = "Wybierz szkółke";

  urlDeafulValues = [
    this.TRAINER_SELECT_DESC,
    this.CLASS_SELECT_DESC,
    this.DATE_SELECT_DESC,
  ];

  urlValue = "";
  headers = HeadersCsv.reservationStandard();
  deafulValues = [
    this.TRAINER_SELECT_DESC,
    this.CLASS_SELECT_DESC,
    this.DATE_SELECT_DESC,
    this.STATUS_SELECT_DESC,
    this.DEAFULT_SELECT_DESC,
  ];

  postData = {
    clientId: "",
    sortBy: "",
    ticketStatus: "",
    page: "0",
  };

  constructor(props) {
    super(props);

    this.state = {
      title: "RezerwujKort.pl - Rezerwację",
      errorToManyRecordsToExport: false,
      data: [],
      restTicketData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
      restTicketLogsData: {
        successful: false,
        status: "",
        message: "",
        data: [],
      },
    };
    this.csvLinkEl = React.createRef();
    this.downloadReport = this.downloadReport.bind(this);
  }

  async componentDidMount() {
    document.title = "Rezerwacje - RezerwujKort.pl";
    registerLocale("pl", pl); // register it with the name you want
    const {
      location: { search },
    } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.postData = await ParseLinkToArray(
      this.urlValue,
      this.postData,
      this.urlDeafulValues
    );

    this.setState({
      restTicketData: await FechGetOneTicket(
        this.props.match.params.club,
        this.props.match.params.id
      ),
      restTicketLogsData: await FechGetOneTicketLogs(
        this.props.match.params.club,
        this.props.match.params.id
      ),
    });
  }

  async downloadReport() {
    var dataTemp = await FechGetOneTicket(
      this.props.match.params.club,
      this.props.match.params.id
    );
    var data;
    if (typeof dataTemp.data !== "undefined") {
      var data = dataTemp.data.reservation;
    }

    this.setState({ errorToManyRecordsToExport: false });
    this.setState({ data: data }, () => {
      setTimeout(() => {
        this.csvLinkEl.current.link.click();
      });
    });
  }

  render() {
    var fileName = "raport " + DateTimeNow();
    const { data } = this.state;

    var loader = "";
    if (!this.state.restTicketData.successful) {
      loader = (
        <div id="loader_div">
          <Spinner animation="border" />
        </div>
      );
      totalRecords = this.state.restTicketData.data;
    }

    var totalRecords = "";
    var totalRecordsLogs = "";
    var tabAllTicketReservations = "";
    var tabAllTicketChangeLogs = "";
    var searchPanel = "";
    var baseInfo = "";
    if (this.state.restTicketData.successful) {
      totalRecords = this.state.restTicketData.data.reservation.length;
      tabAllTicketReservations = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <TabAllTicketReservations
              club={this.props.match.params.club}
              restLessonsData={this.state.restTicketData.data.reservation}
            />
          </Suspense>
        </div>
      );

      totalRecordsLogs = this.state.restTicketLogsData.data.totalElements;
      tabAllTicketChangeLogs = (
        <div>
          <Suspense
            fallback={
              <div>
                <Spinner
                  style={{ borderStyle: "dotted", marginLeft: "50%" }}
                  animation="border"
                />
              </div>
            }
          >
            <TabAllTicketChangeLogs
              club={this.props.match.params.club}
              restLessonsData={this.state.restTicketLogsData.data.content}
            />
          </Suspense>
        </div>
      );

      baseInfo = (
        <Card style={{ width: "100%" }}>
          <Card.Body>
            <Card.Title>Informacje o karnecie:</Card.Title>
            <Table responsive="sm">
              <tbody>
                <tr>
                  <td>
                    <b>Klient:</b>
                  </td>
                  <td>
                    {this.state.restTicketData.data.clientName}&nbsp;
                    {this.state.restTicketData.data.clientSurname}&nbsp; (tel.{" "}
                    {this.state.restTicketData.data.clientPhone})
                    {this.state.restTicketData.data.clientOnline === true ? (
                      <>
                        <br />
                        (Klient z www)
                      </>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td>
                    <b>Rodzaj karnetu:</b>
                  </td>
                  <td>
                    {this.state.restTicketData.data.ticketType} (
                    {this.state.restTicketData.data.ticketPriceType})
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Wartość karnetu:</b>
                  </td>
                  <td>{this.state.restTicketData.data.ticketValue}</td>
                  <td>
                    <b>Rezerwacje przeszłe:</b>
                  </td>
                  <td>
                    {this.state.restTicketData.data.reservationPast} Godzin:{" "}
                    {this.state.restTicketData.data.reservationPastHour}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Wykorzystano:</b>
                  </td>
                  <td>{this.state.restTicketData.data.ticketUsedValue}</td>
                  <td>
                    <b>Rezerwacje zaplanowane:</b>
                  </td>
                  <td>
                    {this.state.restTicketData.data.reservationPlan} Godzin:{" "}
                    {this.state.restTicketData.data.reservationPlanHour}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Nieroliczonych:</b>
                  </td>
                  <td>
                    {this.state.restTicketData.data.ticketUnresolvedValue}
                  </td>
                  <td>
                    <b>Data wygaśnięcia:</b>
                  </td>
                  <td>{this.state.restTicketData.data.dateStop}</td>
                </tr>
                <tr>
                  <td>
                    <b>Pozostało:</b>
                  </td>
                  <td>{this.state.restTicketData.data.ticketFreeValue}</td>
                  <td>
                    <b>Nazwa karnetu:</b>
                  </td>
                  <td>{this.state.restTicketData.data.ticketName}</td>
                </tr>
                <tr>
                  <td>
                    <b>Kto utworzył:</b>
                  </td>
                  <td>{this.state.restTicketData.data.createdByEmail}</td>
                  <td>
                    <b>Kiedy dodany:</b>
                  </td>
                  <td>{this.state.restTicketData.data.createdAt}</td>
                </tr>
                <tr>
                  <td>
                    <b>Czy usunięty:</b>
                  </td>
                  <td>{this.state.restTicketData.data.deleted}</td>
                  <td>
                    <b>Kiedy usunięty:</b>
                  </td>
                  <td>{this.state.restTicketData.data.deletedAt}</td>
                </tr>
                <tr>
                  <td>
                    <b>Opis:</b>
                  </td>
                  <td>{this.state.restTicketData.data.description}</td>
                  <td>
                    <b></b>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      );
      var errorToManyRecordsToExport;
      if (this.state.errorToManyRecordsToExport) {
        errorToManyRecordsToExport = (
          <div style={{ marginTop: 20, lineHeight: "25px", color: "red" }}>
            <b>
              Maksymalnie można eksportować:{" "}
              {this.state.restUtilsData.data.maxCsvRecords} rekordów!
            </b>
          </div>
        );
      }
    }

    return (
      <div>
        <div id="content">
          <BeltReservationApp club={this.props.match.params.club} />

          {baseInfo}

          <br />
          <br />
          <b>Lista wszystkich zmian w karnecie: ({totalRecordsLogs}):</b>
          <br />
          <br />

          {tabAllTicketChangeLogs}

          <br />
          <br />
          <b>Lista wszystkich rezerwacji w karnecie: ({totalRecords}):</b>
          <Button
            variant="success"
            size="lg"
            onClick={this.downloadReport}
            style={{ "margin-left": "20px", float: "right" }}
          >
            Eksportuj dane
          </Button>
          {errorToManyRecordsToExport}

          <CSVLink
            headers={this.headers}
            filename={fileName}
            data={data}
            ref={this.csvLinkEl}
            separator={";"}
          />
          <br />
          <br />

          {loader}

          {tabAllTicketReservations}
        </div>
      </div>
    );
  }
}
export default PageTicketDetails;
