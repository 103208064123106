import React from 'react';

import Belt from '../components/Belt';
import Footer from '../components/Footer';


class RegisterPage extends React.Component {
  
  state = {
      title:"RezerwujKort.pl - Rejestracja"
    }

  componentDidMount() {
    document.title = this.state.title

    }


  render() {
    return (

      <div>

      <Belt />

      <div id="content">
                    <div class="container">
                        <div class="container">
                            <div class="main-subpage-info">
                                <p>
                                Konto zostało utworzone.<br />
                                Na adres email podany w formularzu wysłano wiadomość e-mail. 
                                Zawiera ona link w którego należy kliknąć aby aktywować konto.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>


      <Footer />

    </div>
    );
  }
}
export default RegisterPage;
