import FetchGetDataWithAuthNEW from "../services/FetchGetDataWithAuthNEW";

export default async function FechGetLessonClassDetails(club, lessonId) {

  var url = "/class_app/" + club + "/admin/lesson/" + lessonId;

  var data = await FetchGetDataWithAuthNEW(url)

  return data;
}
