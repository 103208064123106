import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";
import { Table, Card } from "react-bootstrap";

import Belt from '../components/Belt';
import Footer from '../components/Footer';

import AuthService from "../services/auth.service";
import DateTimeNow from "../utils/DateTimeNow";
import PageCounterNew from '../components/PageCounterNew';

import ReactNotifications from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import NotificationDraft from '../Notification/NotificationDraft'

import FechGetLessonClassDetails from '../classAppFechData/FechGetLessonClassDetails'
import FechPostLessonCommentsBySearch from '../classAppFechData/FechPostLessonCommentsBySearch'

import ParseLinkToArray from "../utils/ParseLinkToArray"
import queryString from 'query-string'

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const TabAllComments = React.lazy(() => import('../classAppComponents/TabAllComments'));
const TabAllClassLessonStudents = React.lazy(() => import('../classAppComponents/TabAllClassLessonStudents'));
const AddStudentToLesson = React.lazy(() => import('../classAppForms/AddStudentToLesson'));
const AddCommentToLesson = React.lazy(() => import('../classAppForms/AddCommentToLesson'));

//KLASA WYSWIETLA SZCZEGOLY KONKRETNYCH ZAJEC SZKOLKI
class PageLesson extends React.Component {

  postData = {
    lessonId: '',
    page: ''
  };

  urlDeafulValues = [];

  constructor(props) {
    super(props);

    this.state = {
      title: "RezerwujKort.pl - Szkółki tenisowe",
      logInfo: "LogDebug __" + DateTimeNow() + "__ PageLesson ",
      currentUser: AuthService.getCurrentUser(),
      data: [],
      newsCount: "",
      currentPage: "1",
      rowsCount: "5",
      refreshAddStudent: "",
      refreshAddComment: "",
      restLessonsData: {
        successful: false,
        status: '',
        message: '',
        data: []
      },
      restLessonsCommentsData: {
        successful: false,
        status: '',
        message: '',
        data: []
      }
    }
    this.handlerRefreshAddStudent = this.handlerRefreshAddStudent.bind(this);
    this.handlerRefreshAddComment = this.handlerRefreshAddComment.bind(this);
  }

  async componentDidMount() {

    document.title = this.state.title;

    const { location: { search } } = this.props;
    this.state.search = search;
    this.urlValue = queryString.parse(this.state.search);

    this.postData = await ParseLinkToArray(this.urlValue, this.postData, this.urlDeafulValues);
    this.postData.lessonId = this.props.match.params.id;

    this.state.restLessonsData = await FechGetLessonClassDetails(this.props.match.params.club,
      this.props.match.params.id);

    this.state.restLessonsCommentsData = await FechPostLessonCommentsBySearch(this.props.match.params.club,
      this.postData);

    this.setState({
      refreshAddStudent: 0,
      refreshAddComment: 0
    });
  }

  async handlerRefreshAddStudent() {
    this.setState({
      refreshAddStudent: this.state.refreshAddStudent + 1
    });
  }

  async handlerRefreshAddComment() {
    this.setState({
      restLessonsCommentsData: await FechPostLessonCommentsBySearch(this.props.match.params.club,
        this.postData)
    });
  }

  render() {

    var notification = this.props.location.state;

    if (notification !== undefined &&
      notification[0] !== '' &&
      notification[1] !== '' &&
      notification[2] !== undefined) {
      if (notification[0] === 'success') {
        NotificationDraft.success(notification[1], notification[2]);
      } else {
        NotificationDraft.error(notification[1], notification[2]);
      }
    }

    var start = "1"
    if (this.state.currentPage > 1) {
      start = this.state.rowsCount * (this.state.currentPage - 1);
    }

    const urlValues = new Map();
    urlValues.set('page', "replace");

    var lessonsComments;
    var lessonCommentsCounter;
    if (this.state.restLessonsCommentsData.successful) {
      lessonsComments = <div>
        <Suspense fallback={<div>
          <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
        </div>}>
          <TabAllComments
            restCommentsData={this.state.restLessonsCommentsData.data.content}
            club={this.props.match.params.club}
            handler={this.handlerRefreshAddComment} />
        </Suspense>
      </div>;

      lessonCommentsCounter = <PageCounterNew
        elementCount={this.state.restLessonsCommentsData.data.totalElements}
        urlValues={urlValues}
        urlDeafulValues={this.urlDeafulValues}
        elementOnPage={this.state.rowsCount}
        currentPage={parseInt(this.postData.page) + 1}
        searchName={"comments_search"}
        linkTo={"class_app/" + this.props.match.params.club + "/lesson/" + this.props.match.params.id}
      />
    }

    var beltClassApp = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp club={this.props.match.params.club} />
      </Suspense>
    </div>

    var addStudentToLesson = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <AddStudentToLesson
          club={this.props.match.params.club}
          classid={this.state.restLessonsData.data.classId}
          lessonid={this.props.match.params.id}
          handler={this.handlerRefreshAddStudent}
          refresh={this.state.refreshAddStudent} />
      </Suspense>
    </div>

    var tabAllClassLessonStudents = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <TabAllClassLessonStudents
          club={this.props.match.params.club}
          start={start}
          page={this.state.currentPage}
          classid={this.state.restLessonsData.data.classId}
          lessonId={this.props.match.params.id}
          refresh={this.state.refreshAddStudent} />
      </Suspense>
    </div>

    var addCommentToLesson = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <AddCommentToLesson
          club={this.props.match.params.club}
          classid={this.state.restLessonsData.data.classId}
          lessonid={this.props.match.params.id}
          handler={this.handlerRefreshAddComment} />
      </Suspense>
    </div>

    return (

      <div>
        <ReactNotifications />
        {beltClassApp}

        <div id="content">
        <div style={{ paddingTop: '55px'}}>


            <Card style={{ width: '100%' }}>
              <Card.Body>
                <Card.Title>Informacje o zajęciach:</Card.Title>
                <Table responsive="sm">

                  <tbody>
                    <tr>
                      <td><b>Nazwa szkółki:</b></td>
                      <td>{this.state.restLessonsData.data.className}</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><b>Kort:</b></td>
                      <td>{this.state.restLessonsData.data.court}</td>
                      <td></td>
                      <td></td>
                    </tr>

                    <tr>
                      <td><b>Data:</b></td>
                      <td>{this.state.restLessonsData.data.date}</td>
                      <td><b>Dzień:</b></td>
                      <td>{this.state.restLessonsData.data.dayOfWeek}</td>
                    </tr>
                    <tr>
                      <td><b>Godzina start:</b></td>
                      <td>{this.state.restLessonsData.data.hourStart}</td>
                      <td><b>Godzina koniec:</b></td>
                      <td>{this.state.restLessonsData.data.hourStop}</td>
                    </tr>
                    <tr>
                      <td><b>Trener:</b></td>
                      <td>{this.state.restLessonsData.data.trainerName} {this.state.restLessonsData.data.trainerSurname}</td>
                      <td><b>Utworzone:</b></td>
                      <td>{this.state.restLessonsData.data.createdAt}</td>
                    </tr>
                    <tr>

                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            <br /><br />

            {addStudentToLesson}

            <br /><br />
            <b>Lista obecności:</b>
            <br /><br />

            {tabAllClassLessonStudents}

            <br /><br />
            <b>Komentarze do zajęć:</b>
            <br /><br />

            {lessonsComments}

            {lessonCommentsCounter}

            <br /><br />
            {addCommentToLesson}

          </div>
        </div>


      </div>
    );
  }
}
export default PageLesson;
