export default function DateTimeNow() {

	var today = new Date();
	var day = today.getDate();
	if(day < 10){
		day = '0' + day;
	}
	var month = today.getMonth()+1;
	if(month.toString.length === 1){
		month = '0' + month;
	}
	var date = today.getFullYear()+'-'+(month)+'-'+day;
	var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
	var dateTime = date + ' ' + time;

	return dateTime;
}

