import React from 'react';

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';
import Footer from '../components/Footer';

const RegulationsPage = () => {
  return (

    <div>

      <Belt />

      <BeltLogged />

      <div id="content">
        <div class="container">


          <div class="main-subpage-reg">


            <h3 style={{ 'margin-left': '35%', 'font-size': '15px' }}>Regulamin korzystania z usług serwisu</h3> <br />


            <b>I. POSTANOWIENIA OGÓLNE</b><br /><br />

            <b>1. WSTĘP</b><br /><br />

            1.1 Esor z siedzibą w Starachowicach (dalej „rezerwujkort.pl” lub „Serwis”) ustala Regulamin korzystania z 
            usług Serwisu rezerwujkort.pl (dalej „Regulamin”).<br />
            1.2 Regulamin określa zasady i wymogi techniczne dotyczące korzystania z serwisu rezerwujkort.pl<br />
            1.3 Regulamin jest dostępny dla Użytkowników Serwisu bezpłatnie na stronie www.rezerwujkort.pl/regulamin i może 
            zostać ze strony pobrany celem utrwalenia bądź wydrukowania.<br />
            1.4 Każdy Użytkownik Serwisu zobowiązany jest zapoznać się z treścią Regulaminu oraz przestrzegać jego 
            postanowień od chwili podjęcia czynności zmierzających do skorzystania z Serwisu.<br />
            1.5 Regulamin dotyczy zarówno serwisu internetowego RezerwujKort.pl (miejsca, gdzie
            dokonujesz rezerwacji internetowe i korzystasz z naszych innych usług) jak i wszystkich
            usług serwisu i aplikacji mobilnych dostarczanych przez RezerwujKort.pl.<br /><br />

            <b>2. WYMOGI TECHNICZNE</b><br /><br />

            Wymagania techniczne niezbędne do współpracy z systemem teleinformatycznym, którym posługuje się Serwis są 
            następujące: połączenie z siecią Internet; przeglądarka Internet Explorer 8.0 (lub wyższa), Firefox 15.0.1 
            (lub wyższa), Google Chrome 21.0.1180.89 (lub wyższa) albo Opera 12.01 (lub wyższa); przeglądarka powinna 
            akceptować pliki „cookie”; przeglądarka powinna mieć włączoną obsługę JavaScript.<br />
            W celu korzystania z Serwisu konieczne jest posiadanie przez Użytkownika aktywnego konta poczty 
            elektronicznej (e-mail). Użytkownik, którego system teleinformatyczny nie spełnia określonych w pkt 2.1.wymagań 
            lub nie ma aktywnego konta e-mail nie ma prawa dochodzić roszczeń z powodu niemożności dostępu do Serwisu.<br /><br />

            <b>3. DEFINICJE</b><br /><br />

            Określeniom, którymi posługuje się Regulamin oraz wszelkie inne dokumenty odnoszące się do jego przedmiotu, 
            nadaje się następujące znaczenie:<br />
            1. Administrator Danych Osobowych – oznacza ESOR - Elektroniczne Systemy Obsługi Rezerwacji z siedzibą w 
            Starachowicach email: kontakt@rezerwujkort.pl tel: 660 916 097, który decyduje o celach i 
            środkach przetwarzania danych osobowych Użytkowników;<br />
            2. Dział Obsługi Użytkowników – oznacza wydzieloną jednostkę organizacyjną Serwisu, której celem jest 
            kontakt z Użytkownikiem w celu uzgadniania warunków realizacji usług świadczonych przez Serwis lub 
            realizacji reklamacji. Dział Obsługi Użytkowników jest dostępny dla Użytkownika pod adresem e-mail: kontakt@rezerwujkort.pl;<br />
            5. Hasło – oznacza ustalony przez Użytkownika ciąg znaków literowych, cyfrowych lub innych, znany 
            jedynie Użytkownikowi, umożliwiający mu dostęp do Konta Użytkownika;<br />
            7. Konto Użytkownika – oznacza zasób internetowy Serwisu, do którego dostęp posiada Użytkownik. 
            Funkcje zainstalowane na Koncie Użytkownika umożliwiają mu swobodne korzystanie z usług Serwisu;
            9. Login(email) – oznacza ciąg znaków ustalonych i przypisanych do Użytkownika, indywidualizujący 
            go i składający się z ciągu nie więcej niż pięćdziesięciu znaków literowych, cyfrowych lub innych i 
            jest wymagany wraz z Hasłem do korzystania z Serwisu. Login jest odpowiednikiem adresu e-mail 
            użytkownika, który jest zobowiązany podać w procesie rejestracji<br />
            10. Ośrodek tenisowy/kortowy – zwany dalej „Ośrodkiem” oznacza ośrodek sportowy/tenisowy któremu 
            Serwis umożliwia zamieszczenie na Stronie internetowej w szczególności, ale nie wyłącznie, informacji 
            dotyczących tego ośrodka oraz grafiku zajęć które odbywają się na kortach w określonych dniach i godzinach;<br />
            11. Użytkownik – użytkownik Serwisu, który za pośrednictwem Serwisu korzysta z danych umieszczonych w Serwisie;<br />
            14. Rejestracja - oznacza zespół czynności polegających na prawidłowym wypełnieniu przez Użytkownika 
            formularza on-line znajdującego się na Stronie internetowej. Podczas procesu Rejestracji Użytkownik 
            zobowiązany jest ustalić Hasło oraz wprowadzić indywidualizujący go Login; <br />
            15. Rezerwacja – oznacza zespół 
            czynności podejmowanych przez Użytkownika po zalogowaniu się na Konto Użytkownika polegających na 
            dokonaniu wyboru kortu oraz daty i godziny w czasie których Użytkownik będzie korzystał z kortu na 
            zasadach i warunkach szczegółowo określonych z właścicielem kortu; <br />
            16. Serwis – oznacza Serwis Internetowy, 
            prowadzony przez ESOR - Elektroniczne Systemy Obsługi Rezerwacji dostępny na stronie internetowej: 
            rezerwujkort.pl Strona internetowa lub Witryna – strona internetowa, pod którą prowadzony jest Serwis; <br />
            17. System teleinformatyczny - oznacza zespół współpracujących ze sobą urządzeń informatycznych i oprogramowania, 
            zapewniający przetwarzanie i przechowywanie, a także wysyłanie i odbieranie danych poprzez sieci telekomunikacyjne 
            za pomocą właściwego dla danego rodzaju sieci urządzenia końcowego;<br />
            18.Użytkownik - oznacza pełnoletnią osobę fizyczną, która posiada pełną zdolność do czynności prawnych lub 
            osobę prawną albo jednostkę organizacyjną, której przepisy szczególne przyznają zdolność prawną, która dokonała Rejestracji w Serwisie;<br /><br />

            <b>4. REJESTRACJA UŻYTKOWNIKA SERWISU</b><br /><br />

            4.1. Użytkownikiem Serwisu może być osoba fizyczna, która ukończyła 18 lat i posiada pełną 
            zdolność do czynności prawnych, jak również osoby prawne oraz jednostki organizacyjne nieposiadające 
            osobowości prawnej, którym osobowość prawna nadawana jest przepisami prawa.<br />
            4.2. Warunkiem korzystania z Serwisu jest poprawne wypełnienie Formularza Rejestracji dostępnego na stronie www.rezerwujkort.pl/register<br />
            4.3. Podczas Rejestracji Użytkownik zobowiązany jest podać następujące dane:<br />
            a. gdy jest osobą fizyczną: imię i nazwisko, numer telefonu kontaktowego, adres e-mail;<br />
            b. gdy jest osobą prawną lub jednostką organizacyjną nie posiadającą osobowości prawnej: imię i 
            nazwisko osoby uprawnionej do reprezentacji Użytkownika, firma pod którą działa Użytkownik, numer telefonu kontaktowego, adres e-mail.<br />
            4.4. Ponadto Użytkownik zobowiązany jest do zapoznania się z postanowieniami niniejszego Regulaminu, 
            przestrzegania jego treści, zaakceptowania oraz ustalenia swojego indywidualnego Loginu i Hasła. 
            Zaakceptowanie Regulaminu następuje poprzez kliknięcie w odpowiednią ikonę.<br />
            4.5. Po prawidłowym wypełnieniu Formularza Rejestracji oraz zaakceptowaniu Regulaminu 
            Użytkownik zostanie zarejestrowany w Serwisie.<br />
            4.6. Dokonanie przez Użytkownika Rejestracji w Serwisie zostanie potwierdzone przez rezerwujkort.pl 
            poprzez wysłanie potwierdzenia na adres e-mail wskazany przez Użytkownika w Formularzu Rejestracji. 
            Na podany przez Użytkownika adres e-mail wysłany zostanie również link, poprzez który Użytkownik 
            może w każdym czasie zapoznać się z Regulaminem;<br />
            4.7. Poprzez dokonanie potwierdzenia Rejestracji Użytkownika w Serwisie pomiędzy Użytkownikiem a 
            Serwisem zostaje zawarta umowa o świadczenie usług Serwisu rezerwujkort.pl, na warunkach określonych w niniejszym Regulaminie.<br />
            4.8. Wypełniając Formularz Rejestracji Użytkownik:<br />
            a. oświadcza, że zapoznał się z Regulaminem i będzie go przestrzegał, podane przez niego w 
            Formularzu dane są kompletne i prawdziwe, nie naruszają praw osób trzecich oraz wyraża zgodę na przetwarzanie danych osobowych;<br />
            b. wyraża zgodę na otrzymywanie za pośrednictwem poczty elektronicznej informacji związanych z Serwisem rezerwujkort.pl<br />
            4.9. Po ustaleniu Loginu i Hasła Użytkownik odpowiedzialny jest za jego prawidłowe użycie oraz bezpieczeństwo i 
            zobowiązany jest do zachowania Loginu i Hasła w poufności. Użytkownik ma obowiązek niezwłocznego powiadomienia 
            Serwisu o utracie Loginu i Hasła bądź dysponowaniu nim przez osoby nieuprawnione. Serwis nie ponosi 
            odpowiedzialności za szkody spowodowane ujawnieniem przez Użytkownika Loginu lub Hasła innym osobom.<br /><br />

            <b>5. KONTO UŻYTKOWNIKA</b><br /><br />

            5.1 Po poprawnym zarejestrowaniu Użytkownik otrzyma dostęp do swojego indywidualnego Konta. Korzystać z 
            Konta Użytkownik będzie mógł po zalogowaniu się w Serwisie przy użyciu swojego Loginu i Hasła.<br />
            5.2 Każdy Użytkownik może posiadać wyłącznie jedno Konto w Serwisie.<br />
            5.3 Aktywacja Konta Użytkownika nastąpi po przesłaniu przez Serwis potwierdzenia Rejestracji na adres 
            e-mail Użytkownika wraz z linkiem odsyłającym Użytkownika do Regulaminu.<br />
            5.4 Na Koncie znajdować się będą dane Użytkownika umieszczone przez niego w Formularzu Rejestracji. 
            Dane będą mogły być uaktualniane przez Użytkownika podczas korzystania przez niego z Serwisu. Aktualizacja 
            danych będzie wymagała wypełnienia Formularza aktualizującego.<br />
            5.5 Użytkownik jest wyłącznym dysponentem swojego Konta i Treści na nim umieszczanych.<br />
            5.6 Serwis może ingerować w Treści i informacje znajdujące się na Koncie Użytkownika tylko wówczas, gdy 
            naruszają one prawa osób trzecich, w tym prawa autorskie, są niezgodne z prawem, Regulaminem lub naruszają dobre obyczaje.<br />
            5.7 Użytkownicy Serwisu zobowiązani są bezwzględnie przestrzegać Regulaminu Serwisu, przepisów 
            obowiązującego prawa, postępować z poszanowaniem innych Uczestników oraz zgodnie z zasadami dobrych obyczajów.<br /><br />

            <b>6. SERWIS</b><br /><br />

            6.1. Serwis nie ma obowiązku sprawdzania, czy informacje oraz ogłoszenia umieszczane przez Ośrodek 
            oraz przez inne osoby są zgodne z rzeczywistością i nie naruszają praw osób trzecich. Serwis nie 
            ponosi odpowiedzialności wobec osób trzecich i Użytkowników za zdolność Ośrodka oraz innych osób 
            zamieszczających ogłoszenie bądź informację na Stronie Internetowej do zawarcia i wykonania umowy.<br />
            6.2.Autorskie prawa majątkowe do elementów graficznych, logotypów Serwisu oraz do layout przysługują 
            firmie ESOR - Elektroniczne Systemy Obsługi Rezerwacji. Zabronione jest używanie przez Użytkowników 
            Serwisu elementów graficznych, logotypów oraz layout Serwisu bez zgody Serwisu. Serwis może wprowadzić 
            czasowe ograniczenia w korzystaniu z Serwisu, w związku z problemami technicznymi, koniecznością 
            przeprowadzenia aktualizacji, przerw technicznych w dostępie do sieci, wynikających z winy dostawcy 
            Internetu, przerw technicznych w systemach transakcji bankowych wynikających z winy dostawcy usług bankowych.<br />
            Serwis nie ponosi odpowiedzialności za szkody powstałe na skutek niemożliwości korzystania z Serwisu jeżeli powstały one z winy Użytkownika.<br />
            6.3 Zastrzegamy sobie prawo do dowolnego modyfikowania usług, wyłączenia ich,
            trwałego lub czasowego usunięcia funkcjonalności w serwisie.<br />
            6.4 Nie ponosimy odpowiedzialności za szkody wynikłe podczas korzystania z naszych
            usług, a w szczególności za:<br />
            a. Szkody spowodowane przez klientów związane z niepoprawnym wykorzystywaniem usług, publikacją treści 
            zastrzeżonych w regulaminie, nieprzestrzeganiem regulaminu;<br />
            b. Szkody poniesione przez klientów, które miały związek z zablokowaniem lub usunięciem konta z Systemu;<br />
            c. Szkody poniesione przez klientów spowodowane zawarciem umowy z usługodawcą w przypadku, gdy nie dopełnił 
            obowiązku wynikających z tej umowy, zrobił to niewłaściwie, lub częściowo, lub nie wypełnił go wcale;<br />
            d. Rzetelność, autentyczność i kompletność danych podanych w naszym serwisie zarówno przez klientów jak i usługodawców;<br />
            e. Problemy i szkody wynikłe z powodów problemów technicznych sieci teleinformatycznej lub błędów w 
            funkcjonowaniu lub konfiguracji oprogramowania z którego korzystasz do obsługi naszych usług;<br />
            f. Szkody wynikłe na skutek braku ciągłości usług;<br />
            6.5 Zastrzegamy sobie prawo do tymczasowego zaprzestania świadczenia wybranych
            lub wszystkich usług ze względu na prace konserwacyjne, modyfikacje serwisu lub z
            powodu braku dostępności usług firm trzecich, z których korzystamy w celu
            dostarczenia usługi.<br /><br />

            <b>7. ZAMIESZCZENIE OGŁOSZEŃ SPARINGPARTNERÓW</b><br /><br />

            7.1. Do umieszczania informacji sparingpartnerów ma zastosowanie „Regulamin korzystania z usług serwisu” ze zmianami opisanymi poniżej.<br />
            7.2. Serwis umożliwia Użytkownikom zamieszczanie ofert osób poszukujących partnerów do gry w tenisa.<br />
            7.3. W celu zamieszczenia oferty Użytkownik zakłada Konto, stosując się do zasad wynikających z „Regulaminu korzystania z usług serwisu”.<br />
            7.4. Użytkownicy nie posiadający Konta mogą, poprzez korzystanie z wyszukiwarki, odnaleźć ogłoszenie, posługując się 
            dostępnymi kryteriami – miasto oraz ośrodek. Użytkownicy nie posiadający Konta nie mają dostępu do danych 
            osobowych osoby zamieszczającej ogłoszenie.<br />
            7.5. Użytkownicy posiadający Konto, mogą poprzez korzystanie z wyszukiwarki odnaleźć ogłoszenie. 
            Użytkownicy posiadający Konto mają dostęp do danych osobowych osoby zamieszczającej ogłoszenie.<br />
            7.6. Użytkownik zamieszczający ogłoszenie ponosi odpowiedzialność za prawdziwość zamieszczonych treści. 
            W razie podania nieprawdziwych danych bądź informacji, Serwis ma prawo do natychmiastowego usunięcia ogłoszenia.<br />
            7.7. Serwis ma prawo do natychmiastowego usunięcia ogłoszenia oraz zablokowania Konta Użytkownika w razie 
            podania przez niego w ogłoszeniu imion i nazwisk innych osób, numerów telefonów, adresów stron internetowych 
            oraz innych danych kontaktowych, a także w razie umieszczenia linków do zdjęć lub innych plików znajdujących 
            się w Internecie. Zamieszczanie w ogłoszeniu trenera jakichkolwiek informacji dotyczących innych osób jest 
            zabronione, a Serwis jest uprawniony do usunięcia takiego ogłoszenia.<br />
            7.8. Przeglądanie i wyszukiwanie ofert jest bezpłatne.<br />
            7.9. Zamieszczenie ogłoszenia jest bezpłatne.<br /><br />

            <b>8. REZERWACJE</b><br /><br />

            8.1 RezerwujKort.pl udostępnia możliwość dokonania rezerwacji przez klienta za
            pośrednictwem swojej strony internetowej oraz przez aplikację mobilną.<br />
            8.2 Korzystanie z rezerwacji możliwe jest wyłącznie po akceptacji niniejszego regulaminu.
            Dodatkowo Obiekt sportowy może nałożyć dodatkowe ograniczenia związane z rezerwacją, zgodne ze 
            swoimi wewnętrznymi regulacjami i zasadami rezerwacji, na które RezerwujKort.pl nie ma wpływu.<br />
            8.3 Klient dokonując rezerwacji oświadcza, że rozumie i zapoznał się z treścią regulaminu obiektu, w którym dokonuje rezerwacji.<br />
            8.4 W momencie dokonania rezerwacji wyrażasz zgodę na przekazanie obiektowi, w którym dokonałeś 
            rezerwacji Twoich danych osobowych które są niezbędne do realizacji świadczonej usługi przez usługobiorcę. 
            Usługodawca staje się wtedy administratorem Twoich danych osobowych<br />
            8.5 RezerwujKort.pl nie jest stroną umów, a jedynie udostępnia Serwis i aplikację mobilną w celu 
            nawiązania kontaktu pomiędzy Usługodawcą a użytkownikiem serwisu. Nie ponosimy żadnej 
            odpowiedzialności wobec Ciebie za niedopełnienie obowiązków Usługodawcy, czy należyte 
            wykonanie umowy. Nie ponosimy również odpowiedzialności wobec Usługobiorców za dokonanie płatności za usługę.<br />
            8.6 Nie pobieramy żadnych opłat za korzystanie z aplikacji, z wyłączeniem sytuacji, gdzie zostało to 
            jasno określone w regulaminie usługi, z której chcesz skorzystać, zostałeś o tym poinformowany i wyraziłeś na to zgodę.<br />
            8.7 Dokonując rezerwacji wyrażasz zgodę na kontakt z Tobą bezpośrednio przez
            Usługodawcę lub za pośrednictwem RezerwujKort.pl, poprzez wiadomości SMS,
            wiadomości E-mail czy kontakt telefonicznego w celach niezbędnych do realizacji
            usługi.<br />
            8.8 Podanie przez Ciebie danych jest dobrowolne, ale bez niektórych danych Usługodawca i my, 
            nie będziemy mogli zrealizować usługi rezerwacji i usług dodatkowych<br /><br />
            
            <b>9. PŁATNOŚCI INTERNETOWE</b><br /><br />

            9.1 W niektórych przypadkach, Usługodawca udostępnia możliwość płatności internetowych za usługę. 
            Płatność realizowana jest przez system płatności Przelewy24.pl (zwanym dalej “Operatorem płatności”). 
            RezerwujKort.pl nie jest instytucją finansową, dostawcą usług płatniczych ani instytucją płatniczą w 
            rozumieniu ustawy z dnia 19 sierpnia 2011 r. o usługach płatniczych.<br />
            9.2. RezerwujKort.pl nie gromadzi i nie przetwarza żadnych informacji dotyczących kart płatniczych klienta, 
            czy identyfikatorów logowania do systemów bankowości.
            Przechowywany jest jedynie identyfikator płatności otrzymany od operatora płatności, służący wyłącznie w 
            celu realizacji usługi, który pozwala na określenie, czy płatność została zrealizowana poprawnie.<br />
            9.3. Operatorem płatności jest firma PayPro S.A. z siedzibą w Poznaniu, przy ulicy Pastelowej 8 (60-198), wpisany 
            do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Poznań Nowe Miasto i 
            Wilda, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000347935, numerem NIP 7792369887, o 
            kapitale zakładowym wynoszącym 5 476 300,00 zł, w całości opłaconym oraz do rejestru krajowych instytucji 
            płatniczych prowadzonego przez Komisję Nadzoru Finansowego pod numerem UKNF IP24/2014.<br />
            9.4. Korzystanie z płatności internetowych wiąże się z akceptacją dodatkowych
            regulaminów, dostępnych pod adresem www.przelewy24.pl<br />
            9.5. Wszelkie reklamacje dotyczące płatności internetowych należy zgłaszać bezpośrednio u Usługodawcy 
            lub operatora płatności. Reklamacje dotyczące usług płatniczych, które zgłosisz nam zostaną przekazane 
            bezpośrednio Usługodawcy lub operatorowi płatności. Nie ponosimy odpowiedzialności za opóźnienia 
            rozpatrzenia reklamacji przez usługodawców i operatora płatności<br />
            9.6. W przpadku udostępnienia przez klub możliwości posługiwania się karnetem wszelkie wynikające z tego 
            nadpłty/niedopłaty użytkownik jest zobowiązany skonsultować bezpośrednio z klubem przed dokonaniem rezerwacji.
            Serwis nie dysponuje wiedza na temat rozliczen wynikajacych z karnetóów udosępnianych uzytkownikom przez klub.<br /><br />
            
            <b>10. Płatność za rezerwację karnetem</b><br /><br />

            10.1 Kluby mają możliwość tworzenia karnetów, które mogą być użyte przez klinetów podczas rezerwacji online. Jeśli 
            wartość karnetu jest niższa niż kwota rezerwacji, klient zostanie proszony o dopłatę.<br />
            10.2 Wysokość karnetów oraz jego dstępność jest decyzją klubu w którym klient dokonuje rezerwacje a rezerwujkort.pl nie 
            ma na to żadnego wpływu.<br /><br />


            <b>II POSTANOWIENIA KOŃCOWE</b><br /><br />


            <b>1. ZASADA POUFNOŚCI</b><br /><br />

            1.1 Podane przez Użytkowników dane osobowe Serwis zbiera i przetwarza zgodnie z obowiązującymi przepisami 
            prawa oraz zgodnie z polityką ochrony danych osobowych zawartą w niniejszym Regulaminie.<br />
            1.2 Użytkownik zobowiązany jest nie ujawniać osobom trzecim informacji dotyczących innych Użytkowników, 
            które otrzymał w związku z korzystaniem z Serwisu, chyba że uzyskał uprzednią zgodę od Użytkownika, którego 
            dane dotyczą. Otrzymane od Serwisu informacje Użytkownik może wykorzystać wyłącznie w celach związanych z korzystaniem z usług Serwisu.<br /><br />

            <b>2. OCHRONA DANYCH OSOBOWYCH</b><br /><br />

            2.1 W celu ochrony danych wdrożyliśmy bezpieczny protokół szyfrowania komunikacji (SSL), szyfrowanie 
            danych oraz kontrolę dostępu, dzięki czemu minimalizujemy skutki ewentualnego naruszenia bezpieczeństwa<br />

            2.2. Poprzez dokonanie rejestracji w Serwisie, Użytkownik wyraża zgodę na przetwarzanie przez Serwis jego 
            danych osobowych w zakresie niezbędnym do realizacji Zamówień oraz komunikacji z Użytkownikiem. Podanie 
            jakichkolwiek danych osobowych jest dobrowolne. Podanie danych określonych w pkt 4 niniejszego Regulaminu 
            jest konieczne do zawarcia z Serwisem umowy o świadczenie usług.<br />
            2.3. Serwis jest Administratorem Danych Osobowych Użytkownika oraz osób reprezentujących Użytkownika, 
            i w związku z tym zobowiązuje się do ochrony i zabezpieczenia tych danych przed nieuprawnionym dostępem do 
            nich osób trzecich. Serwis zobowiązuje się do przetwarzania i wykorzystywania gromadzonych danych osobowych 
            zgodnie z przepisami ustawy z dnia 29 sierpnia 1997 roku o ochronie danych osobowych (Dz.U. Nr 133, poz. 883), 
            niniejszym Regulaminem i zawartymi Umowami.<br />
            2.4. Serwis może przetwarzać dane eksploatacyjne Użytkownika, to jest dane charakteryzujące sposób korzystania 
            przez Użytkownika z Serwisu, takie jak:<br />
            - oznaczenia identyfikujące Użytkownika,<br />
            - oznaczenia identyfikujące zakończenie sieci telekomunikacyjnej lub system teleinformatyczny, z którego korzysta Użytkownik,<br />
            - informacje o rozpoczęciu, zakończeniu oraz zakresie każdorazowego korzystania z Serwisu,<br />
            - informacje o skorzystaniu przez Użytkownika z Serwisu.<br />
            2.5. Po zakończeniu korzystania z Serwisu przez Użytkownika, Serwis nie będzie przetwarzać danych osobowych 
            Użytkownika ani osób reprezentujących Użytkownika, za wyjątkiem danych:<br />
            - niezbędnych do rozliczenia Umowy oraz dochodzenia roszczeń z tytułu płatności za zrealizowane Zamówienie,<br />
            - niezbędnych do celów reklamy, badania rynku oraz zachowań i preferencji Użytkowników z przeznaczeniem wyników 
            tych badań na potrzeby polepszenia jakości usług świadczonych przez Serwis za zgodą Użytkownika,<br />
            - niezbędnych do wyjaśnienia okoliczności niedozwolonego korzystania ze Serwisu,<br />
            - dopuszczonych do przetwarzania na podstawie odrębnych ustaw lub umowy.<br />
            2.6. Dane osobowe Użytkowników, którzy zakończyli korzystanie z Serwisu, są usuwane każdego 1 dnia miesiąca 
            następującego po dniu, w którym Użytkownik zgłosił żądanie usunięcia jego danych osobowych. Użytkownicy 
            oraz osoby reprezentujące Użytkownika mają prawo wglądu do swoich danych, możliwość ich poprawienia, a 
            także prawo domagania się usunięcia danych z witryny Serwisu. Żądanie usunięcia danych z witryny Serwisu 
            jest realizowane poprzez wysłanie do Administratora Danych Osobowych za pośrednictwem poczty elektronicznej 
            stosownego żądania wraz z podaniem swojego imienia i nazwiska.<br />
            2.7. Dane Użytkowników mogą być udostępniane podmiotom uprawnionym do ich otrzymania na mocy obowiązujących 
            przepisów prawa, w tym właściwym organom wymiaru sprawiedliwości.<br />
            2.8. Zbiór danych Użytkowników został zgłoszony do rejestracji Generalnemu Inspektorowi Ochrony Danych Osobowych.<br /><br />

            <b>3. POSTĘPOWANIE REKLAMACYJNE</b><br /><br />

            3.1. Użytkownik może złożyć reklamację, jeżeli usługi przewidziane w niniejszym Regulaminie nie są realizowane 
            przez Serwis lub są realizowane niezgodnie z postanowieniami Regulaminu.<br />
            3.2. W przypadku reklamacji dotyczących trudności w dokonaniu Zamówienia, Użytkownik powinien poinformować Serwis 
            o zaistniałych przeszkodach niezwłocznie, nie później niż w terminie 48 godzin od chwili stwierdzenia nieprawidłowości 
            oraz nie później niż 10 dni od daty wystąpienia trudności.<br />
            3.3. Reklamację można złożyć w formie elektronicznej na adres: kontakt@rezerwujkort.pl. Reklamacja powinna 
            zawierać co najmniej nazwę, pod jaką Użytkownik występuje w Serwisie (login) oraz opis zgłaszanych zastrzeżeń. 
            Reklamacje, które nie zawierają wskazanych danych nie podlegają rozpatrzeniu przez Serwis.<br />
            3.4. Jeżeli podane w reklamacji dane lub informacje wymagają uzupełnienia, przed rozpatrzeniem reklamacji Serwis 
            zwraca się do składającego reklamację o jej uzupełnienie we wskazanym zakresie.<br />
            3.5. Serwis rozpoznaje reklamację w terminie 14 dni od daty jej otrzymania w prawidłowej postaci, z zastrzeżeniem pkt. 3.2.<br />
            3.6. Odpowiedź na reklamację wysyłana jest wyłącznie na adres e-mail przypisany do Konta danego Użytkownika. 
            W szczególnie uzasadnionych przypadkach Serwis może wysłać odpowiedź na inny, wskazany przez składającego 
            reklamację, adres e-mail, który nie jest przypisany do Konta Użytkownika.<br />
            3.7. W przypadku pozytywnego rozpatrzenia reklamacji, Użytkownik otrzymuje pełnowartościową treść bądź zwrot poniesionych kosztów.<br /><br />

            <b>4. ZMIANA REGULAMINU</b><br /><br />

            4.1. Serwis ma prawo do jednostronnej zmiany Regulaminu. Zmiany Regulaminu wchodzą w życie po upływie 7 dni 
            od umieszczenia zmienionego Regulaminu na Stronie internetowej Serwisu.<br />
            4.2. O zmianie Regulaminu Serwis zawiadomi aktualnych Użytkowników Serwisu poprzez przesyłanie im odpowiedniej 
            informacji na adres e-mail Użytkownika. Gdy Użytkownik nie akceptuje nowej treści Regulaminu, obowiązany jest 
            zawiadomić o tym fakcie Serwis przed wejściem Regulaminu w życie. Brak akceptacji nowej wersji Regulaminu wiąże 
            się z koniecznością wyrejestrowania Użytkownika z Serwisu. Nie powiadomienie Serwisu przez Użytkownika przed 
            wejściem w życie Regulaminu o braku jego akceptacji, uważane jest za zaakceptowanie zmian Regulaminu.<br /><br />

            <b>5. ROZWIĄZANIE UMOWY</b><br /><br />

            5.1. Użytkownik może w każdym czasie rozwiązać umowę z Serwisem<br />
            5.2. Rozwiązanie umowy następuje poprzez wypełnienie dostępnego na stronie formularza, który obejmuje oświadczenie 
            o rozwiązaniu umowy. Umowa ulega rozwiązaniu ze skutkiem natychmiastowym w momencie potwierdzenia danych z zastrzeżeniem, że:<br />
            - Użytkownik posiada wgląd do Konta oraz dostęp wyłącznie do funkcji pozwalających uregulować należności wobec 
            Serwisu i sfinalizować umowy zawarte w wyniku zakupu Treści.<br />
            5.3. Z ważnych przyczyn umowa może zostać rozwiązana przez Serwis za wypowiedzeniem, z zachowaniem siedmiodniowego terminu wypowiedzenia.<br /><br />

            <b>6. PRAWO WŁAŚCIWE I ROZWIĄZYWANIE SPORÓW</b><br /><br />

            6.1. Prawem właściwym dla umów pomiędzy Użytkownikiem a Serwisem, których przedmiotem są usługi 
            świadczone przez Serwis na warunkach określonych w Regulaminie, jest prawo polskie.<br />
            6.2. W sprawach nieuregulowanych w Regulaminie zastosowanie mają przepisy kodeksu cywilnego oraz inne bezwzględnie obowiązujące przepisy prawa.<br />
            6.3. Wszelkie spory związane z usługami świadczonymi przez Serwis będą rozstrzygane przez właściwe polskie sądy powszechne.<br /><br />


            <b>7. NIEWAŻNOŚĆ POSTANOWIEŃ</b><br /><br />

            7.1. Jeżeli którekolwiek postanowienie Regulaminu zostanie uznane prawomocnym orzeczeniem sądu za 
            nieważne, pozostałe postanowienia pozostają w mocy.<br /><br />


            <b>8. OCHRONA DANYCH OSOBOWYCH:</b><br /><br />
            Polityka prywatności określa zasady przetwarzania danych osobowych i ich ochrony osób korzystających ze 
            Serwisu Internetowego znajdującego się pod adresem: www.rezerwujkort.pl<br />

            8.1. Administratorem danych osobowych osób korzystających ze serwisu internetowego jest … ESOR - 
            Elektroniczne Systemy Obsługi Rezerwacji z siedzibą w Starachowicach (kod: 27-200), NIP 6642006409 
            adres poczty elektronicznej: kontakt@rezerwujkort.pl  numer telefonu: 660 916 097<br />
            Administrator danych osobowych przetwarza dane osobowe zgodnie z wymogami Rozporządzenie Parlamentu 
            Europejskiego I Rady (UE) 2016/679 w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych 
            osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (RODO) oraz 
            ustawą z dnia 10 maja 2018 roku o ochronie danych osobowych (Dz. U. 2018.1000 z dnia 24.05.2018r.).<br />
            8.2. Gromadzone dane obejmują:<br />
            - imię i nazwisko<br />
            - numer telefonu<br />
            - adres poczty elektronicznej<br />
            Dane osobowe gromadzone są podczas wypełniania przez użytkownika formularza rejestracyjnego.<br />
            8.3. Dane osobowe są przetwarzane w celu:<br />
            - zawarcia i realizacji umów rezerwacji kortów za pośrednictwem Serwisu Internetowego, na podstawie art. 
            6 ust. 1 lit b RODO – do czasu zakończenia realizacji umowy;<br />
            - realizacji przez Administratora danych ciążących na nim obowiązków prawnych, w szczególności wynikających 
            z przepisów prawa podatkowego, na podstawie art. 6 ust. 1 lit c RODO, przez 5 lat od końca roku kalendarzowego, 
            w którym upłynął termin płatności należnego podatku w związku z zawartą umową sprzedaży;<br />
            - udzielenia odpowiedzi na pytanie zawarte w formularzu kontaktowym znajdującym się na Stronie internetowej, 
            na podstawie realizacji prawnie uzasadnionych interesów Administratora w postaci komunikacji z użytkownikami 
            strony (art. 6 ust. 1 lit. f RODO), przez czas nie dłuższy niż potrzebny do udzielenia odpowiedzi na zadane pytanie;<br />
            - dochodzenia roszczeń lub obrony przeciwko roszczeniami w związku usługami świadczonymi przez Administratora 
            danych, na podstawie art. art. 6 ust. 1 lit. f RODO – do czasu przedawnienia roszczeń, określonych w przepisach prawa.<br />

            8.4. Podanie danych jest dobrowolne, jednakże jest konieczne w celu złożenia i realizacji Zamówienia. Brak wyrażenia 
            zgody na przetwarzanie danych w powyższym zakresie uniemożliwi realizację umowy sprzedaży.<br />

            8.5. Zgoda na przetwarzanie danych może być cofnięta w każdym czasie, za pośrednictwem Działu Obsługi Klienta – pod 
            adresem: kontak@rezerwujkort.pl bądź numerem telefonu: 660 916 097 Cofnięcie zgody nie ma wpływu na zgodność z 
            prawem przetwarzania danych, którego dokonano przed cofnięciem zgody.<br />

            8.6. Każdy, czyjego dane są przetwarzane, ma prawo:<br />
            - dostępu do swoich danych osobowych,<br />
            - żądania ich sprostowania,<br />
            - usunięcia danych,<br />
            - wniesienia sprzeciwu wobec przetwarzania,<br />
            - żądania ograniczenia przetwarzania,<br />
            - przenoszenia danych,<br />
            - wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych.<br />
            Administrator ma prawdo odmówić usunięcia danych, jeżeli przemawiają za tym przesłanki prawnie uzasadnione.<br />

            8.7. Dane osobowe nie będą przekazywane do państwa trzeciego ani organizacji międzynarodowych.<br />

            8.8. W trakcie przetwarzania danych osobowych nie będzie dochodzić, na podstawie przekazanych danych osobowych, 
            do zautomatyzowanego podejmowania decyzji lub profilowania osób, które wyraziły zgodę na przetwarzanie swoich danych osobowych.<br />

            8.9. Odbiorcami danych osobowych są:
            - podmioty, z którymi Administrator współpracuje w celu realizacji postanowień Regulaminu, tj. w szczególności – 
            pracownicy i współpracownicy Administratora, podmioty odpowiedzialne za infrastrukturę techniczną, dostawcy 
            usług elektronicznych, podmioty realizujące dostawy produktów oraz obsługujące płatności elektroniczne, dostawcy usług logistycznych.<br />

            8.10. Administrator na Stronie internetowej stosuje wtyczki społecznościowe, które pozwalają Użytkownikowi 
            na udostępnienie treści publikowanych na Stronie internetowej w portalu społecznościowym Facebook. Stosowanie 
            wtyczek powoduje, że serwis społecznościowy otrzymuje informacje o korzystaniu przez Użytkownika ze Strony 
            internetowej i może przypisać je do profilu Użytkownika tworzonego w portalu społecznościowym. Cel i zakres 
            zbierania danych osobowych przez portal Facebook umieszczony jest stronie: facebook.com<br />

            8.11. Administrator danych stosuje środki techniczne i organizacyjne na poziomie zapewniającym ochronę przetwarzanych 
            danych, w szczególności dokłada należytych starań aby nie zostały one udostępnione osobom nieupoważnionym. 
            Do przetwarzania danych dopuszczane są wyłącznie osoby upoważnione przez Administratora.<br />

            8.12. Administrator może udostępnić dane osobowe podmiotom uprawnionym do ich otrzymania na mocy obowiązujących 
            przepisów prawa, w tym organom ścigania i organom wymiaru sprawiedliwości, na ich wyraźne żądanie i w przypadkach prawnie określonych.<br /><br />


            <b>ZAŁĄCZNIK NR 3</b><br /><br />

            Informacja o szczególnych zagrożeniach związanych z korzystaniem z usługi  świadczonej drogą elektroniczną
            oraz o funkcji i celu oprogramowania lub danych niebędących
            składnikiem treści usługi<br />

            Sprzedawca, stosownie do art. 6 pkt 1) Ustawy o świadczeniu usług drogą elektroniczną, informuje o szczególnych 
            zagrożeniach związanych z korzystaniem przez Klientów z  usług świadczonych drogą elektroniczną.<br />

            Informacja dotyczy zagrożeń potencjalnych, które jednak powinny być brane pod uwagę mimo stosowania przez Sprzedawcę 
            systemów zabezpieczających infrastrukturę przed nieuprawnionym oddziaływaniem osób trzecich. Do potencjalnych zagrożeń można zaliczyć:<br />
            1) możliwość otrzymania spamu, czyli niezamówionej informacji reklamowej (handlowej) przekazywanej drogą elektroniczną;<br />
            2) możliwość działania szkodliwego oprogramowania;<br />
            3) możliwość łamania zabezpieczeń w celu pozyskania osobistych i poufnych informacji w celu kradzieży tożsamości, 
            poprzez wysyłanie fałszywych wiadomości elektronicznych przypominających wiadomości autentyczne;<br />
            4) możliwość odnalezienia słabości systemu kryptograficznego, a tym samym umożliwienia jego złamania lub 
            obejścia, a w konsekwencji możliwość pozyskania osobistych i poufnych informacji w celu kradzieży tożsamości;<br />
            5) możliwość zarażenia systemu teleinformatycznego przez różnego rodzaju oprogramowania tworzone w celu 
            wyrządzania szkód, w szczególności wirusy, konie trojańskie czy robaki;<br /><br />

            Sprzedawca zaleca, aby każdy Klient dbał o bezpieczeństwo własnego komputera, poprzez stosowanie programu antywirusowego 
            z aktualną bazą wirusów oraz osobisty firewall.<br />

            Sprzedawca, zgodnie z art. 6 pkt 2) Ustawy o świadczeniu usług drogą elektroniczną, informuje, iż funkcja i  cel oprogramowania 
            lub danych niebędących składnikiem treści usługi, wprowadzanych przez Sprzedawcę do systemu teleinformatycznego, 
            którym posługuje się Klient określone zostały w Polityce prywatności oraz stosowania plików „cookies”.<br />


          </div>

        </div>
      </div>
      <Footer />

    </div >

  );
}

export default RegulationsPage;
