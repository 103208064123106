import React from 'react';

import DateNowYyyyMmDd from '../utils/DateNowYyyyMmDd';
import history from "../components/history";

import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl";

import FechGetClubLongDescBySlug from "../fechData/FechGetClubLongDescBySlug";
import FechGetClubReservation from "../fechData/FechGetClubReservation";
import FechGetClubReservationOnline from "../fechData/FechGetClubReservationOnline";

import "react-datepicker/dist/react-datepicker.css";
import DateTimeNow from "../utils/DateTimeNow";

class PageMktDzis extends React.Component {

    constructor(props) {
        super(props);
        this.printHtml = this.printHtml.bind(this);
        this.printAdresse = this.printAdresse.bind(this);
    }

    state = {
        startDate: new Date(),
        dateResrvation: "",
        dateResrvationDayOfWeek: "",
        dateNextDay: "",
        datePreviousDay: "",
        adverbCount: "",
        currentPage: "1",
        logInfo: "LogDebug __" + DateTimeNow() + "__ PageMktDzis ",
        restClubData: {
            successful: false,
            status: '',
            message: '',
            data: []
        },
        restReservationData: {
            successful: false,
            status: '',
            message: '',
            data: []
        }
    }

    printHtml() {
        return { __html: this.state.restClubData.description };
    }

    printAdresse() {
        return { __html: this.state.restClubData.descriptionShort };
    }

    async componentDidMount() {

        this.state.restClubData = await FechGetClubLongDescBySlug("MKT_Lodz");
        document.title = " Dostępność kortów - RezerwujKort.pl"

        registerLocale("pl", pl);

        if (this.props.match.params.date == undefined) {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            this.state.dateResrvation = yyyy + '-' + mm + '-' + dd;
        } else {
            this.state.dateResrvation = this.props.match.params.date;
        }

        const d = new Date(this.state.dateResrvation)

        var days = ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'];
        this.state.dateResrvationDayOfWeek = days[d.getDay()];
        var nextDay = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1);
        var previousDay = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1);
        this.state.dateNextDay = nextDay.getFullYear() + "-" + String(nextDay.getMonth() + 1).padStart(2, '0') + "-" + String(nextDay.getDate()).padStart(2, '0');
        this.state.datePreviousDay = previousDay.getFullYear() + "-" + String(previousDay.getMonth() + 1).padStart(2, '0') + "-" + String(previousDay.getDate()).padStart(2, '0');

        // this.setState({
        //     restReservationData: await FechGetClubReservation('MKT_Lodz',
        //         4,
        //         DateNowYyyyMmDd())
        // });

        this.setState({
            restReservationData: await FechGetClubReservationOnline('MKT_Lodz', DateNowYyyyMmDd(), 1)
          });

        var ff= this.state.restReservationData;
    }

    handleChange = (startDate) => {
        this.setState({
            calendarDate,
        });
        var calendarDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

        history.push('/klub/' + this.state.restClubData.data.addressWww +
            '/rezerwacja_kortu_1/' + calendarDate.getFullYear() +
            "-" + String(calendarDate.getMonth() + 1).padStart(2, '0') +
            "-" + String(calendarDate.getDate()).padStart(2, '0'));
        window.location.reload();
    };

    render() {

        var today = new Date();
  
        var year = today.getFullYear();
        var month = String(today.getMonth() + 1).padStart(2, '0');
        var day = String(today.getDate()).padStart(2, '0');
        
        var formattedDate = `${year}-${month}-${day}`;

        var tableCourtName = [];
        var tableHours = [];
        if (this.state.restReservationData.successful) {
            for (var courtName in this.state.restReservationData.data.courts) {
                tableCourtName.push(<th><span>  {this.state.restReservationData.data.courts[courtName].courtName}  </span></th>);
            }
        }

        if (this.state.restReservationData.successful) {
            for (var counter0 in this.state.restReservationData.data.courts[0].hours) {
                var temp1 = [];
                for (var counter2 in this.state.restReservationData.data.courts) {

                    if (this.state.restReservationData.data.courts[counter2].hours[counter0].hourStatus === "CLOSE") {
                        temp1.push(<td class="court_td_close">
                            <div class="availabilty-field availabilty-close">zamknięty</div>
                        </td>)
                        temp1.push(<td></td>)
                    } else if (this.state.restReservationData.data.courts[counter2].hours[counter0].hourStatus === "OPEN") {
                        temp1.push(<td class="court_td_open">
                            <div class="availabilty-field availabilty-field availabilty-accesses">dostępny</div>
                        </td>)
                        temp1.push(<td></td>)
                    // } else if (this.state.restReservationData.data.courts[counter2].status[counter0] === 4) {
                    //     temp1.push(<td class="court_td_open">
                    //         <div class="availabilty-field availabilty-field availabilty-accesses">dostępny</div>
                    //     </td>)
                    //     temp1.push(<td></td>)
                    } else {
                        temp1.push(<td class="court_td_reserv">
                            <div class="availabilty-field availabilty-busy">zajęty</div>
                        </td>)
                        temp1.push(<td></td>)
                    }
                }
                tableHours.push(<tr>
                    <td class="court_hours"> {this.state.restReservationData.data.courts[0].hours[counter0].hourName}
                    </td>
                    {temp1}
                </tr>);
            }
        }


        var today = new Date();
        var todayPlus45 = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 45);

        var dateResrvationDate;
        if (this.props.match.params.date == undefined) {
            dateResrvationDate = today;
        } else {
            dateResrvationDate = new Date(this.props.match.params.date);
        }

        var blok = "";
        // if (this.state.restClubData.successful) {
        //     if (this.state.dateResrvation < today || this.state.dateResrvation > todayPlus45) {
        //         blok = <div><br /><br />
        //             <div class="tenis-center-content-info1">Zła data. Na stronie można przeglądać wolne terminy na najbliższe 45 dni. Od 2021-06-06 do 2021-07-21

        //                 <a href=""><div class="">Pokaż dzisiejszy dzień</div></a>
        //             </div>
        //         </div>
        //     }
        // }

        var infoMsg;
        if (this.state.restClubData.successful && this.state.restClubData.data.infoMsg != "") {
            infoMsg = <div><br /><br />
                <div class="tenis-center-content-info1"> {this.state.restClubData.infoMsg}</div>
            </div>
        }


        return (

            <>
                <style>
                    {
                        `#court_rezervation {
            width: 90%;
                margin: auto;
                clear: none;
            /*float: left;*/
            /*position: relative;*/
                text-align: center;
        
        }
         
        #court_rezervation table{
                margin: auto;
            /*position: relative;*/
                text-align: center;
                border-collapse: separate;
                border: 0px;
                border-spacing:2px;
        
        }
        
        
        
        #court_rezervation td{
            padding: 5px;
                border-style:none;
        
        }
        #court_rezervation th{
            padding: 5px;
            color:#2b318d;; 
            font-size: 14px;
            font-weight: normal;
            text-align: center;
        }
        
        .desc a{
            color: #D00;
            font-weight: bold;
        }
        .court_td_close{
            color:white; 
            width: 130px;
            background-color: #e00202;
            text-align: center;
            font-size: 11px;
            height: 25px;
        }
        .court_td_close2{
            color:white; 
            width: 130px;
            height: 25px;
            background-color: #e00202;
            text-align: center;
        }
        .court_td_open{
            color:white; 
            height: 25px;
            width: 130px;
            font-size: 12px;
            background-color: #91d902;
            text-align: center;
        }
        .court_td_reserv{
            color:white; 
            height: 25px;
            width: 130px;
            font-size: 12px;
            background-color: #000000;
            text-align: center;
        }
        .court_hours{
            color:#2b318d; 
            width:100px; 
            font-size: 12px;
            font-weight: lighter;
            text-align: center;
        }
        /* trenerzy */
        .courts_tre_info{
            width: 100%;
            text-align: center;
        }
        .courts_tre_info strong{
            /*color:#bd2e02; */
            color:#2b318d;
            font-size: 14px;
            font-weight: bold;
        }
        .courts_tre_info span{
            /*color:#bd2e02; */
            color:#2b318d;
            font-size: 14px;
        }
        .courts_tre_info a{
            color: #e00202;
            font-weight: bold;
            font-size: 14px;
            text-decoration: none;
        }
        .courts_tre_info a:hover{
           text-decoration: underline;
        }
        
        .courts_tre_adv{
            width: 70%;
            float: left;
            margin-left: 150px;
        
        }
        
        
        
        /* ---------------------------
           KONIEC WYGLAD STRON OSRODKOW
           ---------------------------
        */ 
        
        .main-subpage-info{
            padding: 20px;
            background: #f6f6f6;
            border-radius: 10px;
            min-height: 300px;
            margin-bottom: 30px;
        }
        .main-subpage-info p{
            margin-top: 10%;
            text-align: center;
            font-size: 19px;
            color: #717171;
        }
        
        .pokaz_lig { 
          float: left;
          width: 80%;
          min-height: 100px;
          border: 1px solid #e8e8e8;
            background: #fff;
            
            margin-left: 22px;
            margin-top: 22px;
        
        
            
        -moz-border-radius-bottomleft:20px;
            -webkit-border-bottom-left-radius:20px;
            border-bottom-left-radius:20px;
            
            -moz-border-radius-bottomright:20px;
            -webkit-border-bottom-right-radius:20px;
            border-bottom-right-radius:20px;
            
            -moz-border-radius-topright:20px;
            -webkit-border-top-right-radius:20px;
            border-top-right-radius:20px;
            
            -moz-border-radius-topleft:20px;
            -webkit-border-top-left-radius:20px;
            border-top-left-radius:20px;
        
        }
        
        .pokaz_lig_box_tx
        {
          float: left;
          width: 70%;
        
          margin-top: 40px;
        
              margin-left: 10px;
              
        }
        
        .pokaz_lig_btn
        {
          float: left;
          width: 25%;
        
          margin-left: 10px;
        margin-top: 40px;
        text-align: center;
        }
        
        
        
        .btn-more{
            text-align: center;
            outline: 0;
            float: right;
            cursor: pointer;
            color: #fff;
            font-size: 14px;
            min-width: 98px;
            padding: 2px 5px 4px;
            -webkit-border-radius: 4px; 
            -moz-border-radius: 4px; 
            border-radius: 4px; 
            border: 1px solid #87b319;
            background-color: #a7d435;
            text-shadow: 0px 1px 1px rgba(88, 121, 4, 1);
            background: rgb(167,212,53); /* Old browsers */
            /* IE9 SVG, needs conditional override of 'filter' to 'none' */
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2E3ZDQzNSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM4M2E5MjIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
            background: -moz-linear-gradient(top,  rgba(167,212,53,1) 0%, rgba(131,169,34,1) 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(167,212,53,1)), color-stop(100%,rgba(131,169,34,1))); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(top,  rgba(167,212,53,1) 0%,rgba(131,169,34,1) 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(top,  rgba(167,212,53,1) 0%,rgba(131,169,34,1) 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(top,  rgba(167,212,53,1) 0%,rgba(131,169,34,1) 100%); /* IE10+ */
            background: linear-gradient(to bottom,  rgba(167,212,53,1) 0%,rgba(131,169,34,1) 100%); /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a7d435', endColorstr='#83a922',GradientType=0 ); /* IE6-8 */
            -webkit-box-shadow: inset 0px 1px 3px 0px rgba(255, 255, 255, 0.75);
            -moz-box-shadow:    inset 0px 1px 3px 0px rgba(255, 255, 255, 0.75);
            box-shadow:         inset 0px 1px 3px 0px rgba(255, 255, 255, 0.75);
        
        }
        .btn-more:hover{
            border: 1px solid #6b83b4;
            background: rgb(94,109,143); /* Old browsers */
            /* IE9 SVG, needs conditional override of 'filter' to 'none' */
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzVlNmQ4ZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMzYTQzNzIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
            background: -moz-linear-gradient(top,  rgba(94,109,143,1) 0%, rgba(58,67,114,1) 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(94,109,143,1)), color-stop(100%,rgba(58,67,114,1))); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(top,  rgba(94,109,143,1) 0%,rgba(58,67,114,1) 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(top,  rgba(94,109,143,1) 0%,rgba(58,67,114,1) 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(top,  rgba(94,109,143,1) 0%,rgba(58,67,114,1) 100%); /* IE10+ */
            background: linear-gradient(to bottom,  rgba(94,109,143,1) 0%,rgba(58,67,114,1) 100%); /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5e6d8f', endColorstr='#3a4372',GradientType=0 ); /* IE6-8 */
            text-shadow: 0px 1px 1px rgba(0, 0, 0, 1);
        
        }
        
        
        .btn-more:hover{
            border: 1px solid #6b83b4;
            background: rgb(94,109,143); /* Old browsers */
            /* IE9 SVG, needs conditional override of 'filter' to 'none' */
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzVlNmQ4ZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMzYTQzNzIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
            background: -moz-linear-gradient(top,  rgba(94,109,143,1) 0%, rgba(58,67,114,1) 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(94,109,143,1)), color-stop(100%,rgba(58,67,114,1))); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(top,  rgba(94,109,143,1) 0%,rgba(58,67,114,1) 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(top,  rgba(94,109,143,1) 0%,rgba(58,67,114,1) 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(top,  rgba(94,109,143,1) 0%,rgba(58,67,114,1) 100%); /* IE10+ */
            background: linear-gradient(to bottom,  rgba(94,109,143,1) 0%,rgba(58,67,114,1) 100%); /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5e6d8f', endColorstr='#3a4372',GradientType=0 ); /* IE6-8 */
            text-shadow: 0px 1px 1px rgba(0, 0, 0, 1);
        
        }
        .left-item .btn-more{
            margin-top: 7px;
        }`
                    }
                </style>

                <div id="content">
                    <div class="">

                        <link rel="stylesheet" type="text/css" media="screen,projection" href="{{ asset('styles/styleMktLodz.css') }}" />
                        <script src="{{ asset('js/stat.js') }}" type="text/javascript"></script>

                        <div class="courts_tre_info"><br />
                            <a href="http://mktlodz.pl/" target="_blank">

                            <p>← Powrót na mktlodz.pl</p> </a>
                        </div>

                        <div class="courts_tre_info"><br />


                            <strong>Podgląd wolnych terminów kortów w dniu: {formattedDate}</strong><span> </span><b>  </b>
                            <br /><br />

                            <span>Pełen grafik dostępny w aplikacji mobilnej:</span> <br /><br />
                            <a href="https://play.google.com/store/apps/details?id=pl.avroit.rezerwujkort.pl&hl=pl"> <img src={`/images/android.png`} /></a>
                            
                            <a href="https://apps.apple.com/us/app/rezerwujkort-pl/id6475010687?platform=iphone"> <img src={`/images/apple1.png`} /></a>
                            <br /><br />

                            <span>Oraz pod adresem:</span> <br />
                            <a href="https://www.rezerwujkort.pl/klub/mkt_lodz/rezerwacja_kortu_1" target="_blank">

                                <p> www.RezerwujKort.pl</p> </a>
                            <br />
                        </div>


                        <div id="court_rezervation">
                            <table>

                                <tr>
                                    <th></th>

                                    <th>Kort 1 </th>
                                    <td>&nbsp;</td>
                                    <th>Kort 2</th>
                                    <td>&nbsp;</td>
                                    <th>Kort 3 </th>
                                    <td>&nbsp;</td>
                                    <th>Kort 4 </th>
                                    <td>&nbsp;</td>
                                    <th>Kort 5 </th>
                                    <td>&nbsp;</td>
                                    <th>Kort 6 </th>
                                    <td>&nbsp;</td>
                                    <th>Kort 7 </th>
                                    <td>&nbsp;</td>
                                    <th>Kort 8 </th>
                                    <td>&nbsp;</td>
                                    <th>Kort 9 </th>
                                    <td>&nbsp;</td>
                                    <th>Kort 10 </th>
                                    <td>&nbsp;</td>
                                </tr>
                                {tableHours}

                            </table>
                        </div>

                        <br /><br />

                    </div>
                </div>

            </>
        );
    }
}

export default PageMktDzis;
