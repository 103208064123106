import AuthService from "../services/auth.service";

export default async function FetchPostDataWithAuthNEW(url, body) {

	var restData = {
		successful: false,
		status: '',
		message: '',
		data: []
	};

	var currentUser = AuthService.getCurrentUser();
	var token = "";
	if (currentUser != null) {
		token = currentUser.accessToken;
	}

	url = "/rest" + url;

	await fetch(url, {
		method: 'POST',
		withCredentials: true,
		credentials: 'include',
		headers: {
			'Authorization': 'Bearer ' + token,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(body),
	})
		.then(async response => {
			const isJson = response.headers.get('content-type')?.includes('application/json');
			let data = [];
			data = isJson && await response.json();

			if (response.status != 200) {
				restData.successful = false;
				restData.status = response.status;
				if (data !== undefined) {
					restData.message = data.message;
				} else {
					restData.message = "";
				}
			} else {
				restData.successful = true;
				restData.message = data.message;
				restData.status = response.status;
				restData.data = data;
			}
		}, error => {
			const resMessage =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();

			restData.successful = false;
			restData.message = resMessage;

			this.setState({
				successful: false,
				message: resMessage
			});
		});

	if (restData.status == 401) {
		AuthService.logout();
		window.location.href = "/login";
	}
	
	return restData;
}



