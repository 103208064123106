import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";


import AuthService from "../services/auth.service";

const BeltClassNewApp = React.lazy(() => import('../classAppComponents/BeltClassNewApp'));
const AddLesson = React.lazy(() => import('../classAppForms/AddLesson'));

class PageLessonAdd extends React.Component {

  state = {
    currentUser: AuthService.getCurrentUser(),
    data: [],
    currentPage: "1",
    rowsCount: "25",
    title: "RezerwujKort.pl - Szkółki tenisowe"
  }

  async componentDidMount() {

  }

  render() {
    var msg = this.props.location.state;
    var button = "";
    if (msg === undefined) {
      button = "";
    } else {
      button = <div class="tenis-center-content-info">+  {msg}  + </div>;
    }

    var beltClassApp = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <BeltClassNewApp club={this.props.match.params.club} />
      </Suspense>
    </div>

    var addLesson = <div>
    <Suspense fallback={<div>
      <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
    </div>}>
    <AddLesson
      club={this.props.match.params.club}
      idClass={this.props.match.params.idClass}
    />
    </Suspense>
  </div>

    return (

      <div>
        {beltClassApp}

        <div id="content">
          <div class="container">

            <br /><br />

            {button}

            <br /><br />
            <b>Dodaj nowe zajęcia </b>
            <br /><br />

            {addLesson}

          </div>
        </div>


      </div>
    );
  }
}
export default PageLessonAdd;
