import React, { Suspense } from 'react';
import { Spinner } from "react-bootstrap";

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';

const ChangePasswordForm = React.lazy(() => import('../forms/ChangePasswordForm'));

class ProfileChangePasswordPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: "RezerwujKort.pl - Profil"
    }
  }

  componentDidMount() {
    document.title = this.state.title

  }

  render() {

    var changePassword = <div>
      <Suspense fallback={<div>
        <Spinner style={{ 'borderStyle': 'dotted', 'marginLeft': '50%' }} animation="border" />
      </div>}>
        <ChangePasswordForm />
      </Suspense>
    </div>

    return (

      <div>

        <Belt />

        <BeltLogged />

        {changePassword}

        <Footer />

      </div>
    );
  }
}
export default ProfileChangePasswordPage;
