import React from 'react';

import Belt from '../components/Belt';
import BeltLogged from '../components/BeltLogged';

import Footer from '../components/Footer';
import history from "../components/history";
import FetchGetDataWithAuth from "../services/FetchGetDataWithAuth";

import DateTimeNow from "../utils/DateTimeNow";
import AuthService from "../services/auth.service";


class TrainerPage extends React.Component {

  state = {
    currentUser: AuthService.getCurrentUser(),
    dataAdverbTrainer: [],
    adverbCount: "",
    currentPage: "1",
    logInfo: "LogDebug __" + DateTimeNow() + "__ TrainerPage "
  }

  async fechTrainerAdverb(url) {
    var data;
    var token = "";
    if (this.state.currentUser != null) {
      token = this.state.currentUser.accessToken;
    }
    //console.log(this.state.logInfo + "fechSpraingPartnerAdverb start fech");//usun
    data = await FetchGetDataWithAuth(url, token);
    if (data !== undefined) {
      this.setState({ dataAdverbTrainer: data });
      if(data.status == 404){
        history.push('/brak_strony');
        window.location.reload();
      }
    }
    console.log(this.state.logInfo + "datadatadata = " + JSON.stringify(data));//usun
    //console.log(this.state.logInfo + "fechSpraingPartnerAdverb koniec fech aa = " + JSON.stringify(this.state.dataAdverbTrainer));//usun
  }

  componentDidMount() {

    var url = "/trainer/" + this.props.match.params.slug;
    this.fechTrainerAdverb(url)
  }

  render() {


    return (

      <div>

        <Belt />

        <BeltLogged />

        <div id="content">
          <div class="container">
            <div class="main-subpage subpage-user">
              <div class="head">
                <img src={`/images/login.png`} />
                <p>Trenerzy</p>
              </div>
              <div class="main-subpage-content">
                <div id="user-box-left">
                  <div class="user-fullname"> 
                    {this.state.dataAdverbTrainer.name} {' '}{this.state.dataAdverbTrainer.surname}</div>
                  <div class="user-photo">
                    {this.state.dataAdverbTrainer.image ? (
                      <img src={`/images/trainers/${this.state.dataAdverbTrainer.image}`} style={{ maxHeight: 300, maxWidth: 365 }} /> //todo dodax max foto width="300" height="365"
                    ) : (
                      <img src={`/images/trainers/default_sparing.png`} />  //todo dodax max foto width="300" height="365"
                    )}

                  </div>

                  <div class="user-icon-location user-icon semibold">{this.state.dataAdverbTrainer.userCity} {' '}
                    ({this.state.dataAdverbTrainer.userProvince})</div>
                  <div class="user-icon-contact user-icon semibold">
                    {this.state.dataAdverbTrainer.contakt}
                  </div>
                  <div class="user-icon-mail user-icon semibold">
                    {this.state.dataAdverbTrainer.contakt}
                  </div>
                  <div style={{ clear: 'both' }}></div>
                </div>
                <div id="user-box-right">
                  <div class="user-box-right-nag">Trener o sobie:</div>
                  <div class="user-box-right-desc">{this.state.dataAdverbTrainer.description}</div>
                  <div class="user-box-right-nag">Miejsce prowadzenia zajęć:</div>
                  <div class="user-box-right-desc">1. <a href={"/klub/" + this.state.dataAdverbTrainer.clubWww}>{this.state.dataAdverbTrainer.clubName}</a> {/*  //todo link do klubu  */}
                    {this.state.dataAdverbTrainer.club2Name != this.state.dataAdverbTrainer.club1Name ? (
                      <c><br /><a href="xxx">  {this.state.dataAdverbTrainer.club2Name}</a></c>
                    ) : (
                      <a></a>
                    )}

                    {(this.state.dataAdverbTrainer.club3Name != this.state.dataAdverbTrainer.clubName) &&
                      (this.state.dataAdverbTrainer.club3Name != this.state.dataAdverbTrainer.club2Name) ? (
                      <c><br /><a href="xxx">  {this.state.dataAdverbTrainer.club3Name}</a></c>
                    ) : (
                      <a></a>
                    )}
                  </div>
                  <div class="user-box-right-nag">Uprawnienia:</div>
                  <div class="user-box-right-desc">{this.state.dataAdverbTrainer.permissions}</div>
                </div>
                <div style={{ clear: 'both' }}></div>
              </div>
            </div>
          </div>
        </div>

        <Footer />

      </div>

    );
  }
}

export default TrainerPage;
