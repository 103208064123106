import React from "react";
import { Redirect } from "react-router-dom";

// import Form from "react-validation/build/form";
// import Input from "react-validation/build/input";
// import CheckButton from "react-validation/build/button";

import { Col, Form, Button } from "react-bootstrap";
import AuthService from "../services/auth.service";
import FechPostLogin from "../fechData/FechPostLogin";

//UTILS
import DateTimeNow from "../utils/DateTimeNow";

import ValidationFormField from "../utils/ValidationFormField";

class LoginFormNew extends React.Component {
  emptyItem = {
    email: "",
    password: "",
    remember: false,
  };

  //export default class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      logInfo: "LogDebug __" + DateTimeNow() + "__ LoginFormNew ",
      currentUser: AuthService.getCurrentUser(),
      item: this.emptyItem,
      isError: {
        email: "",
        password: "",
      },
    };
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let item = { ...this.state.item };

    // if(name === 'remember' && value === 'on'){
    //   item[name] = true;
    // }else if(name === 'remember' && value === 'off'){
    //   item[name] = false;
    // }else{
    if (name === "remember") {
      item["remember"] = !item.remember;
    } else {
      item[name] = value;
    }

    // }

    this.setState({ item: item });

    //this.fieldValidation(name, value);
  }

  async handleLogin(event) {
    event.preventDefault();

    for (var key in this.state.item) {
      this.fieldValidation(key, this.state.item[key]);
    }

    if (ValidationFormField.formValid(this.state.isError)) {
      var restLoginResponseData = await FechPostLogin(this.state.item);

      let isError = { ...this.state.isError };

      if (restLoginResponseData.successful) {
        localStorage.setItem(
          "user",
          JSON.stringify(restLoginResponseData.data)
        );
        window.location.reload();
      } else if (restLoginResponseData.status === 401) {
        isError.password = "Błędny login lub hasło.";
        this.setState({ isError: isError });
      } else if (restLoginResponseData.message === "Error: Konto usunięte!") {
        isError.password = "Konto usunięte.";
        this.setState({ isError: isError });
      } else if (
        restLoginResponseData.message != null &&
        restLoginResponseData.message != ""
      ) {
        isError.password = restLoginResponseData.message;
        this.setState({ isError: isError });
      } else {
        isError.password = "Wystąpił błąd logowania.";
        this.setState({ isError: isError });
      }

      //history.push('/class_app/' + this.props.club + '/class/1', "Nowe zajęcia zostały dodane");
      //window.location.reload();
    } else {
      console.log("walid : Form is invalid!"); //usun
    }

    //FechPostLogin(this.state.username, this.state.password)

    // if (this.checkBtn.context._errors.length === 0) {
    //   AuthService.login(this.state.username, this.state.password).then(
    //     () => {
    //       //this.props.history.push("/profile");
    //       //this.props.router.push('/profile')
    //       //this.props.history.push('/');
    //       window.location.reload();
    //     },
    //     error => {
    //       // console.log(this.state.logInfo + ".response " + JSON.stringify(error.response)); //usun
    //       // console.log(this.state.logInfo + "error.response.data " + JSON.stringify(error.response.data)); //usun
    //       // console.log(this.state.logInfo + "error.response.data.message " + JSON.stringify(error.response.data.message)); //usun
    //       // console.log(this.state.logInfo + ".error.message " + JSON.stringify(error.message)); //usun
    //       // console.log(this.state.logInfo + "error.toString() " + JSON.stringify(error.toString())); //usun
    //       const resMessage =
    //         (error.response &&
    //           error.response.data &&
    //           error.response.data.message) ||
    //         error.message ||
    //         error.toString();

    //       this.setState({
    //         loading: false,
    //         message: resMessage
    //       });
    //     }
    //   );
    // } else {
    //   this.setState({
    //     loading: false
    //   });
    // }
  }

  fieldValidation(name, value, compareValue) {
    let isError = { ...this.state.isError };

    switch (name) {
      case "email":
        isError.email = ValidationFormField.validNotEmpty("Email", value);
        break;
      case "password":
        isError.password = ValidationFormField.validNotEmpty("Hasło", value);
        break;
      default:
        break;
    }
    this.setState({
      isError,
      [name]: value,
    });
    this.state.isError[name] = isError[name];
  }

  render() {
    const { item } = this.state;
    const { isError } = this.state;
    const { currentUser } = this.state;

    var loginDesc = <div class="pass-icon-rem1"> Nie wylogowuj mnie </div>;

    if (currentUser !== null) {
      return <Redirect to="/profile" />;
    } else {
      return (
        <div id="content">
          <div class="container">
            {isError.email.length > 0 && (
              <div>
                <br />
                <div className="alert alert-danger" role="alert">
                  {isError.email}
                </div>
              </div>
            )}

            {isError.password.length > 0 && (
              <div>
                <br />
                <div className="alert alert-danger" role="alert">
                  {isError.password}
                </div>
              </div>
            )}

            <div id="login">
              <Form
                onSubmit={this.handleLogin}
                ref={(c) => {
                  this.form = c;
                }}
              >
                <input type="hidden" name="_csrf_token" value="" />
                <div class="head">
                  <img
                    src={process.env.PUBLIC_URL + "/images/login.png"}
                    alt=""
                  />
                  <p>Logowanie</p>
                </div>
                <div class="sub-content">
                  <div class="login-item">
                    <div class="login-icon">
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="e-mail"
                        value={item.email || ""}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div class="login-item">
                    <div class="pass-icon">
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        value={item.password || ""}
                        placeholder="hasło"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div class="login-item">
                    <div class="pass-icon-rem">
                      <input
                        type="checkbox"
                        id="remember"
                        name="remember"
                        // value={item.remember}
                        checked={item.remember}
                        onChange={this.handleChange}
                      />
                      {loginDesc}
                    </div>
                  </div>
                  <div class="login-button">
                    <button
                      className="btn btn-primary btn-block"
                      disabled={this.state.loading}
                    >
                      {this.state.loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Login</span>
                    </button>
                  </div>

                  <br />
                  <br />
                  <div class="login-text">
                    <div class="login-text-big">Nie masz jeszcze konta?</div>
                    <div class="login-text-small">
                      Wypełnij <a href={"/register"}>formularz</a> i dołącz do
                      użytkowników naszego portalu
                    </div>
                    <br />
                    <div class="login-text-big">Nie pamiętasz hasła?</div>
                    <div class="login-text-small">
                      Kliknij <a href={"/forgot_password"}>tutaj</a> aby wysłać
                      przypomnienie
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default LoginFormNew;
